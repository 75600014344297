

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":["th"]},
  vueI18nLoader: false,
  locales: [{"name":"Thai","code":"th","iso":"th-TH","file":"th-TH.js"},{"name":"English","code":"en","iso":"en-US","file":"en-US.js"}],
  defaultLocale: "th",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/app/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"Thai","code":"th","iso":"th-TH","file":"th-TH.js"},{"name":"English","code":"en","iso":"en-US","file":"en-US.js"}],
  localeCodes: ["th","en"],
}

export const localeMessages = {
  'th-TH.js': () => import('../../lang/th-TH.js' /* webpackChunkName: "lang-th-TH.js" */),
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
}
