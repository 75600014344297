/**
 * Get convertToPriceFormat
 * @param {Int} number
 * @returns string 1344120000.123455 --> 1,344,120,000.12
 */
const convertToPriceFormat = (number) => {
  let result = 0
  if (number) {
    result = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  return result
}
/**
 * Get convertToMillionFormat
 * @param {Int} number
 * @returns string 1344120000 --> 1,344.12
 */
const convertToMillionFormat = (number) => {
  let result = 0
  if (number) {
    result = number / 1000000
    result = convertToPriceFormat(result)
  }
  return result
}

const formatNumber = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    })
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}

const formatNumberWithCommas = (value) => {
  // การใช้ value.toLocaleString() มีดารตัดทศนิยมที่ไม่จำเป็นโดยอัตโนมัติ
  if (typeof value === 'number') {
    const numberString = value.toString()
    const parts = numberString.split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}

const formatNumberInteger = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value > 0
      ? '+' +
          value.toLocaleString(undefined, {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
          })
      : value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      })
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}

const formatNumberPercent = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value > 0
      ? '+' +
          value.toLocaleString(undefined, {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
          }) +
          '%'
      : value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }) + '%'
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}

const validateNumber = (evt) => {
  evt = evt || window.event
  const charCode = evt.which ? evt.which : evt.keyCode
  if (
    (charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode !== 46 &&
      charCode !== 37) ||
    (evt.target.value.includes('.') && evt.key === '.')
  ) {
    evt.preventDefault()
  } else {
    return true
  }
}

const formatDividend = (value) => {
  if (typeof value === 'number') {
    const fractionPart = (value + '').split('.')[1]
    return fractionPart && fractionPart.length > 2 ? value + '' : formatNumber(value)
  } else {
    return formatNumber(value)
  }
}
const formatExercisePrice = (value) => {
  if (typeof value === 'number') {
    const fractionPart = (value + '').split('.')[1]
    if (!fractionPart || fractionPart.length <= 2) {
      return formatNumber(value, 2)
    } else if (fractionPart.length <= 4) {
      return formatNumber(value, 4)
    } else {
      return value + ''
    }
  } else {
    return formatNumber(value)
  }
}
const formatNumberX = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: digits,
      maximumFractionDigits: 4
    })
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}

/**
 * Remove commas
 * @param {Stirng|Number} number
 * @returns number
 */
const removeCommas = (number) => {
  if (typeof number === 'string') {
    return Number(number.replace(/,/g, ''))
  }
  return number
}

/**
 * Remove commas
 * @param {Stirng|Number} number
 * @returns number
 */
const quoteParValueFormat = (number) => {
  let num = number
  let resultNumber = null
  if (typeof num === 'string' && num !== '-') {
    num = parseFloat(number)
  }
  if (typeof num === 'number') {
    const digitsPrice = num && num.toString().split('.')[1]
    resultNumber = null
    if (digitsPrice && (digitsPrice.length === 1)) {
      const digits = digitsPrice.length + 1
      resultNumber = formatNumber(num, digits)
    } else if (digitsPrice && (digitsPrice.length >= 2)) {
      const digits = digitsPrice.length
      resultNumber = formatNumber(num, digits)
    } else {
      resultNumber = formatNumber(num, 2)
    }
    return resultNumber
  } else if (num === null || num === undefined || num === '') {
    return '-'
  } else {
    return num
  }
}

export {
  convertToPriceFormat,
  convertToMillionFormat,
  formatNumber,
  formatNumberWithCommas,
  formatNumberInteger,
  formatNumberPercent,
  validateNumber,
  formatDividend,
  formatExercisePrice,
  formatNumberX,
  removeCommas,
  quoteParValueFormat
}
