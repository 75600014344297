import { marketListId } from '@/utils/marketlist'
const state = () => ({
  stockData: null,
  unitTrustData: null,
  etfData: null,
  dwData: null,
  drData: null,
  drxData: null,
  futureData: null,
  optionData: null,
  fundData: null
})

const actions = {
  async fetchQuotelist ({ commit, rootState }, payload) {
    const equityList = rootState.search.equityList
    const fundList = rootState.search.fundList
    const tfexList = rootState.search.tfexList

    if (payload.securityType.toLowerCase() === 'fund') {
      commit('setFundData', fundList)
    } else if (payload.securityType.toLowerCase() === 'fu') {
      const futureList = tfexList.filter((data) => {
        return (
          data.marketListId === marketListId.IF ||
          data.marketListId === marketListId.SF ||
          data.marketListId === marketListId.PMF ||
          data.marketListId === marketListId.DPM ||
          data.marketListId === marketListId.CF ||
          data.marketListId === marketListId.IRF ||
          data.marketListId === marketListId.AF ||
          data.marketListId === marketListId.TXI_F ||
          data.marketListId === marketListId.TXS_F ||
          data.marketListId === marketListId.TXM_F ||
          data.marketListId === marketListId.TXD_F ||
          data.marketListId === marketListId.TXC_F ||
          data.marketListId === marketListId.TXR_F ||
          data.marketListId === marketListId.TXA_F
        )
      })
      commit('setFutureData', futureList)
    } else if (payload.securityType.toLowerCase() === 'ot') {
      const futureList = tfexList.filter((data) => {
        return (
          data.marketListId === marketListId.IO ||
          data.marketListId === marketListId.TXI_O ||
          data.marketListId === marketListId.TXI_C ||
          data.marketListId === marketListId.TXI_P
        )
      })
      commit('setOptionData', futureList)
    } else {
      const securityType = payload.securityType.toLowerCase()
      const stockList = equityList.filter(
        item => item.securityType.toLowerCase() === securityType
      )
      if (payload.securityType.toLowerCase() === 's') {
        commit('setStockData', stockList)
      } else if (payload.securityType.toLowerCase() === 'u') {
        commit('setUnitTrustData', stockList)
      } else if (payload.securityType.toLowerCase() === 'l') {
        commit('setETFData', stockList)
      } else if (payload.securityType.toLowerCase() === 'x') {
        commit('setDRData', stockList)
      } else if (payload.securityType.toLowerCase() === 'xf') {
        commit('setDRXData', stockList)
      } else if (securityType === 'v') {
        await this.$axios
          .get('api/set/dw-info/list')
          .then(async (resDwList) => {
            const dwData = await stockList.map((data) => {
              const dwData = resDwList.data.dwList.find(
                dw => dw.symbol === data.symbol
              )
              return {
                ...data,
                ...dwData
              }
            })
            commit('setDWData', dwData)
          })
          .catch(err => err)
      }
    }
  }
}

const mutations = {
  setStockData (state, data) {
    state.stockData = data
  },
  setUnitTrustData (state, data) {
    state.unitTrustData = data
  },
  setETFData (state, data) {
    state.etfData = data
  },
  setOptionData (state, data) {
    state.optionData = data
  },
  setDWData (state, data) {
    state.dwData = data
  },
  setDRData (state, data) {
    state.drData = data
  },
  setDRXData (state, data) {
    state.drxData = data
  },
  setFutureData (state, data) {
    state.futureData = data
  },
  setFundData (state, data) {
    state.fundData = data
  }
}

const getters = {
  getStockData: (state) => {
    return state.stockData
  },
  getUnitTrustData: (state) => {
    return state.unitTrustData
  },
  getETFData: (state) => {
    return state.etfData
  },
  getDWData: (state) => {
    return state.dwData
  },
  getDRData: (state) => {
    return state.drData
  },
  getDRXData: (state) => {
    return state.drxData
  },
  getFutureData: (state) => {
    return state.futureData
  },
  getOptionData: (state) => {
    return state.optionData
  },
  getFundData: (state) => {
    return state.fundData
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
