//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconTriangle'
  // serverCacheKey: () => 'IconTriangle-v1'
}
