import { render, staticRenderFns } from "./FTTopExpandable.vue?vue&type=template&id=5b2ff812&"
import script from "./FTTopExpandable.vue?vue&type=script&lang=js&"
export * from "./FTTopExpandable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/app/components/Banner/Banner.vue').default})
