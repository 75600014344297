function elementObserver (config, callback) {
  const { observeConfig, parent, nodeId } = config
  const _observeConfig = observeConfig || { subtree: false, childList: true }
  const elementToObserve = parent
  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach(function (mutation) {
      mutation.addedNodes.forEach(function (addedNode) {
        if (addedNode.id === nodeId) {
          callback(addedNode)
          // console.log('#child has been added')
          observer.disconnect()
        }
      })
    })
  })
  observer.observe(elementToObserve, _observeConfig)
}
function elementAttributeObserver (config, callback) {
  const { observeConfig, el } = config
  const _observeConfig = observeConfig || { subtree: false, childList: true }
  const elementToObserve = el
  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach(function (mutation) {
      if (mutation.type === 'attributes') {
        callback(mutation)
        // observer.disconnect()
      }
    })
  })
  observer.observe(elementToObserve, _observeConfig)
}

export {
  elementObserver,
  elementAttributeObserver
}
