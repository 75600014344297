/**
 * State
 */
const state = () => {
  return {
    logS: [],
    logC: []
  }
}

/**
 * Mutations
 */
const mutations = {
  addLog (state, data) {
    if (process.server) {
      state.logS.push(data)
    } else {
      state.logC.push(data)
    }
  }
}

/**
 * Actions
 */
const actions = {}

/**
 * Getters
 */
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
