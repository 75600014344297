const getDefaultState = () => {
  return {
    stockList: [],
    mutalFundList: [],
    stockData: {},
    mutalFundData: {},
    symbolData: {}
  }
}

const state = getDefaultState

const mutations = {
  setStockList (state, data) {
    state.stockList = data.securitySymbols
  },
  setMutalFund (state, data) {
    state.mutalFundList = data.funds
  },
  setStockData (state, data) {
    state.stockData = data
  },
  setMutalFundData (state, data) {
    state.mutalFundData = data
  },
  setSymbolData (state, data) {
    state.symbolData = data
  }
}

const actions = {
  async getSymbolData ({ commit }, symbol) {
    const response = await this.$axios.$get(`/api/set/stock/${symbol}/info`)
    commit('setSymbolData', response)
  },
  async getStockList ({ commit }) {
    const response = await this.$axios.$get('/api/set/stock/list', {
      params: {}
    })
    commit('setStockList', response)
  },
  // async getStockData ({ commit }, params) {
  //   const response = await this.$apiMember
  //     .$get('/api/set-fund/back-testing/stock', {
  //       params
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  //   commit('setStockData', response)
  // },
  async getMutalFund ({ commit }) {
    const response = await this.$axios.$get(
      '/api/set-fund/fund/active-fund/list',
      {
        params: {
          securityType: 'fund'
        }
      }
    )
    commit('setMutalFund', response)
  }
  // async getMutalFundData ({ commit }, params) {
  //   const response = await this.$apiMember
  //     .$get('/api/set-fund/back-testing/fund', {
  //       params
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  //   commit('setMutalFundData', response)
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
