const colors = [
  '#048415',
  '#0AA60D',
  '#66CC74',
  '#A9E7B1',

  '#1A8B89',
  '#2EB9B7',
  '#5CD3D2',
  '#9FEBEA',

  '#0E5F9A',
  '#2D82BF',
  '#67ABDD',
  '#9ECCEE',

  '#5C01CF',
  '#843CF8',
  '#B088F1',
  '#D6BDFF',

  '#CC2929',
  '#EB2727',
  '#F07A7A',
  '#F6A4A4',

  '#B75A12',
  '#DE781B',
  '#EE8E37',
  '#F3B276',

  '#CBA009',
  '#E0C119',
  '#F5D83F',

  '#898F80',
  '#A7AE9E',
  '#C2C9B9'
]
function getColor (index) {
  return index > colors.length ? colors[0] : colors[index]
}
function sumByColumn (items, key) {
  return items.reduce((accumulator, current) => {
    if (current[key]) {
      return +accumulator + +current[key]
    } else {
      return +accumulator
    }
  }, 0)
}
function getCategoryText (category) {
  switch (category) {
    case 'equity':
      return 'หุ้น'
    case 'tfex':
      return 'อนุพันธ์'
    case 'fund':
      return 'กองทุนรวม'
    default:
      return '-'
  }
}

export { getColor, sumByColumn, getCategoryText }
