import comparison from './comparison/index'
import calendar from './calendar/index'
import tradingQuotationBySeries from './marketdata/tradingQuotationBySeries/index'
import historicalData from './marketdata/historicalData/index'
import investorType from './marketdata/investorType/index'
import { formatNumber } from '~/helpers/number'

export const modules = {
  calendar,
  comparison,
  tradingQuotationBySeries,
  historicalData,
  investorType
}

const state = () => {
  return {
    options: {
      futures: [],
      marketList: [],
      underlyingSector: []
    },
    summary: {
      marketSummary: {},
      investor: []
    },
    activeFund: [],
    topranking: {
      mostActiveVolume: [],
      mostActiveOi: [],
      topGainer: [],
      topLoser: []
    }
  }
}

const mutations = {
  setDerivativeMarketSummary (state, data) {
    state.summary.marketSummary = {
      marketTime: data.marketTime,
      items: [
        {
          name: 'Total Futures',
          volume: data.totalFutures.volume,
          oi: data.totalFutures.oi,
          isCollapse: true,
          items: data.futures
        },
        {
          name: 'Total Options',
          volume: data.totalOptions.volume,
          oi: data.totalOptions.oi,
          isCollapse: true,
          items: [
            {
              name: 'Call',
              volume: data.options[0].call.volume,
              oi: data.options[0].call.oi
            },
            {
              name: 'Put',
              volume: data.options[0].put.volume,
              oi: data.options[0].put.oi
            },
            {
              name: 'Put / Call',
              volume: formatNumber(data.options[0].pcRatio.volume),
              oi: formatNumber(data.options[0].pcRatio.oi)
            }
          ]
        }
      ],
      totalMarket: data.totalMarket
    }
  },
  setDerivativeFuturesList (state, data) {
    state.options.futures = data.map((item) => {
      return {
        label: item.name,
        value: item.marketList,
        ...item
      }
    })
  },
  setDerivativeInvestor (state, data) {
    state.summary.investor = data
  },
  setDerivativeMarketList (state, data) {
    state.options.marketList = data.map((item) => {
      return {
        label: item.name,
        value: item.marketList,
        ...item
      }
    })
  },
  setDerivativeUnderlyingSector (state, data) {
    state.options.underlyingSector = data.map((item) => {
      return {
        label: item.id,
        value: item.id,
        ...item
      }
    })
  },
  setDerivativeMostActiveVolume (state, data) {
    state.topranking.mostActiveVolume = data
  },
  setDerivativeMostActiveOI (state, data) {
    state.topranking.mostActiveOi = data
  },
  setDerivativeTopGainer (state, data) {
    state.topranking.topGainer = data
  },
  setDerivativeTopLoser (state, data) {
    state.topranking.topLoser = data
  },
  setDerivativeActiveFund (state, data) {
    state.activeFund = data
  }
}

const actions = {
  async getDerivativeMarketSummary ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/summary')
      commit('setDerivativeMarketSummary', response)
    } catch (error) {
      commit('setDerivativeMarketSummary', null)
    }
  },
  async getDerivativeFuturesList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/list?type=futures')
      commit('setDerivativeFuturesList', response)
    } catch (error) {
      commit('setDerivativeFuturesList', null)
    }
  },
  async getDerivativeInvestor ({ commit }, payload) {
    const { type, marketList } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/market/investor-type?type=${type}${marketList ? `&marketList=${marketList}` : ''}`)
      commit('setDerivativeInvestor', response)
    } catch (error) {
      commit('setDerivativeInvestor', null)
    }
  },
  async getDerivativeMarketList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/list')
      commit('setDerivativeMarketList', response)
    } catch (error) {
      commit('setDerivativeMarketList', null)
    }
  },
  async getDerivativeUnderlyingSector ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/SF/underlying-sector')
      commit('setDerivativeUnderlyingSector', response)
    } catch (error) {
      commit('setDerivativeUnderlyingSector', null)
    }
  },
  async getDerivativeMostActiveVolume ({ commit }, payload) {
    const { marketList, underlyingSector, moneynessStatus } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/marketlist/${marketList}/top-ranking?rankingType=mostActiveVolume&limit=5${underlyingSector ? `&underlyingSector=${underlyingSector}` : ''}${moneynessStatus ? `&moneynessStatus=${moneynessStatus}` : ''}`)
      commit('setDerivativeMostActiveVolume', response)
    } catch (error) {
      commit('setDerivativeMostActiveVolume', null)
    }
  },
  async getDerivativeMostActiveOI ({ commit }, payload) {
    const { marketList, underlyingSector, moneynessStatus } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/marketlist/${marketList}/top-ranking?rankingType=mostActiveOI&limit=5${underlyingSector ? `&underlyingSector=${underlyingSector}` : ''}${moneynessStatus ? `&moneynessStatus=${moneynessStatus}` : ''}`)
      commit('setDerivativeMostActiveOI', response)
    } catch (error) {
      commit('setDerivativeMostActiveOI', null)
    }
  },
  async getDerivativeTopGainer ({ commit }, payload) {
    const { marketList, underlyingSector, moneynessStatus } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/marketlist/${marketList}/top-ranking?rankingType=topGainer&limit=5${underlyingSector ? `&underlyingSector=${underlyingSector}` : ''}${moneynessStatus ? `&moneynessStatus=${moneynessStatus}` : ''}`)
      commit('setDerivativeTopGainer', response)
    } catch (error) {
      commit('setDerivativeTopGainer', null)
    }
  },
  async getDerivativeTopLoser ({ commit }, payload) {
    const { marketList, underlyingSector, moneynessStatus } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/marketlist/${marketList}/top-ranking?rankingType=topLoser&limit=5${underlyingSector ? `&underlyingSector=${underlyingSector}` : ''}${moneynessStatus ? `&moneynessStatus=${moneynessStatus}` : ''}`)
      commit('setDerivativeTopLoser', response)
    } catch (error) {
      commit('setDerivativeTopLoser', null)
    }
  },
  async getDerivativeActiveFund ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set-fund/consensus/tfex/overall?type=today')
      commit('setDerivativeActiveFund', response)
    } catch (error) {
      commit('setDerivativeActiveFund', null)
    }
  }
}

const getters = {
  getDerivativeMarketSummary: (state) => {
    return state.summary.marketSummary
  },
  getDerivativeFuturesList: (state) => {
    return state.options.futures
  },
  getDerivativeInvestor: (state) => {
    return state.summary.investor
  },
  getDerivativeMarketList: (state) => {
    return state.options.marketList
  },
  getDerivativeUnderlyingSector: (state) => {
    return state.options.underlyingSector
  },
  getDerivativeMostActiveVolume: (state) => {
    return state.topranking.mostActiveVolume
  },
  getDerivativeMostActiveOI: (state) => {
    return state.topranking.mostActiveOi
  },
  getDerivativeTopGainer: (state) => {
    return state.topranking.topGainer
  },
  getDerivativeTopLoser: (state) => {
    return state.topranking.topLoser
  },
  getDerivativeActiveFund: (state) => {
    return state.activeFund
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
