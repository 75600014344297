const getDefaultState = () => {
  return {
    info: null,
    report: {
      annualReport: []
    },
    fundInfo: null,
    financialStatement: null
  }
}

const state = getDefaultState

const mutations = {
  setInfo (state, data) {
    state.info = data
  },

  setReport (state, data) {
    state.report = data
  },

  setFundInfo (state, data) {
    state.fundInfo = data
  },

  setFinancialStatement (state, data) {
    state.financialStatement = data
  }
}

const actions = {
  async getInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const companyInfo = await this.$axios.$get(
        `api/set/company/${quote}/profile`,
        {
          params: {
            lang: this.$i18n.locale
          }
        }
      )

      commit('setInfo', companyInfo)
    } catch {
      commit('setInfo', null)
    }
  },

  async getReport ({ commit }, payload) {
    const { quote } = payload
    try {
      const annualReport = await this.$axios.$get(
        `api/set/company/${quote}/report/annual`
      )

      const companyReport = await Promise.allSettled([annualReport]).then(
        (data) => {
          return {
            annualReport: data[0].value
          }
        }
      )

      commit('setReport', companyReport)
    } catch {
      commit('setReport', null)
    }
  },

  async getFundInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set-fund/fund-profile/${encodeURIComponent(encodeURIComponent(quote))}/info`
      )
      commit('setFundInfo', response)
    } catch {
      commit('setFundInfo', null)
    }
  },

  async getFinancialStatement ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/financialstatement`,
        {
          params: {
            accountType: 'balance_sheet', // balance_sheet | income_statement | cash_flow
            // fsType, // company | consolidate
            // period, // Q9_2019, Q3_2019
            language: this.$i18n.locale
          }
        }
      )
      commit('setFinancialStatement', response)
    } catch {
      commit('setFinancialStatement', null)
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
