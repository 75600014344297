//
//
//
//

export default {
  name: 'BannerLocationFTTopExpandable',
  data () {
    return {
      bannerData: null,
      locationName: 'B_FT_TOP_EXPANDABLE'
    }
  },
  async created () {
    await this.setBannerData()
  },
  methods: {
    async setBannerData () {
      this.bannerData = await this.$content.getHomeBannerLocation(this.locationName)
    }
  }
}
