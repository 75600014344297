const getDefaultState = () => {
  return {
    info: null,
    shareholders: null,
    boardDirector: [],
    reportWarrantsTerm: null
  }
}

const state = getDefaultState

const mutations = {
  setInfo (state, data) {
    state.info = data
  },

  setShareholders (state, data) {
    if (data) {
      data.majorShareholders = data.majorShareholders.slice(0, 10)
      state.shareholders = data
    }
  },

  setBoardDirector (state, data) {
    if (data) {
      const temp = []
      let order = 1
      for (const director of data) {
        let positionIndex = 1
        for (const position of director.positions) {
          temp.push({
            order,
            positionIndex,
            name: positionIndex <= 1 ? director.name : '',
            position
          })
          positionIndex++
        }
        order++
      }
      state.boardDirector = temp
    }
  },

  setReportWarrantsTerm (state, data) {
    state.reportWarrantsTerm = data
  }
}

const actions = {
  async getInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const companyInfo = await this.$axios.$get(
        `api/set/company/${quote}/profile`,
        {
          params: {
            lang: this.$i18n.locale
          }
        }
      )

      commit('setInfo', companyInfo)
    } catch {
      commit('setInfo', null)
    }
  },

  async getShareholders ({ commit }, payload) {
    const { quote, lang } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/shareholder?lang=${lang}`
      )

      commit('setShareholders', response)
    } catch {
      commit('setShareholders', null)
    }
  },

  async getBoardDirector ({ commit }, payload) {
    const { quote } = payload
    try {
      const boardDirector = await this.$axios.$get(
        `api/set/company/${quote}/board-of-director`
      )

      commit('setBoardDirector', boardDirector)
    } catch {
      commit('setBoardDirector', null)
    }
  },

  async getReportWarrantsTerm ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `api/set/stock/${quote}/report/warrants-term`
      )

      commit('setReportWarrantsTerm', response)
    } catch {
      commit('setReportWarrantsTerm', null)
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
