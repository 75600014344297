//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'InputCheckBox',
  props: {
    size: {
      type: String,
      default: '' // md, sm
    },
    label: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  // mounted () {
  //   this.uncheck() // uncheck checkbox button
  // },
  methods: {
    async uncheck () {
      const elements = await document.querySelectorAll('input[type="checkbox"]')
      for (let i = 0; i < elements.length; i++) {
        const el = elements[i]
        el.checked = false
      }
    }
  }
}
