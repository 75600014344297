//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconCloseNoti'
}
