//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { showRequestNum, hideRequestNum } from '@/directives/modal'
export default {
  name: 'MiscMemberVirtualPortfolioModalOrderMutualFund',
  directives: {
    showRequestNum,
    hideRequestNum
  },
  props: {
    showRequestNum: {
      type: Number,
      default: 0
    },
    symbolList: {
      type: Array,
      default: () => [
        { symbol: '1DIV', nameTH: '1DIV', nameEN: '1DIV', securityType: 'S' },
        { symbol: 'B-LTF', nameTH: 'B-LTF', nameEN: 'B-LTF', securityType: 'W' },
        { symbol: 'SCB-P', nameTH: 'ไทยพาณิชย์ X บุริมสิทธิ์', nameEN: 'Siam Commercial Bank X Preferred', securityType: 'P' },
        { symbol: 'SCB-F', nameTH: 'ไทยพาณิชย์ X ต่างชาติ', nameEN: 'Siam Commercial Bank X Foreign', securityType: 'F' }
      ]
    }
  },
  data () {
    return {
      disableBuy: false,
      hideRequestNum: 0,
      activeMode: 'value',
      modes: [
        { text: 'จำนวนเงินทุน', value: 'value', controls: '', disabled: false },
        { text: 'จำนวนหน่วย', value: 'volume', controls: '', disabled: false }
      ],
      formData: {},
      validationState: {
        symbol: {},
        nav: {},
        amount: {}
      },
      lockBtn: false
    }
  },
  computed: {
    ...mapGetters({
      // สำหรับ delete deactive symbol
      // That's need to pass order data from store
      orderFundData: 'member/virtual-portfolio/getModalOrderFundData'
    }),
    outstandingSecurities () {
      return this.$virtualPort.state.fund?.portfolios?.map(x => ({ symbol: x.symbol, volume: x.unit })) ?? []
    },
    effectiveSymbolList () {
      const outstandingSecurities = this.outstandingSecurities
      return this.orderType === 2 ? this.symbolList.filter(x => outstandingSecurities.find(y => y.symbol === x.symbol)) : this.symbolList
    },
    orderType () {
      return this.formData.orderType ?? 1
    },
    value () {
      const nav = +(this.formData.nav ?? 0)
      const amount = +(this.formData.amount ?? 0)
      if (this.activeMode === 'value') {
        return nav !== 0 ? amount / nav : 0
      } else {
        return nav * amount
      }
    },
    disableSell () {
      return this.outstandingSecurities.length === 0
    }
  },
  watch: {
    showRequestNum (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetFormData()
      }
    },
    'formData.orderType' () {
      // this.formData.symbol = undefined
    },
    'formData.symbol' () {
      this.validationState.symbol.errorLevel = 0
      this.validationState.symbol.errorType = undefined
    },
    'formData.nav' () {
      this.validationState.nav.errorLevel = 0
      this.validationState.nav.errorType = undefined
    },
    'formData.amount' () {
      this.validationState.amount.errorLevel = 0
      this.validationState.amount.errorType = undefined
    }
  },
  methods: {
    async submit () {
      let validatedFail = false
      const { symbol, nav, amount } = this.formData
      const orderType = this.orderType
      const value = this.value
      const activeMode = this.activeMode
      const validationState = this.validationState
      const outstandingSecurityCount = this.outstandingSecurities.length
      const outstandingSecurity = this.outstandingSecurities.find(x => x.symbol === symbol)
      checkSymbol()
      checkNAV()
      checkAmount()
      if (!validatedFail) {
        const order = {
          transactionType: orderType === 2 ? 'sell' : 'buy',
          symbol,
          type: activeMode === 'volume' ? 'unit' : 'money',
          volume: amount,
          nav
        }
        this.lockBtn = true
        const result = await this.$virtualPort.sendOrder('fund', order)
        if (result) {
          this.$toast.success('success', 'ส่งคำสั่งสำเร็จ')
          this.$emit('onSubmitSuccess', symbol)
          this.hideModal()
          this.dotry(() =>
            this.$personalized.clickElement({
              name: 'memberVirtualPortfolio4',
              optionLang: { category: 'กองทุนรวม' }
            })
          )
        } else {
          this.$toast.fail('warning', 'เกิดข้อผิดพลาด')
        }
        this.lockBtn = false
      }

      function checkSymbol () {
        if (!symbol || symbol === '') {
          validatedFail = true
          validationState.symbol.errorLevel = 2
          validationState.symbol.errorType = 'required'
          return  //eslint-disable-line
        }
        if (orderType === 1 && outstandingSecurityCount >= 30 && !outstandingSecurity) {
          validatedFail = true
          validationState.symbol.errorLevel = 2
          validationState.symbol.errorType = 'symbolLimitExceeded'
          return  //eslint-disable-line
        }
      }

      function checkNAV () {
        if (!nav || +nav === 0) {
          validatedFail = true
          validationState.nav.errorLevel = 2
          validationState.nav.errorType = 'required'
          return  //eslint-disable-line
        }
      }

      function checkAmount () {
        if (!amount || +amount === 0) {
          validatedFail = true
          validationState.amount.errorLevel = 2
          validationState.amount.errorType = 'required'
          return  //eslint-disable-line
        }
        if (orderType === 2 && outstandingSecurity) {
          if (activeMode === 'value' && outstandingSecurity.volume < value) {
            validatedFail = true
            validationState.amount.errorLevel = 2
            validationState.amount.errorType = 'notEnoughOutstandingVolumeForThisAmount'
            return  //eslint-disable-line
          } else if (activeMode === 'volume' && outstandingSecurity.volume < +amount) {
            validatedFail = true
            validationState.amount.errorLevel = 2
            validationState.amount.errorType = 'notEnoughOutstandingVolume'
            return  //eslint-disable-line
          }
        }
      }
    },
    resetFormData () {
      this.activeMode = 'value'
      this.formData = {
        orderType: undefined,
        symbol: undefined,
        nav: undefined,
        amount: undefined
      }
      this.modes[0].disabled = false
      this.validationState = {
        symbol: { errorLevel: 0, errorType: undefined },
        nav: { errorLevel: 0, errorType: undefined },
        amount: { errorLevel: 0, errorType: undefined }
      }
    },
    hideModal () {
      this.hideRequestNum++
    },
    handleOrderTypeChanged (evl) {
      this.formData.symbol = undefined
    },
    handleInputSymbol ($event) {
      this.formData.symbol = $event
    },
    handleOnShow () {
      this.setOrderFormData()
    },
    handleOnHide () {
      this.resetFormData()
      this.$store.commit('member/virtual-portfolio/setModalOrserFundData', null)
    },
    setOrderFormData () {
      if (!this.orderFundData) {
        this.disableBuy = false
        return
      }
      const {
        data: { orderType, symbol, averagePrice, unit }
      } = this.orderFundData ?? {}
      this.disableBuy = true // disabled ปุ่ม Buy ของ tab "ประเภทคำสั่ง"
      this.modes[0].disabled = true // disabled ปุ่ม จำนวนเงินทุน ของ tab "คำนวณจำนวนเงินที่ใช้ลงทุนหรือจำนวนหน่วยที่ได้รับ"
      this.formData = {
        orderType, // to be 2(Sell)
        symbol,
        nav: averagePrice,
        amount: unit
      }
      this.activeMode = 'volume' // เมื่อต้องการขายให้ Default ไว้ที่ 'จำนวนหน่วยแทน'
      // console.log({
      //   formData: JSON.parse(
      //     JSON.stringify(this.formData)
      //   ),
      //   orderFundData: JSON.parse(
      //     JSON.stringify(this.orderFundData)
      //   )
      // })
    }
  }
}
