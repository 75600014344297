import dayjs from 'dayjs'
const getDefaultState = () => {
  return {
    favorites: [],
    latestdate: null,
    news: {
      totalCount: 0,
      items: []
    },
    benefit: [],
    research: {
      totalCount: 0,
      items: []
    },
    consensusStock: {
      marketTime: null,
      overall: []
    },
    consensusTfex: {
      marketTime: null,
      tfexOverall: []
    },
    securities: {
      totalCount: 0,
      list: []
    },
    articles: [],
    oppday: [],
    upcoming: []
  }
}
const state = getDefaultState
const mutations = {
  setFavorites (state, data) {
    state.favorites = data
  },
  setLatestdate (state, data) {
    state.latestdate = data
  },
  setNews (state, data) {
    state.news.totalCount = data.totalCount
    state.news.items = data.items
  },
  setResearch (state, data) {
    state.research.totalCount = data.totalCount
    state.research.items = data.items
  },
  setConsensusStock (state, data) {
    state.consensusStock = data
  },
  setConsensusTfex (state, data) {
    state.consensusTfex = data
  },
  setSecurities (state, data) {
    state.securities.totalCount = data.totalCount
    state.securities.list = data.newsInfoList
  },
  setArticles (state, data) {
    state.articles = data
  },
  setBenefit (state, data) {
    state.benefit = data
  },
  setOppday (state, data) {
    state.oppday = data
  },
  setUpcoming (state, data) {
    state.upcoming = data
  }
}
const actions = {
  async getFavorites ({ commit }, payload) {
    const response = await this.$apiMember
      .$get('/api/set-fund/member/favorite-list')
      .then(res => res.favorites)
      .catch(() => [])

    commit('setFavorites', response)
  },
  async getLatestdate ({ commit }, payload) {
    const response = await this.$axios
      .$get('api/set/news/latestdate')
      .then(res => res.datetime)
      .catch(() => {
        return dayjs().format()
      })

    commit('setLatestdate', response)
  },

  async getNews ({ commit }, payload) {
    const params = new URLSearchParams(payload)
    const response = await this.$apiCms
      .$get('/api/cms/v1/set-member/related-symbols/news-cards', { params })
      .then(res => res)
      .catch(() => [])

    commit('setNews', response)
  },

  async getResearch ({ commit }, payload) {
    try {
      const { symbols } = payload
      if (!symbols || !symbols.length > 0) {
        throw new Error('empty symbol')
      }

      const params = new URLSearchParams(payload)
      const response = await this.$apiCms.$get(
        '/api/cms/v1/set-member/related-symbols/research-cards',
        { params }
      )

      commit('setResearch', response)
    } catch (error) {
      commit('setResearch', {
        totalCount: 0,
        items: []
      })
    }
  },
  async getConsensusStock ({ commit }, symbols) {
    try {
      if (!symbols || !symbols.length > 0) {
        throw new Error('empty symbol')
      }

      const params = new URLSearchParams({ symbols })
      const response = await this.$apiMember.$get(
        '/api/set-fund/consensus/stock/favorite',
        { params }
      )
      commit('setConsensusStock', response)
    } catch (error) {
      commit('setConsensusStock', { marketTime: null, overall: [] })
    }
  },
  async getConsensusTfex ({ commit }, symbols) {
    try {
      if (!symbols || !symbols.length > 0) {
        throw new Error('empty symbol')
      }

      const params = new URLSearchParams({ symbols })
      const response = await this.$apiMember.$get(
        '/api/set-fund/consensus/tfex/favorite',
        { params }
      )

      commit('setConsensusTfex', response)
    } catch (error) {
      commit('setConsensusTfex', { marketTime: null, tfexOverall: [] })
    }
  },
  async getArticles ({ commit }, symbols) {
    try {
      if (!symbols || !symbols.length > 0) {
        throw new Error('empty symbol')
      }

      const params = {
        symbols,
        language: 'TH',
        pageIndex: 0,
        pageSize: 9
      }
      const response = await this.$apiCms
        .$get('/api/cms/v1/set-member/related-symbols/knowledge-cards', {
          params
        })
        .then(res => res.items)

      commit('setArticles', response)
    } catch (error) {
      commit('setArticles', [])
    }
  },

  async getBenefit ({ commit }, payload) {
    const { year, month, symbolList } = payload
    const response = await this.$axios
      .$get(
        `/api/set/stock-calendar/${year}/${month}/x-calendar?symbols=${symbolList}`
      )
      .then(res => res)
      .catch(() => [])

    commit('setBenefit', response)
  },

  // async getUpcoming ({ commit }) {
  //   const response = await this.$apiMember
  //     .$get('api/set-fund/upcoming-favorite')
  //     .then((res) => {
  //       return res.data
  //     })
  //     .catch(() => [])
  //   commit('setUpcoming', response)
  // },

  async getSecurities ({ commit }, payload) {
    try {
      const { symbols } = payload
      if (!symbols || !symbols.length > 0) {
        throw new Error('empty symbol')
      }

      const params = new URLSearchParams(payload)
      const response = await this.$axios
        .$get('/api/set/news', { params })
        .then(res => res.paginateNews)

      commit('setSecurities', response)
    } catch (error) {
      commit('setSecurities', {
        totalCount: 0,
        newsInfoList: []
      })
    }
  },

  async getOppday ({ commit }, symbolList) {
    const pLive = await this.$axios.$get(
      'api/set-content/vdo/oppday/list?status=LIVE'
    )

    let oppdayList = []

    oppdayList = pLive.filter(o => symbolList.includes(o.symbol))
    if (oppdayList.length === 0) {
      const pArchive = this.$axios.$get(
        '/api/set-content/vdo/oppday/list?status=ARCHIVE'
      )
      const pUpcoming = this.$axios.$get(
        'api/set-content/vdo/oppday/list?status=UPCOMING'
      )
      const pArchiveAndUpcoming = await Promise.allSettled([
        pArchive,
        pUpcoming
      ])
      oppdayList = pArchiveAndUpcoming
        .flatMap((p) => {
          return p.status === 'fulfilled' ? p.value : []
        })
        .filter(o => symbolList.includes(o.symbol))
    }

    const pProfileList = oppdayList.map((item) => {
      return this.$axios
        .$get(`/api/set/stock/${item.symbol}/profile`)
        .then((res) => {
          return {
            logo: item.logoUrl ?? '/images/logo/defaultImg.png',
            symbol: item.symbol,
            companyName: item.companyNameEN,
            market: res.market,
            industryName: res.industryName,
            sectorName: res.sectorName,
            date: item.startDatetime,
            startTime: item.startDatetime,
            endTime: item.endDatetime,
            status: item.status
          }
        })
        .catch(() => {
          return {
            logo: item.logoUrl ?? '/images/logo/defaultImg.png',
            symbol: item.symbol,
            companyName: item.companyNameEN,
            date: item.startDatetime,
            startTime: item.startDatetime,
            endTime: item.endDatetime,
            status: item.status
          }
        })
    })

    const profileList = await Promise.all(pProfileList)
    commit('setOppday', profileList)
  }
}
const getters = {
  favoriteSymbols: (state) => {
    const favoriteList = state.favorites.flatMap(item => item.symbol)
    return favoriteList
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
