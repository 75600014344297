const getDefaultState = () => {
  return {
    ipoUpcomingList: []
  }
}
const state = getDefaultState
const mutations = {
  setIpoUpcomingList (state, data) {
    state.ipoUpcomingList = data
  }
}

const actions = {
  async getIpoUpcomingList ({ commit }, payload) {
    const { type, limit = 0, status } = payload
    try {
      const response = await this.$axios.$get('/api/set/ipo/upcoming', {
        params: {
          lang: this.$i18n.locale,
          type,
          status,
          limit
        }
      })
      commit('setIpoUpcomingList', response)
    } catch (error) {
      commit('setIpoUpcomingList', [])
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
