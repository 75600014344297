import dayjs from 'dayjs'

const getDefaultState = () => {
  return {
    quotationChart: null,
    historicalTrading: [],
    historicalSessionTrading: [],
    optionsInfoList: null,
    optionsCalculation: null
  }
}

const state = getDefaultState

const mutations = {
  setQuotationChart (state, data) {
    state.quotationChart = data
  },
  setHistoricalTrading (state, data) {
    state.historicalTrading = data
  },
  setHistoricalSessionTrading (state, data) {
    state.historicalSessionTrading = data
  },
  setOptionsInfoList (state, data) {
    state.optionsInfoList = data
  },
  setOptionsCalculation (state, data) {
    state.optionsCalculation = data
  }
}

const actions = {
  async getQuotationChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${quote}/chart-quotation`,
        {
          params: {
            period
          }
        }
      )

      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },

  async getHistoricalTrading ({ commit }, payload) {
    const { quote } = payload
    // const now = dayjs()
    // const from = now.subtract(6, 'month').format('DD/MM/YYYY')
    // const to = now.format('DD/MM/YYYY')
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${quote}/historical-trading`,
        {
          params: {
            // fromDate: from,
            // toDate: to
          }
        }
      )
      commit('setHistoricalTrading', response)
    } catch {
      commit('setHistoricalTrading', [])
    }
  },

  async getHistoricalSessionTrading ({ commit }, payload) {
    const { quote } = payload
    const now = dayjs()
    const from = now.subtract(6, 'month').format('DD/MM/YYYY')
    const to = now.format('DD/MM/YYYY')
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${quote}/historical-session-trading`,
        {
          params: {
            fromDate: from,
            toDate: to
          }
        }
      )

      commit('setHistoricalSessionTrading', response)
    } catch {
      commit('setHistoricalSessionTrading', [])
    }
  },

  async getOptionsInfoList ({ commit }, payload) {
    const { segmentId } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/instrument/${segmentId}/options-info-list`)

      commit('setOptionsInfoList', response)
    } catch {
      commit('setOptionsInfoList', null)
    }
  },
  async getOptionsCalculation ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series/${quote}/options-calculation`)

      commit('setOptionsCalculation', response)
    } catch {
      commit('setOptionsCalculation', null)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
