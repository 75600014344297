export default (context, inject) => {
  const {
    store,
    app
  } = context

  /**
   * Get content
   * @returns content
   */
  const dataContent = () => {
    const cloneDeep = require('lodash/cloneDeep')
    const content = cloneDeep(store.state.contents.content || {})
    return content || {}
  }

  /**
   * Get menu locations
   * @param {String} location
   * @returns menu
   */
  const getMenuByLocation = (location) => {
    const content = dataContent().body || {}
    const menuLocations = content[location] || []
    return menuLocations
  }

  /**
   * Get home banner by locations
   * @param {String} locationCode
   * @returns banner
   */
  const getHomeBannerByLocation = (locationCode) => {
    const { homeBannerLocations } = dataContent().body || {}
    const bannerLocations = homeBannerLocations || []
    const banner = bannerLocations.filter(b => b.locationCode === locationCode)[0]
    return banner
  }
  /**
   * Add page view
   * @param {String} payload.pageUuid
   */
  const addContentPageView = async (payload) => {
    const { clientId } = store.state
    const setMemberUserRef = parseInt(app.$authUser.userRef(), 10) || 0
    const language = app.i18n.locale
    const { pageUuid, pageSourceChannelCode } = payload
    const device = app.$device.isDesktopOrTablet ? 'Desktop' : 'Mobile'
    const options = {
      data: {
        pageUuid,
        language,
        device,
        clientUuid: clientId,
        setMemberUserRef
      },
      config: {
        pageSourceChannelCode
      }
    }
    await store.dispatch('contents/addPageView', options)
  }

  const addNewsRSSPageView = async (payload) => {
    const { uuid } = payload
    const { clientId } = store.state
    const setMemberUserRef = parseInt(app.$authUser.userRef(), 10) || 0
    await app.$apiCms.$post('/api/cms-w/v1/rss/news/view', {
      rssfeedDataUuid: uuid,
      clientUuid: clientId,
      setMemberUserRef
    })
      .then(res => res)
      .catch(err => err)
  }

  const updateNewsView = (payload) => {
    const { contentType } = require('@/utils')
    const { renderType, viewByCard, newsSource, uuid } = payload
    if (newsSource === 'RSS' && viewByCard) {
      addNewsRSSPageView({
        uuid
      })
    } else if ((newsSource === 'CMS' && viewByCard) || renderType === contentType.contentFile) {
      const payload = {
        pageUuid: uuid
      }
      addContentPageView(payload)
    }
  }

  /**
   * Helpers
   */
  const pluginHelper = {
    isPreview () {
      const regxPreview = /^preview/i
      const { route } = context
      const { name } = route
      return regxPreview.test(name)
    },
    getBaseApiParams () {
      const { clientId } = store.state
      const setMemberUserRef = parseInt(app.$authUser.userRef(), 10) || 0
      const language = app.i18n.locale
      const options = {
        language,
        clientUuid: clientId,
        setMemberUserRef
      }
      return options
    }
  }

  /**
   * plugin content
   */
  const pluginContent = {
    async getPage (config) {
      const { path, device, errorConfig } = config
      const apiPath = '/api/cms/v1/pages/path'
      const options = {
        errorConfig,
        params: {
          path,
          device
        }
      }
      const response = await app.$apiPage.$get(apiPath, options)
      return response
    },
    async saveCountShare (locale, contentId) {
      const apiPath = `/api/cms-w/v1/pages/count-share/${locale}/${contentId}`
      await app.$apiCms
        .$post(apiPath)
        .then(res => res)
        .catch(err => err)
    },
    async addPopularKeyword (keyword) {
      if (!keyword) { return }
      const setMemberUserRef = parseInt(app.$authUser.userRef(), 10) || 0
      const language = app.i18n.locale
      const data = {
        language,
        keyword,
        setMemberUserRef
      }
      const apiPath = '/api/cms-w/v1/search/keywords-popular'
      const options = {
        method: 'post',
        url: apiPath,
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await app.$apiCms(options)
        .then(res => res)
        .catch(err => err)
    },
    addPageView (payload) {
      addContentPageView(payload)
    }
  }

  /**
   * Plugin Banner
   */
  const pluginBanner = {
    async getBannerByLocation (locationCode) {
      const { OK } = await require('http-status')
      const { getBannerByLocation } = await import('@/services/banner')
      // const response = await store.dispatch('banner/getBannerByLocation', locationCode)
      const response = await getBannerByLocation.call({ apiCms: app.$apiCms, i18n: app.i18n }, locationCode)
      if (response.status === OK) {
        return response.data
      }
      return null
    },
    getHomeBannerLocation (locationCode) {
      const data = getHomeBannerByLocation(locationCode) || {}
      return data.banner
    },
    updateClickCounter (itemUuId) {
      app.$apiCms
        .$post(`/api/cms-w/v1/banners/click/${itemUuId}`)
        .then(res => res)
        .catch(err => err)
    }
  }

  /**
   * Plugin Menu
   */
  const pluginMenu = {
    async getMenuByLocation (locationCode) {
      const { OK } = await require('http-status')
      const response = await store.dispatch('menu/getMenuByLocation', locationCode)
      if (response.status === OK) {
        return response.data
      }
      return null
    },
    getHomeMenuLocation (locationCode) {
      const menuLocations = getMenuByLocation('homeMenuLocations')
      const m = menuLocations.find(h => h.locationCode === locationCode)
      if (m) {
        return m.menu
      }
      return null
    },
    getLayoutMenuLocation (locationCode) {
      const menuLocations = getMenuByLocation('layoutMenuLocations')
      const m = menuLocations.find(h => h.locationCode === locationCode)
      if (m) {
        return m.menu
      }
      return null
    }
  }

  /**
   * Content news
   */
  const pluginNewsContent = {
    async saveCountShareNews (newsId, symbol) {
      const apiPath = '/api/cms-w/v1/news/set/share-counting'
      const options = {
        params: {
          newsId,
          symbol
        }
      }
      await app.$apiCms
        .$post(apiPath, null, options)
        .then(res => res)
        .catch(err => err)
    },
    updateNewsView (payload) {
      updateNewsView(payload)
    },
    setNewsThumbnail (securityType, source) {
      let imgUrl = ''
      if (securityType !== '') {
        switch (securityType) {
          case ('S'):
            imgUrl += 'stock.jpg'
            break
          case ('L'):
            imgUrl += 'etf.jpg'
            break
          case ('X'):
            imgUrl += 'dr.jpg'
            break
          case ('XF'):
            imgUrl += 'drx.jpg'
            break
          case ('V'):
            imgUrl += 'dw.jpg'
            break
          case ('U'):
            imgUrl += 'unittrust.jpg'
            break
          default:
            imgUrl += 'set.jpg'
            break
        }
      } else if (source === 'TFEX') {
        imgUrl += 'tfex.jpg'
      } else {
        imgUrl += 'set.jpg'
      }
      return imgUrl
    }
  }

  /**
   * Plugin SEO
   */
  const pluginSEO = {
    setContentSEO (objSEO) {
      store.commit('contents/setContentSEO', JSON.parse(JSON.stringify(objSEO)))
    }
  }

  const plugin = { ...pluginHelper, ...pluginContent, ...pluginMenu, ...pluginBanner, ...pluginSEO, ...pluginNewsContent }
  inject('content', plugin)
  context.$content = plugin
}
