const actions = {
  /**
   * Get menu by locations
   * @param {String} locationCode
   * @returns menu
   */
  async getMenuByLocation ({ commit }, locationCode) {
    const url = `/api/cms/v1/menus/location/${locationCode}?lang=${this.$i18n.locale}`
    const response = await this.$apiCms.get(url)
      .then((res) => {
        return res
      })
      .catch(err => err)
    return response
  }
}
export default {
  namespaced: true,
  actions
}
