const textHighlight = (query, content, className = 'text-highlight') => {
  if (!(query && query !== '' && query !== ' ' && content && content !== '')) {
    return content || ''
  }
  try {
    const regex = new RegExp(query.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'), 'gi')
    return content.replace(regex, (match) => {
      return `<span class="${className}">${match}</span>`
    })
  } catch (e) {
    return content || ''
  }
}
const firstTextHighlight = (query, content, className = 'text-highlight') => {
  if (!(query && query !== '' && query !== ' ' && content && content !== '')) {
    return content || ''
  }
  try {
    const regex = new RegExp(`^${query.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')}`, 'i')
    return content.replace(regex, (match) => {
      return `<span class="${className}">${match}</span>`
    })
  } catch (e) {
    return content || ''
  }
}
export {
  textHighlight,
  firstTextHighlight
}
