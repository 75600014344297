//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import {
  formatNumber,
  formatNumberInteger
} from '@/helpers/index'

export default {
  name: 'InputSearch',
  props: {
    value: {
      type: undefined,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    unitLabel: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    showIconSearch: {
      type: Boolean,
      default: true
    },
    showIconCalendar: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    pattern: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      // for input type number
      type: Number,
      default: null
    },
    max: {
      // for input type number
      type: Number,
      default: null
    },
    digits: {
      type: Number,
      default: 2
    },
    infDecimal: {
      // for infinity amount decimal
      type: Boolean,
      default: false
    },
    defaultValue: {
      // default value when empty text
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      elementId: `input-search-${(Math.random() * 1000).toFixed()}`,
      text: this.value,
      amountDecimal: 0,
      isInputFocus: false
    }
  },
  watch: {
    value () {
      this.setText()
    }
  },
  mounted () {
    this.setText()
  },
  methods: {
    formatNumberInteger,
    formatNumber,
    handleInput ($event) {
      if ($event.target) {
        let value = $event.target.value
        if (this.type === 'number') {
          let error = null
          value = value.replace(/[^\d.-]/g, '')
          const isBlank = String(value).trim() === ''
          const countDot = _.countBy(value)['.'] | 0
          const countMinus = _.countBy(value)['-'] | 0
          if ((countDot === 0 || (countDot === 1 && this.amountDecimal) > 0) && (countMinus === 0 || (countMinus === 1 && (this.min < 0 || this.min === null) && value.slice(0, 1) === '-'))) {
            if (!isBlank && !(countDot === 1 && value.slice(-1) === '.') && !(countMinus === 1 && value.slice(0, 1) === '-' && value.length === 1)) {
              this.countDecimals(value)
              const valueNumber = parseFloat(parseFloat(value).toFixed(this.amountDecimal + 1).slice(0, -1))
              const valueAfterDot = value.split('.')[1]
              const countZero = valueAfterDot ? valueAfterDot.length : 0
              if (this.amountDecimal > 0 && !(value.slice(-1) === '0' && countDot === 1 && (this.infDecimal || countZero <= this.amountDecimal))) {
                value = valueNumber
              }
              if (this.min !== null && value < this.min) {
                error = 'min'
              } else if (this.max !== null && value > this.max) {
                error = 'max'
              }
            }
          } else {
            error = 'oversymbol'
          }
          if (error === null) {
            this.text = value
          } else if (error === 'max') {
            this.text = this.max
          } else if (error === 'min') {
            this.text = this.min
          } else {
            this.text = this.value
          }
        }
        this.$emit('input', this.text)
      }
    },
    onBlurInput () {
      this.isInputFocus = false
      this.$emit('input', this.text)
      this.$emit('blur', this.text)
      this.setText()
    },
    onFocusInput () {
      this.isInputFocus = true
      this.setText()
    },
    search () {
      this.$emit('enter', String(this.text).trim())
      if (this.blur && document.getElementById(this.$attrs.id)) {
        document.getElementById(this.$attrs.id).blur()
      }
    },
    setText () {
      if (this.type === 'number') {
        if (!this.isInputFocus) {
          this.countDecimals(this.value)
          let value = this.numberWithComma(parseFloat(this.value).toFixed(this.amountDecimal))
          if (!isNaN(this.value) && String(this.value).trim() !== '' && this.value !== null) {
            this.text = value
          } else {
            value = this.defaultValue
            if (!isNaN(this.defaultValue) && this.defaultValue !== '') {
              value = this.numberWithComma(parseFloat(value).toFixed(this.amountDecimal))
            }
            this.text = value
            this.$emit('input', this.defaultValue)
          }
        } else {
          this.text = this.value
        }
      } else {
        this.text = this.value
      }
    },
    deleteText () {
      this.text = ''
      this.$emit('input', this.text)
    },
    countDecimals (value) {
      if (this.infDecimal) {
        if (isNaN(Math.floor(value)) || Math.floor(value) === Number(value)) {
          this.amountDecimal = this.digits
        } else {
          const amountDecimal = value.toString().split('.')[1].length || 0
          if (amountDecimal > this.digits) {
            this.amountDecimal = amountDecimal
          } else {
            this.amountDecimal = this.digits
          }
        }
      } else {
        this.amountDecimal = this.digits
      }
    },
    numberWithComma (number) {
      if (String(number).includes('.')) {
        return number.replace(/(\d)(?=(\d{3})+(?:\.\d+)(?=.)?$)/g, '$1,')
      } else {
        return number.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
      }
    },
    focusInput () {
      if (this.$refs.inputSearch) {
        this.$refs.inputSearch.focus()
      }
    }
  }
}
