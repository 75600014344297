import stockCalendar from '~/store/home/market-summary/index'

export const modules = {
  stockCalendar
}

/**
 * State
 */
const state = () => ({
  marketHome: {
    indexIndustrySectors: [],
    indexChartData: {
      set: null,
      mai: null,
      tfex: null
    },
    mostActive: null,
    tfexFutureOption: [],
    tfexFutureInvestorVolume: [],
    tfexOptionInvestorVolume: [],
    indexTfexIndustrySectors: [],
    FTSElistIndex: [],
    infoUnderChart: null,
    triIndex: null
  },
  tempChartData: {},
  loadingChart: false,
  marketChartNow: 'SET',
  ipoFirstday: null,
  consensusStockOverall: [],
  consensusTfexOverall: []
})

/**
 * Mutations
 */
const mutations = {
  setMarketHome (state, data) {
    state.marketHome.indexIndustrySectors = data
      ? data.indexIndustrySectors
      : []
  },
  setMarketHomeChart (state, data) {
    state.marketHome.indexChartData[data.tab] = data.response
  },
  setHomeConsensusStockOverall (state, data) {
    state.consensusStockOverall = data
  },
  setHomeConsensusTFEXOverall (state, data) {
    state.consensusTfexOverall = data
  },
  setMarketHomeMostActive (state, data) {
    state.marketHome.mostActive = data
  },
  setMarketHomeTfex (state, data) {
    state.marketHome.indexTfexIndustrySectors = data
  },
  setFtseSet (state, data) {
    state.marketHome.FTSElistIndex = data
  },
  setMarketInfoUnderHomeChart (state, data) {
    state.marketHome.infoUnderChart = data
  },
  setTempChartData (state, data) {
    state.tempChartData[data.market] = data.data
  },
  setLoadingChart (state, data) {
    state.loadingChart = data
  },
  setMarketChartNow (state, data) {
    state.marketChartNow = data
  },
  setTfexFutureInvestorVolume (state, data) {
    state.marketHome.tfexFutureInvestorVolume.date = data.asOfDate
    state.marketHome.tfexFutureInvestorVolume.item = data.investors.map((item) => {
      return {
        type: item.type === 'institution' ? 'นักลงทุนสถาบัน' : (item.type === 'foreign' ? 'นักลงทุนต่างประเทศ' : (item.type === 'individual' ? 'นักลงทุนในประเทศ' : '')),
        buyValue: item.buyValue,
        sellValue: item.sellValue,
        netValue: item.netValue
      }
    })
  },
  setTfexOptionInvestorVolume (state, data) {
    state.marketHome.tfexOptionInvestorVolume.date = data.asOfDate
    state.marketHome.tfexOptionInvestorVolume.item = data.investors.map((item) => {
      return {
        type: item.type === 'institution' ? 'นักลงทุนสถาบัน' : (item.type === 'foreign' ? 'นักลงทุนต่างประเทศ' : (item.type === 'individual' ? 'นักลงทุนในประเทศ' : '')),
        buyValue: item.buyValue,
        sellValue: item.sellValue,
        netValue: item.netValue
      }
    })
  },
  setTFEXFutureOption (state, data) {
    const list = [{ name: 'ทั้งหมด', instrumentId: 'all' }, ...data]
    state.marketHome.tfexFutureOption = list.map((item) => {
      return {
        name: item.name,
        value: item.instrumentId
      }
    })
  },
  setHomeIpoFirstday (state, data) {
    state.ipoFirstday = data
  },
  setTriIndex (state, data) {
    state.marketHome.triIndex = data
  }
}

/**
 * Actions
 */
const actions = {
  async getMarketHome ({ commit }, payload) {
    const { market } = payload
    try {
      const response = await this.$axios.$get(`api/set/index/info/list?type=index&market=${market}`)
      commit('setMarketHome', response)
    } catch (err) {
      commit('setMarketHome', null)
    }
  },
  async getTriIndex ({ commit }) {
    try {
      const response = await this.$axios.$get('api/set/tri/indices')
      commit('setTriIndex', response)
    } catch {
      commit('setTriIndex', null)
    }
  },
  setMarketChartNow ({ commit }, data) {
    commit('setMarketChartNow', data)
  },
  setLoadingChart ({ commit }, data) {
    commit('setLoadingChart', data)
  },
  async getMarketHomeChart ({ state, commit }, payload) {
    const { market, tab } = payload
    if (state.tempChartData[market]) {
      commit('setMarketHomeChart', { tab, response: state.tempChartData[market] })
    } else {
      try {
        const response = await this.$axios.$get(`api/set/index/${market}/chart-quotation?period=1D`)
        commit('setTempChartData', { market, data: response })
        commit('setMarketHomeChart', { tab, response })
      } catch (err) {
        commit('setMarketHomeChart', { tab, response: null })
      }
    }
    commit('setLoadingChart', false)
  },
  async getTfexHomeChart ({ state, commit }, payload) {
    const { market, tab } = payload
    if (state.tempChartData[market]) {
      commit('setMarketHomeChart', { tab, response: state.tempChartData[market] })
    } else {
      try {
        const response = await this.$axios.$get(`api/set/tfex/series/${market}/chart-quotation?period=1D`)
        commit('setTempChartData', { market, data: response })
        commit('setMarketHomeChart', { tab, response })
      } catch (err) {
        commit('setMarketHomeChart', { tab, response: null })
      }
    }
    commit('setLoadingChart', false)
  },
  async getMarketHomeMostActive ({ commit }, payload) {
    const { market, rankingType, securityType, count } = payload
    try {
      if (rankingType === 'popular') {
        const url = `/api/cms/v1/popularquote/getpopular?market=${market}&securityType=${securityType}&limit=${count}`
        await this.$apiCms.get(url).then((res) => {
          const popularObject = {
            market,
            ranking: rankingType,
            securityType: 'S',
            stocks: res.data
          }
          commit('setMarketHomeMostActive', popularObject)
        })
      } else {
        const response = await this.$axios.$get(`api/set/ranking/${rankingType}/${market}/${securityType}?count=${count}`)
        commit('setMarketHomeMostActive', response)
      }
    } catch (err) {
      commit('setMarketHomeMostActive', null)
    }
  },
  async getMarketHomeTfexInstrument ({ commit, dispatch }) {
    try {
      await this.$axios.$get('api/set/tfex/instrument/active-instrument').then(async (res) => {
        await dispatch('getMarketHomeTfexActive', res.instruments)
      })
    } catch (e) {}
  },
  async getMarketHomeTfexActive ({ commit, dispatch }, payload) {
    try {
      // eslint-disable-next-line prefer-const
      let data = []
      for (let i = 0; i < payload.length; i++) {
        await this.$axios.$get(`api/set/tfex/instrument/${payload[i].key}/mostactive?limit=1`).then(async (res) => {
          const symbol = res.series && res.series.length > 0 ? res.series[0].symbol : null
          if (symbol) {
            const info = await dispatch('getMarketHomeTfexActiveInfo', symbol)
            data.push(info)
          }
        })
      }
      commit('setMarketHomeTfex', data)
    } catch (e) {
      commit('setMarketHomeTfex', null)
    }
  },
  async getMarketHomeTfexActiveInfo ({ commit }, payload) {
    try {
      const result = await this.$axios.$get(`api/set/tfex/series/${payload}/info`)
      return result
    } catch (e) {
      return null
    }
  },
  async getMarketInfoUnderHomeChart ({ commit }, payload) {
    const { market } = payload
    try {
      const response = await this.$axios.get(`api/set/market/${market}/info`)
      commit('setMarketInfoUnderHomeChart', response.data)
    } catch (err) {
      commit('setMarketInfoUnderHomeChart', null)
    }
  },
  async getTFEXFutureOption ({ commit }) {
    await this.$axios.get('api/set/tfex/market/list?type=futures').then((res) => {
      commit('setTFEXFutureOption', res.data)
    }).catch(err => err)
  },
  async getTFEXFutureInvestorVolume ({ commit }, payload) {
    await this.$axios.get('api/set/tfex/market/investor-type?type=futures&marketList=' + payload).then((res) => {
      commit('setTfexFutureInvestorVolume', res.data)
    }).catch(err => err)
  },
  async getTFEXOptionInvestorVolume ({ commit }, payload) {
    await this.$axios.get('api/set/tfex/market/investor-type?type=' + payload).then((res) => {
      commit('setTfexOptionInvestorVolume', res.data)
    }).catch(err => err)
  },
  async getStockSecurityType ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/info`)
      return response
    } catch (err) {
      return null
    }
  },
  async getFtseSet ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('/api/set/ftse-set/indices')
      commit('setFtseSet', response)
    } catch (err) {
      commit('setFtseSet', null)
    }
  },
  async getHomeIpoFirstday ({ commit }, payload) {
    try {
      // const response = await this.$axios.$get(`/api/set/ipo/firstday?date=25/12/2020&lang=${this.$i18n.locale}`)
      const response = await this.$axios.$get(`/api/set/ipo/firstday?lang=${this.$i18n.locale}`)
      commit('setHomeIpoFirstday', response)
    } catch (error) {
      commit('setHomeIpoFirstday', null)
    }
  },
  async getHomeConsensusStockOverall ({ commit }, payload) {
    try {
      // const response = await this.$axios.$get(`/api/set/ipo/firstday?date=25/12/2020&lang=${this.$i18n.locale}`)
      const response = await this.$axios.$get('/api/set-fund/consensus/stock/overall') // ?type=today
      // console.log('setHomeConsensusStockOverall=>>>>>', response.overall)
      // commit('setHomeConsensusStockOverall', response.overall.slice(0, 6))
      commit('setHomeConsensusStockOverall', response)
    } catch (error) {
      commit('setHomeConsensusStockOverall', null)
    }
  },
  async getHomeConsensusTFEXOverall ({ commit }, payload) {
    try {
      // const response = await this.$axios.$get(`/api/set/ipo/firstday?date=25/12/2020&lang=${this.$i18n.locale}`)
      const response = await this.$axios.$get('/api/set-fund/consensus/tfex/overall?type=today') //
      // console.log('setHomeConsensusTFEXOverall=>>>>>', response.tfexOverall)
      // commit('setHomeConsensusTFEXOverall', response.tfexOverall.slice(0, 6))
      commit('setHomeConsensusTFEXOverall', response)
    } catch (error) {
      commit('setHomeConsensusTFEXOverall', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  getMarketHome: (state) => {
    return state.marketHome.indexIndustrySectors
  },
  getMarketHomeTfex: (state) => {
    return state.marketHome.indexTfexIndustrySectors
  },
  getFtseSet: (state) => {
    return state.marketHome.FTSElistIndex
  },
  getMarketHomeChart: (state) => {
    return state.marketHome.indexChartData
  },
  getMarketHomeMostActive: (state) => {
    return state.marketHome.mostActive
  },
  getMarketInfoUnderHomeChart: (state) => {
    return state.marketHome.infoUnderChart
  },
  getLoadingChart: (state) => {
    return state.loadingChart
  },
  getMarketChartNow: (state) => {
    return state.getMarketChartNow
  },
  getHomeIpoFirstday (state, data) {
    return state.ipoFirstday || []
  },
  getHomeConsensusStockOverall (state) {
    return state.consensusStockOverall || []
  },
  getHomeConsensusTFEXOverall (state) {
    return state.consensusTfexOverall || []
  },
  getTriIndex (state) {
    return state.marketHome.triIndex
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
