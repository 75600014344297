import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=6786cab0&"
import script from "./Banner.vue?vue&type=script&lang=js&"
export * from "./Banner.vue?vue&type=script&lang=js&"
import style0 from "./Banner.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerAdvertisement: require('/app/components/Banner/Advertisement.vue').default,AdvertisementFloating: require('/app/components/Advertisement/Floating.vue').default,BannerStandard: require('/app/components/Banner/Standard.vue').default})
