//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import {
  integerStyle,
  formatNumber,
  formatNumberInteger,
  formatNumberPercent
} from '@/helpers/index'
import { formatDateShort } from '@/helpers/dateTime'
export default {
  name: 'HomeQuoteTabRecent',
  props: {
    swiperOption: {
      type: Object,
      default: () => {}
    },
    swiperOptionFund: {
      type: Object,
      default: () => {}
    },
    openTabCount: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      optionsTypeStock: [
        {
          label: 'หุ้น',
          value: 'Stock'
        },
        {
          label: 'หน่วยลงทุน',
          value: 'U'
        },
        {
          label: 'ETF',
          value: 'L'
        },
        {
          label: 'DW',
          value: 'V'
        },
        {
          label: 'DR',
          value: 'X'
        },
        {
          label: 'DRx',
          value: 'XF',
          class: 'drx-info'
        },
        {
          label: 'ฟิวเจอร์ส',
          value: 'Futures'
        },
        {
          label: 'ออปชัน',
          value: 'Options'
        },
        {
          label: 'กองทุนรวม',
          value: 'Fund'
        }
      ],
      selectedValue: 'Stock',
      recentQuote: [],
      keyRerender: 0,
      recentlyList: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      stockList: 'recent-view/getStockList',
      unittrustList: 'recent-view/getUnittrustList',
      etfList: 'recent-view/getEtfList',
      drList: 'recent-view/getDrList',
      drxList: 'recent-view/getDrxList',
      dwList: 'recent-view/getDwList',
      futureList: 'recent-view/getFutureList',
      optionList: 'recent-view/getOptionList',
      fundList: 'recent-view/getFundList'
    }),
    listShow () {
      if (this.selectedValue === 'Stock') {
        return this.stockList
      } else if (this.selectedValue === 'U') {
        return this.unittrustList
      } else if (this.selectedValue === 'L') {
        return this.etfList
      } else if (this.selectedValue === 'V') {
        return this.dwList
      } else if (this.selectedValue === 'X') {
        return this.drList
      } else if (this.selectedValue === 'XF') {
        return this.drxList
      } else if (this.selectedValue === 'Futures') {
        return this.futureList
      } else if (this.selectedValue === 'Options') {
        return this.optionList
      } else if (this.selectedValue === 'Fund') {
        return this.fundList && this.fundList.outstandingFunds ? this.fundList.outstandingFunds : []
      } else {
        return []
      }
    }
  },
  watch: {
    openTabCount: {
      handler (value) {
        if (value) {
          this.fetchData()
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.$recently.eventBus.$on('recentlyChanged', async (category) => {
      if (this.active) {
        this.loading = true
        if (category === 'Stock') {
          await this.getStockList()
        } else if (category === 'U') {
          await this.getUnittrustList()
        } else if (category === 'L') {
          await this.getEtfList()
        } else if (category === 'V') {
          await this.getDwList()
        } else if (category === 'X') {
          await this.getDrList()
        } else if (category === 'XF') {
          await this.getDrxList()
        } else if (category === 'Futures') {
          await this.getFutureList()
        } else if (category === 'Options') {
          await this.getOptionList()
        } else if (category === 'Fund') {
          await this.getFundList()
        }
        this.loading = false
      }
    })
  },
  beforeDestroy () {
    this.$recently.eventBus.$off('recentlyChanged')
  },
  methods: {
    ...mapActions({
      getStockList: 'recent-view/getStockList',
      getUnittrustList: 'recent-view/getUnittrustList',
      getDrList: 'recent-view/getDrList',
      getDrxList: 'recent-view/getDrxList',
      getDwList: 'recent-view/getDwList',
      getEtfList: 'recent-view/getEtfList',
      getFundList: 'recent-view/getFundList',
      getFutureList: 'recent-view/getFutureList',
      getOptionList: 'recent-view/getOptionList'
    }),
    integerStyle,
    formatNumber,
    formatNumberInteger,
    formatNumberPercent,
    formatDateShort,
    async fetchData () {
      this.recentlyList = await this.$recently.getList()
      this.loading = true
      await Promise.all([
        this.getStockList(),
        this.getUnittrustList(),
        this.getDrList(),
        this.getDrxList(),
        this.getDwList(),
        this.getEtfList(),
        this.getFundList(),
        this.getFutureList(),
        this.getOptionList()
      ])
      this.loading = false
    },
    eventChangeFavorite (value) {
      this.resetSwiper()
      const typeStock = this.optionsTypeStock.find(data => data.value === value)
      this.$personalized.clickElement({
        name: 'myQuote2',
        optionLang: {
          symbol: typeStock.label
        }
      })
    },
    resetSwiper () {
      setTimeout(() => {
        this.keyRerender += 1
      }, 300)
    },
    newTabToQuote (item) {
      const stockInfo = this.recentlyList.find((data) => {
        return data.symbol === item.symbol
      })
      const urlString = this.$quote.getQuoteUrl({
        ...item,
        securityType: stockInfo.type === 'Futures' || stockInfo.type === 'Options' ? 'TFEX' : stockInfo.type === 'Fund' ? 'FUND' : stockInfo.type
      })
      window.open(urlString, '_blank')
      this.$personalized.clickElement({
        name: 'myQuote3',
        optionLang: {
          symbol: stockInfo.symbol
        }
      })
    }
  }
}
