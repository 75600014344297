const { selectApiEndpoint } = require('@/helpers/select-endpoint')
const _API_RESPONSE_TIME_TO_PRINT_LOG = 300
export default function ({ $axios, app, $cookies, route }, inject) {
  const ENV_USE_DEVELOPMENT = process.env.USE_DEVELOPMENT === 'true'
  const isDevelopment = ENV_USE_DEVELOPMENT ? true : process.env.NODE_ENV === 'development'
  const requestTime = new Date().getTime()
  const accessToken = $cookies.get('sttid') || ''
  const instanceOption = {
    baseURL: process.server || isDevelopment ? process.env.HOST_PROXY : '/',
    meta: {
      requestBeforeStartedAt: requestTime,
      requestStartedAt: requestTime
    },
    headers: {
      common: {
        authorization: `Bearer ${accessToken}`
      }
    }
  }
  // Create a custom axios instance
  const axiosInstance = $axios.create(instanceOption)
  axiosInstance.interceptors.request.use((config) => {
    // Select api endpoint
    if (process.server) {
      const { endpoint, requestPath } = selectApiEndpoint(config.url)
      config.url = requestPath
      config.baseURL = endpoint

      // console.log('=================================================')
      // console.log('[INFO]', 'Page URL:', route.fullPath)
      // console.log('[INFO]', '[Request]API MEMBER - path:', config.url)
      // console.log('[INFO]', '[Request]API MEMBER - params:', config.params || '-')
      // console.log('[INFO]', '[Request]API MEMBER - endpoint:', config.baseURL)
    }
    config.requestStartedAt = new Date().getTime()
    // config.headers['x-client-uuid'] = clientId
    return config
  }, (x) => {
    if (process.server) {
      console.log('=================================================')
      console.error('Page URL:', route.fullPath)
      console.error('[Request]API MEMBER - Error details:', x)
    }
    // console.log('[Request]API CMS - Headers:', x.config.headers)
    throw x
  })

  axiosInstance.interceptors.response.use((x) => {
    if (process.server) {
      const respTime = new Date().getTime() - x.config.meta.requestStartedAt
      const printRespLog = respTime >= _API_RESPONSE_TIME_TO_PRINT_LOG
      if (printRespLog) {
        console.log('=================================================')
        console.log('[INFO]', 'Page URL:', route.fullPath)
        console.log('[INFO]', '[Response]API MEMBER - endpoint:', x.config.baseURL)
        console.log('[INFO]', `[Response - ${new Date()}]API MEMBER - Execution time for: ${x.config.url} - ${respTime} ms`)
      }
    }
    return x
  }, (x) => {
    if (process.server) {
      console.log('=================================================')
      console.error('Page URL:', route.fullPath)
      console.error('[Response]API MEMBER - status:', x.response?.status)
      console.error('[Response]API MEMBER - endpoint:', x.config.baseURL)
      console.error(`[Response - ${new Date()}]API MEMBER - Error response time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
      console.error('[Response]API MEMBER - Error details:', x)
    }
    throw x
  })

  inject('apiMember', axiosInstance)
}
