//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconFilterTable'
}
