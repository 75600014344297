const getDefaultState = () => {
  return {
    dropdownList: [],
    esgList: []
  }
}

const state = getDefaultState

const mutations = {
  setDropdownList (state, data) {
    state.dropdownList = data
  },
  setEsgList (state, data) {
    state.esgList = data
  }
}

const actions = {
  async getDropdownList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set-fund/esg/source')
      commit('setDropdownList', response)
    } catch {
      commit('setDropdownList', [])
    }
  },
  async getEsgList ({ commit }, payload) {
    const { source, isLogin } = payload
    const params = {
      source
    }
    try {
      if (isLogin === true) {
        const response = await this.$axios.$get('/api/set-fund/esg/list', {
          params,
          headers: {
            common: {
              authorization: `Bearer ${this.$cookies.get('sttid')}`
            }
          }
        })
        commit('setEsgList', response)
      } else {
        const response = await this.$axios.$get('/api/set-fund/esg/list', {
          params
        })
        commit('setEsgList', response)
      }
    } catch {
      commit('setEsgList', [])
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
