import { types } from '@/helpers/iaa-consensus/core.js'

export function getTypes () {
  return Promise.resolve(types)
}

const typeStock = new Set(['DailyUpdateArticles', 'ViewByProject'])

export function getIndexList () {
  return this.$axios.$get('/api/set/index/list')
}

export async function getSecurityTypes (type) {
  if (typeStock.has(type)) {
    // eslint-disable-next-line
    const response = await this.$axios.$get('/api/set/stock-screen/condition', {
      params: {
        lang: 'th',
        theme: 'dividend'
      }
    })
    return ['U', 'X', 'V', 'XF']
  } else {
    // eslint-disable-next-line
    const response = await this.$axios.$get('/api/set/tfex/series/list')
    return ['S', 'P', 'F', 'Q']
  }
}

export async function getBrokers () {
  const response = await this.$axios.$get(
    '/api/set-fund/consensus/internal/broker-list',
    {
      params: {
        lang: 'th'
      }
    }
  )
  return response.filter(x => x.isActive)
}

export async function getAuthors () {
  const response = await this.$axios.$get(
    '/api/set-fund/consensus/internal/analyst-name-list'
  )
  return response.filter(x => x.isActive).map(x => x.fullName)
}

export async function getStockList () {
  const response = await this.$axios.$get('/api/set/stock/list', {
    params: {
      securityType: 's'
    }
  })
  return response.securitySymbols
}

export async function getRatingViewList () {
  const response = await this.$apiCms.get(
    '/api/cms/v1/research-settrade/iaa/rating-views',
    {
      params: {
        language: 'TH'
      }
    }
  )
  return response.data
}

export async function getSummaryOfAnalystConsensus (condition) {
  const response = await this.$axios.$get(
    '/api/set-fund/consensus/stock/overall',
    {
      params: {
        lang: 'th',
        symbol: condition.symbol,
        index: condition.index,
        broker: condition.broker,
        analystName: condition.author
      }
    }
  )

  return response
}

export async function getDailyUpdateArticles (condition) {
  const response = await this.$axios.$get(
    '/api/set-fund/consensus/daily-update',
    {
      params: {
        lang: 'th',
        symbol: condition.symbol,
        index: condition.index,
        broker: condition.broker,
        analystName: condition.author
      }
    }
  )
  return { data: response }
}

export async function getViewByProject (condition) {
  const response = await this.$axios.$get(
    '/api/set-fund/consensus/view-by-project',
    {
      params: {
        lang: 'th',
        symbol: condition.symbol,
        index: condition.index,
        broker: condition.broker,
        analystName: condition.author
      }
    }
  )
  return { data: response }
}

export async function getSet50FinancialRatioEstimation (condition) {
  // eslint-disable-next-line
  const response = await this.$axios.$get(
    '/api/set-fund/consensus/compare/set50',
    {
      params: {
        lang: 'th',
        symbol: condition.symbol
      }
    }
  )
  return { data: response }
}

// let i = 0
export async function getNetProfitAndValuation ({ symbol, index }) {
  const response = await this.$axios.$get('/api/set-fund/consensus/net-profit', {
    params: {
      lang: 'th',
      symbol,
      index
    }
  })
  return response
  // const mockData = {
  //   currentYear: '1000',
  //   nextYear: '1001',
  //   targetPriceYear: null,
  //   actualYear: null,
  //   currentYearM1: null,
  //   currentYearM2: null,
  //   currentYearM3: null,
  //   currentYearM4: null,
  //   nextYearM1: null,
  //   nextYearM2: null,
  //   nextYearM3: null,
  //   nextYearM4: null,
  //   groupByMarket: [
  //     {
  //       market: 'SET',
  //       marketTotal: 5,
  //       totalAll: {
  //         fCurrentYearM1: 1,
  //         fCurrentYearM2: null,
  //         fCurrentYearM3: null,
  //         fCurrentYearM4: null,
  //         fNextYearM1: 5,
  //         fNextYearM2: null,
  //         fNextYearM3: null,
  //         fNextYearM4: null
  //       },
  //       qoq: {
  //         fCurrentYearM1: null,
  //         fCurrentYearM2: 2,
  //         fCurrentYearM3: null,
  //         fCurrentYearM4: 4,
  //         fNextYearM1: null,
  //         fNextYearM2: 6,
  //         fNextYearM3: null,
  //         fNextYearM4: 8
  //       },
  //       yoy: {
  //         fCurrentYearM1: null,
  //         fCurrentYearM2: null,
  //         fCurrentYearM3: 3,
  //         fCurrentYearM4: null,
  //         fNextYearM1: null,
  //         fNextYearM2: null,
  //         fNextYearM3: 7,
  //         fNextYearM4: null
  //       },
  //       groupBySector: [
  //         {
  //           sector: 'FOOD',
  //           sectorName: 'Food',
  //           sectorTotal: 3,
  //           averageBySector: {
  //             actualNetProfit: 0.1,
  //             fCurrentYearM1: 0.1,
  //             fCurrentYearM2: 0.1,
  //             fCurrentYearM3: 0.1,
  //             fCurrentYearM4: 0.1,
  //             fNextYearM1: 0.1,
  //             fNextYearM2: 0.1,
  //             fNextYearM3: 0.1,
  //             fNextYearM4: 0.1
  //           },
  //           groupByStock: [
  //             {
  //               symbol: 'CPF',
  //               actualNetProfit: 0.1,
  //               fCurrentYearM1: 0.1,
  //               fCurrentYearM2: 0.1,
  //               fCurrentYearM3: 0.1,
  //               fCurrentYearM4: 0.1,
  //               fNextYearM1: 0.1,
  //               fNextYearM2: 0.1,
  //               fNextYearM3: 0.1,
  //               fNextYearM4: 0.1,
  //               currentYearPe: 0.1,
  //               currentYearPbv: 0.1,
  //               currentYearDiv: 0.1,
  //               targetPrice: 0.1
  //             },
  //             {
  //               symbol: 'GFPT',
  //               actualNetProfit: 1,
  //               fCurrentYearM1: 1,
  //               fCurrentYearM2: 1,
  //               fCurrentYearM3: 1,
  //               fCurrentYearM4: 1,
  //               fNextYearM1: 1,
  //               fNextYearM2: 1,
  //               fNextYearM3: 1,
  //               fNextYearM4: 1,
  //               currentYearPe: 1,
  //               currentYearPbv: 1,
  //               currentYearDiv: 1,
  //               targetPrice: 1
  //             },
  //             {
  //               symbol: 'PM',
  //               actualNetProfit: 1000,
  //               fCurrentYearM1: 1000,
  //               fCurrentYearM2: 1000,
  //               fCurrentYearM3: 1000,
  //               fCurrentYearM4: 1000,
  //               fNextYearM1: 1000,
  //               fNextYearM2: 1000,
  //               fNextYearM3: 1000,
  //               fNextYearM4: 1000,
  //               currentYearPe: 1000,
  //               currentYearPbv: 1000,
  //               currentYearDiv: 1000,
  //               targetPrice: 1000
  //             }
  //           ]
  //         },
  //         {
  //           sector: 'TRANS',
  //           sectorName: 'Transport',
  //           sectorTotal: 2,
  //           averageBySector: {
  //             actualNetProfit: 0.1,
  //             fCurrentYearM1: 0.1,
  //             fCurrentYearM2: 0.1,
  //             fCurrentYearM3: 0.1,
  //             fCurrentYearM4: 0.1,
  //             fNextYearM1: 0.1,
  //             fNextYearM2: 0.1,
  //             fNextYearM3: 0.1,
  //             fNextYearM4: 0.1
  //           },
  //           groupByStock: [
  //             {
  //               symbol: 'BEM',
  //               actualNetProfit: 10,
  //               fCurrentYearM1: 10,
  //               fCurrentYearM2: 10,
  //               fCurrentYearM3: 10,
  //               fCurrentYearM4: 10,
  //               fNextYearM1: 10,
  //               fNextYearM2: 10,
  //               fNextYearM3: 10,
  //               fNextYearM4: 10,
  //               currentYearPe: 10,
  //               currentYearPbv: 10,
  //               currentYearDiv: 10,
  //               targetPrice: 10
  //             },
  //             {
  //               symbol: 'BTS',
  //               actualNetProfit: 10.5,
  //               fCurrentYearM1: 10.5,
  //               fCurrentYearM2: 10.5,
  //               fCurrentYearM3: 10.5,
  //               fCurrentYearM4: 10.5,
  //               fNextYearM1: 10.5,
  //               fNextYearM2: 10.5,
  //               fNextYearM3: 10.5,
  //               fNextYearM4: 10.5,
  //               currentYearPe: 10.5,
  //               currentYearPbv: 10.5,
  //               currentYearDiv: 10.5,
  //               targetPrice: 10.5
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       market: 'mai',
  //       marketTotal: 5,
  //       totalAll: {
  //         fCurrentYearM1: 1,
  //         fCurrentYearM2: null,
  //         fCurrentYearM3: null,
  //         fCurrentYearM4: null,
  //         fNextYearM1: 5,
  //         fNextYearM2: null,
  //         fNextYearM3: null,
  //         fNextYearM4: null
  //       },
  //       qoq: {
  //         fCurrentYearM1: null,
  //         fCurrentYearM2: 2,
  //         fCurrentYearM3: null,
  //         fCurrentYearM4: 4,
  //         fNextYearM1: null,
  //         fNextYearM2: 6,
  //         fNextYearM3: null,
  //         fNextYearM4: 8
  //       },
  //       yoy: {
  //         fCurrentYearM1: null,
  //         fCurrentYearM2: null,
  //         fCurrentYearM3: 3,
  //         fCurrentYearM4: null,
  //         fNextYearM1: null,
  //         fNextYearM2: null,
  //         fNextYearM3: 7,
  //         fNextYearM4: null
  //       },
  //       groupBySector: [
  //         {
  //           sector: 'FOOD',
  //           sectorName: 'Food',
  //           sectorTotal: 1,
  //           averageBySector: {
  //             actualNetProfit: 0.1,
  //             fCurrentYearM1: 0.1,
  //             fCurrentYearM2: 0.1,
  //             fCurrentYearM3: 0.1,
  //             fCurrentYearM4: 0.1,
  //             fNextYearM1: 0.1,
  //             fNextYearM2: 0.1,
  //             fNextYearM3: 0.1,
  //             fNextYearM4: 0.1
  //           },
  //           groupByStock: [
  //             {
  //               symbol: 'AU',
  //               actualNetProfit: 10,
  //               fCurrentYearM1: 10,
  //               fCurrentYearM2: 10,
  //               fCurrentYearM3: 10,
  //               fCurrentYearM4: 10,
  //               fNextYearM1: 10,
  //               fNextYearM2: 10,
  //               fNextYearM3: 10,
  //               fNextYearM4: 10,
  //               currentYearPe: 10,
  //               currentYearPbv: 10,
  //               currentYearDiv: 10,
  //               targetPrice: 10
  //             }
  //           ]
  //         },
  //         {
  //           sector: 'CONSUMP',
  //           sectorName: 'Consumption',
  //           sectorTotal: 1,
  //           averageBySector: {
  //             actualNetProfit: 0.1,
  //             fCurrentYearM1: 0.1,
  //             fCurrentYearM2: 0.1,
  //             fCurrentYearM3: 0.1,
  //             fCurrentYearM4: 0.1,
  //             fNextYearM1: 0.1,
  //             fNextYearM2: 0.1,
  //             fNextYearM3: 0.1,
  //             fNextYearM4: 0.1
  //           },
  //           groupByStock: [
  //             {
  //               symbol: 'IP',
  //               actualNetProfit: 10,
  //               fCurrentYearM1: 10,
  //               fCurrentYearM2: 10,
  //               fCurrentYearM3: 10,
  //               fCurrentYearM4: 10,
  //               fNextYearM1: 10,
  //               fNextYearM2: 10,
  //               fNextYearM3: 10,
  //               fNextYearM4: 10,
  //               currentYearPe: 10,
  //               currentYearPbv: 10,
  //               currentYearDiv: 10,
  //               targetPrice: 10
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // }
  // if (i++ % 2 === 0) {
  //   return mockData
  // } else {
  //   return { ...mockData, groupByMarket: mockData.groupByMarket.slice(0, 1) }
  // }
}

export function getGroupBySector ({ symbol, index }) {
  return this.$axios.$get('/api/set-fund/consensus/group-by-sector', {
    params: {
      lang: 'th',
      symbol,
      index
    }
  })
}

export function getGroupByBroker ({ symbol, broker }) {
  return this.$axios.$get('/api/set-fund/consensus/group-by-broker', {
    params: {
      lang: 'th',
      symbol,
      broker
    }
  })
}
