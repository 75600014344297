//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FooterSocial',
  props: {
    socialMedia: {
      type: Object,
      default: Object
    }
  },
  // serverCacheKey: props => `FooterSocial-${JSON.stringify(props.socialMedia)}`,
  methods: {
    handleClickItem (socialMediaName) {
      this.$personalized.clickElement({
        name: 'all14',
        optionLang: {
          socialMediaName
        }
      })
    }
  }
}
