const getDefaultState = () => {
  return {
    info: null,
    quotationChart: null,
    quotationChartAccumulated: null,
    profile: null,
    outstandingChart: [],
    marketCapChart: [],
    pricePerformanceChart: [],
    historicalTrading: [],
    benefits: [],
    dividendChart: [],
    dividendChartYearly: []
    // newsList: null
  }
}

const state = getDefaultState

const mutations = {
  setInfo (state, data) {
    state.info = data
  },

  setQuotationChart (state, data) {
    state.quotationChart = data
  },

  setQuotationChartAccumulated (state, data) {
    state.quotationChartAccumulated = data
  },

  setProfile (state, data) {
    state.profile = data
  },

  setOutstandingChart (state, data) {
    state.outstandingChart = data
  },

  setMarketCapChart (state, data) {
    state.marketCapChart = data
  },

  setPricePerformanceChart (state, data) {
    state.pricePerformanceChart = data
  },

  setHistoricalTrading (state, data) {
    state.historicalTrading = data
  },

  setBenefits (state, data) {
    state.benefits = data
  },

  setDividendChart (state, data) {
    state.dividendChart = data
  },

  setDividendChartYearly (state, data) {
    state.dividendChartYearly = data
  }

  // setNewsList (state, data) {
  //   state.newsList = data
  // }
}

const actions = {
  async getInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/drx/${quote}/info`)

      commit('setInfo', response)
    } catch {
      commit('setInfo', null)
    }
  },

  async getIntradayChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/drx/${quote}/intraday-chart`)

      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },

  async getQuotationChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/chart-quotation`, {
        params: {
          period,
          accumulated: false
        }
      })

      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },

  async getQuotationChartAccumulated ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(`/api/set/drx/${quote}/intraday-chart`, {
        params: {
          period,
          accumulated: true
        }
      })

      commit('setQuotationChartAccumulated', response)
    } catch {
      commit('setQuotationChartAccumulated', null)
    }
  },

  async getProfile ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/dr/${quote}/profile`, {
        params: {
          lang: this.$i18n.locale
        }
      })

      commit('setProfile', response)
    } catch {
      commit('setProfile', null)
    }
  },

  async getOutstandingChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(`/api/set/dr/${quote}/chart-outstanding`, {
        params: {
          period
        }
      })

      commit('setOutstandingChart', response)
    } catch {
      commit('setOutstandingChart', [])
    }
  },

  async getMarketCapChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(`/api/set/dr/${quote}/chart-market-cap`, {
        params: {
          period
        }
      })

      commit('setMarketCapChart', response)
    } catch {
      commit('setMarketCapChart', [])
    }
  },

  async getPricePerformanceChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const quoteList = await this.$axios.$get(`/api/set/stock/${quote}/chart-performance`, {
        params: {
          period
        }
      })

      const pricePerformanceChart = [
        {
          legend: quote, // if quote is uppercase symbol is uppercase too
          quotations: quoteList.quotations.map((item, index, array) => {
            return {
              datetime: item.datetime,
              pricePercent: item.price.toFixed(2)
            }
          }) || []
        }
      ]

      commit('setPricePerformanceChart', pricePerformanceChart)
    } catch {
      commit('setPricePerformanceChart', [])
    }
  },

  async getHistoricalTrading ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/historical-trading`)

      commit('setHistoricalTrading', response)
    } catch {
      commit('setHistoricalTrading', [])
    }
  },

  async getBenefits ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/corporate-action`)

      commit('setBenefits', response)
    } catch {
      commit('setBenefits', [])
    }
  },

  async getDividendChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/dividend-chart`)

      commit('setDividendChart', response)
    } catch {
      commit('setDividendChart', [])
    }
  },

  async getDividendChartYearly ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/yearly-dividend-chart`)

      commit('setDividendChartYearly', response)
    } catch {
      commit('setDividendChartYearly', [])
    }
  }

  // async getNewsList ({ commit }, payload) {
  //   const { quote } = payload
  //   try {
  //     const response = await this.$axios.$get(`/api/set/news/${quote}/list`)

  //     commit('setNewsList', response)
  //   } catch {
  //     commit('setNewsList', null)
  //   }
  // }
}

const getters = {
  pricePerformanceData: (state) => {
    return state.pricePerformanceChart.map(item => item.quotations) || []
  },
  pricePerformanceLegend: (state) => {
    return state.pricePerformanceChart.map(item => item.legend) || []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
