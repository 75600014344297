const state = () => ({
  instrumentType: [],
  instrumentClass: [],
  calendarList: []
})

const mutations = {
  setInstrumentType (state, data) {
    state.instrumentType = data
  },
  setCalendar (state, data) {
    if (data && data.list) {
      let filterList = []
      if (data.typeId) {
        filterList = data.list.map((item, index) => {
          return {
            date: item.date,
            lastTrades: item.lastTrades.filter(e => e.marketListId === data.typeId)
          }
        })
      } else {
        filterList = data.list
      }
      let items = filterList.map((item, index) => {
        return {
          dates: item.date,
          customData: item.lastTrades.map((val, j) => {
            const series = data.instrumentId ? val.seriesList.filter(e => e.instrumentName === data.instrumentId) : val.seriesList
            if (series && series.length > 0) {
              return {
                type: val.marketListId,
                typeName: val.marketListName,
                series
              }
            }
            return null
          })
        }
      })
      items = items.filter(e => e.customData.length > 0 && e.customData[0] !== null)
      state.calendarList = items
    }
  },
  setInstrumentClass (state, data) {
    if (data && data.instruments && data.instruments.length > 0) {
      state.instrumentClass = data.instruments.map((item) => {
        return {
          label: item.value,
          value: item.value
        }
      })
    } else {
      state.instrumentClass = []
    }
  }
}

const actions = {
  async getCalendarItems ({ commit }, payload) {
    const { year, month, typeId, instrumentId } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/calendar/${year}/${month < 10 ? `0${month}` : month}`)
      commit('setCalendar', { list: response, typeId, instrumentId })
    } catch (error) {
      commit('setCalendar', null)
    }
  },
  async getInstrumentType ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/list')
      commit('setInstrumentType', response)
    } catch (error) {
      commit('setInstrumentType', null)
    }
  },
  async getInstrumentClass ({ commit }, payload) {
    const { typeId } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series-search/instrument?marketListId=${typeId}`)
      commit('setInstrumentClass', response)
    } catch (error) {
      commit('setInstrumentClass', null)
    }
  }
}
const getters = {
  getCalendarItems: (state) => {
    return state.calendarList
  },
  getInstrumentType: (state) => {
    return state.instrumentType
  },
  getInstrumentClass: (state) => {
    return state.instrumentClass
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
