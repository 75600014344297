import { render, staticRenderFns } from "./QuoteFooter.vue?vue&type=template&id=bd0faea8&scoped=true&"
import script from "./QuoteFooter.vue?vue&type=script&lang=js&"
export * from "./QuoteFooter.vue?vue&type=script&lang=js&"
import style0 from "./QuoteFooter.vue?vue&type=style&index=0&id=bd0faea8&lang=scss&scoped=true&"
import style1 from "./QuoteFooter.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd0faea8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMinus: require('/app/components/Icon/Minus.vue').default,IconClose: require('/app/components/Icon/Close.vue').default,Tab: require('/app/components/NavTabs/Tab.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default,HomeQuoteTabRecent: require('/app/components/Pages/Home/Quote/QuoteTabRecent.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,HomeQuoteTabFavorite: require('/app/components/Pages/Home/Quote/QuoteTabFavorite.vue').default,HomeQuoteTabVirtualPortfolio: require('/app/components/Pages/Home/Quote/QuoteTabVirtualPortfolio.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default})
