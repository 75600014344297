//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeQuoteButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  }
}
