import { render, staticRenderFns } from "./ModalDeleteFavourite.vue?vue&type=template&id=c0aa9f48&scoped=true&"
import script from "./ModalDeleteFavourite.vue?vue&type=script&lang=js&"
export * from "./ModalDeleteFavourite.vue?vue&type=script&lang=js&"
import style0 from "./ModalDeleteFavourite.vue?vue&type=style&index=0&id=c0aa9f48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0aa9f48",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClose: require('/app/components/Icon/Close.vue').default,Button: require('/app/components/Button/Button.vue').default,Button: require('/app/components/Button/Button.vue').default,IconBin: require('/app/components/Icon/Bin.vue').default,Modal: require('/app/components/Modal/Modal.vue').default})
