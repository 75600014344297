/* Helper gobal */
/**
 * get only domain
 * @param {String} url
 * @return {String} host name
 */
const getDomainFromURL = (url) => {
  if (!url) {
    return ''
  }
  const d = new URL(url)
  return d.hostname || ''
}
/**
 * Handle invalid path
 * @param {String} path
 * @returns Boolean
 */
const isInvalidPath = (path) => {
  const splitPath = path.split('/')
  let inValid = false
  if (splitPath.length >= 3) {
    inValid = /^\?/i.test(splitPath[2]) || !splitPath[2]
  }
  return inValid
}

/**
 * Return a new object
 * @param {object} base
 * @param {object} extra
 * @returns {object}
 */
const mergeObject = function (base, extra) {
  if (extra && typeof extra === 'object') {
    Object.assign(base, extra)
  }
  return base
}
/**
 * clone data like a cloneDeep
 * @param {any} data
 * @returns {any}
 */
const cloneObject = (data) => {
  return JSON.parse(JSON.stringify(data))
}

/**
 * Number with commas
 * @param {number} number
 * @returns number with commas
 */
const numberWithCommas = function (number) {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return '-'
  }
}

/**
 * set attr tag a
 * @param {Object} data // menu
 * @returns object attr
 */
const setAttributeLink = function (data) {
  const attr = {
    href: null,
    target: null,
    rel: null
  }
  if (data) {
    let openNewTab = false
    if ('openNewTab' in data) {
      openNewTab = data.openNewTab
    } else if ('link_external' in data) {
      openNewTab = data.link_external // type number(1 = '_blank', 0 = '_self')
    }

    let rel = null
    let target = '_self'
    if (openNewTab) {
      rel = 'noopener noreferrer'
      target = '_blank'
    }
    attr.rel = rel
    attr.target = target
    if (data.url) {
      attr.href = data.url
    }
  }
  // if (menu) {
  //   if (menu.openNewTab) {
  //     attr.rel = 'noopener noreferrer'
  //   }
  //   attr.target = menu.openNewTab ? '_blank' : '_self'
  //   if (menu.url) {
  //     attr.href = menu.url
  //   }
  // }
  return attr
}
/**
 * set attr tag a
 * @param {Object} data // banner
 * @returns object attr
 */
const setBannerItemAttrLink = function (data) {
  const attr = {
    href: null,
    target: null,
    rel: null
  }
  if (data) {
    let openNewTab = false
    if ('openNewTab' in data) {
      openNewTab = data.openNewTab
    } else if ('link_external' in data) {
      openNewTab = data.link_external // type number(1 = '_blank', 0 = '_self')
    }

    let rel = null
    let target = '_self'
    if (openNewTab) {
      rel = 'noopener noreferrer'
      target = '_blank'
    }
    attr.rel = rel
    attr.target = target
    if (data.url && data.url !== '#') {
      attr.href = data.url
    }
  }
  return attr
}

const integerStyle = (number) => {
  const regex = /(?=.*?\d)^\$?((^[-+]?[0-9]\d{0,20}(,\d{3})*)|\d+)?(\.\d{1,})?$/
  const value =
  regex.test(number) &&
  Number(typeof number === 'string' ? number.split(',').join('') : number)
  if (value === 0) {
    return 'theme-normal'
  } else if (value < 0) {
    return 'theme-danger'
  } else if (value > 0) {
    return 'theme-success'
  } else {
    return ''
  }
}
const integerBgStyle = (number) => {
  const regex = /(?=.*?\d)^\$?((^[-+]?[0-9]\d{0,20}(,\d{3})*)|\d+)?(\.\d{1,})?$/
  const value =
  regex.test(number) &&
  Number(typeof number === 'string' ? number.split(',').join('') : number)
  if (value === 0) {
    return 'theme-normal-bg'
  } else if (value < 0) {
    return 'theme-danger-bg'
  } else if (value > 0) {
    return 'theme-success-bg'
  } else {
    return ''
  }
}

const numberColor = (number) => {
  if (Number(number) > 0) {
    return 'text-positive'
  } else if (Number(number) < 0) {
    return 'text-negative'
  } else {
    return 'text-deep-gray'
  }
}

const integerTradingviewStyle = (number) => {
  if (Number(number) === 0) {
    return 'theme-normal'
  } else if (Number(number) < 0) {
    return 'tdv-theme-danger'
  } else {
    return 'tdv-theme-success'
  }
}

const integerLevelStyle = (number) => {
  if (number < -2) {
    return 'theme-danger-3'
  } else if (number < -1 && number >= -2) {
    return 'theme-danger-2'
  } else if (number < 0 && number >= -1) {
    return 'theme-danger-1'
  } else if (number <= 1 && number > 0) {
    return 'theme-success-1'
  } else if (number <= 2 && number > 1) {
    return 'theme-success-2'
  } else if (number > 2) {
    return 'theme-success-3'
  } else {
    return 'theme-normal'
  }
}

const formatNumber = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    })
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}
/**
 * This function support real numbers
 * @param {Number} value
 * @returns String number format of real numbers
 */
const formatNumberWithCommas = (value) => {
  // การใช้ value.toLocaleString() มีดารตัดทศนิยมที่ไม่จำเป็นโดยอัตโนมัติ
  if (typeof value === 'number') {
    const numberString = value.toString()
    const parts = numberString.split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}
const formatNumberInteger = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value > 0
      ? '+' +
          value.toLocaleString(undefined, {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
          })
      : value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      })
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}

const formatNumberPercent = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value > 0
      ? '+' +
          value.toLocaleString(undefined, {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
          }) +
          '%'
      : value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }) + '%'
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}

function dashIfEmpty (value) {
  if (value === null || value === undefined || value === '') {
    return '-'
  }

  return value
}

const openTab = (url, target) => {
  // Create link in memory
  const a = window.document.createElement('a')
  a.target = target || '_blank'
  a.href = url

  // Dispatch fake click
  const e = window.document.createEvent('MouseEvents')
  e.initMouseEvent(
    'click',
    true,
    true,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  )
  a.dispatchEvent(e)
}

/**
 * Set client id
 * @param {String} oldClientUuid
 * @returns clientUuid
 */
const setClientId = async (oldClientUuid) => {
  const { uuid } = await require('@/utils')
  const clientUuid = oldClientUuid
  const already = !!clientUuid
  let newClientUuid = clientUuid
  if (already === false) {
    newClientUuid = uuid()
  }
  return newClientUuid
}

function hasValue (value) {
  if (value === null || value === undefined || value === '') {
    return false
  }

  return true
}
const handleImageError = (target, option, callback) => {
  const { src, ratio } = option
  if (!src) { return }
  let defaultImage = '/images/placeholder-16x9.jpg'
  if (ratio === '1x1') {
    defaultImage = '/images/placeholder-1x1.jpg'
  }
  if (ratio === '4x3') {
    defaultImage = '/images/placeholder-4x3.jpg'
  }
  target.src = defaultImage
  if (typeof callback === 'function') {
    callback()
  }
}
const removeTrailingSlash = (str) => {
  return str.replace(/\/+$/, '')
}
export {
  getDomainFromURL,
  mergeObject,
  cloneObject,
  numberWithCommas,
  setAttributeLink,
  setBannerItemAttrLink,
  integerStyle,
  integerBgStyle,
  integerTradingviewStyle,
  integerLevelStyle,
  formatNumber,
  formatNumberWithCommas,
  formatNumberInteger,
  formatNumberPercent,
  dashIfEmpty,
  openTab,
  setClientId,
  isInvalidPath,
  hasValue,
  numberColor,
  handleImageError,
  removeTrailingSlash
}
