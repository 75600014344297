//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { customAlphabet } from 'nanoid'
import { setBannerItemAttrLink } from '@/helpers'
import { personalizedBannerTrigger } from '@/helpers/banner'
export default {
  name: 'BannerItems',
  inject: ['showPaginationOutSide', 'eventTrackingName'],
  props: {
    width: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Boolean,
      default: false
    },
    navigation: {
      type: Boolean,
      default: false
    },
    transitionSec: {
      type: Number,
      default: 0
    },
    bannerName: {
      type: String,
      default: ''
    },
    bannerUuid: {
      type: String,
      default: ''
    }
  },
  data () {
    const nanoId = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 10)()
    return {
      ready: false,
      bannerId: `banner${nanoId}`,
      bannerSlideClass: `banner${this.bannerUuid}-slide`,
      bannerCarouselClass: `carousel-banner${this.bannerUuid}`,
      bannerPaginationClass: `banner${this.bannerUuid}-swiper-pagination`,
      swiperOptions: {}
    }
  },
  computed: {
    autoplay () {
      if (this.transitionSec) {
        return {
          delay: this.transitionSec * 1000,
          disableOnInteraction: false
        }
      }
      return false
    },
    styleWidth () {
      if (this.width > 0) {
        return {
          maxWidth: `${this.width}px`
        }
      }
      return null
    }
  },
  mounted () {
    this.setOptions()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    setBannerItemAttrLink,
    initSwiper (swiper) {
      this.handleSwiperResize(swiper)
      swiper.on('resize', () => {
        this.handleSwiperResize(swiper)
      })
      // personalizedBanner
      personalizedBannerTrigger(this.bannerUuid, () => {
        swiper.update()
        this.resetEvent()
      })
      this.registerEvent()
    },
    handleSwiperResize (swiper) {
      swiper.allowTouchMove = !swiper.isLocked
    },
    setOptions () {
      let options = {
        watchOverflow: true,
        loop: this.items.length > 1,
        autoplay: this.autoplay,
        showPagination: this.pagination && this.showPaginationOutSide === false && this.items.length > 1,
        showNavigation: this.navigation && this.items.length > 1,
        showNavigationOutSide: true,
        navigation: {
          nextEl: `.${this.bannerCarouselClass} .swiper-btn-next`,
          prevEl: `.${this.bannerCarouselClass} .swiper-btn-prev`
        }
      }
      if (this.pagination && this.showPaginationOutSide === true) {
        const pagination = {
          pagination: {
            el: `#${this.bannerId} .banner-slide-pagination`,
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + '"><div class="circle"></div></span>'
            }
          }
        }
        options = { ...options, ...pagination }
      }
      this.swiperOptions = options
      this.ready = true
    },
    async resetEvent () {
      await this.removeEvent()
      this.registerEvent()
    },
    bannerItemElList () {
      const itemElList = [].slice.call(document.querySelectorAll(`#${this.bannerId} .swiper-slide`))
      return itemElList
    },
    registerEvent () {
      this.addEventClickBannerItem()
    },
    removeEvent () {
      this.removeEventClickBannerItem()
    },
    addEventClickBannerItem () {
      const itemElList = this.bannerItemElList()
      itemElList.forEach((el, i) => {
        el.addEventListener('click', this.handleClickBannerItem)
      })
    },
    removeEventClickBannerItem () {
      const itemElList = this.bannerItemElList()
      itemElList.forEach((el) => {
        el.removeEventListener('click', this.handleClickBannerItem)
      })
    },
    handleClickBannerItem (e) {
      const el = e.currentTarget
      const { swiperSlideIndex } = el?.dataset || {}
      const item = this.items[swiperSlideIndex]
      // console.log('[DEBUG]', JSON.parse(JSON.stringify(item)))
      if (item) {
        this.handleClickSlideItem(item)
      }
    },
    handleClickSlideItem (item) {
      const { url } = item
      let eventTracking = item.eventTracking
      if ('event_tracking' in item) {
        const isEtk = item.event_tracking.is_etk
        eventTracking = { ...item.event_tracking, isEtk }
      }
      if (url) {
        this.handleClickTracking({
          title: item.name,
          eventTracking
        })
      }
      this.$content.updateClickCounter(item.bannerItemUuid)
    },
    handleClickTracking (data) {
      const eventTracking = data.eventTracking || {}
      const isEtk = eventTracking.isEtk
      if (isEtk) {
        const title = data.title
        if (this.eventTrackingName) {
          this.$personalized.clickElement({
            name: this.eventTrackingName,
            optionLang: {
              bannerName: this.bannerName,
              bannerItemName: title
            }
          })
        } else {
          const sectionName = this.bannerName ? `Banner - ${this.bannerName}` : ''
          this.$personalized.clickElement({
            title,
            sectionName
          })
        }
      }
    }
  }
}
