//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatNumber, formatNumberWithCommas } from '@/helpers/number'
const allowedEvents = new Set([
  // by normal keypress
  'insertText',
  'deleteContentBackward',
  'deleteContentForward',
  // by clipboard
  'insertFromPaste',
  'deleteByCut',
  // by mouse
  'insertFromDrop',
  'deleteByDrag'
])
export default {
  name: 'MiscMemberVirtualPortfolioInputNumber',
  props: {
    value: {
      type: Number,
      default: undefined
    },
    lowerBound: {
      type: Object,
      default: () => ({ value: 0, inclusive: true })
    },
    upperBound: {
      type: Object,
      default: () => ({ value: 1000000, inclusive: true })
    },
    digit: {
      type: Number,
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '0'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputValue: ''
    }
  },
  computed: {},
  watch: {
    value: {
      handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === undefined || newValue === null || Number.isNaN(newValue)) {
            this.inputValue = ''
          } else {
            const { text } = this.clamp(newValue)
            this.inputValue = text
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    clamp (value) {
      if (this.digit === null) {
        return { value, text: formatNumberWithCommas(value) }
      }
      const digitFactor = Math.pow(10, this.digit)
      const lowerBound = this.lowerBound.value * digitFactor + (this.lowerBound.inclusive ? 0 : 1)
      const upperBound = this.upperBound.value * digitFactor - (this.upperBound.inclusive ? 0 : 1)
      let num = value * digitFactor
      if (num < lowerBound) {
        num = lowerBound
      } else if (num > upperBound) {
        num = upperBound
      }
      const newNum = num.toString().split('.')
      if (newNum.length > 1 && newNum[1].toString().length > 6) {
        num = num + 0.1
      }
      num = Math.trunc(num)

      const sign = num < 0 ? '-' : ''
      num = Math.abs(num)
      const integer = Math.trunc(num / digitFactor)
      let numberValue
      let textValue
      if (digitFactor > 1) {
        const fractionalDigits = (Math.trunc(num % digitFactor) + '').padStart(this.digit, '0')
        numberValue = +`${sign}${integer}.${fractionalDigits}`
        textValue = `${sign}${formatNumber(integer, 0)}.${fractionalDigits}`
      } else {
        numberValue = +`${sign}${integer}`
        textValue = `${sign}${formatNumber(integer, 0)}`
      }
      return { value: numberValue, text: textValue }
    },
    beforeInput (e) {
      if (!allowedEvents.has(e.inputType)) {
        e.preventDefault()
        return
      }
      const guessedValue = guessNewValue()
      if (!/^-?\d*\.?\d*$/.test(guessedValue)) {
        e.preventDefault()
        return // eslint-disable-line
      }

      function guessNewValue () {
        const { selectionStart, selectionEnd } = e.target
        const eventType = e.inputType
        const value = e.target.value
        if (eventType === 'insertText' || eventType === 'insertFromDrop' || eventType === 'insertFromPaste') {
          return value.substring(0, selectionStart) + e.data + value.substring(selectionEnd, value.length)
        } else if (eventType === 'deleteContentBackward') {
          if (selectionStart === selectionEnd) {
            return value.substring(0, selectionStart - 1) + value.substring(selectionEnd, value.length)
          } else {
            return value.substring(0, selectionStart) + value.substring(selectionEnd, value.length)
          }
        } else if (eventType === 'deleteContentForward') {
          if (selectionStart === selectionEnd) {
            return value.substring(0, selectionStart) + value.substring(selectionEnd + 1, value.length)
          } else {
            return value.substring(0, selectionStart) + value.substring(selectionEnd, value.length)
          }
        } else if (eventType === 'deleteByDrag' || eventType === 'deleteByCut') {
          return value.substring(0, selectionStart) + value.substring(selectionEnd, value.length)
        } else {
          return value
        }
      }
    },
    onFocus (e) {
      e.target.value = this.value ?? ''
    },
    onBlur (e) {
      const elementValue = e.target.value
      e.target.value = this.inputValue

      if (!elementValue || elementValue.length === 0) {
        this.$emit('input', undefined)
        return
      }

      const changedValue = +elementValue
      if (Number.isNaN(changedValue)) {
        this.$emit('input', undefined)
        return
      }

      const { value } = this.clamp(changedValue)
      this.$emit('input', value)
    }
  }
}
