//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ObserverAdsAts } from '@/helpers/ads'

export default {
  head () {
    return this.$embedded.embedded.default()
  },
  computed: {
    isDesktop () {
      return this.$device.isDesktop
    }
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler (newPage, oldPage) {
        const { contentDataLayer: { currentPath } } = this.$store.state
        const isPush = process.client && currentPath !== newPage
        if (isPush) {
          this.$personalized.pagePersonalized()
        }
      }
    }
  },
  mounted () {
    if (this.$device.isMobile === true) {
      ObserverAdsAts()
    }
  }
}
