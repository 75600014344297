//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dropdownShown } from '@/directives/dropdown'
import { formatSecurityType } from '@/helpers/utilities'
export default {
  name: 'MiscMemberVirtualPortfolioInputSymbol',
  components: {
    TextHighlight: {
      props: {
        text: {
          type: String,
          default: ''
        },
        highlight: {
          type: String,
          default: ''
        }
      },
      render (h) {
        const parts = []
        let text = this.text
        if (
          this.highlight &&
          this.highlight.length > 0 &&
          new RegExp(`^${this.highlight}`, 'i').test(this.text)
        ) {
          const highlightedText = text.substr(0, this.highlight.length)
          text = text.substr(this.highlight.length)
          const span = h(
            'span',
            {
              class: {
                'text-highlight': true
              }
            },
            highlightedText
          )
          parts.push(span)
        }
        parts.push(text)
        return h('span', parts)
      }
    }
  },
  directives: {
    unfocus: {
      update (el, binding, vnode) {
        // [new]unfocusTracker != [old]unfocusTracker
        if (binding.value !== binding.oldValue) {
          el.blur()
        }
      }
    },
    dropdownShown
  },
  props: {
    placeholder: {
      type: String,
      default: 'ค้นหาหลักทรัพย์'
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentInputValue: '',
      isInputFocused: false,
      unfocusTracker: 0,
      showDropdown: false,
      showIconSearch: true,
      currentSelectionIndex: 0,
      inputSymbolId: `input-symbol-${(Math.random() * 1000).toFixed()}`
    }
  },
  computed: {
    filteredItems () {
      return [...filter.call(this, 700)]

      function* filter (limit) {
        let counter = 0
        const regex = new RegExp(`^${this.currentInputValue}`, 'i')
        for (const option of this.options) {
          // if (option.symbol === this.value) {
          //   continue
          // }
          if (!regex.test(option.symbol)) {
            continue
          }
          yield option

          counter++
          if (limit && counter >= limit) {
            break
          }
        }
      }
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentInputValue = newValue ?? ''
      }
    },
    isInputFocused (newValue, oldValue) {
      if (newValue !== oldValue && newValue === false) {
        this.unfocusTracker++
      }
    }
  },
  methods: {
    formatSecurityType,
    onInput ($event) {
      this.showDropdown = true
      this.currentInputValue = $event.target.value
      this.setCurrentSelectionIndex()
    },
    onFocus () {
      this.showDropdown = true
      this.isInputFocused = true
    },
    onBlur () {
      if (this.showDropdown) {
        this.currentInputValue = this.value ?? ''
        this.showDropdown = true
        this.isInputFocused = true
      }
    },
    selectItem (item) {
      this.currentInputValue = item.symbol
      this.$emit('input', item.symbol)
      this.isInputFocused = false
      this.showDropdown = false
    },
    onClear () {
      this.currentInputValue = ''
      this.$emit('input', undefined)
    },
    onEnter () {
      if (
        this.isInputFocused &&
        this.currentSelectionIndex < this.filteredItems.length
      ) {
        this.selectItem(this.filteredItems[this.currentSelectionIndex])
      }
    },
    onArrowDown () {
      if (
        this.isInputFocused &&
        this.currentSelectionIndex < this.filteredItems.length - 1
      ) {
        this.currentSelectionIndex++
        this.scrollSelectionIntoView()
      }
    },
    onArrowUp () {
      if (this.isInputFocused && this.currentSelectionIndex > 0) {
        this.currentSelectionIndex--
        this.scrollSelectionIntoView()
      }
    },
    scrollSelectionIntoView () {
      setTimeout(() => {
        const listNode = document.querySelector(
          `#${this.inputSymbolId} .dropdown-menu`
        )
        const activeNode = document.querySelector(
          `#${this.inputSymbolId} .dropdown-menu .dropdown-search-item-active`
        )
        if (
          !(
            activeNode.offsetTop >= listNode.scrollTop &&
            activeNode.offsetTop + activeNode.offsetHeight <
              listNode.scrollTop + listNode.offsetHeight
          )
        ) {
          let scrollTo = 0
          if (activeNode.offsetTop > listNode.scrollTop) {
            scrollTo =
              activeNode.offsetTop +
              activeNode.offsetHeight -
              listNode.offsetHeight
          } else if (activeNode.offsetTop < listNode.scrollTop) {
            scrollTo = activeNode.offsetTop
          }
          listNode.scrollTo(0, scrollTo)
        }
      })
    },
    setCurrentSelectionIndex () {
      if (
        this.isInputFocused &&
        this.currentSelectionIndex >= this.filteredItems.length
      ) {
        this.currentSelectionIndex = (this.filteredItems.length || 1) - 1
      }
    },
    handleOutsideClick () {
      this.isInputFocused = false
      this.showDropdown = false
    }
  }
}
