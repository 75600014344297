const countLastEntry = async (store, payload) => {
  const data = await store.dispatch('popular/countPopular', payload)

  return data
}

const CalDateDiff = (Date1, Date2) => {
  const ms = Date1 - Date2
  let h, m, s
  s = Math.floor(ms / 1000)
  m = Math.floor(s / 60)
  s = s % 60
  h = Math.floor(m / 60)
  m = m % 60
  const d = Math.floor(h / 24)
  h = h % 24

  return {
    day: d,
    hour: h,
    minute: m,
    second: s
  }
}

const localStoragesGetItem = (name) => {
  if (name) {
    const result = localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : []
    return result
  }

  return []
}

const localStoragesSetItem = (name, data) => {
  if (name && data) {
    localStorage.setItem(name, JSON.stringify(data))
  }
}

export default (context, inject) => {
  const { store } = context
  const plugin = {
    /**
     * Check Popular Quote
     * @param {*} routeData
     */
    async check (routeData) {
      const lastEntryCookie = localStoragesGetItem('pop_last_count')
      const cookieData = localStoragesGetItem('pop_count_syminfo')
      const channelCodeParam = process.env.WEB_CHANNEL

      // console.log('popQuote check data', !cookieData)

      if (cookieData == null || cookieData.length <= 0) {
        if (lastEntryCookie == null || lastEntryCookie.length <= 0) {
          // console.log('not have cookie and entry cookie.')
          // await add count site here (Wait api)
          // const canCountData = true
          const canCountData = await countLastEntry(store, {
            symbol: routeData.symbol,
            market: routeData.market,
            securityType: routeData.securityType,
            uuid: null,
            channelCode: channelCodeParam,
            lastCount: new Date().toUTCString()
          })

          // create cookie
          if (canCountData && canCountData.data != null) {
            // console.log('not have cookie and entry cookie.', 'set cookie')
            const arrData = []
            const arrLastCount = []
            arrLastCount.push({
              symbol: canCountData.data.symbol,
              lastCount: canCountData.data.lastCount
            })
            arrData.push(canCountData.data)
            localStoragesSetItem('pop_count_syminfo', arrData)
            localStoragesSetItem('pop_last_count', arrLastCount)
          }
        } else if (lastEntryCookie && lastEntryCookie.length > 0) {
          // console.log('not have cookie but have entry cookie.')
          const lastConutDate = lastEntryCookie.find(data => data.symbol !== undefined && data.symbol.toLowerCase() === routeData.symbol.toLowerCase())

          if (lastConutDate) {
            // console.log('not have cookie but have entry cookie.', 'lastcount have data')
            const nowUTC = new Date(new Date().toUTCString())
            const DateDiff = CalDateDiff(nowUTC, new Date(lastConutDate.lastCount))

            if (DateDiff.day >= 1) {
              // console.log('not have cookie but have entry cookie.', 'over 12 hour')
              const canCountData = await store.dispatch('popular/countPopular', {
                symbol: routeData.symbol,
                market: routeData.market,
                securityType: routeData.securityType,
                uuid: null,
                channelCode: channelCodeParam,
                lastCount: new Date().toUTCString()
              })

              if (canCountData && canCountData.data != null) {
                // console.log('not have cookie but have entry cookie', 'set cookie')
                const indexOfLastCount = lastEntryCookie.findIndex(data => data.symbol.toLowerCase() === lastConutDate.symbol.toLowerCase())
                const datas = lastEntryCookie

                if (indexOfLastCount > -1) {
                  // console.log('not have cookie but have entry cookie', 'set lastcount cookie')
                  // datas[indexOfLastCount].lastCount = new Date().toUTCString()
                  datas[indexOfLastCount].lastCount = canCountData.data.lastCount
                  localStoragesSetItem('pop_last_count', datas)
                }

                const arrData = []
                arrData.push(canCountData.data)
                localStoragesSetItem('pop_count_syminfo', arrData)
              }
            }
          } else {
            // console.log('not have cookie but have entry cookie.', 'lastcount not have data')
            // await add count site here (Wait api)
            // const canCountData = true
            const canCountData = await store.dispatch('popular/countPopular', {
              symbol: routeData.symbol,
              market: routeData.market,
              securityType: routeData.securityType,
              uuid: null,
              channelCode: channelCodeParam,
              lastCount: new Date().toUTCString()
            })
            // // console.log('first create', canCountData)
            // create cookie
            if (canCountData && canCountData.data != null) {
              const arrData = []
              const arrLastCount = lastEntryCookie
              arrData.push(canCountData.data)
              arrLastCount.push({
                symbol: canCountData.data.symbol,
                lastCount: canCountData.data.lastCount
              })

              localStoragesSetItem('pop_count_syminfo', arrData)
              localStoragesSetItem('pop_last_count', arrLastCount)
            }
          }
        }
      } else if (cookieData && cookieData.length > 0) {
        // console.log('have cookie')
        const haveData = cookieData.find(data => data.market === routeData.market && data.symbol === routeData.symbol)

        if (!haveData) {
          // console.log('have cookie not have data')
          // await add count site here (Wait api)
          const canCountData = await store.dispatch('popular/countPopular', {
            symbol: routeData.symbol,
            market: routeData.market,
            securityType: routeData.securityType,
            uuid: null,
            channelCode: channelCodeParam,
            lastCount: new Date().toUTCString()
          })

          if (canCountData && canCountData.data != null) {
            if (lastEntryCookie == null && lastEntryCookie.length <= 0) {
              const arrLastCount = []
              arrLastCount.push({
                symbol: canCountData.data.symbol,
                lastCount: canCountData.data.lastCount
              })
              localStoragesSetItem('pop_last_count', arrLastCount)
            } else if (lastEntryCookie && lastEntryCookie.length > 0) {
              const lastConutDate = lastEntryCookie.find(data => data.symbol !== undefined && data.symbol.toLowerCase() === routeData.symbol.toLowerCase())

              if (lastConutDate) {
                const indexOfLastCount = lastEntryCookie.findIndex(data => data.symbol.toLowerCase() === lastConutDate.symbol.toLowerCase())
                const datas = lastEntryCookie

                if (indexOfLastCount > -1) {
                  // datas[indexOfLastCount].lastCount = new Date().toUTCString()
                  datas[indexOfLastCount].lastCount = canCountData.data.lastCount
                  localStoragesSetItem('pop_last_count', datas)
                }
              } else {
                const datas = lastEntryCookie
                datas.push({
                  symbol: canCountData.data.symbol,
                  lastCount: canCountData.data.lastCount
                })

                localStoragesSetItem('pop_last_count', datas)
              }
            }

            // push new data
            const cData = cookieData
            cData.push(canCountData.data)

            // create cookie
            localStoragesSetItem('pop_count_syminfo', cData)
          }
        } else {
          const lastConutDate = lastEntryCookie && lastEntryCookie.length > 0 ? lastEntryCookie.find(data => data.symbol !== undefined && data.symbol.toLowerCase() === haveData.symbol.toLowerCase()) : null
          // console.log('have cookie and have data', lastConutDate)

          if (lastConutDate) {
            const nowUTC = new Date(new Date().toUTCString())
            const DateDiff = CalDateDiff(nowUTC, new Date(lastConutDate.lastCount))
            // console.log('have cookie and have data', 'check in 12 hours', nowUTC, new Date(lastConutDate.lastCount), DateDiff)

            if (DateDiff.day >= 1) {
              // console.log('have cookie and have data and out 12 hours', 'set cookie')
              // await add count site here (Wait api)
              const canCountData = await store.dispatch('popular/countPopular', {
                symbol: routeData.symbol,
                market: routeData.market,
                securityType: routeData.securityType,
                uuid: haveData.uuid,
                channelCode: channelCodeParam,
                lastCount: new Date().toUTCString()
              })

              if (canCountData && canCountData.data != null) {
                // console.log('have cookie and have data', 'set cookie')
                if (lastEntryCookie == null && lastEntryCookie.length <= 0) {
                  // console.log('have cookie and have data but not have lastentry cookie', 'set cookie')
                  const arrLastCount = []
                  arrLastCount.push({
                    symbol: canCountData.data.symbol,
                    lastCount: canCountData.data.lastCount
                  })
                  localStoragesSetItem('pop_last_count', arrLastCount)
                } else if (lastEntryCookie && lastEntryCookie.length > 0) {
                  // console.log('have cookie, have data and have lastentry cookie', 'set cookie')
                  const lastConutDate = lastEntryCookie.find(data => data.symbol !== undefined && data.symbol.toLowerCase() === routeData.symbol.toLowerCase())

                  if (lastConutDate) {
                    const symbolLastCount = lastConutDate.symbol.toLowerCase()
                    const indexOfLastCount = lastEntryCookie.findIndex(data => data.symbol.toLowerCase() === symbolLastCount)
                    const datas = lastEntryCookie
                    // console.log('have cookie, have data, have lastentry cookie and have lastcount data', 'lastEntryData', lastEntryCookie, 'lastConutDate Data', lastConutDate, `indexOfLastCount ${indexOfLastCount}`)

                    if (indexOfLastCount > -1) {
                      // console.log('have cookie, have data, have lastentry cookie and have lastcount data', 'set lastcount cookie')
                      // datas[indexOfLastCount].lastCount = new Date().toUTCString()
                      datas[indexOfLastCount].lastCount = canCountData.data.lastCount
                      localStoragesSetItem('pop_last_count', datas)
                    }
                  } else {
                    // console.log('have cookie, have data, have lastentry cookie but not have lastcount data', 'set cookie')
                    const datas = lastEntryCookie
                    datas.push({
                      symbol: canCountData.data.symbol,
                      lastCount: canCountData.data.lastCount
                    })

                    localStoragesSetItem('pop_last_count', datas)
                  }
                }

                if (canCountData.data && canCountData.data.count) {
                  // console.log('have cookie and have data before update cookie', cookieData)
                  const mappedData = cookieData
                  const indexMappedData = mappedData.findIndex(data => data.symbol.toLowerCase() === canCountData.data.symbol.toLowerCase())
                  if (indexMappedData !== -1) {
                    // console.log('mapping data', indexMappedData)
                    mappedData[indexMappedData].count = canCountData.data.count
                    mappedData[indexMappedData].lastCount = canCountData.data.lastCount
                  }

                  // create cookie
                  localStoragesSetItem('pop_count_syminfo', mappedData)
                }
              }
            }
          } else {
            // console.log('have cookie and have data but last entry is blank or not have lastentry cookie', 'set last entry data but not count anything.', haveData)
            const arrLastCount = []
            arrLastCount.push({
              symbol: haveData.symbol,
              lastCount: haveData.lastCount
            })
            localStoragesSetItem('pop_last_count', arrLastCount)
          }
        }
      }
    }
  }

  inject('popularQuote', plugin)
  context.$popularQuote = plugin
}
