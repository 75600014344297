const personalizedBannerTrigger = (bannerUuid, fnCallback) => {
  const element = document.querySelector(`.banner-${bannerUuid}`)
  const observer = new IntersectionObserver(() => {
    // const compStyles = window.getComputedStyle(element)
    const banner = document.querySelector(`.banner-${bannerUuid} .swiper-wrapper`)
    if (banner && !banner.style.transform) {
      fnCallback()
    }
  }, { rootMargin: '250px 250px 250px 250px' })
  if (element) {
    observer.observe(element)
  }
}
export { personalizedBannerTrigger }
