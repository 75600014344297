// import dayjs from 'dayjs'
import { dayjs } from '@/helpers/dateTime'
import { marketListId } from '@/utils/marketlist'

const state = () => ({
  popularKeywords: [],
  popular: [],
  stocks: [],
  equityList: [], // is stockList
  tfexList: [],
  fundList: [],
  fundVirtualport: [],
  lastNews: [],
  news: []
})
const mutations = {
  setPopularKeywords (state, data) {
    state.popularKeywords = data
  },
  setPopular (state, data) {
    state.popular = data
  },
  setStocks (state, data) {
    state.stocks = data
  },
  setEquityList (state, data) {
    state.equityList = data
  },
  setTFEXList (state, data) {
    state.tfexList = data
  },
  setFundList (state, data) {
    state.fundList = data
  },
  setFundVirtualport  (state, data) {
    state.fundVirtualport = data
  },
  setLastNews (state, data) {
    state.lastNews = data
  },
  setNews (state, data) {
    state.news = data
  }
}

const actions = {
  async getPopularKeywords ({ commit }) {
    commit('setPopularKeywords', [])
    const type = 'PathStructure|ArticleDetail|ManageInfographic|VideoDetail|PodcastDetail|ELearning|EBookDetail|ManageContent|ResearchSET|News|ExternalPage'
    try {
      const res = await this.$apiCms.get(`/api/cms/v1/search/keywords-popular?types=${type}&language=${this.$i18n.locale}&pageSize=10&frequency=Weekly`)
      commit('setPopularKeywords', res.data || [])
    } catch (err) {
      commit('setPopularKeywords', [])
    }
  },
  getPopularQuote ({ state, dispatch, commit }) {
    let result = []
    this.$apiCms
      .get(
        'api/cms/v1/popularquote/getpopular?market=SET&securityType=S&limit=5'
      )
      .then((res) => {
        if (res && res.data.length) {
          let popular = res.data
          popular = popular.map((item, index) => {
            const checkIsQuote = state.stocks.find(
              q =>
                item.symbol &&
                q.symbol &&
                q.symbol.replace(/\s/g, '').toUpperCase() ===
                  item.symbol.replace(/\s/g, '').toUpperCase()
            )
            if (checkIsQuote) {
              return {
                ...item,
                securityType: checkIsQuote.securityType,
                nameTH: checkIsQuote.nameTH,
                nameEn: checkIsQuote.nameEN
              }
            } else {
              return {
                ...item,
                securityType: '',
                nameTH: '',
                nameEn: ''
              }
            }
          })
          result = popular
          commit('setPopular', result)
        }
      })
  },
  async getStockList ({ state, commit }) {
    const data = await Promise.allSettled([
      this.$axios.$get('api/set/stock/list'),
      this.$axios.$get('/api/set-fund/fund/active-fund/list'),
      this.$axios.$get('/api/set/tfex/series/list')
    ]).then((data) => {
      const [{ value: stock }, { value: funds }, { value: tfex }] = data
      const stockData = stock ? stock.securitySymbols : []
      const fundsData = funds ? funds.funds : []
      const tfexData = tfex ? tfex.series : []

      const tfexList = tfexData?.map((data) => {
        const expiredDate = data.lastTradingDate
        const month = dayjs(expiredDate).locale('th').format('MMM')
        const year = dayjs(expiredDate).locale('th').format('BBBB')
        return {
          ...data,
          isTFEXSeries: true,
          symbol: data.symbol,
          nameTH: `เดือนหมดอายุ: ${month} ${year}`,
          nameEN: `เดือนหมดอายุ: ${month} ${year}`,
          securityType: data.marketListId ? data.marketListId : marketListId.SF // Some Future
        }
      }) // future, option
      const fundList = fundsData?.map((data) => {
        return {
          ...data,
          isFund: true,
          securityType: 'FUND'
        }
      }) // กองทุนรวม
      commit('setEquityList', stockData)
      commit('setTFEXList', tfexList)
      commit('setFundList', fundList)

      return stockData
        .concat(tfexList)
        .concat(fundList)
    })
    commit('setStocks', data)
  },
  async getFundVirtualport ({ state, commit }) {
    const data = await Promise.allSettled([
      this.$axios.$get('/api/set-fund/fund/virtualport/list')
    ]).then((data) => {
      const [{ value: funds }] = data
      const fundsData = funds ? funds.funds : []
      const fundList = fundsData?.map((data) => {
        return {
          ...data,
          securityType: 'FUND'
        }
      }) // กองทุนรวม
      commit('setFundVirtualport', fundList)

      return fundList
    })
    commit('setFundVirtualport', data)
  },
  async getLastNews ({ state, commit }) {
    if (!state.lastNews.length) {
      // await this.$axios.get('api/set/news/search?sourceId=company&perPage=4')
      const allowedPeriod = await this.$axios.$get('/api/set/news/allowed-period')
      const fromDate = dayjs(allowedPeriod[1]).format('DD/MM/YYYY')
      const toDate = fromDate
      await this.$apiCms.get('/api/cms/v1/news/all', {
        params: {
          lang: this.$i18n.local,
          newsSource: 'SETAPI',
          pageIndex: 0,
          pageSize: 4,
          fromDate,
          toDate
        }
      }).then((res) => {
        if (res.status !== 500 && res.data?.items) {
          commit('setLastNews', res.data?.items)
        } else {
          commit('setLastNews', [])
        }
      }).catch(err => err)
    }
  },
  async getNews ({ state, commit }, quote) {
    const allowedPeriod = await this.$axios.$get('/api/set/news/allowed-period')
    const fromDate = dayjs(allowedPeriod[1]).format('DD/MM/YYYY')
    const toDate = fromDate
    await this.$apiCms.get('/api/cms/v1/news/all', {
      params: {
        lang: this.$i18n.local,
        newsSource: 'SETAPI',
        search: quote,
        pageIndex: 0,
        pageSize: 4,
        fromDate,
        toDate
      }
    }).then((res) => {
      if (res.status !== 500 && res.data?.items) {
        commit('setNews', res.data?.items)
      } else {
        commit('setNews', [])
      }
    }).catch(err => err)
  }
}

const getters = {
  getPopularKeywords (state) {
    return state.popularKeywords
  },
  getPopular (state) {
    return state.popular
  },
  getStocks (state) {
    return state.stocks
  },
  getEquityList (state) {
    return state.equityList
  },
  getTFEXList (state) {
    return state.tfexList
  },
  getFundList (state) {
    return state.fundList
  },
  getFundVirtualport (state) {
    return state.fundVirtualport
  },
  getLastNews: (state) => {
    return state.lastNews ? state.lastNews : null
  },
  getNews: (state) => {
    return state.news ? state.news : null
  }
}

export default {
  actions,
  mutations,
  state,
  getters
}
