//
//
//
//

export default {
  name: 'Divider',
  props: {
    borderWidth: {
      type: Number,
      default: 1
    },
    borderStyle: {
      type: String,
      default: 'solid'
    },
    borderColor: {
      type: String,
      default: '#717478'
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dividerStyle () {
      let style = {}
      const borderCSS = `${this.borderWidth}px ${this.borderStyle} ${this.borderColor}`
      if (this.vertical === true) {
        style = {
          borderRight: borderCSS,
          height: '100%',
          display: 'inline-block'
        }
      } else {
        style = {
          borderTop: borderCSS,
          width: '100%'
        }
      }
      return style
    }
  }
}
