//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import dayjs from 'dayjs'
import { formatNumberInteger, formatNumber } from '@/helpers/number'
import { dayjs as _dayjs } from '@/helpers/dateTime'
// require('dayjs/locale/th')
export default {
  name: 'HeaderMarketSummary',
  data () {
    return {
      loading: true,
      setData: [],
      setDataMai: [],
      setMarket: {
        setDropdownText: '',
        datetime: '',
        market_status: '',
        setLast: '',
        setChange: '',
        setPerChange: '',
        setVolume: '',
        setValue: ''
      },
      marqueeIndex: 0,
      intervalTimer: null
    }
  },

  computed: {
    marqueeListLength () {
      return this.setData.length || 0
    }
  },

  mounted () {
    // await this.getMarketSummary()
    // if (this.setData[0] && this.setData[0].marketStatus !== 'Closed') {
    //   this.startUpdateStatus()
    // }
    this.observeWindowLoaded()
  },

  beforeDestroy () {
    this.intervalTimer && clearInterval(this.intervalTimer)
  },

  destroyed () {
    this.intervalTimer && clearInterval(this.intervalTimer)
  },
  methods: {
    formatNumberInteger,
    formatNumber,
    observeWindowLoaded () {
      // let status = 'after window load'
      if (this.$store.state.windowLoaded === true) {
        setTimeout(() => {
          this.init()
        }, 1000)
      } else {
        // status = 'trigger load'
        setTimeout(() => {
          this.init()
        }, 1000)
      }
      // console.log(status)
    },
    async init () {
      await this.getMarketSummary()
      if (this.setData[0] && this.setData[0].marketStatus !== 'Closed') {
        this.startUpdateStatus()
      }
      this.loading = false
    },
    async getMarketSummary () {
      await this.$axios
        .get('api/set/index/info/list?type=INDEX')
        .then((res) => {
          this.setData = res.data.indexIndustrySectors || []
          const setMkData = this.setData[this.marqueeIndex]
          this.setMarketData(setMkData)
        })
        .catch(() => {
          this.setData = []
        })
    },

    setMarketData (data) {
      const setMkData = data || {}
      this.setMarket = {
        setDropdownText: setMkData ? setMkData.querySymbol : 0,
        datetime: setMkData && setMkData.marketDateTime !== null
          ? _dayjs(setMkData.marketDateTime)
            .locale(this.$i18n.locale)
            .format('D MMM BBBB HH:mm:ss')
          : '-', // dayjs().locale(this.$i18n.locale).format('D MMM BBBB HH:mm:ss'),
        marketStatus: setMkData
          ? setMkData.marketStatus !== ''
            ? setMkData.marketStatus
            : 'Closed'
          : 'Closed',
        setLast: setMkData ? setMkData.last : 0,
        setChange: setMkData ? setMkData.change : 0,
        setPerChange: setMkData ? setMkData.percentChange : 0,
        setVolume: setMkData ? setMkData.volume : 0,
        setValue: setMkData ? setMkData.value : 0
      }
    },

    startUpdateStatus () {
      this.intervalTimer = setInterval(async () => {
        if (this.marqueeIndex === this.marqueeListLength - 1) {
          this.marqueeIndex = 0
          await this.getMarketSummary()
          this.changeStatus()
        } else {
          ++this.marqueeIndex
          await this.getMarketSummary()
          this.changeStatus()
        }
      }, 5000)
    },

    changeStatus () {
      const eleLast = document.querySelectorAll('.marquee-active')
      // add class active
      eleLast.forEach((ele) => {
        ele.classList.add('active')
      })
      // remove class active
      setTimeout(() => {
        eleLast.forEach((ele) => {
          ele.classList.remove('active')
        })
      }, 250)
    },

    integerStyle (number) {
      if (Number(number) === 0) {
        return 'theme-normal'
      } else if (Number(number) < 0) {
        return 'theme-danger'
      } else {
        return 'theme-success'
      }
    },

    async onChange (index) {
      this.marqueeIndex = index
      await this.getMarketSummary()
      this.changeStatus()
    },

    checkMarketStatus (status) {
      return status
        ? status.includes('Open')
          ? 'market-status-open'
          : 'market-status-closed'
        : 'market-status-closed'
    },

    mouseoverHandle () {
      this.intervalTimer && clearInterval(this.intervalTimer)
    },

    mouseleaveHandel () {
      if (this.setData[0] && this.setData[0].marketStatus) {
        this.startUpdateStatus()
      }
    }
  }
}
