// import * as api from '@/helpers/mutualfund/api.js'

const getDefaultState = () => {
  return {
    equityList: [],
    derivativeList: [],
    fundList: [],
    marketList: [],
    instrumentType: [],
    loading: {
      primary: false,
      secondary: false
    },

    // ใช้ pass data ของแต่ละ item ในตาราง พอร์ตหุ้นจำลองของฉัน
    modalOrder: {
      /**
       * stock/fund type
       * orderType: Number, // 1 = Buy, 2 = Sell
       * show: Boolean,
       * data: Object
       */
      stock: null,
      fund: null
    }
  }
}

const state = getDefaultState

const mutations = {
  setModalOrderStockData (state, data) {
    state.modalOrder.stock = data
  },
  setModalOrserFundData (state, data) {
    state.modalOrder.fund = data
  },
  setEquityList (state, data) {
    state.equityList = data
  },
  setDerivativeList (state, data) {
    state.derivativeList = data
  },
  setFundList (state, data) {
    state.fundList = data
  },
  setMarketList (state, data) {
    state.marketList = data
  },
  setInstrumentType (state, data) {
    state.instrumentType = data
  },
  setLoadingPrimary (state, data) {
    state.loading.primary = data
  },
  setLoadingSecondary (state, data) {
    state.loading.secondary = data
  }
}

const actions = {
  // async getEquityList ({ commit }) {
  //   const response = await this.$axios.$get('/api/set/stock/list', { params: { securityType: 's' } })
  //     .then(x => x.securitySymbols)
  //     .catch(() => [])
  //   commit('setEquityList', response)
  // },
  // async getDerivativeList ({ commit }) {
  //   const response = await this.$axios.$get('/api/set/tfex/series/list')
  //     .then(x => x.series)
  //     .catch(() => [])
  //   commit('setDerivativeList', response)
  // },
  // async getFundList ({ commit }) {
  //   const response = await api.getActiveFundList.call(this)
  //     .then(x => x.funds)
  //     .catch(() => [])
  //   commit('setFundList', response)
  // },
  async getMarketList ({ commit }) {
    const response = await this.$axios
      .$get('/api/set/tfex/market/list')
      .catch(() => [])
    commit('setMarketList', response)
  },
  async getInstrumentType ({ commit }, marketListId) {
    const response = await this.$axios
      .$get('/api/set/tfex/series-search/instrument', {
        params: { marketListId }
      })
      .then(x => x.instruments)
      .catch(() => [])
    commit('setInstrumentType', response)
  }
}

const getters = {
  loadingAll (state) {
    return state.loading.secondary || state.loading.primary
  },
  getModalOrderStockData (state) {
    return state.modalOrder.stock
  },
  getModalOrderFundData (state) {
    return state.modalOrder.fund
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
