//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeQuoteFooter',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      quoteTab: [
        {
          title: 'Recently Viewed',
          active: true,
          tabName: 'recently',
          controls: 'qoute-detail-tab-pane-1',
          key: 0
        },
        {
          title: 'Favourite',
          active: false,
          tabName: 'favourite',
          controls: 'qoute-detail-tab-pane-2',
          key: 0
        },
        {
          title: 'Virtual Portfolio',
          active: false,
          tabName: 'virtual',
          controls: 'qoute-detail-tab-pane-3',
          key: 0
        }
      ],
      swiperOption: {
        showPagination: false,
        showNavigation: true,
        showNavigationOutSide: true,
        loop: false,
        spaceBetween: 1,
        slidesPerView: 'auto',
        slidesPerGroup: 4,
        navigation: {
          nextEl: '.my-quote-detail .swiper-btn-next',
          prevEl: '.my-quote-detail .swiper-btn-prev'
        },
        breakpoints: {
          960: {
            slidesPerGroup: 5
          }
        }
      },
      swiperOptionFund: {
        showPagination: false,
        showNavigation: true,
        showNavigationOutSide: true,
        loop: false,
        spaceBetween: 1,
        slidesPerView: 3,
        slidesPerGroup: 3,
        navigation: {
          nextEl: '.my-quote-detail .swiper-btn-next',
          prevEl: '.my-quote-detail .swiper-btn-prev'
        },
        breakpoints: {
          1980: {
            slidesPerView: 8,
            slidesPerGroup: 5
          },
          1710: {
            slidesPerView: 7,
            slidesPerGroup: 5
          },
          1520: {
            slidesPerView: 6,
            slidesPerGroup: 5
          },
          1350: {
            slidesPerView: 5,
            slidesPerGroup: 5
          },
          1150: {
            slidesPerView: 4,
            slidesPerGroup: 4
          },
          900: {
            slidesPerView: 3,
            slidesPerGroup: 4
          }
        }
      },
      recentlyList: [],
      keyRerender: 0,
      openCountQuoteTabRecent: 0,
      openCountQuoteTabFavorite: 0,
      openCountQuoteTabVirtualPortfolio: 0,
      isSlide: false
    }
  },
  computed: {
    equityList () {
      return this.$virtualPort?.state?.equity
    },
    tfexList () {
      return this.$virtualPort?.state?.tfex
    },
    fundPortList () {
      return this.$virtualPort?.state?.fund
    },
    currentTab () {
      return this.quoteTab.find(data => data.active).title
    },
    favourite () {
      return this.$favourite?.state ?? {}
    },
    category () {
      return this.$favourite?.category ?? []
    },
    favouriteCount () {
      return this.category.map(key => (this.favourite[key] ?? []).length).reduce((a, b) => a + b, 0)
    },
    isShowViewMore () {
      if (this.currentTab === 'Recently Viewed') {
        return this.recentlyList.length
      } else if (this.currentTab === 'Favourite') {
        return this.favouriteCount !== 0
      } else if (this.currentTab === 'Virtual Portfolio') {
        return this.equityList?.equityPortfolios?.length || this.fundPortList?.portfolios?.length || this.tfexList?.futures?.portfolio.length || this.tfexList?.options?.portfolio?.length
      }
      return false
    }
  },
  watch: {
    isActive (value) {
      if (value) {
        this.initialTab()
      }
      this.isSlide = true
      setTimeout(() => {
        this.isSlide = false
      }, 1000)
    }
  },
  async mounted () {
    this.recentlyList = await this.$recently.getList()
    if (this.isActive) {
      this.initialTab()
    }
  },
  methods: {
    initialTab () {
      const tabName = localStorage.getItem('isOpenMyQuote')
      if (tabName) {
        this.changeTab(tabName)
      } else {
        this.changeTab('recently') // default tab
      }
    },
    changeTab (tabName) {
      if (tabName === 'recently') {
        this.openCountQuoteTabRecent += 1
      } else if (tabName === 'favourite') {
        this.openCountQuoteTabFavorite += 1
      } else if (tabName === 'virtual') {
        this.openCountQuoteTabVirtualPortfolio += 1
      } else {
        tabName = 'recently' // Default First Tab
        this.openCountQuoteTabRecent += 1
      }
      this.quoteTab = this.quoteTab.map((data) => {
        let active = false
        let key = data.key
        if (data.tabName === tabName) {
          active = true
          key += 1
        }
        return {
          ...data,
          key,
          active
        }
      })
      localStorage.setItem('isOpenMyQuote', tabName)
      // this.keyRerender += 1
    },
    toUrl () {
      window.open(this.getViewMoreUrl())
      this.$personalized.clickElement({
        name: 'myQuote4',
        optionLang: {
          menu: this.currentTab
        }
      })
    },
    getViewMoreUrl () {
      if (this.currentTab === 'Recently Viewed') {
        return this.localePath({
          name: 'recently-viewed'
        })
      } else if (this.currentTab === 'Favourite') {
        return this.localePath({
          name: 'member-favourite'
        })
      } else if (this.currentTab === 'Virtual Portfolio') {
        // const eleTabVirtualPortfolio = this.$refs.tabVirtualPortfolio
        // const selectedValue = eleTabVirtualPortfolio?.selectedValue ?? ''
        return this.localePath({
          name: 'member-virtual-portfolio'
          // query: {
          //   type: selectedValue
          // }
        })
      }
      return ''
    },
    clickEventChangeTab (tabName) {
      this.changeTab(tabName)
      this.$personalized.clickElement({
        name: 'myQuote1',
        optionLang: {
          menu: this.currentTab
        }
      })
    }
  }
}
