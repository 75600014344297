//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BannerAdvertisement',
  inject: ['showCaption'],
  props: {
    html: {
      type: String,
      default: null
    }
  }
}
