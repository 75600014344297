import { render, staticRenderFns } from "./MarketSummary.vue?vue&type=template&id=68d585f6&"
import script from "./MarketSummary.vue?vue&type=script&lang=js&"
export * from "./MarketSummary.vue?vue&type=script&lang=js&"
import style0 from "./MarketSummary.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Placeholder: require('/app/components/Placeholder/Placeholder.vue').default,IconFilterTable: require('/app/components/Icon/FilterTable.vue').default,Button: require('/app/components/Button/Button.vue').default,IconClock: require('/app/components/Icon/Clock.vue').default})
