const getDefaultState = () => {
  return {
    etfList: {},
    intradayOverview: {},
    underlying: [],
    ytdPercent: [],
    riskLevel: [],
    issuer: [],
    topGainer: null,
    topLoser: null,
    totalNav: null,
    topYTD: null,
    mostActiveValue: null,
    marketCap: null,
    thematic: []
  }
}

const state = getDefaultState

const mutations = {
  setETFList (state, data) {
    state.etfList = data
  },
  setIntradayOverview (state, data) {
    state.intradayOverview = data
  },
  setUnderlying (state, data) {
    state.underlying = data
  },
  setYtdPercent (state, data) {
    state.ytdPercent = data
  },
  setRiskLevel (state, data) {
    state.riskLevel = data
  },
  setIssuer (state, data) {
    state.issuer = data
  },
  setTopGainer (state, data) {
    state.topGainer = data
  },
  setTopLoser (state, data) {
    state.topLoser = data
  },
  setTotalNav (state, data) {
    state.totalNav = data
  },
  setTopYTD (state, data) {
    state.topYTD = data
  },
  setMostActiveValue (state, data) {
    state.mostActiveValue = data
  },
  setMarketCap (state, data) {
    state.marketCap = data
  },
  setThematic (state, data) {
    state.thematic = data
  }
}

const actions = {
  async getETFList ({ commit }, payload) {
    try {
      const {
        symbols,
        themeId,
        underlyingClassIds,
        ytdPercentChanges,
        riskLevels,
        issuerIds,
        dividendPolicy
      } = payload
      let urlPath = '/api/set/etf/search?lang=th&'
      urlPath += symbols ? `symbols=${symbols}&` : ''
      urlPath += themeId && themeId !== '0'
        ? `themeId=${themeId}&`
        : ''
      urlPath += underlyingClassIds && underlyingClassIds !== '0'
        ? `underlyingClassIds=${underlyingClassIds}&`
        : ''
      urlPath += ytdPercentChanges && ytdPercentChanges !== '0'
        ? `ytdPercentChanges=${ytdPercentChanges}&`
        : ''
      urlPath += riskLevels && riskLevels !== '0' ? `riskLevels=${encodeURIComponent(riskLevels)}&` : ''
      urlPath += issuerIds && issuerIds !== '0' ? `issuerIds=${issuerIds}&` : ''
      urlPath +=
        dividendPolicy && dividendPolicy.length !== 2
          ? `dividendPolicy=${dividendPolicy[0]}&`
          : ''
      const response = await this.$axios.$get(urlPath)
      commit('setETFList', response)
    } catch {
      commit('setETFList', [])
    }
  },
  async getIntradayOverview ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/etf/overview')
      const responseTrading = await this.$axios.$get('/api/set/etf/trading-stock-summary')
      commit('setIntradayOverview', {
        ...response,
        ...responseTrading
      })
    } catch {
      commit('setIntradayOverview', {})
    }
  },
  async getUnderlying ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/search/condition/underlying-class'
      )
      const option = response.map((data) => {
        return {
          value: data.id,
          label: data.nameTH
        }
      })
      commit('setUnderlying', option)
    } catch {
      commit('setUnderlying', null)
    }
  },
  async getYtdPercent ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/search/condition/ytd-percent-change'
      )
      const option = response.map((data) => {
        return {
          value: data.id.toString(),
          label: data.nameTh
        }
      })
      commit('setYtdPercent', option)
    } catch {
      commit('setYtdPercent', [])
    }
  },
  async getRiskLevel ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/search/condition/risk-level'
      )
      const riskGroup = [
        {
          label: 'ความเสี่ยงต่ำ',
          lavel: ['1']
        },
        {
          label: 'ความเสี่ยงปานกลางค่อนข้างต่ำ',
          lavel: ['2', '3', '4']
        },
        {
          label: 'ความเสี่ยงปานกลางค่อนข้างสูง',
          lavel: ['5']
        },
        {
          label: 'ความเสี่ยงสูง',
          lavel: ['6', '7']
        },
        {
          label: 'ความเสี่ยงสูงมาก',
          lavel: ['8']
        },
        {
          label: 'ความเสี่ยงสูงมากอย่างมีนัยสำคัญ',
          lavel: ['8+']
        }
      ]
      const option = []
      for (const data of riskGroup) {
        const optionValue = data.lavel.filter((dataLabel) => {
          return response.includes(dataLabel)
        })
        if (optionValue.length) {
          option.push({
            label: data.label,
            children: optionValue.map((dataValue) => {
              return {
                value: dataValue,
                label: `ระดับ ${dataValue}`
              }
            })
          })
        }
      }
      commit('setRiskLevel', option)
    } catch {
      commit('setRiskLevel', [])
    }
  },
  async getIssuer ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/search/condition/issuer'
      )
      const option = response.map((data) => {
        return {
          value: data.id.toString(),
          label: data.nameTh
        }
      })
      commit('setIssuer', option)
    } catch {
      commit('setIssuer', [])
    }
  },
  async getTopGainer ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/ranking/topGainer/SET/L?count=5'
      )
      commit('setTopGainer', response)
    } catch {
      commit('setTopGainer', null)
    }
  },
  async getTopLoser ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/ranking/topLoser/SET/L?count=5'
      )
      commit('setTopLoser', response)
    } catch {
      commit('setTopLoser', null)
    }
  },
  async getTotalNav ({ commit }) {
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/list?lang=th&limit=${5}&rankBy=totalNav`
      )
      commit('setTotalNav', response)
    } catch {
      commit('setTotalNav', null)
    }
  },
  async getTopYTD ({ commit }) {
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/list?lang=th&limit=${5}&rankBy=ytdPercentChange`
      )
      commit('setTopYTD', response)
    } catch {
      commit('setTopYTD', null)
    }
  },
  async getMostActiveValue ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/ranking/MostActiveValue/SET/L?count=5'
      )
      commit('setMostActiveValue', response)
    } catch {
      commit('setMostActiveValue', null)
    }
  },
  async getMarketCap ({ commit }) {
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/list?lang=th&limit=${5}&rankBy=marketCap`
      )
      commit('setMarketCap', response)
    } catch {
      commit('setMarketCap', null)
    }
  },
  async getThematic ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/etf/theme/list')
      commit('setThematic', response)
    } catch {
      commit('setThematic', [])
    }
  }
}

const getters = {
  getETFList: (state) => {
    return state.etfList
  },
  getIntradayOverview: (state) => {
    return state.intradayOverview
  },
  getUnderlying: (state) => {
    return state.underlying
  },
  getYtdPercent: (state) => {
    return state.ytdPercent
  },
  getRiskLevel: (state) => {
    return state.riskLevel
  },
  getIssuer: (state) => {
    return state.issuer
  },
  getTopGainer: (state) => {
    return state.topGainer
  },
  getTopLoser: (state) => {
    return state.topLoser
  },
  getTotalNav: (state) => {
    return state.totalNav
  },
  getTopYTD: (state) => {
    return state.topYTD
  },
  getMostActiveValue: (state) => {
    return state.mostActiveValue
  },
  getMarketCap: (state) => {
    return state.marketCap
  },
  getThematic: (state) => {
    return state.thematic
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
