export function getFavouriteByCategory (category) {
  return this.$apiMember.$get(`/api/set-fund/favorite/${category}`)
    .then(result => result)
    .catch(_error => ([]))
}

export function getFavouriteStockPrice (symbols) {
  return Promise.all([
    this.$axios.$get('/api/set/stock-info/list-by-symbols', { params: { symbols } }),
    this.$axios.$get('/api/set/stock-compare/highlight-stat', { params: { symbols } })
  ])
}

export function getFavouriteETFPrice (symbols) {
  return Promise.all([
    this.$axios.$get('/api/set/stock-info/list-by-symbols', { params: { symbols } }),
    this.$axios.$get('/api/set/etf/search', { params: { symbols } }),
    this.$axios.$get('/api/set-fund/fund-compare/list-by-symbols', { params: { symbols } })
  ])
}

export function getFavouriteDWPrice (symbols) {
  return this.$axios.$get('/api/set/dw-info/list-by-symbols', { params: { symbols } })
}

export function getFavouriteDRPrice (symbols) {
  return Promise.all([
    this.$axios.$get('/api/set/stock-info/list-by-symbols', { params: { symbols } }),
    this.$axios.$get('/api/set/dr/search', { params: { symbols } })
  ])
}

export function getFavouriteDRxPrice (symbols) {
  return Promise.all([
    this.$axios.$get('/api/set/stock-info/list-by-symbols', { params: { symbols } }),
    this.$axios.$get('/api/set/drx/search', { params: { symbols } })
  ])
}

export function getFavouriteFuturesPrice (symbols) {
  return this.$axios.$get('/api/set/tfex/series/info/list-by-symbols', { params: { symbols } })
}

export function getFavouriteOptionsPrice (symbols) {
  return this.$axios.$get('/api/set/tfex/series/info/list-by-symbols', { params: { symbols } })
}

export function getFavouriteFundPrice (symbols) {
  return this.$axios.$get('/api/set-fund/fund-compare/list-by-symbols', { params: { symbols } })
}

export function addFavourite (category, symbol) {
  // https://stackoverflow.com/a/50176112
  return this.$apiMember.post(`/api/set-fund/favorite/${category}/${encodeSymbol(symbol)}`)
}

export function removeFavourite (category, symbol) {
  // https://stackoverflow.com/a/50176112
  return this.$apiMember.delete(`/api/set-fund/favorite/${category}/${encodeSymbol(symbol)}`)
}

export function getFavoriteDelisted (category) {
  return this.$apiMember.$get(`/api/set-fund/favorite/${category}/delisted`)
    .then(result => result)
    .catch(_error => ([]))
}

export function encodeSymbol (symbol) {
  let fixedSymbol = symbol
  if (symbol.includes('/')) {
    fixedSymbol = encodeURIComponent(encodeURIComponent(fixedSymbol))
  }
  return fixedSymbol
}
