const getDefaultState = () => {
  return {
    categories: [],
    channel: [],
    weekly: [],
    monthly: [],
    searchArticles: {
      totalCount: 0,
      articleList: []
    }
  }
}
const state = getDefaultState

const mutations = {
  setCategories (state, data) {
    state.categories = data
  },
  setChannel (state, data) {
    state.channel = data
  },
  setWeekly (state, data) {
    state.weekly = data
  },
  setMonthly (state, data) {
    state.monthly = data
  },
  setSearchArticles (state, data) {
    state.searchArticles.totalCount = data.totalCount
    state.searchArticles.articleList = data.items
  }
}

const actions = {
  async getCategories ({ commit }) {
    const response = await this.$apiCms
      .get('/api/cms/v1/knowledge/categories')
      .then((res) => {
        return res.data
      })
      .catch(() => [])
    commit('setCategories', response)
  },
  async getChannel ({ commit }) {
    const params = {
      knowledgeTypes: 'ArticleDetail'
    }
    const response = await this.$apiCms
      .get('/api/cms/v1/authors/by-knowledge-type/', { params })
      .then((res) => {
        return res.data
      })
      .catch(() => [])
    commit('setChannel', response)
  },
  async getWeekly ({ commit }) {
    const params = {
      frequency: 'Weekly',
      pageIndex: 0,
      pageSize: 5
    }
    const response = await this.$apiCms
      .get('/api/cms/v1/knowledge/popular-articles', { params })
      .then((res) => {
        return res.data.items
      })
      .catch(() => [])
    commit('setWeekly', response)
  },
  async getMonthly ({ commit }) {
    const params = {
      frequency: 'Monthly',
      pageIndex: 0,
      pageSize: 5
    }
    const response = await this.$apiCms
      .get('/api/cms/v1/knowledge/popular-articles', { params })
      .then((res) => {
        return res.data.items
      })
      .catch(() => [])
    commit('setMonthly', response)
  },
  async getSearchArticles ({ commit }, payload) {
    const params = new URLSearchParams(payload)
    const response = await this.$apiCms
      .get('/api/cms/v1/knowledge/articles', { params })
      .then((res) => {
        return res.data
      })
      .catch(() => [])
    commit('setSearchArticles', response)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
