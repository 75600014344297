const getDefaultState = () => {
  return {
    activeFundList: [],
    amcList: [],
    typeFundList: [],
    specificationCodeList: [],
    FiveYearTopPerformanceList: [],
    // outstandingFundListByType: [],
    outstandingFundList: [],
    upcomingIPOList: [],
    recentlyFundList: [],
    thematicList: [],
    popularList: []
  }
}

const state = getDefaultState

const mutations = {
  setActiveFundList (state, data) {
    state.activeFundList =
      data && data.funds
        ? data.funds.map((data) => {
          return {
            ...data
            // value: data.id,
            // label: data.nameTH
          }
        })
        : []
  },
  setAmcList (state, data) {
    state.amcList = data && data.fundAmcs
      ? data.fundAmcs.map((data) => {
        return {
          value: data.id,
          label: data.nameTh
        }
      })
      : []
  },
  setTypeFundList (state, data) {
    const CONST_LABEL_GROUP = {
      Domestic_Equity: 'กองทุนรวมตราสารทุนในประเทศ/ต่างประเทศบางส่วน',
      Foreign_Equity: 'กองทุนรวมตราสารทุนต่างประเทศ',
      Sector_Fund: 'กองทุนรวมตราสารทุนประเภท Sector Fund',
      Index_Fund: 'กองทุนรวมตราสารทุนประเภทกองทุนรวมดัชนี',
      Domestic_Fixed_Income: 'กองทุนรวมตราสารหนี้ในประเทศ/ต่างประเทศบางส่วน',
      Foreign_Fixed_Income: 'กองทุนรวมตราสารหนี้ต่างประเทศ',
      Domestic_Allocation: 'กองทุนรวมผสมในประเทศ/ต่างประเทศบางส่วน',
      Foreign_Allocation: 'กองทุนรวมผสมต่างประเทศ',
      Property: 'กองทุนรวมอสังหาริมทรัพย์/Infra/REIT',
      Commodities: 'กองทุนรวม Commodities',
      Miscellaneous: 'กองทุนรวมที่ไม่สามารถจัดกลุ่มได้'
    }
    state.typeFundList = data && data.fundTypeGroups
      ? data.fundTypeGroups.map((data) => {
        return {
          label: CONST_LABEL_GROUP[data.fundTypes[0].groupCode],
          children: data.fundTypes.map((fundType) => {
            return {
              value: fundType.aimcType,
              label: `${fundType.description} (${fundType.aimcType})`
            }
          })
        }
      })
      : []
  },
  setSpecificationCodeList (state, data) {
    state.specificationCodeList = data && data.specificationCodes
      ? data.specificationCodes.map((data) => {
        return {
          value: data.code,
          label: data.description === 'ALL' ? 'ทั่วไป' : data.description
        }
      })
      : []
  },
  setFiveYearTopPerformanceList (state, data) {
    state.FiveYearTopPerformanceList = data
  },
  // setOutstandingFundListByType (state, data) {
  //   state.outstandingFundListByType = data
  // },
  setOutstandingFundList (state, data) {
    state.outstandingFundList = data
  },
  setUpcomingIPOList (state, data) {
    state.upcomingIPOList = data
  },
  setRecentlyFundList (state, data) {
    state.recentlyFundList = data
  },
  setThematicList (state, data) {
    state.thematicList = data
  },
  setPopularList (state, data) {
    state.popularList = data
  }
}

const actions = {
  async getActiveFundList ({ commit }) {
    try {
      const url = '/api/set-fund/fund/active-fund/list'
      const response = await this.$axios.$get(url)
      commit('setActiveFundList', response)
    } catch {
      commit('setActiveFundList', [])
    }
  },
  async getAmcList ({ commit }) {
    try {
      const url = '/api/set-fund/fund/amc/list'
      const response = await this.$axios.$get(url)
      commit('setAmcList', response)
    } catch {
      commit('setAmcList', [])
    }
  },
  async getTypeFundList ({ commit }) {
    try {
      const url = '/api/set-fund/fund/type/list'
      const response = await this.$axios.$get(url)
      commit('setTypeFundList', response)
    } catch {
      commit('setTypeFundList', [])
    }
  },
  async getSpecificationCodeList ({ commit }) {
    try {
      const url = '/api/set-fund/fund/specification-code/list'
      const response = await this.$axios.$get(url)
      commit('setSpecificationCodeList', response)
    } catch {
      commit('setSpecificationCodeList', [])
    }
  },
  async getFiveYearTopPerformanceList ({ commit }) {
    try {
      const url = '/api/set-fund/fund/5years-top-performance'
      const response = await this.$axios.$get(url)
      commit('setFiveYearTopPerformanceList', response)
    } catch {
      commit('setFiveYearTopPerformanceList', [])
    }
  },
  async getOutstandingFundListByType ({ commit }, type) {
    try {
      const url = `/api/set-fund/fund/outstanding-fund/${type}`
      const response = await this.$axios.$get(url)
      // commit('setOutstandingFundListByType', response)
      return response
    } catch {
      // commit('setOutstandingFundListByType', [])
      return null
    }
  },
  async getOutstandingFundList ({ commit }, symbols) {
    try {
      const url = `/api/set-fund/fund/outstanding-fund/list-by-symbols?symbols=${symbols}`
      const response = await this.$axios.$get(url)
      commit('setOutstandingFundList', response)
    } catch {
      commit('setOutstandingFundList', [])
    }
  },
  async getUpcomingIPOList ({ commit }, lang) {
    try {
      const url = `/api/set-fund/fund/upcoming-ipo?lang=${lang}`
      const response = await this.$axios.$get(url)
      commit('setUpcomingIPOList', response)
    } catch {
      commit('setUpcomingIPOList', [])
    }
  },
  async getRecentlyFundList ({ commit }, lang) {
    try {
      const url = `/api/set-fund/fund/recently-listed?lang=${lang}`
      const response = await this.$axios.$get(url)
      commit('setRecentlyFundList', response)
    } catch {
      commit('setRecentlyFundList', [])
    }
  },
  async getThematicList ({ commit }) {
    try {
      const url = '/api/set-fund/fund/aimc-type-performance/list'
      const response = await this.$axios.$get(url)
      commit('setThematicList', response)
    } catch {
      commit('setThematicList', [])
    }
  },
  async getPopularList ({ commit }) {
    try {
      const url = '/api/cms/v1/popularquote/getpopular?market=SET&securityType=fund&limit=5'
      const response = await this.$apiCms.get(url)
      const symbolList = []
      for (let i = 0; i < response.data.length; i++) {
        symbolList.push(encodeURIComponent(response.data[i].symbol))
      }
      const responseInfo = await this.$axios.get(`api/set-fund/fund/outstanding-fund/list-by-symbols?symbols=${symbolList.slice(0, 20).join(',')}`)
      commit('setPopularList', responseInfo.data ? responseInfo.data.outstandingFunds : [])
    } catch {
      commit('setPopularList', [])
    }
  }
}

const getters = {
  getActiveFundList: (state) => {
    return state.activeFundList ?? []
  },
  getAmcList: (state) => {
    return state.amcList ?? []
  },
  getTypeFundList: (state) => {
    return state.typeFundList ?? []
  },
  getSpecificationCodeList: (state) => {
    return state.specificationCodeList ?? []
  },
  getFiveYearTopPerformanceList: (state) => {
    return state.FiveYearTopPerformanceList ?? []
  },
  // getOutstandingFundListByType: (state) => {
  //   return state.outstandingFundListByType ?? []
  // },
  getOutstandingFundList: (state) => {
    return state.outstandingFundList ?? []
  },
  getUpcomingIPOList: (state) => {
    return state.upcomingIPOList ?? []
  },
  getRecentlyFundList: (state) => {
    return state.recentlyFundList ?? []
  },
  getThematicList: (state) => {
    return state.thematicList ?? []
  },
  getPopularList: (state) => {
    return state.popularList ?? []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
