import companyInformation from '@/store/equity/stock/common/companyInformation/index'
import financialStatement from '@/store/equity/stock/common/financialStatement/index'

export const modules = {
  companyInformation,
  financialStatement
}

const getDefaultState = () => {
  return {
    quotationChart: null,
    quotationChartAccumulated: null,
    highlightData: null,
    keyFinancialData: null,
    relatedProduct: null,
    pricePerformanceChart: [],
    historicalTrading: [],
    benefits: [],
    dividendChart: [],
    dividendChartYearly: [],
    newsList: null,
    sectorComparison: null,
    defaultCalendar: null,
    stockList: null,
    esg: {
      esg: null,
      esgSource: null
    },
    iaaConsensus: {
      overall: null,
      info: null,
      chart: [],
      ratingViews: []
    }
  }
}

const state = getDefaultState

const mutations = {
  setQuotationChart (state, data) {
    state.quotationChart = data
  },

  setQuotationChartAccumulated (state, data) {
    state.quotationChartAccumulated = data
  },

  setHighlightData (state, data) {
    state.highlightData = data
  },

  setKeyFinancialData (state, data) {
    state.keyFinancialData = data
  },

  setRelatedProduct (state, data) {
    state.relatedProduct = data
  },

  setPricePerformanceChart (state, data) {
    state.pricePerformanceChart = data
  },

  setHistoricalTrading (state, data) {
    state.historicalTrading = data
  },

  setBenefits (state, data) {
    // state.benefits = [
    //   {
    //     caType: 'XD', // เครื่องหมาย
    //     categoryLabel: 'เงินปันผล',
    //     categoryValue: '0.82 บาท',
    //     xdate: new Date(2021, 3, 4), // วันที่ขึ้นเครื่องหมาย
    //     bookCloseDate: null, // วันปิดสมุดทะเบียน
    //     recordDate: new Date(2021, 3, 5), // วันกำหนดรายชื่อผู้ถือหุ้น
    //     paymentDate: new Date(2021, 4, 30), // วันที่จ่ายเงินปันผล
    //     type: 'XD',
    //     dividend: '0.82', // เงินปันผล(ต่อหุ้น)
    //     currency: 'บาท', // หน่วย
    //     beginOperation: new Date(2021, 9, 20), // รอบผลประกอบการ
    //     endOperation: new Date(2021, 9, 24), // รอบผลประกอบการ
    //     sourceOfDividend: 'กำไรสะสม', // เงินปันผลจาก
    //     remark: null // หมายเหตุ
    //   },
    //   {
    //     symbol: 'SF',
    //     caType: 'XD(ST)',
    //     type: 'XD',
    //     bookCloseDate: null,
    //     recordDate: '2019-04-02T00:00:00+07:00',
    //     remark: null,
    //     paymentDate: '2019-04-17T00:00:00+07:00',
    //     beginOperation: '2018-01-01T00:00:00+07:00',
    //     endOperation: '2018-12-31T00:00:00+07:00',
    //     sourceOfDividend: 'ปันผลจากกำไรสุทธิ',
    //     dividend: null,
    //     currency: null,
    //     ratio: '5:1',
    //     xdate: '2019-04-01T00:00:00+07:00'
    //   },
    //   {
    //     type: 'XR',
    //     caType: 'XR', // เครื่องหมาย
    //     categoryLabel: 'อัตราส่วน (เดิม:ใหม่) ณ ราคา (บาท)',
    //     categoryValue: '5 : 1 ณ 4.00 บาท',
    //     xdate: new Date(2021, 3, 4), // วันที่ขึ้นเครื่องหมาย
    //     bookCloseDate: null, // วันปิดสมุดทะเบียน
    //     recordDate: new Date(2021, 3, 5), // วันกำหนดรายชื่อผู้ถือหุ้น
    //     rightsFor: 'หุ้นสามัญ', // หลักทรัพย์ที่ได้สิทธิ
    //     beginSubscription: new Date(2021, 9, 20), // ช่วงระยะเวลาจองซื้อ (เรื่ม)
    //     endSubscription: new Date(2021, 9, 24), // ช่วงระยะเวลาจองซื้อ (สิ้นสุด)
    //     ratio: '5 : 1', // อัตราส่วน (เดิม:ใหม่)
    //     price: 4.0, // ราคา
    //     currency: 'บาท', // หน่วย
    //     condition: 'ปันผลจากกำไรสะสม', // เงื่อนไข
    //     remark: null // หมายเหตุ
    //   },
    //   {
    //     type: 'XW',
    //     caType: 'XW', // เครื่องหมาย
    //     categoryLabel: 'อัตราส่วน (เดิม:ใหม่) ณ ราคา (บาท)',
    //     categoryValue: '3 : 1 ณ 5.00 บาท',
    //     xdate: new Date(2021, 3, 4), // วันที่ขึ้นเครื่องหมาย
    //     bookCloseDate: null, // วันปิดสมุดทะเบียน
    //     recordDate: new Date(2021, 3, 5), // วันกำหนดรายชื่อผู้ถือหุ้น
    //     rightsFor: 'หุ้นสามัญ', // หลักทรัพย์ที่ได้สิทธิ
    //     beginSubscription: new Date(2021, 9, 20), // ช่วงระยะเวลาจองซื้อ (เรื่ม)
    //     endSubscription: new Date(2021, 9, 24), // ช่วงระยะเวลาจองซื้อ (สิ้นสุด)
    //     ratio: '3 : 1', // อัตราส่วน (เดิม:ใหม่)
    //     price: 5.00, // ราคา
    //     currency: 'บาท', // หน่วย
    //     condition: 'ปันผลจากกำไรสะสม', // เงื่อนไข
    //     remark: null // หมายเหตุ
    //   },
    //   {
    //     type: 'XT',
    //     caType: 'XT', // เครื่องหมาย
    //     categoryLabel: 'อัตราส่วน (เดิม:ใหม่) ณ ราคา (บาท)',
    //     categoryValue: '5 : 1 ณ 1.00 บาท',
    //     xdate: new Date(2021, 3, 4), // วันที่ขึ้นเครื่องหมาย
    //     bookCloseDate: null, // วันปิดสมุดทะเบียน
    //     recordDate: new Date(2021, 3, 5), // วันกำหนดรายชื่อผู้ถือหุ้น
    //     rightsFor: 'ใบแสดงสิทธิในการซื้อหุ้นเพิ่มทุนที่โอนสิทธิได้', // หลักทรัพย์ที่ได้สิทธิ
    //     allocatedShares: '381,149,916', // จำนวนหุ้นจัดสรร (หุ้น)
    //     beginSubscription: null, // ช่วงระยะเวลาจองซื้อ (เรื่ม)
    //     endSubscription: null, // ช่วงระยะเวลาจองซื้อ (สิ้นสุด)
    //     ratio: '5 : 1', // อัตราส่วน (เดิม:ใหม่)
    //     price: 1.00, // ราคา
    //     currency: 'บาท', // หน่วย
    //     condition: null, // เงื่อนไข
    //     remark: null // หมายเหตุ
    //   },
    //   {
    //     type: 'XI',
    //     caType: 'XI', // เครื่องหมาย
    //     categoryLabel: 'รับดอกเบี้ย',
    //     categoryValue: '5.90%',
    //     xdate: new Date(2021, 3, 4) // วันที่ขึ้นเครื่องหมาย
    //   },
    //   {
    //     type: 'XP',
    //     caType: 'XP', // เครื่องหมาย
    //     categoryLabel: null,
    //     categoryValue: null,
    //     xdate: new Date(2021, 3, 4) // วันที่ขึ้นเครื่องหมาย
    //   },
    //   {
    //     type: 'XA',
    //     caType: 'XA', // เครื่องหมาย
    //     categoryLabel: null,
    //     categoryValue: null,
    //     xdate: new Date(2021, 3, 4) // วันที่ขึ้นเครื่องหมาย
    //   },
    //   {
    //     type: 'XE',
    //     caType: 'XE', // เครื่องหมาย
    //     categoryLabel: 'ราคาแปลงสภาพ (บาท)',
    //     categoryValue: '3.00',
    //     xdate: new Date(2021, 3, 4), // วันที่ขึ้นเครื่องหมาย
    //     bookCloseDate: null, // วันปิดสมุดทะเบียน
    //     recordDate: new Date(2021, 3, 5), // วันกำหนดรายชื่อผู้ถือหุ้น
    //     beginNotice: new Date(2021, 8, 4), // วันแจ้งความจำนงใช้สิทธิ
    //     endNotice: new Date(2021, 8, 18), // วันแจ้งความจำนงใช้สิทธิ
    //     beginExercise: new Date(2021, 8, 4), // วันที่แปลงสภาพ
    //     endExercise: new Date(2021, 8, 19), // วันที่แปลงสภาพ
    //     price: 3.00, // ราคาแปลงสภาพ (บาท)
    //     currency: null, // หน่วย
    //     ratio: '1 : 1', // อัตราส่วน (ใบสำคัญแสดงสิทธิฯ :หุ้นสามัญ)
    //     remark: null // หมายเหตุ
    //   },
    //   {
    //     type: 'XM',
    //     caType: 'XM', // เครื่องหมาย
    //     categoryLabel: 'วาระการประชุม',
    //     categoryValue: 'จ่ายปันผลเป็นเงินสด, การเปลี่ยนแปลงกรรมการ',
    //     xdate: new Date(2021, 3, 4), // วันที่ขึ้นเครื่องหมาย
    //     bookCloseDate: null, // วันปิดสมุดทะเบียน
    //     recordDate: new Date(2021, 3, 5), // วันกำหนดรายชื่อผู้ถือหุ้น
    //     meetingDate: new Date(2021, 8, 10, 13, 30), // วันที่ประชุม
    //     meetingType: 'ประชุมสามัญ',
    //     agenda: 'จ่ายปันผลเป็นเงินสด, การเปลี่ยนแปลงกรรมการ', // วาระการประชุม
    //     venue:
    //       'ห้องบางกอกคอนเวนชั่น เซ็นเตอร์ ชั้น 5 ศูนย์การค้าเซ็นทรัลพลาซาลาดพร้าว เลขที่ 1695 ถนนพหลโยธิน แขวงจตุจักร กรุงเทพฯ 10900', // สถานที่จัดประชุม
    //     remark: null // หมายเหตุ
    //   },
    //   {
    //     type: 'XN',
    //     caType: 'XN', // เครื่องหมาย
    //     categoryLabel: 'เงินคืน  (บาท / หุ้น)',
    //     categoryValue: '0.0442',
    //     xdate: new Date(2021, 3, 4), // วันที่ขึ้นเครื่องหมาย
    //     bookCloseDate: null, // วันปิดสมุดทะเบียน
    //     recordDate: new Date(2021, 3, 5), // วันกำหนดรายชื่อผู้ถือหุ้น
    //     paymentDate: new Date(2021, 9, 3), // วันจ่ายเงิน
    //     returnAmount: '0.0442', // เงินคืน (บาท/หุ้น)
    //     currency: null, // หน่วย
    //     remark: null // หมายเหตุ
    //   },
    //   {
    //     type: 'XB',
    //     caType: 'XB', // เครื่องหมาย
    //     categoryLabel: 'ราคาจองซื้อ',
    //     categoryValue: '18.00 บาท',
    //     xdate: new Date(2021, 3, 4), // วันที่ขึ้นเครื่องหมาย
    //     bookCloseDate: null, // วันปิดสมุดทะเบียน
    //     recordDate: new Date(2021, 3, 5), // วันกำหนดรายชื่อผู้ถือหุ้น
    //     price: '18.00', // ราคาจองซื้อ
    //     currency: 'บาท', // หน่วย
    //     ratio: '95.1997 : 1', // อัตราส่วน (เดิม : ใหม่)
    //     benefitType: 'สิทธิในการจองซื้อหลักทรัพย์ของบริษัทในเครือ', // ประเภทการให้สิทธิประโยชน์
    //     securityType: 'หุ้นสามัญ', // ประเภทหุ้นจัดสรร
    //     subsidiaryCompany: 'บริษัท ปตท. น้ำมันและการค้าปลีก จำกัด (มหาชน)', // บริษัทย่อยที่จัดสรรหุ้นใหม่ให้แก่ผู้ถือหุ้น
    //     remark: null // หมายเหตุ
    //   }
    // ]
    state.benefits = data
  },

  setDividendChart (state, data) {
    state.dividendChart = data
  },

  setDividendChartYearly (state, data) {
    state.dividendChartYearly = data
  },

  setNewsList (state, data) {
    state.newsList = data
  },

  setSectorComparison (state, data) {
    state.sectorComparison = data
  },

  setDefaultCalendar (state, data) {
    state.defaultCalendar = data
  },

  setStockList (state, data) {
    state.stockList = data
  },

  setEsg (state, data) {
    state.esg.esg = data
  },

  setEsgSource (state, data) {
    state.esg.esgSource = data
  },

  setIaaConsensusOverall (state, data) {
    state.iaaConsensus.overall = data
  },

  setIaaConsensusData (state, data) {
    state.iaaConsensus.info = data
  },
  setIaaConsensusChart (state, data) {
    state.iaaConsensus.chart = data
  },
  setIaaRatingViews (state, data) {
    state.iaaConsensus.ratingViews = data
  }
}

const actions = {
  async getQuotationChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation`,
        {
          params: {
            period,
            accumulated: false
          }
        }
      )

      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },

  async getQuotationChartAccumulated ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation`,
        {
          params: {
            period,
            accumulated: true
          }
        }
      )

      commit('setQuotationChartAccumulated', response)
    } catch {
      commit('setQuotationChartAccumulated', null)
    }
  },

  async getHighlightData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/highlight-data`
      )

      commit('setHighlightData', response)
    } catch {
      commit('setHighlightData', null)
    }
  },

  async getKeyFinancialData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/key-financial-data`
      )

      commit('setKeyFinancialData', response)
    } catch {
      commit('setKeyFinancialData', null)
    }
  },

  async getRelatedProduct ({ commit }, payload) {
    const { quote, product } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/related-product/${product}`
      )

      commit('setRelatedProduct', response)
    } catch {
      commit('setRelatedProduct', null)
    }
  },

  async getPricePerformanceChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const quoteData = this.$axios.$get(
        `/api/set/stock/${quote}/chart-performance`,
        {
          params: {
            period
          }
        }
      )

      const indexData = this.$axios.$get(`/api/set/stock/${quote}/index-list`)

      const result = await Promise.allSettled([quoteData, indexData]).then(
        async (data) => {
          const [{ value: quoteList }, { value: indexList }] = data
          const performanceData = []

          performanceData.push({
            legend: indexList.symbol,
            quotations:
              quoteList.quotations.map((item, index, array) => {
                return {
                  datetime: item.datetime,
                  pricePercent: item.price.toFixed(2)
                }
              }) || []
          })

          if (indexList.market && (indexList.sector || indexList.industry)) {
            const sectorData = this.$axios.$get(
              `/api/set/index/${
                indexList.market === 'mai'
                  ? indexList.industry
                  : indexList.sector
              }/chart-performance`,
              {
                params: {
                  period
                }
              }
            )

            const marketData = this.$axios.$get(
              `/api/set/index/${indexList.market}/chart-performance`,
              {
                params: {
                  period
                }
              }
            )

            const [{ value: sectorList }, { value: marketList }] =
              await Promise.allSettled([sectorData, marketData])

            performanceData.push({
              legend:
                indexList.market === 'mai'
                  ? indexList.industry
                  : indexList.sector,
              quotations:
                sectorList.quotations.map((item, index, array) => {
                  return {
                    datetime: item.datetime,
                    pricePercent: item.price.toFixed(2)
                  }
                }) || []
            })

            performanceData.push({
              legend: indexList.market,
              quotations:
                marketList.quotations.map((item, index, array) => {
                  return {
                    datetime: item.datetime,
                    pricePercent: item.price.toFixed(2)
                  }
                }) || []
            })
          }

          return performanceData
        }
      )

      commit('setPricePerformanceChart', result)
    } catch {
      commit('setPricePerformanceChart', [])
    }
  },

  async getHistoricalTrading ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/historical-trading`
      )

      commit('setHistoricalTrading', response)
    } catch {
      commit('setHistoricalTrading', [])
    }
  },

  async getBenefits ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/corporate-action`
      )
      commit('setBenefits', response)
    } catch {
      commit('setBenefits', [])
    }
  },

  async getDividendChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/dividend-chart`
      )

      commit('setDividendChart', response)
    } catch {
      commit('setDividendChart', [])
    }
  },

  async getDividendChartYearly ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/yearly-dividend-chart`
      )

      commit('setDividendChartYearly', response)
    } catch {
      commit('setDividendChartYearly', [])
    }
  },

  async getNewsList ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/news/${quote}/list`)
      commit('setNewsList', response)
    } catch {
      commit('setNewsList', null)
    }
  },

  async getSectorComparison ({ commit }, payload) {
    const { quote, type, date } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/rank-in-sector?type=${type}&date=${date}`
      )
      commit('setSectorComparison', response)
    } catch {
      commit('setSectorComparison', null)
    }
  },

  async getDefaultCalendar ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/calendar/datadate')
      commit('setDefaultCalendar', response)
    } catch {
      commit('setDefaultCalendar', null)
    }
  },

  async getStockList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/stock/list', {
        params: {
          securityType: 's'
        }
      })

      commit('setStockList', response)
    } catch {
      commit('setStockList', null)
    }
  },

  async getEsg ({ commit }, payload) {
    const { quote } = payload
    try {
      const isLogin = await this.$authUser.isLogin()
      const response = await getResult.call(this, isLogin)
      function getResult (isLogin) {
        if (isLogin) {
          return this.$apiMember.$get(`/api/set-fund/esg/${quote}/info`)
        } else {
          return this.$axios.$get(`/api/set-fund/esg/${quote}/info`)
        }
      }
      commit('setEsg', response)
    } catch {
      commit('setEsg', null)
    }
  },

  async getEsgSource ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set-fund/esg/source')
      commit('setEsgSource', response)
    } catch {
      commit('setEsgSource', null)
    }
  },

  async getIaaConsensusOverall ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        '/api/set-fund/consensus/stock/overall',
        {
          params: {
            lang: this.$i18n.locale,
            symbol: quote
          }
        }
      )
      commit('setIaaConsensusOverall', response)
    } catch {
      commit('setIaaConsensusOverall', null)
    }
  },
  async getIaaConsensusInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set-fund/consensus/stock/${quote}/consensus`
      )
      commit('setIaaConsensusData', response)
    } catch {
      commit('setIaaConsensusData', null)
    }
  },
  async getIaaConsensusChart ({ commit }, payload) {
    const { quote, period = '1Y' } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation`,
        {
          params: {
            period,
            accumulated: false
          }
        }
      )

      commit('setIaaConsensusChart', response)
    } catch {
      commit('setIaaConsensusChart', null)
    }
  },
  async getIaaRatingViews ({ commit }) {
    const response = await this.$apiCms
      .get('/api/cms/v1/research-settrade/iaa/rating-views', {
        params: {
          language: 'TH'
        }
      })
      .then((res) => {
        return res.data
      })
      .catch(() => [])
    commit('setIaaRatingViews', response)
  }
}

const getters = {
  pricePerformanceData: (state) => {
    return state.pricePerformanceChart.map(item => item.quotations)
  },
  pricePerformanceLegend: (state) => {
    return state.pricePerformanceChart.map(item => item.legend)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
