const state = () => ({
  investorTypeData: null
})

const mutations = {
  setInvestorTypeData (state, data) {
    state.investorTypeData = data
  }
}

const actions = {
  async getInvestorTypeData ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/tfex/market/investor-type/summary'
      )
      commit('setInvestorTypeData', response)
    } catch (error) {
      commit('setInvestorTypeData', null)
    }
  }
}

const getters = {
  getInvestorTypeData: (state) => {
    return state.investorTypeData || null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
