import { render, staticRenderFns } from "./SwiperCarousel.vue?vue&type=template&id=6f331469&"
import script from "./SwiperCarousel.vue?vue&type=script&lang=js&"
export * from "./SwiperCarousel.vue?vue&type=script&lang=js&"
import style0 from "swiper/css/swiper.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./SwiperCarousel.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconChevronBoldLeft: require('/app/components/Icon/ChevronBoldLeft.vue').default,Button: require('/app/components/Button/Button.vue').default,IconChevronBoldRight: require('/app/components/Icon/ChevronBoldRight.vue').default})
