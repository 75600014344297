//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { showRequestNum, hideRequestNum } from '@/directives/modal'
import { marketListId } from '@/utils/marketlist'
export default {
  name: 'MiscMemberVirtualPortfolioModalOrderDerivative',
  directives: {
    showRequestNum,
    hideRequestNum
  },
  props: {
    showRequestNum: {
      type: Number,
      default: 0
    },
    symbolList: {
      type: Array,
      default: () => [
        { symbol: 'PTT', nameTH: 'ปตท', nameEN: 'PTT', securityType: 'S' },
        {
          symbol: 'PTT-W1',
          nameTH: 'ใบสำคัญแสดงสิทธิ1 ของ ปตท',
          nameEN: 'Warrant1 of PTT',
          securityType: 'W'
        },
        {
          symbol: 'SCB-P',
          nameTH: 'ไทยพาณิชย์ X บุริมสิทธิ์',
          nameEN: 'Siam Commercial Bank X Preferred',
          securityType: 'P'
        },
        {
          symbol: 'SCB-F',
          nameTH: 'ไทยพาณิชย์ X ต่างชาติ',
          nameEN: 'Siam Commercial Bank X Foreign',
          securityType: 'F'
        }
      ]
    }
  },
  data () {
    return {
      marketListId,
      hideRequestNum: 0,
      formData: {},
      validationState: {
        symbol: {},
        volume: {},
        price: {}
      },
      lockBtn: false
    }
  },
  computed: {
    outstandingSecurities () {
      return [
        ...(this.$virtualPort.state.tfex?.futures?.portfolio ?? []),
        ...(this.$virtualPort.state.tfex?.options?.portfolio ?? [])
      ]
    },
    effectiveSymbolList () {
      const outstandingSecurities = this.outstandingSecurities
      return this.orderType === 2
        ? this.symbolList.filter(x =>
          outstandingSecurities.find(y => y.symbol === x.symbol)
        )
        : this.symbolList
    },
    orderType () {
      return this.formData.orderType ?? 1
    },
    value () {
      const volume = +(this.formData.volume ?? 0)
      const price = +(this.formData.price ?? 0)
      const fee = this.formData.hasFee ? +(this.formData.fee ?? 0) : 0
      if (this.orderType === 2) {
        // sell
        return volume * price - fee * volume
      } else {
        // buy
        return volume * price + fee * volume
      }
    },
    disableSell () {
      return this.outstandingSecurities.length === 0
    },
    isSymbolOption () {
      if (!this.formData.symbol) {
        return false
      }

      const info = this.symbolList.find(x => x.symbol === this.formData.symbol)
      if (!info) {
        return false
      }

      return (
        info.securityType === marketListId.IO ||
        info.securityType === marketListId.TXI_O ||
        info.securityType === marketListId.TXI_C ||
        info.securityType === marketListId.TXI_P
      )
    }
  },
  watch: {
    showRequestNum (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData = {
          orderType: undefined,
          symbol: undefined,
          volume: undefined,
          price: undefined,
          hasFee: false,
          fee: undefined
        }
        this.validationState = {
          symbol: { errorLevel: 0, errorType: undefined },
          volume: { errorLevel: 0, errorType: undefined },
          price: { errorLevel: 0, errorType: undefined }
        }
      }
    },
    'formData.orderType' () {
      this.formData.symbol = undefined
    },
    'formData.symbol' () {
      this.validationState.symbol.errorLevel = 0
      this.validationState.symbol.errorType = undefined
    },
    'formData.volume' () {
      this.validationState.volume.errorLevel = 0
      this.validationState.volume.errorType = undefined
    },
    'formData.price' () {
      this.validationState.price.errorLevel = 0
      this.validationState.price.errorType = undefined
    }
  },
  methods: {
    async submit () {
      let validatedFail = false
      const { symbol, volume, price, hasFee, fee } = this.formData //eslint-disable-line
      const orderType = this.orderType
      const validationState = this.validationState
      const outstandingSecurities =
        (this.isSymbolOption
          ? this.$virtualPort.state.tfex?.options?.portfolio
          : this.$virtualPort.state.tfex?.futures?.portfolio) ?? []
      const outstandingSecurity = this.outstandingSecurities.find(
        x => x.symbol === symbol
      )
      checkSymbol()
      checkVolume()
      checkPrice()
      if (!validatedFail) {
        const order = {
          side: orderType === 2 ? 'SHORT' : 'LONG',
          symbol,
          volume,
          commission: hasFee ? +fee ?? 0 : 0,
          price
        }
        this.lockBtn = true
        const result = await this.$virtualPort.sendOrder('tfex', order)
        if (result) {
          this.$toast.success('success', 'ส่งคำสั่งสำเร็จ')
          this.$emit('onSubmitSuccess', symbol)
          this.hideModal()
          this.dotry(() =>
            this.$personalized.clickElement({
              name: 'memberVirtualPortfolio4',
              optionLang: { category: 'อนุพันธ์' }
            })
          )
        } else {
          this.$toast.fail('warning', 'เกิดข้อผิดพลาด')
        }
        this.lockBtn = false
      }

      function checkSymbol () {
        if (!symbol || symbol === '') {
          validatedFail = true
          validationState.symbol.errorLevel = 2
          validationState.symbol.errorType = 'required'
          return //eslint-disable-line
        }
        if (
          orderType === 1 &&
          outstandingSecurities.length >= 30 &&
          !outstandingSecurity
        ) {
          validatedFail = true
          validationState.symbol.errorLevel = 2
          validationState.symbol.errorType = 'symbolLimitExceeded'
          return //eslint-disable-line
        }
      }

      function checkVolume () {
        if (!volume || +volume === 0) {
          validatedFail = true
          validationState.volume.errorLevel = 2
          validationState.volume.errorType = 'required'
          return //eslint-disable-line
        }
        if (
          orderType === 2 &&
          outstandingSecurity &&
          outstandingSecurity.volume < +volume
        ) {
          validatedFail = true
          validationState.volume.errorLevel = 2
          validationState.volume.errorType = 'notEnoughOutstandingVolume'
          return //eslint-disable-line
        }
      }

      function checkPrice () {
        if (!price || +price === 0) {
          validatedFail = true
          validationState.price.errorLevel = 2
          validationState.price.errorType = 'required'
          return //eslint-disable-line
        }
      }
    },
    hideModal () {
      this.hideRequestNum++
    }
  }
}
