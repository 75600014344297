const getDefaultState = () => {
  return {
    stockList: [],
    etfList: [],
    drList: [],
    drxList: [],
    dwList: [],
    futureList: [],
    optionList: [],
    fundList: {
      asOfDate: null,
      outstandingFunds: []
    },
    unittrustList: [],
    underlyingPrice: []
  }
}

const state = getDefaultState

const mutations = {
  setStockList (state, data) {
    state.stockList = data
  },
  setEtfList (state, data) {
    state.etfList = data
  },
  setDrList (state, data) {
    state.drList = data
  },
  setDrxList (state, data) {
    state.drxList = data
  },
  setDwList (state, data) {
    state.dwList = data
  },
  setFutureList (state, data) {
    state.futureList = data
  },
  setOptionList (state, data) {
    state.optionList = data
  },
  setFundList (state, data) {
    state.fundList = data
  },
  setUnittrustList (state, data) {
    state.unittrustList = data
  },
  setUnderlyingPrice (state, data) {
    state.underlyingPrice = data
  }
}

const actions = {
  async getStockList ({ commit, dispatch }) {
    let recentList = await this.$recently.getList()
    recentList = recentList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
    recentList = recentList?.filter(
      data =>
        data.type === 'S' ||
        data.type === 'F' ||
        data.type === 'P' ||
        data.type === 'Q' ||
        data.type === 'W'
    )
    if (recentList.length) {
      const stockList = await dispatch(
        'getDataStockList',
        recentList
      )
      // if (stockList) {
      //   for (let i = 0; i < stockList.length; i++) {
      //     const Chart = await dispatch('getQuotationChart', stockList[i].symbol)
      //     stockList[i].dayChart = Chart
      //   }
      // }
      commit('setStockList', stockList)
    } else {
      commit('setStockList', [])
    }
  },
  async getUnittrustList ({ commit, dispatch }) {
    let recentList = await this.$recently.getList()
    recentList = recentList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
    recentList = recentList?.filter(data => data.type === 'U')
    if (recentList.length) {
      const unittrustList = await dispatch(
        'getDataStockList',
        recentList
      )
      // if (unittrustList) {
      //   for (let i = 0; i < unittrustList.length; i++) {
      //     const Chart = await dispatch('getQuotationChart', unittrustList[i].symbol)
      //     unittrustList[i].dayChart = Chart
      //   }
      // }
      commit('setUnittrustList', unittrustList)
    } else {
      commit('setUnittrustList', [])
    }
  },
  async getEtfList ({ commit, dispatch }) {
    // const recentList = [
    //   '1DIV',
    //   'BMSCG',
    //   'BMSCITH',
    //   'GLD'
    // ]
    let recentList = await this.$recently.getList()
    recentList = recentList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
    recentList = recentList?.filter(data => data.type === 'L')
    if (recentList.length) {
      const etfList = await dispatch(
        'getDataETFList',
        recentList
      )
      commit('setEtfList', etfList)
    } else {
      commit('setEtfList', [])
    }
  },
  async getDrList ({ commit, dispatch }) {
    let recentList = await this.$recently.getList()
    recentList = recentList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
    recentList = recentList?.filter(data => data.type === 'X')
    if (recentList.length) {
      const drList = await dispatch(
        'getDataStockList',
        recentList
      )
      commit('setDrList', drList)
    } else {
      commit('setDrList', [])
    }
  },
  async getDrxList ({ commit, dispatch }) {
    let recentList = await this.$recently.getList()
    recentList = recentList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
    recentList = recentList?.filter(data => data.type === 'XF')
    if (recentList.length) {
      const drxList = await dispatch(
        'getDataStockList',
        recentList
      )
      commit('setDrxList', drxList)
    } else {
      commit('setDrxList', [])
    }
  },
  async getDwList ({ commit, dispatch }) {
    let recentList = await this.$recently.getList()
    recentList = recentList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
    recentList = recentList?.filter(data => data.type === 'V')
    if (recentList.length) {
      const dwList = await dispatch(
        'getDataDWList',
        recentList
      )
      commit('setDwList', dwList)
    } else {
      commit('setDwList', [])
    }
  },
  async getFutureList ({ commit, dispatch }) {
    let recentList = await this.$recently.getList()
    recentList = recentList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
    recentList = recentList?.filter(data => data.type === 'Futures')
    if (recentList.length) {
      const futureList = await dispatch(
        'getDataTFEXList',
        recentList
      )
      commit('setFutureList', futureList)
    } else {
      commit('setFutureList', [])
    }
  },
  async getOptionList ({ commit, dispatch }) {
    let recentList = await this.$recently.getList()
    recentList = recentList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
    recentList = recentList?.filter(data => data.type === 'Options')
    if (recentList.length) {
      const optionList = await dispatch(
        'getDataTFEXList',
        recentList
      )
      commit('setOptionList', optionList)
    } else {
      commit('setOptionList', [])
    }
  },
  async getFundList ({ commit, dispatch }) {
    let recentList = await this.$recently.getList()
    recentList = recentList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
    recentList = recentList?.filter(data => data.type === 'Fund')
    if (recentList.length) {
      const fundList = await dispatch(
        'getDataFundList',
        recentList
      )
      commit('setFundList', fundList)
    } else {
      commit('setFundList', {
        asOfDate: null,
        outstandingFunds: []
      })
    }
  },
  async getDataStockList ({ commit, dispatch }, symbolsList) {
    const symbols = symbolsList.map((data) => {
      return encodeURIComponent(data.symbol ?? data)
    })
    const infoList = await dispatch(
      'getInfoList',
      symbols.slice(0, 20).join(',')
    )
    if (infoList) {
      return infoList
    } else {
      const promiseCollection = []
      for (let i = 0; i < symbols.length; i++) {
        promiseCollection.push(dispatch('getInfo', symbols[i]))
      }
      const stocklist = []
      await Promise.allSettled(promiseCollection).then((res) => {
        for (let i = 0; i < res.length; i++) {
          let info = {
            symbol: symbols[i]
          }
          if (res[i].status !== 'rejected' && res[i].value) {
            info = {
              ...info,
              ...res[i].value
            }
          }
          stocklist.push(info)
        }
      })
      return stocklist
    }
  },
  async getDataETFList ({ commit }, symbolsList) {
    try {
      const symbols = symbolsList.map((data) => {
        return data.symbol ?? data
      })
      const urlPath = '/api/set/etf/search?lang=th&'
      const response = await this.$axios.$get(urlPath)
      return response.data.filter((data) => {
        return symbols.includes(data.symbol)
      }).map((data) => {
        return {
          ...data,
          securityType: 'L',
          marketDateTime: response.date
        }
      })
    } catch {
      return []
    }
  },
  async getDataDWList ({ commit }, symbolsList) {
    try {
      const symbols = symbolsList.map((data) => {
        return data.symbol ?? data
      })
      const urlPath = '/api/set/dw-info/list?underlyingNative=F'
      const response = await this.$axios.$get(urlPath)
      const dwListForign = response.dwList.filter((data) => {
        return symbols.includes(data.symbol)
      }).map((data) => {
        return {
          ...data,
          securityType: 'V',
          marketDateTime: response.marketTime,
          underlyingNative: 'F'
        }
      })
      const urlPathLocal = '/api/set/dw-info/list?underlyingNative=L'
      const responseLocal = await this.$axios.$get(urlPathLocal)
      const dwListLocal = responseLocal.dwList.filter((data) => {
        return symbols.includes(data.symbol)
      }).map((data) => {
        return {
          ...data,
          securityType: 'V',
          marketDateTime: response.marketTime,
          underlyingNative: 'L'
        }
      })
      const dwList = [
        ...dwListForign,
        ...dwListLocal
      ]
      return symbolsList.map((data) => {
        const dwInfo = dwList.find(dataFind => dataFind.symbol === data.symbol)
        return {
          ...data,
          ...dwInfo
        }
      })
    } catch {
      return []
    }
  },
  async getDataTFEXList ({ commit, dispatch }, symbolsList) {
    const symbols = symbolsList.map((data) => {
      return encodeURIComponent(data.symbol ?? data)
    })
    const infoList = await dispatch(
      'getTFEXList',
      symbols.slice(0, 20).join(',')
    )
    if (infoList) {
      return infoList
    } else {
      const promiseCollection = []
      for (let i = 0; i < symbols.length; i++) {
        promiseCollection.push(dispatch('getInfoTFEX', symbols[i]))
      }
      const stocklist = []
      await Promise.allSettled(promiseCollection).then((res) => {
        for (let i = 0; i < res.length; i++) {
          let info = {
            symbol: symbols[i]
          }
          if (res[i].status !== 'rejected' && res[i].value) {
            info = {
              ...info,
              ...res[i].value,
              securityType: 'TFEX'
            }
          }
          stocklist.push(info)
        }
      })
      return stocklist
    }
  },
  async getDataFundList ({ commit, dispatch }, symbolsList) {
    const symbols = symbolsList.map((data) => {
      return encodeURIComponent(data.symbol ?? data)
    })
    const infoList = await dispatch(
      'getOutstadningFundList',
      symbols.slice(0, 20).join(',')
    )
    return infoList
  },
  async getInfo ({ state, commit }, symbol) {
    let result = null
    await this.$axios
      .get(`api/set/stock/${symbol}/info`)
      .then((res) => {
        result = res.data
      })
      .catch(err => err)
    return result
  },
  async getInfoList ({ state, commit }, symbol) {
    let result = null
    await this.$axios
      .get(`api/set/stock-info/list-by-symbols?symbols=${symbol}`)
      .then((res) => {
        result = res.data
      })
      .catch(err => err)
    return result
  },
  async getInfoTFEX ({ state, commit }, symbol) {
    let result = null
    await this.$axios
      .get(`api/set/tfex/series/${symbol}/info`)
      .then((res) => {
        result = res.data
      })
      .catch(err => err)
    return result
  },
  async getTFEXList ({ state, commit }, symbol) {
    let result = null
    await this.$axios
      .get(`api/set/tfex/series/info/list-by-symbols?symbols=${symbol}`)
      .then((res) => {
        result = res.data
      })
      .catch(err => err)
    return result
  },
  async getOutstadningFundList ({ state, commit }, symbol) {
    let result = null
    await this.$axios
      .get(`api/set-fund/fund/outstanding-fund/list-by-symbols?symbols=${symbol}`)
      .then((res) => {
        result = res.data
      })
      .catch(err => err)
    return result
  },
  async getQuotationChart ({ commit }, symbol) {
    try {
      let response = await this.$axios.$get(
        `/api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=false`
      )
      const quotationChart = response ?? {
        prior: 0,
        quotations: []
      }
      response = {
        ...quotationChart,
        quotations: quotationChart.quotations.map(x => ({
          ...x,
          datetime: new Date(x.datetime)
        }))
      }
      return response
    } catch (err) {
      return null
    }
  },
  async getUnderlyingPrice ({ commit }) {
    try {
      const apiIF = this.$axios.$get('/api/set/tfex/underlying/equity/IF/price')
      const apiIO = this.$axios.$get('/api/set/tfex/underlying/equity/IO/price')
      const apiSIF = this.$axios.$get('/api/set/tfex/underlying/equity/SIF/price')
      const apiSF = this.$axios.$get('/api/set/tfex/underlying/equity/SF/price')
      const response = await Promise.allSettled([apiIF, apiIO, apiSIF, apiSF])
      commit('setUnderlyingPrice', [...response[0].value, ...response[1].value, ...response[2].value, ...response[3].value]
      )
    } catch (error) {
      commit('setUnderlyingPrice', [])
    }
  }
}

const getters = {
  getStockList: (state) => {
    return state.stockList ?? []
  },
  getEtfList: (state) => {
    return state.etfList ?? []
  },
  getDrList: (state) => {
    return state.drList ?? []
  },
  getDrxList: (state) => {
    return state.drxList ?? []
  },
  getDwList: (state) => {
    return state.dwList ?? []
  },
  getFutureList: (state) => {
    return state.futureList ?? []
  },
  getOptionList: (state) => {
    return state.optionList ?? []
  },
  getFundList: (state) => {
    return state.fundList ?? []
  },
  getUnittrustList: (state) => {
    return state.unittrustList ?? []
  },
  getUnderlyingPrice: (state) => {
    return state.underlyingPrice ?? []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
