const allCate = [
  {
    text: 'ทั้งหมด',
    value: ''
  }
]
const getDefaultState = () => {
  return {
    analystResult: {
      totalCount: 0,
      resultInfoList: []
    },
    analysStockResult: {
      totalCount: 0,
      resultInfoList: []
    },
    popular: {
      daily: [],
      weekly: [],
      monthly: []
    },
    masterData: {
      category: [
        {
          text: 'ทั้งหมด',
          value: ''
        }
      ],
      filter: {},
      type: [],
      securityType: [],
      allowedPeriod: {},
      latestdate: null
    }
  }
}

const state = getDefaultState

const mutations = {
  setAnalyst (state, data) {
    state.analystResult.reseachLatest = data.reseachLatest
    state.analystResult.resultInfoList = data.researchItems
  },
  setStockAnalyst (state, data) {
    state.analysStockResult.reseachLatest = data.reseachLatest
    state.analysStockResult.resultInfoList = data.researchItems
  },
  setFilter (state, data) {
    state.masterData.filter = data
  },
  setCategory (state, data) {
    state.masterData.category = [...allCate, ...data]
  },
  setDaily (state, data) {
    state.popular.daily = data
  },
  setWeekly (state, data) {
    state.popular.weekly = data
  },
  setMonthly (state, data) {
    state.popular.monthly = data
  }
}

const actions = {
  async searchAnalyst ({ commit }, payload) {
    try {
      const params = new URLSearchParams(payload)
      const cmsUrl = '/api/cms/v1/research-settrade/search'
      const result = await this.$apiCms
        .get(cmsUrl, { params })
        .then((res) => {
          return res.data
        })
        .catch(err => err)
      commit('setAnalyst', result)
    } catch (err) {
      commit('setAnalyst', null)
    }
  },
  async searchStockAnalyst ({ commit }, payload) {
    try {
      const params = new URLSearchParams(payload)
      const cmsUrl = '/api/cms/v1/research-settrade/search'
      const result = await this.$apiCms
        .get(cmsUrl, { params })
        .then((res) => {
          return res.data
        })
        .catch(err => err)
      commit('setStockAnalyst', result)
    } catch (err) {
      commit('setStockAnalyst', null)
    }
  },
  async getCategory ({ commit }) {
    try {
      const result = await this.$apiCms
        .get('/api/cms/v1/research-settrade/categories')
        .then((res) => {
          return res.data
        })
        .catch(() => [])
      commit('setCategory', result)
    } catch (error) {
      commit('setCategory', [])
    }
  },
  async getFilter ({ commit }, payload) {
    try {
      const params = new URLSearchParams(payload)
      const result = await this.$apiCms
        .get('/api/cms/v1/research-settrade/filter', { params })
        .then((res) => {
          return res.data
        })
        .catch(() => [])
      commit('setFilter', result)
    } catch (error) {
      commit('setFilter', {})
    }
  },
  async getDaily ({ commit }) {
    const response = await this.$apiCms
      .get(
        '/api/cms/v1/research-settrade/popular-research?frequency=Daily&language=TH&pageIndex=0&pageSize=5'
      )
      .then((res) => {
        return res.data.items
      })
      .catch(() => [])
    commit('setDaily', response)
  },
  async getWeekly ({ commit }) {
    const response = await this.$apiCms
      .get(
        '/api/cms/v1/research-settrade/popular-research?frequency=Weekly&language=TH&pageIndex=0&pageSize=5'
      )
      .then((res) => {
        return res.data.items
      })
      .catch(() => [])
    commit('setWeekly', response)
  },
  async getMonthly ({ commit }) {
    const response = await this.$apiCms
      .get(
        '/api/cms/v1/research-settrade/popular-research?frequency=Monthly&language=TH&pageIndex=0&pageSize=5'
      )
      .then((res) => {
        return res.data.items
      })
      .catch(() => [])
    commit('setMonthly', response)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
