import etfInformation from '@/store/equity/etf/etfInformation/index'

export const modules = {
  etfInformation
}

const getDefaultState = () => {
  return {
    info: null,
    quotationChart: null,
    quotationChartAccumulated: null,
    highlightData: null,
    etfhighlightData: null,
    historicalTrading: [],
    pricePerformanceChart: {},
    chartUnderlyingPerformance: [],
    etfPricePerformance: null,
    tradingStat: [],
    tradingStatUnadjusted: [],
    navPerformanceChart: null,
    fundHistoricalPerformance: null,
    benefits: [],
    dividendChart: [],
    dividendChartYearly: [],
    newsList: null
  }
}

const state = getDefaultState

const mutations = {
  setInfo (state, data) {
    state.info = data
  },

  setQuotationChart (state, data) {
    state.quotationChart = data
  },

  setQuotationChartAccumulated (state, data) {
    state.quotationChartAccumulated = data
  },

  setHighlightData (state, data) {
    state.highlightData = data
  },

  setETFHighlightData (state, data) {
    state.etfhighlightData = data
  },

  setHistoricalTrading (state, data) {
    state.historicalTrading = data
  },

  setPricePerformanceChart (state, data) {
    state.pricePerformanceChart = data
  },

  setChartUnderlyingPerformance (state, data) {
    state.chartUnderlyingPerformance = data
  },

  setETFPricePerformance (state, data) {
    state.etfPricePerformance = data
  },

  setTradingStat (state, data) {
    state.tradingStat = data
  },

  setTradingStatUnadjusted (state, data) {
    state.tradingStatUnadjusted = data
  },

  setNAVPerformanceChart (state, data) {
    state.navPerformanceChart = data
  },

  setFundHistoricalPerformance (state, data) {
    state.fundHistoricalPerformance = data
  },

  setBenefits (state, data) {
    state.benefits = data
  },

  setDividendChart (state, data) {
    state.dividendChart = data
  },

  setDividendChartYearly (state, data) {
    state.dividendChartYearly = data
  },

  setNewsList (state, data) {
    state.newsList = data
  }
}

const actions = {
  async getInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/info`)

      commit('setInfo', response)
    } catch {
      commit('setInfo', null)
    }
  },

  async getQuotationChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation`,
        {
          params: {
            period,
            accumulated: false
          }
        }
      )

      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },

  async getQuotationChartAccumulated ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation`,
        {
          params: {
            period,
            accumulated: true
          }
        }
      )

      commit('setQuotationChartAccumulated', response)
    } catch {
      commit('setQuotationChartAccumulated', null)
    }
  },

  async getHighlightData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/highlight-data`
      )

      commit('setHighlightData', response)
    } catch {
      commit('setHighlightData', null)
    }
  },

  async getETFHighlightData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/${quote}/highlight-data`
      )

      commit('setETFHighlightData', response)
    } catch {
      commit('setETFHighlightData', null)
    }
  },

  async getHistoricalTrading ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/historical-trading`
      )

      commit('setHistoricalTrading', response)
    } catch {
      commit('setHistoricalTrading', [])
    }
  },

  async getPricePerformanceChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-performance`,
        {
          params: {
            period
          }
        }
      )

      commit('setPricePerformanceChart', response)
    } catch {
      commit('setPricePerformanceChart', null)
    }
  },

  async getChartUnderlyingPerformance ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/${quote}/chart-underlying-performance`,
        {
          params: {
            period
          }
        }
      )

      commit('setChartUnderlyingPerformance', response)
    } catch {
      commit('setChartUnderlyingPerformance', null)
    }
  },

  async getETFPricePerformance ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/${quote}/price-performance`
      )

      commit('setETFPricePerformance', response)
    } catch {
      commit('setETFPricePerformance', null)
    }
  },

  async getTradingStat ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/company-highlight/trading-stat`,
        { params: { adjustedPrice: true } }
      )

      commit('setTradingStat', response)
    } catch {
      commit('setTradingStat', null)
    }
  },

  async getTradingStatUnadjusted ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/company-highlight/trading-stat`,
        { params: { adjustedPrice: false } }
      )

      commit('setTradingStatUnadjusted', response)
    } catch {
      commit('setTradingStatUnadjusted', null)
    }
  },

  async getNAVPerformanceChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/${quote}/chart-nav-performance`,
        {
          params: {
            period
          }
        }
      )
      commit('setNAVPerformanceChart', response)
    } catch {
      commit('setNAVPerformanceChart', null)
    }
  },

  async getFundHistoricalPerformance ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set-fund/fund-profile/${encodeURIComponent(encodeURIComponent(quote))}/historical-performance`
      )

      commit('setFundHistoricalPerformance', response)
    } catch {
      commit('setFundHistoricalPerformance', null)
    }
  },

  async getBenefits ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/corporate-action`
      )
      commit('setBenefits', response)
    } catch {
      commit('setBenefits', [])
    }
  },

  async getDividendChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/dividend-chart`)

      commit('setDividendChart', response)
    } catch {
      commit('setDividendChart', [])
    }
  },

  async getDividendChartYearly ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/yearly-dividend-chart`)

      commit('setDividendChartYearly', response)
    } catch {
      commit('setDividendChartYearly', [])
    }
  },

  async getNewsList ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/news/${quote}/list`)

      commit('setNewsList', response)
    } catch {
      commit('setNewsList', null)
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
