const state = () => {
  return {
    symbols: []
  }
}

const mutations = {
  setSymbol (state, data) {
    state.symbols = data
  }
}

const actions = {
  async getSymbol ({ commit }) {
    try {
      const response = await Promise.all([
        this.$axios.$get(
          '/api/set/technical-chart/search?query=&type=&exchange=&limit=10000'
        ),
        this.$axios.$get('/api/set-fund/fund-technical-chart/search?query=&limit=10000')
      ])
      commit('setSymbol', [...response[0], ...response[1]])
    } catch {
      commit('setSymbol', [])
    }
  }
}

const getters = {
  activeSymbols (state) {
    return state.symbols
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
