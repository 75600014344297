//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dayName } from '@/helpers/dateTime'
import { elementObserver, elementAttributeObserver } from '@/helpers/element-observer'
export default {
  name: 'ChatBotZwizPersonalizedPopup',
  data () {
    const cookiesName = 'bull-popup-hidden'
    const bullPopupHidden = this.$cookies.get(cookiesName)
    return {
      cookiesName,
      popupPosition: {
        bottom: 20
      },
      bullPopupHidden
    }
  },
  computed: {
    day () {
      const date = this.$store.state.date
      return dayName(date)
    },
    chatTitle () {
      if (this.$authUser.permissionUser()) {
        const { firstName, lastName } = this.$authUser.userProfile()
        const fullName = firstName + ' ' + lastName
        return 'สวัสดีคุณ' + ' ' + fullName
      }
      return 'สวัสดี วัน' + this.day
    }
  },
  mounted () {
    elementObserver({
      observeConfig: { subtree: true, childList: true },
      parent: document.body,
      nodeId: 'myQuoteMain'
    }, () => {
      this.updatePosition()
    })
    elementAttributeObserver({
      observeConfig: { attributeFilter: ['class'] },
      el: document.getElementById('popup-outer-box')
    }, () => {
      this.bullPopupHidden = this.$cookies.get(this.cookiesName)
      this.updatePosition()
    })
  },
  methods: {
    updatePosition () {
      const container = document.querySelector('.chat-layout')
      if (!container) { return }
      const btnMyQuote = document.getElementById('btn-my-quote')
      const { offsetTop: btnMyQuoteTop } = btnMyQuote || { offsetTop: 0 }
      const bottom = window.innerHeight - btnMyQuoteTop + 20
      container.style.bottom = bottom + 'px'
      this.popupPosition.bottom = bottom + 'px'
    },
    closeChatMedia () {
      const chatIcon = document.getElementById('chatbot-bull-icon')
      chatIcon.style.display = 'none'
      this.$refs.popup.style.display = 'none'
    }
  }
}
