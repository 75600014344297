//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FooterDownloadApp',
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClickItem () {
      this.$personalized.clickElement({
        name: 'all13',
        optionLang: {
          applicationName: this.title
        }
      })
    }
  }
}
