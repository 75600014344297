import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/technical-chart/index.js'), 'technical-chart/index.js')
  resolveStoreModules(require('../store/tabs/index.js'), 'tabs/index.js')
  resolveStoreModules(require('../store/summary/index.js'), 'summary/index.js')
  resolveStoreModules(require('../store/search/index.js'), 'search/index.js')
  resolveStoreModules(require('../store/recent-view/index.js'), 'recent-view/index.js')
  resolveStoreModules(require('../store/profile/index.js'), 'profile/index.js')
  resolveStoreModules(require('../store/popular/index.js'), 'popular/index.js')
  resolveStoreModules(require('../store/news-and-articles/index.js'), 'news-and-articles/index.js')
  resolveStoreModules(require('../store/mutualfund/index.js'), 'mutualfund/index.js')
  resolveStoreModules(require('../store/menu/index.js'), 'menu/index.js')
  resolveStoreModules(require('../store/home/index.js'), 'home/index.js')
  resolveStoreModules(require('../store/getquote/index.js'), 'getquote/index.js')
  resolveStoreModules(require('../store/form/index.js'), 'form/index.js')
  resolveStoreModules(require('../store/filterdropdown/index.js'), 'filterdropdown/index.js')
  resolveStoreModules(require('../store/factsheet/index.js'), 'factsheet/index.js')
  resolveStoreModules(require('../store/equity/index.js'), 'equity/index.js')
  resolveStoreModules(require('../store/economicData/index.js'), 'economicData/index.js')
  resolveStoreModules(require('../store/derivative/index.js'), 'derivative/index.js')
  resolveStoreModules(require('../store/contents/index.js'), 'contents/index.js')
  resolveStoreModules(require('../store/auth/index.js'), 'auth/index.js')
  resolveStoreModules(require('../store/research/iaa-tfex-consensus/index.js'), 'research/iaa-tfex-consensus/index.js')
  resolveStoreModules(require('../store/research/iaa-consensus/index.js'), 'research/iaa-consensus/index.js')
  resolveStoreModules(require('../store/research/analyst-research/index.js'), 'research/analyst-research/index.js')
  resolveStoreModules(require('../store/news-and-articles/news/index.js'), 'news-and-articles/news/index.js')
  resolveStoreModules(require('../store/news-and-articles/articles/index.js'), 'news-and-articles/articles/index.js')
  resolveStoreModules(require('../store/mutualfund/screening/index.js'), 'mutualfund/screening/index.js')
  resolveStoreModules(require('../store/mutualfund/overview/index.js'), 'mutualfund/overview/index.js')
  resolveStoreModules(require('../store/mutualfund/ipo-search/index.js'), 'mutualfund/ipo-search/index.js')
  resolveStoreModules(require('../store/mutualfund/comparison/index.js'), 'mutualfund/comparison/index.js')
  resolveStoreModules(require('../store/member/virtual-portfolio/index.js'), 'member/virtual-portfolio/index.js')
  resolveStoreModules(require('../store/member/upcoming-favourite/index.js'), 'member/upcoming-favourite/index.js')
  resolveStoreModules(require('../store/member/my-feed/index.js'), 'member/my-feed/index.js')
  resolveStoreModules(require('../store/member/favourite/index.js'), 'member/favourite/index.js')
  resolveStoreModules(require('../store/member/backtesting/index.js'), 'member/backtesting/index.js')
  resolveStoreModules(require('../store/home/stock/index.js'), 'home/stock/index.js')
  resolveStoreModules(require('../store/home/market-summary/index.js'), 'home/market-summary/index.js')
  resolveStoreModules(require('../store/home/market-movement/index.js'), 'home/market-movement/index.js')
  resolveStoreModules(require('../store/factsheet/dw/index.js'), 'factsheet/dw/index.js')
  resolveStoreModules(require('../store/factsheet/dr/index.js'), 'factsheet/dr/index.js')
  resolveStoreModules(require('../store/equity/stockScreening/index.js'), 'equity/stockScreening/index.js')
  resolveStoreModules(require('../store/equity/stockComparison/index.js'), 'equity/stockComparison/index.js')
  resolveStoreModules(require('../store/equity/stockCalendar/index.js'), 'equity/stockCalendar/index.js')
  resolveStoreModules(require('../store/equity/stock/index.js'), 'equity/stock/index.js')
  resolveStoreModules(require('../store/equity/etf/index.js'), 'equity/etf/index.js')
  resolveStoreModules(require('../store/equity/dw/index.js'), 'equity/dw/index.js')
  resolveStoreModules(require('../store/equity/drx/index.js'), 'equity/drx/index.js')
  resolveStoreModules(require('../store/equity/dr/index.js'), 'equity/dr/index.js')
  resolveStoreModules(require('../store/equity/companysnapshot/index.js'), 'equity/companysnapshot/index.js')
  resolveStoreModules(require('../store/derivative/quote/index.js'), 'derivative/quote/index.js')
  resolveStoreModules(require('../store/derivative/overview/index.js'), 'derivative/overview/index.js')
  resolveStoreModules(require('../store/equity/stock/warrant/index.js'), 'equity/stock/warrant/index.js')
  resolveStoreModules(require('../store/equity/stock/unitTrust/index.js'), 'equity/stock/unitTrust/index.js')
  resolveStoreModules(require('../store/equity/stock/preferredForeign/index.js'), 'equity/stock/preferredForeign/index.js')
  resolveStoreModules(require('../store/equity/stock/preferred/index.js'), 'equity/stock/preferred/index.js')
  resolveStoreModules(require('../store/equity/stock/commonForeign/index.js'), 'equity/stock/commonForeign/index.js')
  resolveStoreModules(require('../store/equity/stock/common/index.js'), 'equity/stock/common/index.js')
  resolveStoreModules(require('../store/equity/market/etf/index.js'), 'equity/market/etf/index.js')
  resolveStoreModules(require('../store/equity/market/dw/index.js'), 'equity/market/dw/index.js')
  resolveStoreModules(require('../store/equity/market/drx/index.js'), 'equity/market/drx/index.js')
  resolveStoreModules(require('../store/equity/market/dr/index.js'), 'equity/market/dr/index.js')
  resolveStoreModules(require('../store/equity/market-data/overview/index.js'), 'equity/market-data/overview/index.js')
  resolveStoreModules(require('../store/equity/ipo-corner/upcoming-ipo/index.js'), 'equity/ipo-corner/upcoming-ipo/index.js')
  resolveStoreModules(require('../store/equity/ipo-corner/ipo-performance/index.js'), 'equity/ipo-corner/ipo-performance/index.js')
  resolveStoreModules(require('../store/equity/etf/etfInformation/index.js'), 'equity/etf/etfInformation/index.js')
  resolveStoreModules(require('../store/equity/esgInvestment/esgRating/index.js'), 'equity/esgInvestment/esgRating/index.js')
  resolveStoreModules(require('../store/derivative/quote/preciousMetal/index.js'), 'derivative/quote/preciousMetal/index.js')
  resolveStoreModules(require('../store/derivative/quote/interestRate/index.js'), 'derivative/quote/interestRate/index.js')
  resolveStoreModules(require('../store/derivative/quote/equity/index.js'), 'derivative/quote/equity/index.js')
  resolveStoreModules(require('../store/derivative/quote/currency/index.js'), 'derivative/quote/currency/index.js')
  resolveStoreModules(require('../store/derivative/quote/agriculture/index.js'), 'derivative/quote/agriculture/index.js')
  resolveStoreModules(require('../store/derivative/overview/comparison/index.js'), 'derivative/overview/comparison/index.js')
  resolveStoreModules(require('../store/derivative/overview/calendar/index.js'), 'derivative/overview/calendar/index.js')
  resolveStoreModules(require('../store/equity/stock/warrant/companyInformation/index.js'), 'equity/stock/warrant/companyInformation/index.js')
  resolveStoreModules(require('../store/equity/stock/unitTrust/companyInformation/index.js'), 'equity/stock/unitTrust/companyInformation/index.js')
  resolveStoreModules(require('../store/equity/stock/preferredForeign/companyInformation/index.js'), 'equity/stock/preferredForeign/companyInformation/index.js')
  resolveStoreModules(require('../store/equity/stock/preferred/companyInformation/index.js'), 'equity/stock/preferred/companyInformation/index.js')
  resolveStoreModules(require('../store/equity/stock/commonForeign/companyInformation/index.js'), 'equity/stock/commonForeign/companyInformation/index.js')
  resolveStoreModules(require('../store/equity/stock/common/financialStatement/index.js'), 'equity/stock/common/financialStatement/index.js')
  resolveStoreModules(require('../store/equity/stock/common/companyInformation/index.js'), 'equity/stock/common/companyInformation/index.js')
  resolveStoreModules(require('../store/derivative/quote/equity/singleStockFutures/index.js'), 'derivative/quote/equity/singleStockFutures/index.js')
  resolveStoreModules(require('../store/derivative/quote/equity/indexOptions/index.js'), 'derivative/quote/equity/indexOptions/index.js')
  resolveStoreModules(require('../store/derivative/quote/equity/indexFutures/index.js'), 'derivative/quote/equity/indexFutures/index.js')
  resolveStoreModules(require('../store/derivative/overview/marketdata/tradingQuotationBySeries/index.js'), 'derivative/overview/marketdata/tradingQuotationBySeries/index.js')
  resolveStoreModules(require('../store/derivative/overview/marketdata/investorType/index.js'), 'derivative/overview/marketdata/investorType/index.js')
  resolveStoreModules(require('../store/derivative/overview/marketdata/historicalData/index.js'), 'derivative/overview/marketdata/historicalData/index.js')
  resolveStoreModules(require('../store/derivative/overview/marketdata/blockTrade/index.js'), 'derivative/overview/marketdata/blockTrade/index.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
