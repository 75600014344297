/**
 * New uuid
 * @returns uuid
 */
const uuid = () => {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
  return uuid
}

/**
 * Element pdpa
 */
const elementPDPA = {
  name: 'pdpa'
}

/**
 * Render Type
 */
const contentRenderType = {
  fixedLayout: 'FixedLayout',
  pageBuilder: 'PageBuilder',
  externalLink: 'ExternalLink'
}
const contentType = {
  externalLink: 'ExternalLink',
  contentFile: 'ContentFile'
}

/**
 * Console colors reference
 */
const consoleColors = {
  reset: '\x1B[0m',
  bright: '\x1B[1m',
  Dim: '\x1B[2m',
  underscore: '\x1B[4m',
  blink: '\x1B[5m',
  reverse: '\x1B[7m',
  hidden: '\x1B[8m',

  fgBlack: '\x1B[30m',
  fgRed: '\x1B[31m',
  fgGreen: '\x1B[32m',
  fgYellow: '\x1B[33m',
  fgBlue: '\x1B[34m',
  fgMagenta: '\x1B[35m',
  fgCyan: '\x1B[36m',
  fgWhite: '\x1B[37m',

  bgBlack: '\x1B[40m',
  bgRed: '\x1B[41m',
  bgGreen: '\x1B[42m',
  bgYellow: '\x1B[43m',
  bgBlue: '\x1B[44m',
  bgMagenta: '\x1B[45m',
  bgCyan: '\x1B[46m',
  bgWhite: '\x1B[47m'
}

function interopDefault (promise) {
  return promise.then(m => m.default || m)
}

export {
  uuid,
  elementPDPA,
  contentRenderType,
  contentType,
  consoleColors,
  interopDefault
}
