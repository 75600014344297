/**
 * State
 */
const state = () => {
  return {
    topRankStockList: [],
    tradingStat: [],
    financialData: [],
    pricePerformanceChart: [],
    stockList: [],
    allowedPeriod: {}
  }
}

/**
 * Mutations
 */
const mutations = {
  setTopRankStockList (state, data) {
    if (data != null) {
      state.topRankStockList = data
    }
  },
  setPricePerformanceChart (state, data) {
    if (data != null) {
      state.pricePerformanceChart = data
    }
  },
  setTradingStat (state, data) {
    if (data != null) {
      state.tradingStat = data
    }
  },
  setFinancialData (state, data) {
    if (data != null) {
      state.financialData = data
    }
  },
  setStockList (state, data) {
    if (data != null) {
      state.stockList = data
    }
  },
  setAllowedPeriod (state, data) {
    state.allowedPeriod = data
  }
}

/**
 * Actions
 */
const actions = {
  async getTop5Stock ({ commit }, payload) {
    const { rankingType } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/ranking/${rankingType}/SET/S?count=5`
      )

      const topRankStockList = response.stocks.map(stock => stock.symbol)
      commit('setTopRankStockList', topRankStockList)
    } catch (error) {
      commit('setTopRankStockList', null)
    }
  },
  async getTop5PopularQuote ({ commit }) {
    const url =
      '/api/cms/v1/popularquote/getpopular?market=SET&securityType=S&limit=5'
    try {
      const response = await this.$apiCms.get(url)
      const topRankStockList = response.data.map(stock => stock.symbol)

      commit('setTopRankStockList', topRankStockList)
    } catch (err) {
      commit('setTopRankStockList', null)
    }
  },
  async getPricePerformanceChart ({ commit }, payload) {
    const { symbols, period } = payload
    try {
      let quotationsList = symbols.map((symbol) => {
        return this.$axios.$get(`/api/set/stock/${symbol}/chart-performance`, {
          params: {
            period
          }
        })
      })

      quotationsList = await Promise.allSettled(quotationsList)
      quotationsList = quotationsList.map((quotation, index) => {
        return {
          legend: symbols[index],
          quotations: quotation.value
            ? quotation.value.quotations.reverse()
            : []
        }
      })

      commit('setPricePerformanceChart', quotationsList)
    } catch (e) {
      commit('setPricePerformanceChart', [])
    }
  },
  async getTradingStat ({ commit }, payload) {
    try {
      const params = new URLSearchParams(payload)
      await this.$axios
        .$get('/api/set/stock-compare/trading-stat', { params })
        .then((res) => {
          commit('setTradingStat', res)
        })
    } catch (error) {
      commit('setTradingStat', null)
    }
  },
  async getFinancialData ({ commit }, payload) {
    try {
      const params = new URLSearchParams(payload)
      await this.$axios
        .$get('/api/set/stock-compare/financial-data', { params })
        .then((res) => {
          commit('setFinancialData', res)
        })
    } catch (error) {
      commit('setFinancialData', null)
    }
  },
  async getStockList ({ commit }) {
    try {
      await this.$axios
        .$get('/api/set/stock/list', {
          params: {
            securityType: 's'
          }
        })
        .then((res) => {
          commit('setStockList', res.securitySymbols)
        })
    } catch {
      commit('setStockList', null)
    }
  },
  async getAllowedPeriod ({ commit }, payload) {
    const { period } = payload
    try {
      const result = await this.$axios.$get(
        `/api/set/calendar/period/${period}`
      )
      commit('setAllowedPeriod', result)
    } catch (error) {
      commit('setAllowedPeriod', {})
    }
  }
}

/**
 * Getters
 */
const getters = {
  pricePerformanceData: (state) => {
    return state.pricePerformanceChart.map(item => item.quotations)
  },
  pricePerformanceLegend: (state) => {
    return state.pricePerformanceChart.map(item => item.legend)
  },
  longestPricePerformanceData: (state, getters) => {
    let maxLength = 0
    let maxLengthIndex = 0
    getters.pricePerformanceData.forEach((item, index) => {
      if (item.length > maxLength) {
        maxLength = item.length
        maxLengthIndex = index
      }
    })
    return getters.pricePerformanceData[maxLengthIndex]
  }
  // defaultCalendar: (state) => {
  //   return state.defaultCalendar?.datetime ?? ''
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
