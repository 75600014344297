import stock from '@/store/equity/stock/index'
import dr from '@/store/equity/dr/index'
import drx from '@/store/equity/drx/index'
import etf from '@/store/equity/etf/index'
import stockCalendar from '@/store/equity/stockCalendar/index'

export const modules = {
  stock,
  dr,
  drx,
  etf,
  stockCalendar
}
