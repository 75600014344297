import _ from 'lodash'
import * as api from '@/helpers/favourite/api.js'

const getDefaultState = () => {
  return {
    stock: {
      list: [],
      highlight: []
    },
    etf: {
      list: [],
      search: [],
      fund: []
    },
    dw: {
      marketDateTime: null,
      dwList: []
    },
    dr: {
      list: [],
      search: []
    },
    drx: {
      list: [],
      search: []
    },
    futures: [],
    options: [],
    fund: {
      marketDateTime: null,
      overviewInfo: [],
      performanceInfo: []
    },
    underlyingPrice: [],
    delete: {
      symbol: '',
      category: ''
    },
    underlyingMetal: {},
    underlyingCurrency: {},
    interestRateList: {},
    interestRate5Year: [],
    referenceData: {},
    otherMarket: {},
    favoriteDelisted: {
      stock: [],
      etf: [],
      dw: [],
      dr: [],
      drx: [],
      futures: [],
      options: [],
      fund: []
    }
  }
}

const state = getDefaultState

const mutations = {
  setStockPrice (state, data) {
    state.stock.list = data?.list ?? []
    state.stock.highlight = data?.highlight ?? []
  },

  setETFPrice (state, data) {
    state.etf.list = data?.list ?? []
    state.etf.search = data?.search?.data ?? []
    state.etf.fund = data?.fund?.filterFunds?.map((data) => {
      return data.performanceInfo
    }) ?? []
  },

  setDWPrice (state, data) {
    state.dw.marketDateTime = data?.marketTime ?? null
    state.dw.dwList = data?.dwList ?? []
  },

  setDRPrice (state, data) {
    state.dr.list = data?.list ?? []
    state.dr.search = data?.search?.data ?? []
  },

  setDRxPrice (state, data) {
    state.drx.list = data?.list ?? []
    state.drx.search = data?.search?.data ?? []
  },

  setFuturesPrice (state, data) {
    state.futures = data ?? []
  },

  setOptionsPrice (state, data) {
    state.options = data ?? []
  },

  setFundPrice (state, data) {
    state.fund.marketDateTime = data?.asOfDate ?? null
    state.fund.overviewInfo = data?.filterFunds?.map((data) => {
      return data.overviewInfo
    }) ?? []
    state.fund.performanceInfo = data?.filterFunds?.map((data) => {
      return data.performanceInfo
    }) ?? []
  },

  setUnderlyingPrice (state, data) {
    state.underlyingPrice = data ?? []
  },

  setUnderlyingMetal (state, data) {
    state.underlyingMetal = data ?? {}
  },

  setUnderlyingCurrency (state, data) {
    state.underlyingCurrency = data ?? {}
  },

  setInterestRateList (state, data) {
    state.interestRateList = data ?? {}
  },

  setInterestRate5Year (state, data) {
    state.interestRate5Year = data ?? []
  },

  setReferenceData (state, data) {
    state.referenceData = data ?? {}
  },

  setOtherMarket (state, data) {
    state.otherMarket = data ?? {}
  },

  setFavoriteDelisted (state, data) {
    state.favoriteDelisted.dw = data ?? {}
  },
  setFavoriteDelistedStock (state, data) {
    state.favoriteDelisted.stock = data ?? {}
  },
  setFavoriteDelistedETF (state, data) {
    state.favoriteDelisted.etf = data ?? {}
  },
  setFavoriteDelistedDR (state, data) {
    state.favoriteDelisted.dr = data ?? {}
  },
  setFavoriteDelistedDRx (state, data) {
    state.favoriteDelisted.drx = data ?? {}
  },
  setFavoriteDelistedFutures (state, data) {
    state.favoriteDelisted.futures = data ?? {}
  },
  setFavoriteDelistedOptions (state, data) {
    state.favoriteDelisted.options = data ?? {}
  },
  setFavoriteDelistedFund (state, data) {
    state.favoriteDelisted.fund = data ?? {}
  },
  setDelete (state, data) {
    state.delete = data
  }
}

const actions = {
  async getStockPrice ({ commit }, symbols) {
    try {
      const response = await api.getFavouriteStockPrice.call(this, symbols?.join())
      const [list, highlight] = response ?? []

      commit('setStockPrice', { list, highlight })
    } catch {
      commit('setStockPrice', {})
    }
  },

  async getETFPrice ({ commit }, symbols) {
    try {
      const response = await api.getFavouriteETFPrice.call(this, symbols?.join())
      const [list, search, fund] = response ?? []

      commit('setETFPrice', { list, search, fund })
    } catch {
      commit('setETFPrice', {})
    }
  },

  async getDWPrice ({ commit }, symbols) {
    try {
      const response = await api.getFavouriteDWPrice.call(this, symbols?.join())

      commit('setDWPrice', response)
    } catch {
      commit('setDWPrice', [])
    }
  },

  async getDRPrice ({ commit }, symbols) {
    try {
      const response = await api.getFavouriteDRPrice.call(this, symbols?.join())
      const [list, search] = response ?? []

      commit('setDRPrice', { list, search })
    } catch {
      commit('setDRPrice', {})
    }
  },

  async getDRxPrice ({ commit }, symbols) {
    try {
      const response = await api.getFavouriteDRxPrice.call(this, symbols?.join())
      const [list, search] = response ?? []

      commit('setDRxPrice', { list, search })
    } catch {
      commit('setDRxPrice', {})
    }
  },

  async getFuturesPrice ({ commit }, symbols) {
    try {
      const response = await api.getFavouriteFuturesPrice.call(this, symbols?.join())

      commit('setFuturesPrice', response)
    } catch {
      commit('setFuturesPrice', [])
    }
  },

  async getOptionsPrice ({ commit }, symbols) {
    try {
      const response = await api.getFavouriteOptionsPrice.call(this, symbols?.join())

      commit('setOptionsPrice', response)
    } catch {
      commit('setOptionsPrice', [])
    }
  },

  async getFundPrice ({ commit }, symbols) {
    try {
      const response = await api.getFavouriteFundPrice.call(this, symbols?.join())

      commit('setFundPrice', response)
    } catch {
      commit('setFundPrice', {})
    }
  },

  async getUnderlyingPrice ({ commit }) {
    try {
      const response = await Promise.all([
        this.$axios.$get('/api/set/tfex/underlying/equity/TXI_F/price'),
        this.$axios.$get('/api/set/tfex/underlying/equity/TXI_O/price'),
        // this.$axios.$get('/api/set/tfex/underlying/equity/SIF/price'),
        this.$axios.$get('/api/set/tfex/underlying/equity/TXS_F/price')
      ])
      const merged = _.merge(
        _.keyBy(response[0], 'symbol'),
        _.keyBy(response[1], 'symbol'),
        _.keyBy(response[2], 'symbol'),
        _.keyBy(response[3], 'symbol')
      )

      commit('setUnderlyingPrice', _.values(merged))
    } catch {
      commit('setUnderlyingPrice', [])
    }
  },

  async getUnderlyingMetal ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/underlying/metal/reference-data')
      commit('setUnderlyingMetal', response)
    } catch (error) {
      commit('setUnderlyingMetal', {})
    }
  },

  async getUnderlyingCurrency ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/underlying/currency/reference-data')
      commit('setUnderlyingCurrency', response)
    } catch (error) {
      commit('setUnderlyingCurrency', {})
    }
  },

  async getInterestRateList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/underlying/interest-rate/list')

      commit('setInterestRateList', response)
    } catch {
      commit('setInterestRateList', {})
    }
  },

  async getInterestRate5Year ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/underlying/interest-rate/5year-bond-yeild')

      commit('setInterestRate5Year', response)
    } catch {
      commit('setInterestRate5Year', [])
    }
  },

  async getReferenceData ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/underlying/agriculture/rubber/reference-data')

      commit('setReferenceData', response)
    } catch {
      commit('setReferenceData', {})
    }
  },

  async getOtherMarket ({ commit }, payload) {
    try {
      const { market } = payload
      const response = await this.$axios.$get('/api/set/tfex/underlying/agriculture/rubber/other-market',
        { params: { market } }
      )

      commit('setOtherMarket', response)
    } catch {
      commit('setOtherMarket', {})
    }
  },
  async getFavoriteDelistedStock ({ commit }, category) {
    try {
      const response = await api.getFavoriteDelisted.call(this, category)
      commit('setFavoriteDelistedStock', response)
    } catch {
      commit('setFavoriteDelistedStock', [])
    }
  },
  async getFavoriteDelistedETF ({ commit }, category) {
    try {
      const response = await api.getFavoriteDelisted.call(this, category)
      commit('setFavoriteDelistedETF', response)
    } catch {
      commit('setFavoriteDelistedETF', [])
    }
  },
  async getFavoriteDelisted ({ commit }, category) {
    try {
      const response = await api.getFavoriteDelisted.call(this, category)
      commit('setFavoriteDelisted', response)
    } catch {
      commit('setFavoriteDelisted', [])
    }
  },
  async getFavoriteDelistedDR ({ commit }, category) {
    try {
      const response = await api.getFavoriteDelisted.call(this, category)
      commit('setFavoriteDelistedDR', response)
    } catch {
      commit('setFavoriteDelistedDR', [])
    }
  },
  async getFavoriteDelistedDRx ({ commit }, category) {
    try {
      const response = await api.getFavoriteDelisted.call(this, category)
      commit('setFavoriteDelistedDRx', response)
    } catch {
      commit('setFavoriteDelistedDRx', [])
    }
  },
  async getFavoriteDelistedFutures ({ commit }, category) {
    try {
      const response = await api.getFavoriteDelisted.call(this, category)
      commit('setFavoriteDelistedFutures', response)
    } catch {
      commit('setFavoriteDelistedFutures', [])
    }
  },
  async getFavoriteDelistedOptions ({ commit }, category) {
    try {
      const response = await api.getFavoriteDelisted.call(this, category)
      console.log('setFavoriteDelistedOptions', response)
      commit('setFavoriteDelistedOptions', response)
    } catch {
      commit('setFavoriteDelistedOptions', [])
    }
  },
  async getFavoriteDelistedFund ({ commit }, category) {
    try {
      const response = await api.getFavoriteDelisted.call(this, category)
      console.log('getFavoriteDelistedFund', response)
      commit('setFavoriteDelistedFund', response)
    } catch {
      commit('setFavoriteDelistedFund', [])
    }
  }
}

const getters = {
  stockPriceList: (state) => {
    const list = state.stock.list.map((data) => {
      return {
        symbol: data.symbol,
        last: data.last,
        change: data.change,
        percentChange: data.percentChange,
        totalVolume: data.totalVolume,
        totalValue: data.totalValue,
        low: data.low,
        high: data.high,
        bidPrice: data?.bids?.[0]?.price,
        bidVolume: data?.bids?.[0]?.volume,
        offerPrice: data?.offers?.[0]?.price,
        offerVolume: data?.offers?.[0]?.volume,
        high52Weeks: data.high52Weeks,
        low52Weeks: data.low52Weeks,
        ceiling: data.ceiling,
        floor: data.floor,
        marketCap: data.marketCap,
        nvdrNetVolume: data.nvdrNetVolume,
        dividendYield: data.dividendYield,
        pbv: data.pbRatio
      }
    }).concat(
      state.favoriteDelisted.stock.map(item => ({ ...item, securityType: item.securityType, flag: item.flag, totalVolume: null, totalValue: null, marketCap: null }))
    )

    const highlight = state.stock.highlight.map((data) => {
      return {
        symbol: data.symbol,
        eps: data.eps,
        grossProfitMargin: data.grossProfitMargin,
        netProfitMargin: data.netProfitMargin,
        pe: data.pe,
        roa: data.roa,
        roe: data.roe,
        deRatio: data.deRatio,
        quarter: data.quarter,
        year: data.year,
        asOfDate: data.asOfDate
      }
    })

    const merged = _.merge(_.keyBy(list, 'symbol'), _.keyBy(highlight, 'symbol'))
    return {
      marketDateTime: state.stock?.list?.[0]?.marketDateTime,
      favourite: _.values(merged)
    }
  },

  etfPriceList: (state) => {
    const list = state.etf.list.map((data) => {
      return {
        symbol: data.symbol,
        last: data.last,
        change: data.change,
        percentChange: data.percentChange,
        inav: data?.inav?.inav,
        totalVolume: data.totalVolume,
        totalValue: data.totalValue,
        bidPrice: data?.bids?.[0]?.price,
        bidVolume: data?.bids?.[0]?.volume,
        offerPrice: data?.offers?.[0]?.price,
        offerVolume: data?.offers?.[0]?.volume,
        high52Weeks: data.high52Weeks,
        low52Weeks: data.low52Weeks,
        ceiling: data.ceiling,
        floor: data.floor,
        marketCap: data.marketCap,
        dividendYield: data.dividendYield,
        pbv: data.pbRatio,
        nvdrNetVolume: data.nvdrNetVolume
      }
    }).concat(
      state.favoriteDelisted.etf.map(item => ({ ...item, securityType: item.securityType, flag: item.flag, totalVolume: null, totalValue: null, marketCap: null }))
    )

    const search = state.etf.search.map((data) => {
      return {
        symbol: data.symbol,
        nav: data.nav,
        navDate: data.navDate
      }
    })

    const fund = state.etf.fund.map((data) => {
      return {
        symbol: data.symbol,
        oneMonthPercentChange: data.oneMonthPercentChange,
        threeMonthPercentChange: data.threeMonthPercentChange,
        sixMonthPercentChange: data.sixMonthPercentChange,
        ytdPercentChange: data.ytdPercentChange,
        oneYearPercentChange: data.oneYearPercentChange,
        threeYearPercentChange: data.threeYearPercentChange,
        fiveYearPercentChange: data.fiveYearPercentChange,
        tenYearPercentChange: data.tenYearPercentChange
      }
    })

    const merged = _.merge(_.keyBy(list, 'symbol'), _.keyBy(search, 'symbol'), _.keyBy(fund, 'symbol'))
    return {
      marketDateTime: state.etf?.list?.[0]?.marketDateTime,
      favourite: _.values(merged)
    }
  },

  dwPriceList: (state) => {
    return {
      marketDateTime: state.dw.marketDateTime,
      favourite: state.dw.dwList.map((data) => {
        return {
          symbol: data.symbol,
          underlying: data.underlyingSymbol,
          dwType: data.dwType,
          last: data.last,
          change: data.change,
          percentChange: data.percentChange,
          eg: data.eg,
          iv: data.iv,
          moneynessStatus: data.moneynessStatus,
          moneynessPercent: data.moneynessPercent,
          delta: data.delta,
          ttm: data.ttm,
          timeStatus: data.timeStatus ? data.timeStatus : new Date().setDate(new Date().getDate() - 1) > new Date(data.lastTradingDate) ? 'expired' : ''
        }
      }).concat(state.favoriteDelisted.dw)
    }
  },

  drPriceList: (state) => {
    const list = state.dr.list.map((data) => {
      return {
        symbol: data.symbol,
        underlying: data.underlying,
        last: data.last,
        change: data.change,
        percentChange: data.percentChange,
        totalVolume: data.totalVolume,
        totalValue: data.totalValue,
        marketCap: data.marketCap,
        offerPrice: data?.offers?.[0]?.price
      }
    }).concat(
      state.favoriteDelisted.dr.map(item => ({ ...item, securityType: item.securityType, flag: item.flag, totalVolume: null, totalValue: null, marketCap: null }))
    )

    const search = state.dr.search.map((data) => {
      return {
        symbol: data.symbol,
        listedShare: data.listedShare,
        outstandingDate: data.outstandingDate,
        outstandingShare: data.outstandingShare,
        conversionRatio: data.conversionRatio,
        underlyingExchange: data.underlyingExchange
      }
    })

    const merged = _.merge(_.keyBy(list, 'symbol'), _.keyBy(search, 'symbol'))
    return {
      marketDateTime: state.dr?.list?.[0]?.marketDateTime,
      favourite: _.values(merged)
    }
  },

  drxPriceList: (state) => {
    const list = state.drx.list.map((data) => {
      return {
        symbol: data.symbol,
        underlying: data.underlying,
        last: data.last,
        change: data.change,
        percentChange: data.percentChange,
        totalVolume: data.totalVolume,
        totalValue: data.totalValue,
        marketCap: data.marketCap,
        offerPrice: data?.offers?.[0]?.price
      }
    }).concat(
      state.favoriteDelisted.drx.map(item => ({ ...item, securityType: item.securityType, flag: item.flag, totalVolume: null, totalValue: null, marketCap: null }))
    )

    const search = state.drx.search.map((data) => {
      return {
        symbol: data.symbol,
        listedShare: data.listedShare,
        outstandingDate: data.outstandingDate,
        outstandingShare: data.outstandingShare,
        conversionRatio: data.conversionRatio,
        underlyingExchange: data.underlyingExchange
      }
    })

    const merged = _.merge(_.keyBy(list, 'symbol'), _.keyBy(search, 'symbol'))
    return {
      marketDateTime: state.drx?.list?.[0]?.marketDateTime,
      favourite: _.values(merged)
    }
  },

  futuresPriceList: (state) => {
    return {
      marketDateTime: state.futures?.[0]?.marketTime,
      favourite: state.futures.map((data) => {
        return {
          symbol: data.symbol,
          securityType: data.marketListId,
          underlying: data.underlying,
          last: data.last,
          change: data.change,
          percentChange: data.percentChange,
          volume: data.volume,
          oi: data.oi,
          lastTradingDate: data.lastTradingDate,
          dayToMaturity: data.dayToMaturity
        }
      }).concat(
        state.favoriteDelisted.futures.map(item => ({ ...item, securityType: item.securityType, flag: item.flag, totalVolume: null, totalValue: null, marketCap: null }))
      )
    }
  },

  optionsPriceList: (state) => {
    return {
      marketDateTime: state.options?.[0]?.marketTime,
      favourite: state.options.map((data) => {
        return {
          symbol: data.symbol,
          securityType: data.marketListId,
          optionsType: data.optionsType,
          underlying: data.underlying,
          last: data.last,
          change: data.change,
          percentChange: data.percentChange,
          totalVolume: data.totalVolume,
          oi: data.oi,
          lastTradingDate: data.lastTradingDate,
          dayToMaturity: data.dayToMaturity
        }
      }).concat(
        state.favoriteDelisted.options.map(item => ({ ...item, securityType: item.securityType, flag: item.flag, totalVolume: null, totalValue: null, marketCap: null }))
      )
    }
  },

  fundPriceList: (state) => {
    const overviewInfo = state.fund.overviewInfo.map((data) => {
      return {
        symbol: data.symbol,
        name: data.name,
        amcName: data.amcName,
        aimcType: data.aimcType,
        dividendPolicy: data.dividendPolicy
      }
    }).concat(
      state.favoriteDelisted.fund.map(item => ({ ...item, securityType: item.securityType, flag: item.flag }))
    )

    const performanceInfo = state.fund.performanceInfo.map((data) => {
      return {
        symbol: data.symbol,
        navPerUnit: data.navPerUnit,
        date: data.date,
        oneMonthPercentChange: data.oneMonthPercentChange,
        threeMonthPercentChange: data.threeMonthPercentChange,
        sixMonthPercentChange: data.sixMonthPercentChange,
        ytdPercentChange: data.ytdPercentChange,
        oneYearPercentChange: data.oneYearPercentChange,
        threeYearPercentChange: data.threeYearPercentChange,
        fiveYearPercentChange: data.fiveYearPercentChange,
        tenYearPercentChange: data.tenYearPercentChange
      }
    })

    const merged = _.merge(_.keyBy(overviewInfo, 'symbol'), _.keyBy(performanceInfo, 'symbol'))
    return {
      marketDateTime: state.fund.marketDateTime,
      favourite: _.values(merged)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
