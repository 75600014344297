//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { openTab } from '~/helpers'
export default {
  name: 'AppBar',
  components: {
    BDropdown,
    BDropdownItem
  },
  filters: {
    nameFirstChar (value) {
      if (value) {
        const letter = /[a-zA-Z,ก-ฮ]/
        const firstLetter = letter.exec(value)
        return firstLetter ? firstLetter[0] : firstLetter.charAt(0)
      } else {
        return undefined
      }
    }
  },
  data () {
    return {
      showMenuMobile: false,
      isStick: false,
      isShowSearch: false,
      keywordSearch: '',
      isMobile: this.$device.isMobile || this.$device.isTablet,
      searchQuoteMobile: false,
      menuShortcuts: [],
      locationName: 'M_SHORTCUT'
    }
  },
  computed: {
    setMemberUrl () {
      // return process.env.SETTRADE_MEMBER_LOGIN_URL
      return this.$authUser.login()
    },
    menuHeader () {
      const menu = this.$content.getLayoutMenuLocation('M_HEADER')
      return menu
    },
    lastUpdate () {
      if (this.menuHeader?.lastUpdate) {
        return new Date(this.menuHeader.lastUpdate)
      }
      return this.menuHeader?.lastUpdate
    },
    menuList () {
      if (this.menuHeader) {
        return this.menuHeader.menuItems
      }
      return []
    },
    permissionUser () {
      return this.$authUser.permissionUser()
    },
    userProfile () {
      return this.$authUser.userProfile()
    }
  },
  // async created () {
  //   await this.setMenuData()
  // },
  async mounted () {
    this.registerEvent()
    await this.main()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    sectionTop () {
      return document.getElementById('sectionTop') || { clientHeight: 0 }
    },
    getNavbarHeight () {
      return document.getElementById('appbar').clientHeight || 0
    },
    getNavbarTop () {
      const top = document.getElementById('appbar').offsetTop
      return top
    },
    getScrollTop () {
      return window.pageYOffset || document.documentElement.scrollTop
    },
    toGetQuote () {
      this.$personalized.clickElement({
        name: 'all9'
      })
      openTab(
        this.localePath({
          path: '/get-quote'
        })
      )
      // window.location.href = this.localePath({
      //   path: '/get-quote'
      // })
    },
    updateBodyPaddingTop (paddingTop) {
      const site = document.querySelector('.site-content')
      if (site) {
        site.style.paddingTop = `${paddingTop}px`
      }
    },
    main () {
      this.lastScrollTop = this.getNavbarTop()
      this.handleScroll()
    },
    handleScroll () {
      const sectionTop = this.sectionTop()
      const lastScrollTop = sectionTop.clientHeight
      if (sectionTop) {
        this.lastScrollTop = lastScrollTop
        // update sticky
        const currentScrollTop = this.getScrollTop()
        const isScrollDown = currentScrollTop > this.lastScrollTop
        this.isStick = isScrollDown
        const navbarHeight = isScrollDown ? this.getNavbarHeight() : 0
        this.updateBodyPaddingTop(navbarHeight)
      }
      this.updateNavbarMobileTop()
    },
    handleResize () {
      const { deviceSizes } = require('@/config/core')
      this.showMenuMobile =
        this.showMenuMobile && window.innerWidth <= deviceSizes.lg
    },
    registerEvent () {
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('resize', this.handleResize)
    },
    removeEvent () {
      window.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('resize', this.handleResize)
    },
    toggleMenuMobile () {
      // const appbar = document.getElementById('appbar')
      this.showMenuMobile = !this.showMenuMobile
      setTimeout(() => {
        this.updateNavbarMobileTop()
      }, 150)
      if (this.showMenuMobile) {
        const scrollbarWidth = this.getScrollbarWidth()
        document.body.classList.add('menu-open')
        document.body.classList.add('dropdown-open')
        document.body.style.paddingRight = `${scrollbarWidth}px`
        // appbar.style.maxWidth = `${window.innerWidth - scrollbarWidth}px`
        // appNavBarMobile.style.maxWidth = `${window.innerWidth - scrollbarWidth}px`
      } else {
        setTimeout(() => {
          document.body.style.paddingRight = ''
          // appbar.style.maxWidth = null
          // appNavBarMobile.style.maxWidth = null
          document.body.classList.remove('menu-open')
        }, 200)
      }
    },
    updateNavbarMobileTop () {
      const appNavBarMobile = document.getElementById('app-nav-bar-mobile')
      if (appNavBarMobile) {
        // const sectionTop = this.sectionTop()
        let top = this.getNavbarHeight()
        if (!this.isStick) {
          top =
            this.getNavbarTop() + this.getNavbarHeight() - this.getScrollTop()
        }
        appNavBarMobile.style.top = top + 'px'
      }
    },
    getScrollbarWidth () {
      const documentWidth = document.documentElement.clientWidth
      return Math.abs(window.innerWidth - documentWidth)
    },
    focusInput () {
      this.isShowSearch = true
    },
    closeSearch () {
      setTimeout(() => {
        this.isShowSearch = false
        if (!this.searchQuoteMobile) {
          this.$refs.AppBarSearchBox.close()
        }
      }, 300)
    },
    changekeyword (keyword) {
      this.keywordSearch = keyword
    },
    handleOutsideClick () {
      if (this.isShowSearch) {
        const eleAppBarSearchBox = this.$refs.AppBarSearchBox
        if (eleAppBarSearchBox) {
          if (this.searchQuoteMobile) {
            this.$refs.AppBarSearchBox.closeMobile()
          } else {
            this.$refs.AppBarSearchBox.close()
          }
        }
        this.closeSearch()
      }
    },
    logout () {
      this.$authUser.logout()
    },
    clickEventToUrlMember () {
      this.$personalized.clickElement({
        name: 'all12'
      })
    },
    gotoMemberMenu (path) {
      this.$router.push({
        path: '/th/member/' + path
      })
    }
    // async setMenuData () {
    //   await this.$content.getMenuByLocation(this.locationName)
    //     .then((menu) => {
    //       this.menuShortcuts = menu.menuItems || []
    //     })
    //     .catch(err => err)
    // }
  }
}
