const getDefaultState = () => {
  return {
    info: null,
    quotationChart: null,
    quotationChartAccumulated: null,
    profile: null,
    tradingInfo: null,
    marketInfo: null,
    dwOtherList: [],
    relatedDocument: null,
    historicalTrading: [],
    newsList: null
  }
}

const state = getDefaultState

const mutations = {
  setInfo (state, data) {
    state.info = data
  },

  setQuotationChart (state, data) {
    state.quotationChart = data
  },

  setQuotationChartAccumulated (state, data) {
    state.quotationChartAccumulated = data
  },

  setProfile (state, data) {
    state.profile = data
  },

  setTradingInfo (state, data) {
    state.tradingInfo = data
  },

  setMarketInfo (state, data) {
    state.marketInfo = data
  },

  setDWOtherList (state, data) {
    state.dwOtherList = data
  },

  setRelatedDocument (state, data) {
    state.relatedDocument = data
  },

  setHistoricalTrading (state, data) {
    state.historicalTrading = data
  },

  setOutstandingChart (state, data) {
    state.outstandingChart = data
  },

  setNewsList (state, data) {
    state.newsList = data
  }
}

const actions = {
  async getInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/dw/${quote}/info`)
      commit('setInfo', response)
    } catch {
      commit('setInfo', null)
    }
  },

  async getQuotationChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation`,
        {
          params: {
            period,
            accumulated: false
          }
        }
      )
      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },

  async getQuotationChartAccumulated ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation`,
        {
          params: {
            period,
            accumulated: true
          }
        }
      )

      commit('setQuotationChartAccumulated', response)
    } catch {
      commit('setQuotationChartAccumulated', null)
    }
  },

  async getProfile ({ commit, dispatch }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/dw/${quote}/profile`, {
        params: {
          lang: this.$i18n.locale
        }
      })
      commit('setProfile', response)
    } catch {
      commit('setProfile', null)
    }
  },

  async getDWList ({ commit }, payload) {
    const { underlying } = payload
    try {
      const response = await this.$axios.$get('/api/set/dw-info/list', {
        params: {
          underlyingSymbol: underlying
        }
      })
      commit('setDWOtherList', response.dwList)
    } catch {
      commit('setDWOtherList', null)
    }
  },

  async getMarketInfo ({ commit }, payload) {
    try {
      let item = {
        symbol: null,
        change: null,
        last: null,
        percentChange: null,
        marketTime: null
      }
      const { underlying, underlyingType } = payload
      if (underlyingType === 'I') {
        const response = await this.$axios.$get(
          `/api/set/index/${underlying}/info`
        )
        if (response) {
          item = {
            symbol: response.symbol,
            change: response.change,
            last: response.last,
            percentChange: response.percentChange,
            marketTime: response.marketDateTime
          }
        }
      } else if (underlyingType === 'S') {
        const response = await this.$axios.$get(
          `/api/set/stock/${underlying}/info`
        )
        if (response) {
          item = {
            symbol: response.symbol,
            change: response.change,
            last: response.last,
            percentChange: response.percentChange,
            marketTime: response.marketDateTime
          }
        }
      }
      commit('setMarketInfo', item)
    } catch {
      commit('setMarketInfo', null)
    }
  },

  async getTradingInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/info`)
      commit('setTradingInfo', response)
    } catch {
      commit('setTradingInfo', null)
    }
  },

  async getRelatedDocument ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/dw/${quote}/related-document`,
        {
          params: {
            lang: this.$i18n.locale
          }
        }
      )
      commit('setRelatedDocument', response)
    } catch {
      commit('setRelatedDocument', null)
    }
  },

  async getHistoricalTrading ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/historical-trading`
      )
      commit('setHistoricalTrading', response)
    } catch {
      commit('setHistoricalTrading', [])
    }
  },

  async getNewsList ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/news/${quote}/list`)
      commit('setNewsList', response)
    } catch {
      commit('setNewsList', null)
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
