/* eslint-disable no-console */
const { selectApiCMSEndpoint } = require('@/helpers/select-endpoint')
const { isPreview } = require('@/helpers/content')
const _API_RESPONSE_TIME_TO_PRINT_LOG = 300
// const isPreview = (contentUrl) => {
//   return /\/preview\//i.test(contentUrl)
// }

/**
 * Initial plugin
 */
export default function ({ $axios, app, $cookies, $device, route }, inject) {
  /**
   * Set instance
   */
  const enableDebugging = process.env.ENABLE_DEBUGGING === 'true'
  const ENV_USE_DEVELOPMENT = process.env.USE_DEVELOPMENT === 'true'
  const isDevelopment = ENV_USE_DEVELOPMENT ? true : process.env.NODE_ENV === 'development'
  // const apiKey = process.env.API_CMS_KEY
  const webChannel = process.env.WEB_CHANNEL
  const requestTime = new Date().getTime()
  const instanceOption = {
    // baseURL: process.server || isDevelopment ? process.env.HOST_PROXY : '/',
    baseURL: isDevelopment ? process.env.HOST_PROXY : '/',
    meta: {
      requestBeforeStartedAt: requestTime,
      requestStartedAt: requestTime
    },
    headers: {
      common: {
        // 'x-api-key': apiKey,
        'x-channel': webChannel
      }
    }
  }

  // Create a custom axios instance
  const axiosInstance = $axios.create(instanceOption)
  axiosInstance.interceptors.request.use((config) => {
    // Multisite CMS
    const { url, method, baseURL: defaultBaseURL } = config ?? {}
    const { endpoint, requestPath } = selectApiCMSEndpoint({
      url,
      method,
      isServerToServer: process.server,
      defaultBaseURL
    })
    // console.log({ endpoint, requestPath, defaultBaseURL })
    if (endpoint) {
      config.baseURL = endpoint
    }
    if (requestPath) {
      config.url = requestPath
    }
    // if (process.server) {
    //   console.log('=================================================')
    //   console.log('[INFO]', 'Page URL:', route.fullPath)
    //   console.log('[INFO]', '[Request]API CMS - path:', config.url)
    //   console.log('[INFO]', '[Request]API CMS - params:', config.params || '-')
    //   console.log('[INFO]', '[Request]API CMS - endpoint:', config.baseURL)
    // }
    config.requestStartedAt = new Date().getTime()

    if (process.server) {
      const { isMobile } = $device
      const device = isMobile ? 'mobile' : 'desktop'
      config.headers['x-device'] = device
    }

    const clientId = app.store.state.clientId || ''
    if (clientId) {
      config.headers['x-client-uuid'] = clientId
    }
    const uref = $cookies.get('uref') || ''
    const userRef = parseInt(uref, 10) || 0
    if (userRef) {
      config.headers['x-userid'] = userRef
    }

    const { info } = app.store.state.contents.content || {}
    const { pageUuid } = (info || {})
    if (pageUuid) {
      config.headers['x-page-uuid'] = (pageUuid || '')
    }
    if (isPreview(config.params?.path)) {
      config.headers['x-content'] = 'preview'
    }
    return config
  }, (x) => {
    if (process.server) {
      console.log('=================================================')
      console.error('Page URL:', route.fullPath)
      console.error('[Request]API CMS - Error details:', x)
    }
    // console.log('[Request]API CMS - Headers:', x.config.headers)
    throw x
  })

  axiosInstance.interceptors.response.use((x) => {
    if (process.server) {
      const respTime = new Date().getTime() - x.config.meta.requestStartedAt
      const printRespLog = respTime >= _API_RESPONSE_TIME_TO_PRINT_LOG
      if (printRespLog) {
        console.log('=================================================')
        console.log('[INFO]', 'Page URL:', route.fullPath)
        console.log('[INFO]', '[Response]API CMS - status:', x.status, enableDebugging, process.env.ENABLE_DEBUGGING)
        console.log('[INFO]', `[Response - ${new Date()}]API CMS - Execution time for: ${x.config.baseURL}${x.config.url} - ${respTime} ms`)
        if (enableDebugging) {
          console.log('[INFO]', '[Response]API CMS - headers:', x.config.headers)
        }
      }
    }

    return x
  }, (x) => {
    if (process.server) {
      console.log('=================================================')
      console.error('Page URL:', route.fullPath)
      console.error('[Response]API CMS - status:', x.response?.status)
      console.error('[Response]API CMS - headers:', x.config.headers)
      console.error(`[Response - ${new Date()}]API CMS - Error response time for: ${x.config.baseURL}${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
      console.error('[Response]API CMS - Error details:', x)
    }
    throw x
  })

  // Inject to context as $api
  inject('apiCms', axiosInstance)
}
