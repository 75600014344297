export const showRequestNum = {
  update (el, binding, vnode) {
    if (binding.value > 0 && binding.value !== binding.oldValue) {
      vnode.componentInstance.show()
    }
  }
}

export const hideRequestNum = {
  update (el, binding, vnode) {
    if (binding.value > 0 && binding.value !== binding.oldValue) {
      vnode.componentInstance.hide()
    }
  }
}
