import { render, staticRenderFns } from "./QuoteTabRecent.vue?vue&type=template&id=76193bf9&scoped=true&"
import script from "./QuoteTabRecent.vue?vue&type=script&lang=js&"
export * from "./QuoteTabRecent.vue?vue&type=script&lang=js&"
import style0 from "./QuoteTabRecent.vue?vue&type=style&index=0&id=76193bf9&lang=scss&scoped=true&"
import style1 from "./QuoteTabRecent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76193bf9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLoadingChart: require('/app/components/Icon/LoadingChart.vue').default,MiscEquityMarketInputSelect: require('/app/components/Misc/Equity/Market/InputSelect.vue').default,SwiperCarousel: require('/app/components/SwiperCarousel/SwiperCarousel.vue').default})
