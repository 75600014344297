//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
import { mapGetters, mapActions } from 'vuex'
import { formatDateShort } from '@/helpers/dateTime'
import {
  integerStyle,
  formatNumber,
  formatNumberInteger,
  formatNumberPercent
} from '@/helpers/index'
export default {
  name: 'HomeQuoteTabVirtualPortfolio',
  props: {
    openTabCount: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      optionsTypeStock: [],
      selectedValue: '',
      recentQuote: [],
      keyRerender: 0,
      isLogin: false,
      loading: false,
      isInitError: false,
      swiperOption: {
        showPagination: false,
        showNavigation: true,
        showNavigationOutSide: true,
        loop: false,
        spaceBetween: 1,
        slidesPerView: 1,
        slidesPerGroup: 4,
        navigation: {
          nextEl: '.my-quote-detail .swiper-btn-next',
          prevEl: '.my-quote-detail .swiper-btn-prev'
        },
        breakpoints: {
          1980: {
            slidesPerView: 5,
            slidesPerGroup: 5
          },
          1710: {
            slidesPerView: 4,
            slidesPerGroup: 5
          },
          1520: {
            slidesPerView: 3,
            slidesPerGroup: 4
          },
          1200: {
            slidesPerView: 2,
            slidesPerGroup: 4
          }
        }
      },
      linkContactSet: 'https://www.set.or.th/th/about/contact-us/contact-center',
      excludeRoute: [
        'member-virtual-portfolio'
      ]
    }
  },
  computed: {
    // ...mapGetters({
    //   stockList: 'recent-view/getStockList',
    //   optionList: 'recent-view/getOptionList',
    //   fundList: 'recent-view/getFundList'
    // }),
    equityList () {
      return this.$virtualPort?.state?.equity
    },
    tfexList () {
      return this.$virtualPort?.state?.tfex
    },
    fundList () {
      return this.$virtualPort?.state?.fund
    },
    ...mapGetters({
      stocks: 'search/getStocks'
    }),
    virtualPortSelect () {
      return this.$virtualPort.state[this.selectedValue]
    },
    isHavePort () {
      const equity = cloneDeep(this.equityList)
      const tfex = cloneDeep(this.tfexList)
      const fund = cloneDeep(this.fundList)
      return equity || tfex || fund
    },
    listShow () {
      return this.getPortfolio(this.virtualPortSelect).sort((a, b) => a.symbol.localeCompare(b.symbol))
    },
    routeBaseName () {
      return this.getRouteBaseName()
    }
  },
  watch: {
    openTabCount: {
      handler (value) {
        if (value) {
          this.fetchData()
          if (value === 1) {
            this.$store.dispatch('member/virtual-portfolio/getMarketList')
          }
        }
      },
      deep: true,
      immediate: true
    },
    equityList (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.renderDropdown()
      }
    },
    tfexList (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.renderDropdown()
      }
    },
    fundList (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.renderDropdown()
      }
    },
    routeBaseName (newRoute, oldRoute) {
      if (this.excludeRoute.includes(oldRoute)) {
        this.initEventBus()
      }
    }
  },
  mounted () {
    if (this.$authUser.permissionUser()) {
      this.initEventBus()
    }
  },
  beforeDestroy () {
    if (!this.excludeRoute.includes(this.routeBaseName)) {
      this.$virtualPort.eventBus.$off('portfolioChanged')
    }
  },
  methods: {
    ...mapActions({
    }),
    async fetchData () {
      this.loading = true
      this.isLogin = await this.$authUser.isLogin()
      if (this.isLogin) {
        this.isInitError = await this.$virtualPort
          .initialization()
          .then(x => false)
          .catch(x => true)
        this.renderDropdown()
      }
      this.loading = false
    },
    formatDateShort,
    integerStyle,
    formatNumber,
    formatNumberInteger,
    formatNumberPercent,
    initEventBus () {
      this.$virtualPort.eventBus.$on('portfolioChanged', async (category) => {
        if (this.active) {
          this.loading = true
          await this.renderDropdown()
          this.loading = false
        }
      })
    },
    eventChangeVirtualPort (value) {
      // await this.$virtualPort.reloadPortfolio(value)
      this.resetSwiper()
      const typeStock = this.optionsTypeStock.find(data => data.value === value)
      this.$personalized.clickElement({
        name: 'myQuote10',
        optionLang: {
          title: typeStock.label
        }
      })
    },
    resetSwiper () {
      setTimeout(() => {
        this.keyRerender += 1
      }, 300)
    },
    renderDropdown () {
      if (!this.isInitError) {
        const optionsTypeStock = [
          {
            label: 'สร้างพอร์ตหุ้น',
            value: 'equity',
            icon: 'IconCreatePort',
            type: 'button',
            onSelect: () => {
              this.openModalAddPort('equity')
            }
          },
          {
            label: 'สร้างพอร์ตอนุพันธ์',
            value: 'tfex',
            icon: 'IconCreatePort',
            type: 'button',
            onSelect: () => {
              this.openModalAddPort('tfex')
            }
          },
          {
            label: 'สร้างพอร์ตกองทุนรวม',
            value: 'fund',
            icon: 'IconCreatePort',
            type: 'button',
            onSelect: () => {
              this.openModalAddPort('fund')
            }
          }
        ]
        const equity = cloneDeep(this.equityList)
        const tfex = cloneDeep(this.tfexList)
        const fund = cloneDeep(this.fundList)
        let selectedValue = this.virtualPortSelect ? this.selectedValue : ''
        if (equity) {
          const typeStock = optionsTypeStock[0]
          typeStock.label = equity.name
          typeStock.icon = ''
          typeStock.type = ''
          typeStock.onSelect = ''
          selectedValue = selectedValue === '' ? 'equity' : selectedValue
        }
        if (tfex) {
          const typeStock = optionsTypeStock[1]
          typeStock.label = tfex.name
          typeStock.icon = ''
          typeStock.type = ''
          typeStock.onSelect = ''
          selectedValue = selectedValue === '' ? 'tfex' : selectedValue
        }
        if (fund) {
          const typeStock = optionsTypeStock[2]
          typeStock.label = fund.name
          typeStock.icon = ''
          typeStock.type = ''
          typeStock.onSelect = ''
          selectedValue = selectedValue === '' ? 'fund' : selectedValue
        }
        this.optionsTypeStock = optionsTypeStock
        this.selectedValue = selectedValue
      }
    },
    newTabToQuote (item) {
      const stockInfo = this.stocks.find((data) => {
        return data.symbol === item.symbol
      })
      if (stockInfo) {
        const securityType = stockInfo.securityType
        const urlString = this.$quote.getQuoteUrl({
          ...item,
          securityType
        })
        window.open(urlString, '_blank')
        this.$personalized.clickElement({
          name: 'myQuote12',
          optionLang: {
            symbol: stockInfo.symbol
          }
        })
      }
    },
    getPortfolio (list) {
      if (this.selectedValue === 'tfex') {
        return [
          ...list?.futures?.portfolio ?? [],
          ...list?.options?.portfolio ?? []
        ]
      } else if (this.selectedValue === 'fund') {
        return list.portfolios
      } else {
        return list.equityPortfolios
      }
    },
    openModalAddPort (type) {
      this.$emit('openModalAddPort', {
        type,
        onCreatePortSuccess: this.onCreatePortSuccess
      })
    },
    async onCreatePortSuccess (type) {
      await this.fetchData()
      this.selectedValue = type
    }
  }
}
