import { specificPagePath } from '~/config/core'
const {
  OK, // 200
  MOVED_PERMANENTLY, // 301
  NOT_FOUND // 404
} = require('http-status')

const matchSpecificPage = (route, locale) => {
  const { slug } = route.params
  // const encodeSlug = encodeURIComponent(slug)
  const path = decodeURIComponent(route.path).replace('/' + slug, '')
  const url = path.search(locale) === -1 ? `/${locale}/${path}` : path
  const pattern = new RegExp('^' + url + '$')
  const found = specificPagePath.filter(function (value) {
    const newPath = `/${locale}/${value}`
    const examplePathPattern = new RegExp(`/${locale}/example`, 'i')
    return pattern.test(newPath) || examplePathPattern.test(route.path)
  })
  return found.length > 0
}
// const pathWhiteList = [
//   '/home',
//   '/equities/quote'
// ]

/**
 * State
 */
const state = () => ({
  breadcrumb: null,
  content: null,
  pageType: null,
  renderType: null,
  conflict: false,
  contentSEO: null
})

/**
 * Mutations
 */
const mutations = {
  setContent (state, data) {
    state.content = data
  },
  setPageType (state, data) {
    state.pageType = data
  },
  setRenderType (state, data) {
    state.renderType = data
  },
  setConflict (state, data) {
    state.conflict = data
  },
  setContentSEO (state, data) {
    state.contentSEO = data
  },
  setBreadcrumb (state, data) {
    state.breadcrumb = data
  }
}

/**
 * Actions
 */
const actions = {
  async getPageBreadcrumb ({ commit }, pathStructure) {
    const apiPath = '/api/cms/v1/pages/breadcrumb'
    const options = {
      params: {
        path: pathStructure
      }
    }
    await this.$apiCms.get(apiPath, options).then((res) => {
      // console.log('Breadcrumb from api', JSON.parse(JSON.stringify(res.data)))
      commit('setBreadcrumb', res.data)
    }).catch(err => err)
  },
  async addPageView ({ commit }, payload) {
    const { data, config } = payload
    const { pageSourceChannelCode } = config ?? {}
    let headersWebChannel = null
    if (pageSourceChannelCode) {
      headersWebChannel = {
        'x-channel': `${pageSourceChannelCode}`
      }
    }
    const apiPath = '/api/cms-w/v1/pages/view'
    const options = {
      method: 'post',
      url: apiPath,
      data,
      headers: {
        'Content-Type': 'application/json',
        ...headersWebChannel
      }
    }
    await this.$apiCms(options)
      .then(res => res)
      .catch(err => err)
  },
  async getContent ({ commit }, payload) {
    const { fullPath, path } = payload
    const isDesktop = (payload.query.device || 'desktop').toLowerCase() === 'desktop'
    const device = this.$device.isDesktopOrTablet && isDesktop ? 'Desktop' : 'Mobile'
    const isPreview = payload.query.mode === 'preview'
    const lang = this.$i18n.locale

    const { params } = payload
    const isSpecificPage = await matchSpecificPage({
      params,
      path
    }, lang)

    // console.log('isSpecificPage', isSpecificPage)

    const contentPath = isPreview ? path : fullPath

    // check white list
    /*
    const wlPath = contentPath.replace(`/${lang}`, '')
    const whiteListMatch = pathWhiteList.includes(wlPath)
    console.log('whiteListMatch', whiteListMatch)
    */

    const isNotSpecificPage = isSpecificPage === false
    const errorConfig = {
      errorRedirect: isNotSpecificPage,
      isSpecificPage
    }
    const getPBContentRes = await this.$content.getPage({
      path: contentPath,
      device,
      errorConfig
    })
    const { status } = getPBContentRes
    if (status === OK) {
      const { info: { pageType, renderType } } = getPBContentRes.data
      const isConflict = renderType === 'PageBuilder' && isSpecificPage

      commit('setConflict', isConflict)
      commit('setPageType', pageType)
      commit('setRenderType', renderType)
      commit('setContent', getPBContentRes.data)
    }
    // console.log('get home page for specific page', (status === NOT_FOUND || status === MOVED_PERMANENTLY) && isSpecificPage === true)
    if ((status === NOT_FOUND || status === MOVED_PERMANENTLY) && isSpecificPage === true) {
      const errorConfig = {
        errorRedirect: false,
        isSpecificPage: true
      }
      const getHomeContentRes = await this.$content.getPage({
        path: `/${lang}/home`,
        device,
        errorConfig
      })
      const { status } = getHomeContentRes
      if (status === OK) {
        const { info: { pageType, renderType } } = getHomeContentRes.data
        commit('setPageType', pageType)
        commit('setRenderType', renderType)
        commit('setContent', getHomeContentRes.data)
      }
    }
  }
  /*
  async getContent ({ commit }, payload) {
    const { fullPath, path } = payload
    const isDesktop = (payload.query.device || 'desktop').toLowerCase() === 'desktop'
    const device = this.$device.isDesktopOrTablet && isDesktop ? 'Desktop' : 'Mobile'
    // const lang = this.$i18n.locale
    const isPreview = payload.query.mode === 'preview'
    let contentUrl = isPreview ? path : fullPath

    const { params } = payload
    const isSpecificPage = await matchSpecificPage({
      params,
      path
    }, this.$i18n.locale)

    if (isSpecificPage) {
      contentUrl = `/${this.$i18n.locale}/home`
    }

    // Start get content
    // const apiPath = '/pages/path'
    const apiPath = '/api/cms/v1/pages/path'
    const options = {
      errorRedirect: true,
      params: {
        path: contentUrl,
        device
      }
    }
    await this.$apiCms.get(apiPath, options)
      .then((res) => {
        commit('setContent', res.data)
      })
      .catch(error => error)
  }
  */
}

/**
 * Getters
 */
const getters = {
  getPageTypeData: (state) => {
    return state.pageType ?? ''
  },
  getRenderTypeData: (state) => {
    return state.renderType ?? ''
  },
  getContentData: (state) => {
    return state.content ? state.content : {}
  },
  getPageBreadcrumbData: (state) => {
    return state.breadcrumb ? state.breadcrumb : []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
