//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BannerLocationStickyAds',
  data () {
    return {
      bannerData: null,
      locationName: 'B_MOBILE_STICKY_ADS'
    }
  },
  async fetch () {
    await this.setBannerData()
  },
  methods: {
    async setBannerData () {
      await this.$content.getBannerByLocation(this.locationName)
        .then((banner) => { this.bannerData = banner })
        .catch(err => err)
    }
  }
}
