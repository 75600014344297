//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StickyBar',
  data () {
    return {
      lastScrollTop: 0,
      currentTop: 0,
      sticky: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initStickyBar()
    })
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    initStickyBar () {
      this.lastScrollTop = this.getStickyBarTop()
      this.makeStickyBarSpace()
      this.registerEvent()
    },
    makeStickyBarSpace () {
      const stickyBarInner = this.$refs.stickyBarInner
      this.$refs.stickyBarSpace.style.height = stickyBarInner.clientHeight + 'px'
    },
    getStickyBarTop () {
      if (!this.$refs.stickyBar) { return 0 }
      const top = this.$refs.stickyBar.offsetTop
      return top
    },
    getScrollTop () {
      return window.pageYOffset || document.documentElement.scrollTop
    },
    registerEvent () {
      window.addEventListener('resize', this.handleWindowResize)
      window.addEventListener('scroll', this.handleWindowScroll)
    },
    removeEvent () {
      window.removeEventListener('resize', this.handleWindowResize)
      window.removeEventListener('scroll', this.handleWindowScroll)
    },
    handleWindowScroll () {
      if (!this.$refs.stickyBar) { return }
      this.setPosition()
    },
    handleWindowResize () {
      if (!this.$refs.stickyBar) { return }
      this.setPosition()
    },
    setPosition () {
      const nowTop = this.getScrollTop()
      if (nowTop === this.lastScrollTop) {
        this.reset()
        return
      }
      const stickyBarInner = this.$refs.stickyBarInner
      const stickyBarInnerClasses = [].slice.call(stickyBarInner.classList)
      const startTop = this.lastScrollTop + stickyBarInner.clientHeight
      if (nowTop > startTop - 5 && !stickyBarInnerClasses.includes('sticky')) {
        stickyBarInner.style.top = (stickyBarInner.clientHeight * -1) + 'px'
      }
      if (nowTop > startTop) {
        stickyBarInner.classList.add('sticky')
        if (nowTop > this.currentTop) {
          stickyBarInner.style.top = (stickyBarInner.clientHeight * -1) + 'px'
          stickyBarInner.classList.add('hide')
          stickyBarInner.classList.remove('show')
          this.updateAppbar(0)
        } else {
          stickyBarInner.style.top = 0
          stickyBarInner.classList.add('show')
          stickyBarInner.classList.remove('hide')
          this.updateAppbar(stickyBarInner.clientHeight)
        }
      }
      this.currentTop = this.getScrollTop()
    },
    reset () {
      const stickyBarInner = this.$refs.stickyBarInner
      if (!stickyBarInner) { return }
      stickyBarInner.classList.remove('sticky')
      stickyBarInner.classList.remove('show')
      stickyBarInner.classList.remove('hide')
      stickyBarInner.style.top = null
      const appbar = document.getElementById('appbar')
      if (appbar) {
        appbar.classList.remove('fixed-top')
        appbar.style.marginTop = null
      }
    },
    updateAppbar (marginTop) {
      const appbar = document.getElementById('appbar')
      if (!appbar) {
        return false
      }
      appbar.style.marginTop = marginTop + 'px'
      appbar.classList.add('sticky-transition-margin-top')
      const appbarSticky = document.querySelector('#appbar.fixed-top')
      if (!appbarSticky) {
        appbar.classList.add('sticky-disable-transition-margin-top')
        appbar.classList.remove('sticky-transition-margin-top')
        appbar.style.marginTop = null
      }
    }
  }
}
