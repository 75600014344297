//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MenuItem',
  props: {
    lastUpdate: {
      type: Date,
      default: null
    },
    menuItemUuid: {
      type: String,
      default: null
    },
    linkClass: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: '#'
    },
    title: {
      type: String,
      default: 'Link'
    },
    newTab: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    accordionToggle: {
      type: String,
      default: null
    },
    accordionTarget: {
      type: String,
      default: null
    },
    accordionExpanded: {
      type: String,
      default: null
    },
    accordionControls: {
      type: String,
      default: null
    }
  },
  serverCacheKey: props => `menu_item::${props.menuItemUuid}::${props.lastUpdate?.toISOString()}`,
  computed: {
    target () {
      if (this.newTab === true) {
        return '_blank'
      }
      return null
    },
    rel () {
      if (this.newTab === true) {
        return 'noreferrer'
      }
      return null
    }
  }
}
