//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { customAlphabet } from 'nanoid'
import { mapGetters } from 'vuex'
export default {
  name: 'Tabs',
  props: {
    tabsClassName: {
      type: String,
      default: () => `tabs-${customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789', 8)()}`
    },
    tabPills: {
      type: Boolean,
      default: false
    },
    tabLink: {
      type: Boolean,
      default: false
    },
    tabSlide: {
      type: Boolean,
      default: false
    },
    freeMode: {
      type: Boolean,
      default: true
    },
    arrow: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    scopedClass: {
      type: String,
      default: null
    },
    slidesPerView: {
      type: Number,
      default: 0
    },
    slidesPerGroup: {
      type: Number,
      default: 0
    }
  },
  data () {
    const slidesPerView = this.slidesPerView ? this.slidesPerView : 'auto'
    const slidesPerGroup = this.slidesPerGroup ? this.slidesPerGroup : 3
    const showNavigation = this.arrow
    const freeMode = this.freeMode
    // const nextEl = (this.scopedClass ? `${this.scopedClass}` : '') + '.tab-slide .swiper-btn-next'
    // const prevEl = (this.scopedClass ? `${this.scopedClass}` : '') + '.tab-slide .swiper-btn-prev'
    const nextEl = `.${this.tabsClassName}` + '.tab-slide .swiper-btn-next'
    const prevEl = `.${this.tabsClassName}` + '.tab-slide .swiper-btn-prev'
    const wrapperElClass = ['swiper-wrapper nav tabs flex-nowrap', this.customClass]
    if (this.tabPills) {
      wrapperElClass.push('nav-pills')
    } else {
      wrapperElClass.push('nav-tabs')
    }
    return {
      isLockSlide: false,
      tabs: [],
      swiperElement: null,
      tabsCarouselOption: {
        watchOverflow: true,
        showPagination: false,
        showNavigation,
        slidesPerView,
        slidesPerGroup,
        freeMode,
        navigation: {
          nextEl,
          prevEl
        },
        wrapperEl: {
          role: 'tablist',
          class: wrapperElClass.join(' ')
        },
        on: {
          lock: () => {
            this.showNavigation = false
            this.showNavigationOutSide = false
            this.isLockSlide = false
          },
          unlock: () => {
            if (this.arrow) {
              this.showNavigation = true
              this.showNavigationOutSide = true
              this.isLockSlide = true
            } else {
              this.showNavigation = false
              this.showNavigationOutSide = false
              this.isLockSlide = false
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      tabChanged: 'tabs/getChanged'
    })
  },
  watch: {
    tabChanged (value) {
      setTimeout(() => {
        if (this.$refs.tabs.swiper) {
          this.$refs.tabs.swiper.update()
        }
      }, 155)
    }
  },
  // created () {
  //   this.tabs = this.$children || []
  //   // this.tabs = this.$children.length > 0 ? this.$children : []
  // },
  mounted () {
    this.$nextTick(() => {
      this.initTab()
      this.$emit('initTab')
    })
  },
  beforeDestroy () {
    this.tabs = null
  },
  methods: {
    initTab () {
      if (!this.tabLink) {
        this.registerTab()
        this.currentTab()
      }
    },
    registerTab () {
      const selector = `.${this.tabsClassName}` + ' [data-bs-toggle="tab"]'
      this.tabs = [].slice.call(document.querySelectorAll(selector))
      const { Tab } = require('bootstrap')
      this.tabs?.forEach((tab) => {
        const triggerEl = tab // tab.$el
        // set tab style
        if (this.tabPills === true) {
          triggerEl.classList.add('btn-tab-pill')
        }

        // new trigger
        // const tabTrigger = new Tab(triggerEl)
        const tabTrigger = Tab.getOrCreateInstance(triggerEl)
        // tab.show()
        triggerEl.addEventListener('click', (event) => {
          event.stopPropagation()
          event.preventDefault()
          tabTrigger.show()

          const { activeClassName } = tab.dataset
          this.setActiveClass(tabTrigger._element, activeClassName)
          this.$store.commit('tabs/setChanged', true)
        })
        triggerEl.addEventListener('shown.bs.tab', function (event) {
          triggerEl.click()
        })
      })
    },
    setActiveClass (element, activeClassName) {
      if (this.tabLink) { return }
      this.clearActiveClass()
      if (activeClassName) {
        const classNames = activeClassName.split(' ')
        classNames.forEach((className) => {
          element.classList.add(className)
        })
      }
    },
    clearActiveClass () {
      this.tabs?.forEach((tab) => {
        const triggerEl = tab
        const { activeClassName } = tab.dataset
        if (activeClassName) {
          const classNames = activeClassName.split(' ')
          classNames.forEach((className) => {
            triggerEl.classList.remove(className)
          })
        }
      })
    },
    currentTab (controlsGroup) {
      let tab = null
      this.tabs?.forEach((child) => {
        const triggerEl = child.$el
        if (child.controls) {
          if (child.controls.includes(controlsGroup) && triggerEl.classList.contains('active')) {
            tab = child.controls
          }
        }
      })
      return tab
    },
    tabsSlideInit (swiper) {
      this.$emit('tabsSlideInit', swiper)
    },
    swiperInit (swiper) {
      if (swiper) {
        if (!swiper.isLocked) {
          this.$refs.tabs.$el.classList.add('shadow-right')
        }

        swiper.on('setTransition', () => {
          this.updateShadow(swiper)
        })

        swiper.on('resize', () => {
          swiper.updateSlides()
          this.updateShadow(swiper)
        })

        this.initTab()

        // do slide init
        this.tabsSlideInit(swiper)

        // active tab with link
        this.activeTabsLink(swiper)
      }
    },
    updateShadow (swiper) {
      if (swiper.isLocked) {
        this.$refs.tabs.$el.classList.remove('shadow-left')
        this.$refs.tabs.$el.classList.remove('shadow-right')
        return
      }
      if (swiper.isBeginning) {
        this.$refs.tabs.$el.classList.remove('shadow-left')
        this.$refs.tabs.$el.classList.add('shadow-right')
      } else if (swiper.isEnd) {
        this.$refs.tabs.$el.classList.add('shadow-left')
        this.$refs.tabs.$el.classList.remove('shadow-right')
      } else {
        this.$refs.tabs.$el.classList.add('shadow-left')
        this.$refs.tabs.$el.classList.add('shadow-right')
      }
    },
    activeTabsLink (swiper) {
      if (swiper && swiper.slides && swiper.slides.length) {
        const itemList = swiper.slides
        const sitePath = this.$route.path
        for (let i = 0; i < itemList.length; i++) {
          const href = itemList[i].getAttribute('href')
          if (sitePath.includes(href)) {
            swiper.slideTo(i, 0)
          }
        }
      }
    }
  }
}
