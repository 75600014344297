import Vue from 'vue'
// import dayjs from 'dayjs'
import { dashIfEmpty, numberWithCommas } from '@/helpers/index'
import {
  formatNumber,
  formatNumberInteger,
  formatNumberPercent,
  convertToMillionFormat,
  formatNumberX,
  quoteParValueFormat
} from '@/helpers/number'
import {
  formatDateShort,
  formatDateShort2,
  formatDateTimeLong,
  formatMonthYear,
  formatDateOnly,
  formatMonthYearShort,
  formatTimeShort
} from '@/helpers/dateTime'
import {
  formatSecurityType
} from '@/helpers/utilities'

import customdayjs from '@/plugins/dayjs'
require('dayjs/locale/th')
// const buddhistEra = require('dayjs/plugin/buddhistEra')
// dayjs.extend(buddhistEra)
// const buddhistEra = require('dayjs/plugin/buddhistEra')
// customdayjs.extend(buddhistEra)

const dayjs = (date) => {
  return customdayjs(date).tz()
}
// const buddhistEra = require('dayjs/plugin/buddhistEra')
// dayjs.extend(buddhistEra)

export default (context, inject) => {
  Vue.filter('dashIfEmpty', dashIfEmpty)
  // 21 ก.ย. 2564
  Vue.filter('formatDateShort', value =>
    formatDateShort(value, context.i18n.locale)
  )
  // 16/08/2018
  Vue.filter('formatDateShort2', value =>
    formatDateShort2(value, context.i18n.locale)
  )
  // 21 ก.ย. 2564 18:12
  Vue.filter('formatDateTimeShort', (value) => {
    if (value && dayjs(value).isValid()) {
      return dayjs(value).locale(context.i18n.locale).format('D MMM BBBB HH:mm')
    } else if (value === null || value === undefined || value === '') {
      return '-'
    }

    return value
  })
  // 21 ก.ย. 2564 18:12:56
  Vue.filter('formatDateTimeLong', (value) => {
    if (value && dayjs(value).isValid()) {
      return dayjs(value)
        .locale(context.i18n.locale)
        .format('D MMM BBBB HH:mm:ss')
    } else if (value === null || value === undefined || value === '') {
      return '-'
    }

    return value
  })

  Vue.filter('formatMonthYear', (value) => {
    if (value && dayjs(value).isValid()) {
      return dayjs(value).locale(context.i18n.locale).format('MMM BBBB')
    } else if (value === null || value === undefined || value === '') {
      return '-'
    }

    return value
  })
  Vue.filter('formatVolume', (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    } else if (value === null || value === undefined || value === '') {
      return '-'
    } else {
      return value
    }
  })
  Vue.filter('formatDateShort', (date, locale = 'th') =>
    formatDateShort(date, locale)
  )
  Vue.filter('formatDateOnly', (date, locale = 'th') =>
    formatDateOnly(date, locale)
  )
  Vue.filter('formatMonthYearShort', (date, locale = 'th') =>
    formatMonthYearShort(date, locale)
  )
  Vue.filter('formatMonthYear', (date, locale = 'th') =>
    formatMonthYear(date, locale)
  )
  Vue.filter('formatTimeShort', (date, locale = 'th') =>
    formatTimeShort(date, locale)
  )

  Vue.filter('formatNumber', (value, digits = 2) => formatNumber(value, digits))

  Vue.filter('formatNumberInteger', (value, digits = 2) =>
    formatNumberInteger(value, digits)
  )

  Vue.filter('formatNumberPercent', (value, digits = 2) =>
    formatNumberPercent(value, digits)
  )

  Vue.filter('convertToMillionFormat', (value) => {
    return convertToMillionFormat(value || null)
  })
  Vue.filter('numberWithCommas', numberWithCommas)

  Vue.filter('formatDateShortTimeLong', (date, locale = 'th') =>
    formatDateTimeLong(date, locale)
  )

  Vue.filter('formatNumberX', (value, digits = 2) =>
    formatNumberX(value, digits)
  )

  Vue.filter('formatSecurityType', (value) => {
    return formatSecurityType(value || null)
  })

  Vue.filter('quoteParValueFormat', (value) => {
    return quoteParValueFormat(value || null)
  })
}
