//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { formatDateShort } from '@/helpers/dateTime'
import {
  integerStyle,
  formatNumber,
  formatNumberInteger,
  formatNumberPercent
} from '@/helpers/index'

export default {
  name: 'HomeQuoteTabFavorite',
  props: {
    swiperOption: {
      type: Object,
      default: () => {}
    },
    swiperOptionFund: {
      type: Object,
      default: () => {}
    },
    openTabCount: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      optionsTypeStock: [
        {
          label: 'หุ้น',
          value: 'stock'
        },
        {
          label: 'ETF',
          value: 'etf'
        },
        {
          label: 'DW',
          value: 'dw'
        },
        {
          label: 'DR',
          value: 'dr'
        },
        {
          label: 'DRx',
          value: 'drx',
          class: 'drx-info'
        },
        {
          label: 'ฟิวเจอร์ส',
          value: 'futures'
        },
        {
          label: 'ออปชัน',
          value: 'options'
        },
        {
          label: 'กองทุนรวม',
          value: 'fund'
        }
      ],
      selectedValue: 'stock',
      recentQuote: [],
      keyRerender: 0,
      isLogin: false,
      loading: true,
      excludeRoute: [
        'member-favourite',
        'member-my-feed',
        'technical-chart'
      ]
    }
  },
  computed: {
    ...mapGetters('member/favourite', [
      'stockPriceList',
      'etfPriceList',
      'dwPriceList',
      'drPriceList',
      'drxPriceList',
      'futuresPriceList',
      'optionsPriceList',
      'fundPriceList'
    ]),
    favourite () {
      return this.$favourite?.state ?? {}
    },
    listShow () {
      return this.getListShow(this.selectedValue).sort((a, b) => a.symbol.localeCompare(b.symbol))
    },
    routeBaseName () {
      return this.getRouteBaseName()
    }
  },
  watch: {
    openTabCount: {
      handler (value) {
        if (value) {
          this.fetchData()
        }
      },
      deep: true,
      immediate: true
    },
    routeBaseName (newRoute, oldRoute) {
      // event bus removed by the pages in excludeRoute so it must re init
      if (this.excludeRoute.includes(oldRoute)) {
        this.initEventBus()
      }
    }
  },
  mounted () {
    this.initEventBus()
  },
  beforeDestroy () {
    // remove if not in excludeRoute
    if (!this.excludeRoute.includes(this.routeBaseName)) {
      this.$favourite.eventBus.$off('favouriteChanged')
    }
  },
  methods: {
    formatDateShort,
    integerStyle,
    formatNumber,
    formatNumberInteger,
    formatNumberPercent,
    async fetchData () {
      this.loading = true
      this.isLogin = await this.$authUser.isLogin()
      if (this.isLogin) {
        await this.$favourite.initialFavouritePrice()
        if (this.checkReloadFavourite('stock')) {
          await this.reloadFavouritePrice('stock')
        }
        this.resetSwiper()
      }

      this.loading = false
    },
    initEventBus () {
      this.$favourite.eventBus.$on('favouriteChanged', async (category) => {
        // this logic duplicate in member-favourite
        if (this.active && this.routeBaseName !== 'member-favourite') {
          if (this.$favourite.state[category].length > 0) {
            await this.$favourite.reloadFavouritePrice(category)
          } else {
            this.$favourite.removeFavouritePrice(category)
          }
        }
      })
    },
    openModalAddQuote () {
      if (this.listShow && this.listShow.length >= 20) {
        this.$favourite.openModalFavSymbolFull()
      } else {
        this.$emit('openModalAddQuote', this.selectedValue)
      }
    },
    getListShow (category) {
      switch (category) {
        case 'stock':
          return this.stockPriceList?.favourite ?? []
        case 'etf':
          return this.etfPriceList?.favourite ?? []
        case 'dw':
          return this.dwPriceList?.favourite ?? []
        case 'dr':
          return this.drPriceList?.favourite ?? []
        case 'drx':
          return this.drxPriceList?.favourite ?? []
        case 'futures':
          return this.futuresPriceList?.favourite ?? []
        case 'options':
          return this.optionsPriceList?.favourite ?? []
        case 'fund':
          return this.fundPriceList?.favourite ?? []
        default:
          return this.stockPriceList?.favourite ?? []
      }
    },
    async eventChangeFavorite (value) {
      if (this.checkReloadFavourite(value)) {
        await this.reloadFavouritePrice(value)
      }
      this.resetSwiper()
      const typeStock = this.optionsTypeStock.find(data => data.value === value)
      this.$personalized.clickElement({
        name: 'myQuote5',
        optionLang: {
          symbol: typeStock.label
        }
      })
    },
    checkReloadFavourite (value) {
      const listShow = this.getListShow(value)
      const favoriteList = this.favourite[value] ?? []
      if (favoriteList.length !== listShow.length) {
        return true
      }
      for (const data of listShow) {
        if (!favoriteList.includes(data.symbol)) {
          return true
        }
      }
      return false
    },
    reloadFavouritePrice (value) {
      this.$favourite.reloadFavouritePrice(value)
    },
    resetSwiper () {
      setTimeout(() => {
        this.keyRerender += 1
      }, 300)
    },
    newTabToQuote (item) {
      const CONST_SECURITY_TYPE = {
        stock: 'S',
        etf: 'L',
        dw: 'V',
        dr: 'X',
        drx: 'XF',
        futures: 'TFEX',
        options: 'TFEX',
        fund: 'FUND'
      }
      const urlString = this.$quote.getQuoteUrl({
        ...item,
        securityType: CONST_SECURITY_TYPE[this.selectedValue]
      })
      window.open(urlString, '_blank')
      this.$personalized.clickElement({
        name: 'myQuote7',
        optionLang: {
          symbol: item.symbol
        }
      })
    }
    // checkDwExpire (timeStatus) {
    //   return this.selectedValue === 'dw' && timeStatus === 'expired'
    // }
  }
}
