import Vue from 'vue'
const localStorageName = 'recently'
const activeSymbols = 'activeSymbols'
const securityType = {
  index: 'index',
  stock: 'Stock',
  unit_trust: 'U',
  etf: 'L',
  dw: 'V',
  dr: 'X',
  drx: 'XF',
  future: 'Futures',
  option: 'Options',
  fund: 'Fund'
}
export default ({ route, store, $axios }, inject) => {
  const eventBus = new Vue()
  const process = {
    async last () {
      await checkSymbolActive()
      const recentlyList =
        localStorage.getItem(localStorageName) === null
          ? []
          : JSON.parse(localStorage.getItem(localStorageName))
      if (recentlyList.length === 0) {
        return ''
      } else {
        return recentlyList
          .map((recently) => {
            return {
              ...recently,
              date: new Date(recently.date)
            }
          })
          .sort((a, b) => {
            return b.date - a.date
          })[0]
      }
    },
    async add (symbol, type) {
      await updateLocalStorage({
        symbol: symbol.toUpperCase(),
        type,
        date: new Date()
      })
    },
    async getList () {
      await checkSymbolActive()
      return localStorage.getItem(localStorageName) === null
        ? []
        : JSON.parse(localStorage.getItem(localStorageName))
    },
    clearAll () {
      localStorage.removeItem(localStorageName)
    },
    clearByType (type) {
      const recentlyList = localStorage.getItem(localStorageName) === null
        ? []
        : JSON.parse(localStorage.getItem(localStorageName))
      let newRecentlyList = []
      switch (type) {
        case (securityType.stock) :
          newRecentlyList = recentlyList
            .filter((item) => {
              return (
                item.type !== 'S' &&
                item.type !== 'F' &&
                item.type !== 'P' &&
                item.type !== 'Q' &&
                item.type !== 'W'
              )
            })
          break
        case (securityType.dw) :
          newRecentlyList = recentlyList
            .filter((item) => {
              return (
                item.type !== securityType.dw
              )
            })
          break
        case (securityType.etf) :
          newRecentlyList = recentlyList
            .filter((item) => {
              return (
                item.type !== securityType.etf
              )
            })
          break
        case (securityType.dr) :
          newRecentlyList = recentlyList
            .filter((item) => {
              return (
                item.type !== securityType.dr
              )
            })
          break
        case (securityType.drx) :
          newRecentlyList = recentlyList
            .filter((item) => {
              return (
                item.type !== securityType.drx
              )
            })
          break
        case (securityType.unit_trust) :
          newRecentlyList = recentlyList
            .filter((item) => {
              return (
                item.type !== securityType.unit_trust
              )
            })
          break
        case (securityType.option) :
          newRecentlyList = recentlyList
            .filter((item) => {
              return (
                item.type !== securityType.option
              )
            })
          break
        case (securityType.future) :
          newRecentlyList = recentlyList
            .filter((item) => {
              return (
                item.type !== securityType.future
              )
            })
          break
        case (securityType.fund) :
          newRecentlyList = recentlyList
            .filter((item) => {
              return (
                item.type !== securityType.fund
              )
            })
          break
        default:
          newRecentlyList = recentlyList
      }
      localStorage.removeItem(localStorageName)
      localStorage.setItem(localStorageName, JSON.stringify(newRecentlyList))
    },
    eventBus
  }
  // model add to cookie Ex. => { symbol: 'PTT', type: 'S', date: '' }
  const updateLocalStorage = async (symbol) => {
    await checkSymbolActive()
    let recentlyList =
      localStorage.getItem(localStorageName) === null
        ? []
        : JSON.parse(localStorage.getItem(localStorageName))
    const indexSymbol = recentlyList
      .map((e) => {
        return e.symbol
      })
      .indexOf(symbol.symbol)
    if (indexSymbol !== -1) {
      recentlyList.splice(indexSymbol, 1)
    }
    recentlyList = checkTypeMax(recentlyList)
    recentlyList.push(symbol)
    localStorage.removeItem(localStorageName)
    localStorage.setItem(localStorageName, JSON.stringify(recentlyList))
    eventBus.$emit('recentlyChanged', symbol.type)
  }
  const checkTypeMax = (recentlyList) => {
    let stockItem = recentlyList
      .filter((item) => {
        return (
          item.type === 'S' ||
          item.type === 'F' ||
          item.type === 'P' ||
          item.type === 'Q' ||
          item.type === 'W'
        )
      })
      .map((recently) => {
        return {
          ...recently,
          date: new Date(recently.date)
        }
      })
      .sort((a, b) => {
        return b.date - a.date
      })
    if (stockItem.length > 20) {
      stockItem = stockItem.slice(0, 20)
    }
    let dwItem = recentlyList
      .filter((item) => {
        return item.type === 'V'
      })
      .map((recently) => {
        return {
          ...recently,
          date: new Date(recently.date)
        }
      })
      .sort((a, b) => {
        return b.date - a.date
      })
    if (dwItem.length > 20) {
      dwItem = dwItem.slice(0, 20)
    }
    let etfItem = recentlyList
      .filter((item) => {
        return item.type === 'L'
      })
      .map((recently) => {
        return {
          ...recently,
          date: new Date(recently.date)
        }
      })
      .sort((a, b) => {
        return b.date - a.date
      })
    if (etfItem.length > 20) {
      etfItem = etfItem.slice(0, 20)
    }
    let drItem = recentlyList
      .filter((item) => {
        return item.type === 'X'
      })
      .map((recently) => {
        return {
          ...recently,
          date: new Date(recently.date)
        }
      })
      .sort((a, b) => {
        return b.date - a.date
      })
    if (drItem.length > 20) {
      drItem = drItem.slice(0, 20)
    }
    let drxItem = recentlyList
      .filter((item) => {
        return item.type === 'XF'
      })
      .map((recently) => {
        return {
          ...recently,
          date: new Date(recently.date)
        }
      })
      .sort((a, b) => {
        return b.date - a.date
      })
    if (drxItem.length > 20) {
      drxItem = drxItem.slice(0, 20)
    }
    let unitTrustItem = recentlyList
      .filter((item) => {
        return item.type === 'U'
      })
      .map((recently) => {
        return {
          ...recently,
          date: new Date(recently.date)
        }
      })
      .sort((a, b) => {
        return b.date - a.date
      })
    if (unitTrustItem.length > 20) {
      unitTrustItem = unitTrustItem.slice(0, 20)
    }
    let optionItem = recentlyList
      .filter((item) => {
        return item.type === 'Options'
      })
      .map((recently) => {
        return {
          ...recently,
          date: new Date(recently.date)
        }
      })
      .sort((a, b) => {
        return b.date - a.date
      })
    if (optionItem.length > 20) {
      optionItem = optionItem.slice(0, 20)
    }
    let futureItem = recentlyList
      .filter((item) => {
        return item.type === 'Futures'
      })
      .map((recently) => {
        return {
          ...recently,
          date: new Date(recently.date)
        }
      })
      .sort((a, b) => {
        return b.date - a.date
      })
    if (futureItem.length > 20) {
      futureItem = futureItem.slice(0, 20)
    }
    let fundItem = recentlyList
      .filter((item) => {
        return item.type === 'Fund'
      })
      .map((recently) => {
        return {
          ...recently,
          date: new Date(recently.date)
        }
      })
      .sort((a, b) => {
        return b.date - a.date
      })
    if (fundItem.length > 20) {
      fundItem = fundItem.slice(0, 20)
    }
    return [
      ...stockItem,
      ...dwItem,
      ...etfItem,
      ...drItem,
      ...drxItem,
      ...unitTrustItem,
      ...optionItem,
      ...futureItem,
      ...fundItem
    ]
  }
  const checkSymbolActive = async () => {
    const recentlyList =
      localStorage.getItem(localStorageName) === null
        ? []
        : JSON.parse(localStorage.getItem(localStorageName))
    if (recentlyList.length > 0) {
      // list all active symbol
      let symbolList = localStorage.getItem(activeSymbols) === null
        ? []
        : JSON.parse(localStorage.getItem(activeSymbols))
      if (symbolList === null || symbolList.length === 0) {
        const response = await Promise.all([
          $axios.$get(
            '/api/set/technical-chart/search?query=&type=&exchange=&limit=10000'
          ),
          $axios.$get('/api/set-fund/fund-technical-chart/search?query=&limit=10000')
        ])
        symbolList = [...response[0], ...response[1]]
        localStorage.removeItem(activeSymbols)
        localStorage.setItem(activeSymbols, JSON.stringify(symbolList))
      }
      const newRecentlyList = []
      recentlyList.forEach((item) => {
        const indexSymbol = symbolList
          .map((e) => {
            return e.symbol
          })
          .indexOf(item.symbol)
        const duplicate = newRecentlyList.filter((duplicate) => {
          return duplicate.symbol === item.symbol
        }).length
        if (indexSymbol !== -1 && duplicate === 0) {
          newRecentlyList.push(item)
        }
      })
      localStorage.removeItem(localStorageName)
      localStorage.setItem(localStorageName, JSON.stringify(newRecentlyList))
    }
  }
  inject('recently', process)
}
