//
//
//
//

export default {
  head () {
    const lang = this.$i18n.locale
    let langDataList = require('@/lang/specific-page/th.json')
    if (lang === 'en') {
      // get data page en
      langDataList = require('@/lang/specific-page/en.json')
    }
    const listPage = langDataList?.pages || []
    const currentPath = this.$route.path.replace('/' + lang, '')
    const data = listPage.find(item => item.routePath === currentPath)
    if (data) {
      const { title, description, keywords } = data
      return {
        title,
        meta: [
          { hid: 'description', name: 'description', content: description },
          { name: 'keywords', content: keywords }
        ]
      }
    }
  }
}
