import { render, staticRenderFns } from "./member.vue?vue&type=template&id=37ababed&"
import script from "./member.vue?vue&type=script&lang=js&"
export * from "./member.vue?vue&type=script&lang=js&"
import style0 from "./member.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StickyBar: require('/app/components/StickyBar.vue').default,HeaderMarketSummary: require('/app/components/Header/MarketSummary.vue').default,Header: require('/app/components/Header/Header.vue').default,BannerLocationIBBottom: require('/app/components/Banner/Location/IBBottom.vue').default,Footer: require('/app/components/Footer/Footer.vue').default,AdvertisementLightbox: require('/app/components/Advertisement/Lightbox.vue').default,ChatBotZwiz: require('/app/components/ChatBotZwiz/index.vue').default,HomeQuote: require('/app/components/Pages/Home/Quote/Quote.vue').default,Pdpa: require('/app/components/Pdpa/Pdpa.vue').default,ModalFullSymbol: require('/app/components/Modal/ModalFullSymbol.vue').default,ModalDeleteFavourite: require('/app/components/Modal/ModalDeleteFavourite.vue').default})
