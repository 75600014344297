/**
 * Page Data Layer
 */
export default ({ $personalized }) => {
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    handleDOMLoaded()
  } else {
    document.addEventListener('DOMContentLoaded', handleDOMLoaded)
  }
  function handleDOMLoaded () {
    if ($personalized) {
      $personalized.pagePersonalized()
    }
  }
  // function handleWindowLoaded () {
  //   console.log('WINDOW LOADED!!!')
  // }
  // window.addEventListener('load', handleWindowLoaded)
  // document.addEventListener('DOMContentLoaded', handleDOMLoaded)
}
