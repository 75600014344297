//
//
//
//

export default {
  name: 'BannerLocationFFFloating',
  data () {
    return {
      bannerData: null,
      locationName: 'B_FF_FLOATING'
    }
  },
  async created () {
    await this.setBannerData()
  },
  methods: {
    async setBannerData () {
      this.bannerData = await this.$content.getHomeBannerLocation(this.locationName)
    }
  }
}
