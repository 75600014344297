import { render, staticRenderFns } from "./ModalFullSymbol.vue?vue&type=template&id=af09d230&"
import script from "./ModalFullSymbol.vue?vue&type=script&lang=js&"
export * from "./ModalFullSymbol.vue?vue&type=script&lang=js&"
import style0 from "./ModalFullSymbol.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClose: require('/app/components/Icon/Close.vue').default,Button: require('/app/components/Button/Button.vue').default,IconLinkNewTab: require('/app/components/Icon/LinkNewTab.vue').default,Modal: require('/app/components/Modal/Modal.vue').default})
