//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppBarNavMobileMenuCollapse',
  props: {
    menuItem: {
      type: Object,
      default: () => {}
    },
    levelNumber: {
      type: Number,
      default: 0
    },
    parentTitle: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const { Collapse } = require('bootstrap')
      const options = {
        toggle: false
      }
      this.collapse = new Collapse(this.$refs.collapse, options)
    }
  }
}
