//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Banner',
  provide () {
    return {
      bannerData: this.bannerData,
      showCaption: this.showCaption,
      showPaginationOutSide: this.showPaginationOutSide,
      personalizedCustomClass: this.personalizedCustomClass,
      personalizedSpacingClass: this.personalizedSpacingClass,
      eventTrackingName: this.eventTrackingName
    }
  },
  props: {
    bannerData: {
      type: Object,
      default: Object
    },
    showCaption: {
      type: Boolean,
      default: true
    },
    showPaginationOutSide: {
      type: Boolean,
      default: false
    },
    personalizedCustomClass: {
      type: String,
      default: null
    },
    personalizedSpacingClass: {
      type: String,
      default: null
    },
    // i18n event tracking name
    eventTrackingName: {
      type: String,
      default: null
    }
  },
  computed: {
    bannerType () {
      return this.bannerData?.bannerType || ''
    },
    isAdvertisement () {
      return this.bannerType === 'Advertisement'
    },
    isFloatingAdvertisement () {
      return this.bannerType === 'FloatingAdvertisement'
    },
    hasBannerData () {
      let hasData = false
      if (this.isAdvertisement || this.isFloatingAdvertisement) {
        hasData = !!this.html
      } else {
        hasData = !!this.bannerData
      }
      // if (this.isAdvertisement || this.isFloatingAdvertisement) {
      //   hasData = !!this.html
      // } else {
      //   hasData = !!this.bannerData.bannerItems && this.bannerData.bannerItems.length > 0
      // }
      return hasData
    },
    autoCloseInSec () {
      return this.bannerData?.autoCloseInSec || 0
    },
    html () {
      if (this.$device.isMobile) {
        return this.bannerData?.adsCodeMobile || ''
      }
      return this.bannerData?.adsCodeDesktop || ''
    }
  }
}
