const getDefaultState = () => {
  return {
    info: {},
    report: {
      annualReport: [],
      from56Report: [],
      oneReport: []
    },
    shareholders: null,
    fundFee: null,
    fundSubscription: null,
    fundAssetAllocation: null,
    fundTop5Holding: null
  }
}

const state = getDefaultState

const mutations = {
  setInfo (state, data) {
    state.info = data
  },
  setReport (state, data) {
    state.report = data
  },
  setShareholders (state, data) {
    if (data) {
      data.majorShareholders = data.majorShareholders.slice(0, 10)
      state.shareholders = data
    }
  },
  setFundFee (state, data) {
    state.fundFee = data
  },
  setFundSubscription (state, data) {
    state.fundSubscription = data
  },
  setFundAssetAllocation (state, data) {
    state.fundAssetAllocation = data
  },
  setFundTop5Holding (state, data) {
    state.fundTop5Holding = data
  }
}

const actions = {
  async getInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const etfInfo = await this.$axios.$get(`api/set/etf/${quote}/profile`, {
        params: {
          lang: this.$i18n.locale
        }
      })

      commit('setInfo', etfInfo)
    } catch {
      commit('setInfo', null)
    }
  },
  async getReport ({ commit }, payload) {
    const { quote } = payload
    try {
      const annualReport = await this.$axios.$get(
        `api/set/company/${quote}/report/annual`
      )

      const from56Report = await this.$axios.$get(
        `api/set/company/${quote}/report/form56`
      )

      const oneReport = this.$axios.$get(`api/set/company/${quote}/report/one`)

      const companyReport = await Promise.allSettled([
        annualReport,
        from56Report,
        oneReport
      ]).then((data) => {
        return {
          annualReport: data[0].value,
          from56Report: data[1].value,
          oneReport: data[2].value
        }
      })

      commit('setReport', companyReport)
    } catch {
      commit('setReport', null)
    }
  },
  async getShareholders ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/shareholder`,
        {
          params: {
            lang: this.$i18n.locale
          }
        }
      )

      commit('setShareholders', response)
    } catch {
      commit('setShareholders', null)
    }
  },
  async getFundFee ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set-fund/fund-profile/${encodeURIComponent(encodeURIComponent(quote))}/fee`
      )
      commit('setFundFee', response)
    } catch {
      commit('setFundFee', null)
    }
  },
  async getFundSubscription ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set-fund/fund-profile/${encodeURIComponent(encodeURIComponent(quote))}/subscription`
      )
      commit('setFundSubscription', response)
    } catch {
      commit('setFundSubscription', null)
    }
  },
  async getFundAssetAllocation ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set-fund/fund-profile/${encodeURIComponent(encodeURIComponent(quote))}/asset-allocation`
      )
      commit('setFundAssetAllocation', response)
    } catch {
      commit('setFundAssetAllocation', null)
    }
  },
  async getFundTop5Holding ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set-fund/fund-profile/${encodeURIComponent(encodeURIComponent(quote))}/top5-holding`
      )
      commit('setFundTop5Holding', response)
    } catch {
      commit('setFundTop5Holding', null)
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
