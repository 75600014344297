import common from '@/store/equity/stock/common/index'
import commonForeign from '@/store/equity/stock/commonForeign/index'
import preferred from '@/store/equity/stock/preferred/index'
import preferredForeign from '@/store/equity/stock/preferredForeign/index'
import unitTrust from '@/store/equity/stock/unitTrust/index'
import warrant from '@/store/equity/stock/warrant/index'

export const modules = {
  common,
  commonForeign,
  preferred,
  preferredForeign,
  unitTrust,
  warrant
}

const getDefaultState = () => {
  return {
    info: null,
    infoProfile: null,
    relatedQuoteOther: null,
    relatedQuoteWarrant: null
  }
}

const state = getDefaultState

const mutations = {
  setInfo (state, data) {
    state.info = data
  },
  setInfoProfile (state, data) {
    state.infoProfile = data
  },
  setRelatedQuoteOther (state, data) {
    state.relatedQuoteOther = data
  },
  setRelatedQuoteWarrant (state, data) {
    state.relatedQuoteWarrant = data
  }
}

const actions = {
  async getInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/info`)

      commit('setInfo', response)
    } catch {
      commit('setInfo', null)
    }
  },
  async getInfoProfile ({ commit, dispatch }, payload) {
    const { quote, lang } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/profile`,
        {
          params: {
            lang
          }
        }
      )
      commit('setInfoProfile', response)
    } catch {
      commit('setInfoProfile', null)
    }
  },
  async getRelatedQuoteOther ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/related-product/O`)

      // response.relatedProducts = [
      //   {
      //     symbol: quote.toUpperCase(),
      //     last: 999.99
      //   },
      //   {
      //     symbol: 'PTT',
      //     last: 999.99,
      //     securityType: 'S'
      //   },
      //   {
      //     symbol: 'M-CHAI-F',
      //     last: 999.99,
      //     securityType: 'F'
      //   },
      //   {
      //     symbol: 'SCB-P',
      //     last: 999.99,
      //     securityType: 'P'
      //   },
      //   {
      //     symbol: 'SCB-Q',
      //     last: 999.99,
      //     securityType: 'Q'
      //   }
      // ]

      commit('setRelatedQuoteOther', response)
    } catch {
      commit('setRelatedQuoteOther', null)
    }
  },
  async getRelatedQuoteWarrant ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/related-product/W`)

      // response.relatedProducts = [
      //   {
      //     symbol: 'BANPU-W4',
      //     last: 999.99,
      //     securityType: 'W'
      //   },
      //   {
      //     symbol: 'BANPU-W5',
      //     last: 999.99,
      //     securityType: 'W'
      //   }
      // ]

      commit('setRelatedQuoteWarrant', response)
    } catch {
      commit('setRelatedQuoteWarrant', null)
    }
  }
}

const getters = {
  relatedQuotes: (state) => {
    const map = new Map()

    for (const item of [...(state.relatedQuoteOther?.relatedProducts ?? []), ...(state.relatedQuoteWarrant?.relatedProducts ?? [])]) {
      if (map.has(item.symbol)) {
        map.delete(item.symbol)
      }
      map.set(item.symbol, item)
    }

    return [...map.values()]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
