import { render, staticRenderFns } from "./SearchBox.vue?vue&type=template&id=6d0b5526&"
import script from "./SearchBox.vue?vue&type=script&lang=js&"
export * from "./SearchBox.vue?vue&type=script&lang=js&"
import style0 from "./SearchBox.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconSearch: require('/app/components/Icon/Search.vue').default,IconCloseSearch: require('/app/components/Icon/CloseSearch.vue').default})
