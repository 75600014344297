//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SearchRecent'
  // serverCacheKey: () => 'IconSearch-v1'
}
