//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneObject } from '@/helpers'
export default {
  name: 'BannerStandard',
  inject: ['bannerData', 'personalizedCustomClass', 'personalizedSpacingClass'],
  computed: {
    bannerList () {
      const bannerItems = cloneObject(this.bannerData.bannerItems || [])
      const newBannerItems = bannerItems.map((item) => {
        let bannerImage = item.desktopImage
        if (this.$device.isMobile === true) {
          bannerImage = item.mobileImage
        }
        const obj = {
          ...item,
          isMobile: this.$device.isMobile,
          bannerImage
        }
        delete obj.desktopImage
        delete obj.mobileImage
        return obj
      })
      return newBannerItems
    },
    navigation () {
      return this.bannerData.controlArrow && this.bannerList.length > 1
    },
    bannerWidth () {
      const width = this.bannerData.moblieWidth
      if (this.$device.isMobile === false) {
        return this.bannerData.width
      }
      return width
    },
    bannerItemsSpacingClass () {
      if (this.bannerList.length > 0) {
        return this.personalizedSpacingClass
      }
      return ''
    }
  }
}
