//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapGetters({
      content: 'contents/getContentData'
    }),
    contentBody () {
      return this.content.body
    },
    contactInfo () {
      const { contactInfo } = this.contentBody || {}
      return contactInfo
    },
    socialMedia () {
      const {
        facebookUrl,
        youtubeUrl,
        lineUrl,
        twitterUrl,
        instagramUrl,
        tiktokUrl,
        contactUrl
      } = this.contactInfo || {}
      return {
        facebookUrl,
        youtubeUrl,
        lineUrl,
        twitterUrl,
        instagramUrl,
        tiktokUrl,
        contactUrl
      }
    },
    footerMenuDisclaimerAndPolicy () {
      const menu = this.getMenuLocations('M_POLICY_FOOTER') || {}
      return menu
    },
    footerMenuWeblinkAndSitemap () {
      const menu = this.getMenuLocations('M_WEBLINK_SITEMAP') || {}
      return menu
    },
    footerMenuDisclaimerAndPolicyLastUpdate () {
      if (this.footerMenuDisclaimerAndPolicy?.lastUpdate) {
        return new Date(this.footerMenuDisclaimerAndPolicy.lastUpdate)
      }
      return this.footerMenuDisclaimerAndPolicy?.lastUpdate
    },
    footerMenuWeblinkAndSitemapLastUpdate () {
      if (this.footerMenuWeblinkAndSitemap?.lastUpdate) {
        return new Date(this.footerMenuWeblinkAndSitemap.lastUpdate)
      }
      return this.footerMenuWeblinkAndSitemap?.lastUpdate
    }
  },
  methods: {
    getMenuLocations (locationCode) {
      const content = this.contentBody
      if (!content) {
        return null
      }
      const layoutMenuLocations = content.layoutMenuLocations || []
      const m = layoutMenuLocations.find(h => h.locationCode === locationCode)
      if (m) {
        return m.menu
      }
      return null
    },
    addStr (str, index, stringToAdd) {
      return (
        str.substring(0, index) + stringToAdd + str.substring(index, str.length)
      )
    }
  }
}
