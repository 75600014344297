/**
 * State
 */
const state = () => ({
  index: {
    info: [],
    chart: {
    },
    marketInfo: {
    },
    marketPerformance: {
    }
  },
  topRanking: {
    info: {},
    chart: []
  }
})

/**
   * Mutations
   */
const mutations = {
  setMarketIndexDataInfo (state, data) {
    if (data) {
      state.index.info = data.indexIndustrySectors
    }
  },
  setMarketIndexDataChart (state, marketChart) {
    state.index.chart = marketChart
    // if (marketChart) {
    //   state.index.chart = {
    //     SET: marketChart[0],
    //     SET50: marketChart[1],
    //     SET100: marketChart[2],
    //     sSET: marketChart[3],
    //     SETCLMV: marketChart[4],
    //     SETHD: marketChart[5],
    //     SETESG: marketChart[6],
    //     SETWB: marketChart[7],
    //     mai: marketChart[8]
    //   }
    // }
  },
  setMarketIndexInfo (state, data) {
    if (data) {
      state.index.marketInfo = {
        SET: data[0],
        mai: data[1]
      }
    }
  },
  setMarketPerformance (state, data) {
    if (data) {
      state.index.marketPerformance = {
        SET: data[0],
        mai: data[1]
      }
    }
  },
  setTop20Data (state, data) {
    state.topRanking.info = data
    state.topRanking.chart = []
  },
  setTop20Chart (state, data) {
    state.topRanking.chart.push(data)
  },
  setTop20PopularQuoteData (state, data) {
    state.topRanking.info = data
    state.topRanking.chart = []
  },
  setMarketDateTime (state, data) {
    state.marketDateTime = data
  }
}

/**
   * Actions
   */
const actions = {
  async getMarketOverview ({ dispatch }) {
    await Promise.allSettled([
      dispatch('getMarketIndexInfo'),
      dispatch('getMarketInfo')
      // dispatch('getMarketPerformance')
    ])
  },
  async getMarketIndexInfo ({ commit }) {
    try {
      const res = await this.$axios.$get(
        'api/set/index/info/list?type=index'
      )
      commit('setMarketIndexDataInfo', res)
    } catch (error) {
      commit('setMarketIndexDataInfo', null)
    }
  },
  async getMarketIndexChart ({ commit }, indexList) {
    try {
      // const marketInfo = await this.$axios.$get(
      //   'api/set/index/info/list?type=index'
      // )
      const length = indexList.length
      const marketChartData = {}
      for (let i = 0; i < length; i++) {
        const symbol = indexList[i]
        const url = `/api/set/index/${symbol}/chart-quotation?period=1D`
        await this.$axios.$get(url)
          .then((resData) => {
            marketChartData[symbol] = { ...resData }
          })
          .catch(() => null)
      }
      // const SETChart = this.$axios.$get(
      //   'api/set/index/SET/chart-quotation?period=1D'
      // )
      // const SET50Chart = this.$axios.$get(
      //   'api/set/index/SET50/chart-quotation?period=1D'
      // )
      // const SET100Chart = this.$axios.$get(
      //   'api/set/index/SET100/chart-quotation?period=1D'
      // )
      // const sSETChart = this.$axios.$get(
      //   'api/set/index/sSET/chart-quotation?period=1D'
      // )
      // const SETCLMVChart = this.$axios.$get(
      //   'api/set/index/SETCLMV/chart-quotation?period=1D'
      // )
      // const SETHDChart = this.$axios.$get(
      //   'api/set/index/SETHD/chart-quotation?period=1D'
      // )
      // const SETESGChart = this.$axios.$get(
      //   'api/set/index/SETESG/chart-quotation?period=1D'
      // )
      // const SETWBChart = this.$axios.$get(
      //   'api/set/index/SETWB/chart-quotation?period=1D'
      // )
      // const maiChart = this.$axios.$get(
      //   'api/set/index/mai/chart-quotation?period=1D'
      // )
      // let marketChart = []

      // marketChart = await Promise.allSettled([
      //   SETChart,
      //   SET50Chart,
      //   SET100Chart,
      //   sSETChart,
      //   SETCLMVChart,
      //   SETHDChart,
      //   SETESGChart,
      //   SETWBChart,
      //   maiChart
      // ]).then((data) => {
      //   return [
      //     data[0].value,
      //     data[1].value,
      //     data[2].value,
      //     data[3].value,
      //     data[4].value,
      //     data[5].value,
      //     data[6].value,
      //     data[7].value,
      //     data[8].value
      //   ]
      // })

      commit('setMarketIndexDataChart', marketChartData)
    } catch (e) {
      commit('setMarketIndexDataChart', null)
    }
  },
  async getMarketInfo ({ commit }) {
    try {
      const set = await this.$axios.$get('/api/set/market/SET/info')
      const mai = await this.$axios.$get('/api/set/market/mai/info')
      const marketInfo = await Promise.allSettled([set, mai]).then((data) => {
        return [
          data[0].value,
          data[1].value
        ]
      })
      commit('setMarketIndexInfo', marketInfo)
    } catch (err) {
      commit('setMarketIndexInfo', null)
    }
  },
  async getMarketPerformance ({ commit }) {
    try {
      const set = await this.$axios.$get('/api/set/index/SET/performance')
      const mai = await this.$axios.$get('/api/set/index/mai/performance')
      const marketPerformance = await Promise.allSettled([set, mai]).then((data) => {
        return [
          data[0].value,
          data[1].value
        ]
      })
      commit('setMarketPerformance', marketPerformance)
    } catch (err) {
      commit('setMarketPerformance', null)
    }
  },
  async getTop20Data ({ commit, dispatch }, payload) {
    const { rankingType, market } = payload
    try {
      // eslint-disable-next-line prefer-const
      await this.$axios.$get(`/api/set/ranking/${rankingType}/${market}/S?count=20`).then((res) => {
        commit('setTop20Data', res)
        // res.stocks.forEach(async (item) => {
        //   await dispatch('getStockTop20IntradayChart', { quote: item.symbol })
        // })
      })
    } catch (error) {
      commit('setTop20Data', null)
    }
  },
  async getStockTop20IntradayChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation?period=1D`
      )
      commit('setTop20Chart', response)
    } catch (err) {
      commit('setTop20Chart', null)
    }
  },
  async getStockTop20PopularQuote ({ commit, dispatch }, payload) {
    const { market } = payload
    const url = `/api/cms/v1/popularquote/getpopular?market=${market}&securityType=S&limit=20`
    try {
      await this.$apiCms.get(url).then((res) => {
        commit('setTop20PopularQuoteData', res.data)
        // if (res.data && res.data.length > 0) {
        //   res.data.forEach(async (item) => {
        //     await dispatch('getStockTop20IntradayChart', { quote: item.symbol })
        //   })
        // }
      })
    } catch (err) {
      commit('setTop20PopularQuoteData', null)
    }
  },
  async getMarketDateTime ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/market/SET/info')
      commit('setMarketDateTime', response)
    } catch {
      commit('setMarketDateTime', [])
    }
  }
}

/**
   * Getters
   */
const getters = {
  getMarketIndex: (state) => {
    return state.index
  },
  getMarketIndexChart: (state) => {
    return state.index.chart
  },
  getMarketIndexInfo: (state) => {
    return state.index.info
  },
  getMarketInfo: (state) => {
    return state.index.marketInfo
  },
  getMarketPerformance: (state) => {
    return state.index.marketPerformance
  },
  getMarketTopRankingInfo: (state) => {
    return state.topRanking.info
  },
  getMarketTopRankingChart: (state) => {
    return state.topRanking.chart
  },
  getMarketDateTime: (state) => {
    return state.marketDateTime
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
