const getDefaultState = () => {
  return {
    drxList: {
      date: null,
      data: []
    },
    mostActiveVolume: [],
    mostActiveValue: [],
    topGainer: [],
    toploser: [],
    popularQuote: [],
    marketOverview: {},
    drxSearch: {
      date: null,
      data: []
    },
    drxNewList: {
      date: null,
      data: []
    },
    optionUnderlying: [],
    optionIssuers: [],
    optionYTDPercent: [],
    quotationChart: null
  }
}

const state = getDefaultState

const mutations = {
  setDRXList (state, data) {
    state.drxList = data || {
      date: null,
      data: []
    }
  },
  setMostActiveVolume (state, data) {
    state.mostActiveVolume = data || []
  },
  setMostActiveValue (state, data) {
    state.mostActiveValue = data || []
  },
  setTopGainer (state, data) {
    state.topGainer = data || []
  },
  setToploser (state, data) {
    state.toploser = data || []
  },
  setDRXPopular (state, data) {
    state.popularQuote = data || []
  },
  setMarketOverview (state, data) {
    state.marketOverview = data || {}
  },
  setDRXSearch (state, data) {
    state.drxSearch = data || {
      date: null,
      data: []
    }
  },
  setDRXNewList (state, data) {
    state.drxNewList = data || {
      date: null,
      data: []
    }
  },
  setOptionUnderlying (state, data) {
    state.optionUnderlying = data || []
  },
  setOptionIssuers (state, data) {
    state.optionIssuers = data || []
  },
  setOptionYTDPercent (state, data) {
    state.optionYTDPercent = data || []
  },
  setQuotationChart (state, data) {
    state.quotationChart = data
  }
}

const actions = {
  async getDRXList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/list?lang=th')
      commit('setDRXList', response)
    } catch {
      commit('setDRXList', null)
    }
  },
  async getQuotationChart ({ commit }, symbol) {
    try {
      let response = await this.$axios.$get(`/api/set/drx/${symbol}/intraday-chart`)
      const quotationChart = response ?? {
        prior: 0,
        quotations: []
      }
      response = {
        ...quotationChart,
        quotations: quotationChart.quotations.map(x => ({
          ...x,
          datetime: new Date(x.datetime)
        }))
      }
      commit('setQuotationChart', response)
      return response
      // commit('setQuotationChart', {
      //   prior: 0,
      //   quotations: []
      // })
      // return {
      //   prior: 0,
      //   quotations: []
      // }
    } catch (err) {
      commit('setQuotationChart', null)
      return null
    }
  },
  async getMostActiveVolume ({ commit, dispatch }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/top-ranking/mostActiveVolume')
      if (response) {
        for (let i = 0; i < response.length; i++) {
          const Chart = await dispatch('getQuotationChart', response[i].symbol)
          response[i].dayChart = Chart
        }
      }
      commit('setMostActiveVolume', response)
    } catch {
      commit('setMostActiveVolume', null)
    }
  },
  async getMostActiveValue ({ commit, dispatch }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/top-ranking/mostActiveValue')
      if (response) {
        for (let i = 0; i < response.length; i++) {
          const Chart = await dispatch('getQuotationChart', response[i].symbol)
          response[i].dayChart = Chart
        }
      }
      commit('setMostActiveValue', response)
    } catch {
      commit('setMostActiveValue', null)
    }
  },
  async getTopGainer ({ commit, dispatch }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/top-ranking/topGainer')
      if (response) {
        for (let i = 0; i < response.length; i++) {
          const Chart = await dispatch('getQuotationChart', response[i].symbol)
          response[i].dayChart = Chart
        }
      }
      commit('setTopGainer', response)
    } catch {
      commit('setTopGainer', null)
    }
  },
  async getToploser ({ commit, dispatch }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/top-ranking/topLoser')
      if (response) {
        for (let i = 0; i < response.length; i++) {
          const Chart = await dispatch('getQuotationChart', response[i].symbol)
          response[i].dayChart = Chart
        }
      }
      commit('setToploser', response)
    } catch {
      commit('setToploser', null)
    }
  },
  async getDRXPopular ({ commit, dispatch }) {
    try {
      const path = '/api/cms/v1/popularquote/getpopular?market=SET&securityType=XF&limit=10'
      const response = await this.$apiCms.get(path)
      if (response.data) {
        for (let i = 0; i < response.data.length; i++) {
          const Chart = await dispatch('getQuotationChart', response.data[i].symbol)
          response.data[i].dayChart = Chart
        }
      }
      commit('setDRXPopular', response.data)
    } catch {
      commit('setDRXPopular', null)
    }
  },
  async getMarketOverview ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/market-overview')
      commit('setMarketOverview', response)
    } catch {
      commit('setMarketOverview', null)
    }
  },
  async getDRXSearch ({ commit }, payload) {
    try {
      const {
        symbols,
        underlyingClassIds,
        issuerIds,
        ytdPercentChanges,
        dividendCompare,
        dividendYield
      } = payload
      let urlPath = '/api/set/drx/search?'
      urlPath += symbols ? `symbols=${symbols}&` : ''
      urlPath += underlyingClassIds ? `underlyingClassIds=${underlyingClassIds}&` : ''
      urlPath += issuerIds ? `issuerIds=${issuerIds}&` : ''
      urlPath += ytdPercentChanges ? `ytdPercentChanges=${ytdPercentChanges}&` : ''
      urlPath += dividendCompare ? `dividendCompare=${dividendCompare}&` : ''
      urlPath += dividendYield !== null && dividendYield !== undefined ? `dividendYield=${dividendYield}&` : ''
      const response = await this.$axios.$get(urlPath)
      commit('setDRXSearch', response)
    } catch {
      commit('setDRXSearch', null)
    }
  },
  async getDRXNewList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/new-listed')
      commit('setDRXNewList', response)
    } catch {
      commit('setDRXNewList', null)
    }
  },
  async getOptionUnderlying ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/search/condition/underlying-class')
      const option = response.map((data) => {
        return {
          value: data.id,
          label: data.nameTH
        }
      })
      commit('setOptionUnderlying', option)
    } catch {
      commit('setOptionUnderlying', null)
    }
  },
  async getOptionIssuers ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/search/condition/issuers')
      const option = response.map((data) => {
        return {
          value: data.id,
          label: data.symbol
        }
      })
      commit('setOptionIssuers', option)
    } catch {
      commit('setOptionIssuers', null)
    }
  },
  getOptionYTDPercent ({ commit }) {
    try {
      const option = [
        {
          label: 'มากกว่า',
          value: 'gt'
        },
        {
          label: 'มากกว่าหรือเท่ากับ',
          value: 'ge'
        },
        {
          label: 'น้อยกว่า',
          value: 'lt'
        },
        {
          label: 'น้อยกว่าหรือเท่ากับ',
          value: 'le'
        }
      ]
      commit('setOptionYTDPercent', option)
    } catch {
      commit('setOptionYTDPercent', null)
    }
  }
}

const getters = {
  getDRXList: (state) => {
    return state.drxList
  },
  getMostActiveVolume: (state) => {
    return state.mostActiveVolume
  },
  getMostActiveValue: (state) => {
    return state.mostActiveValue
  },
  getTopGainer: (state) => {
    return state.topGainer
  },
  getToploser: (state) => {
    return state.toploser
  },
  getDRXPopular: (state) => {
    return state.popularQuote
  },
  getMarketOverview: (state) => {
    return state.marketOverview
  },
  getDRXSearch: (state) => {
    return state.drxSearch
  },
  getDRXNewList: (state) => {
    return state.drxNewList
  },
  getOptionUnderlying: (state) => {
    return state.optionUnderlying
  },
  getOptionIssuers: (state) => {
    return state.optionIssuers
  },
  getOptionYTDPercent: (state) => {
    return state.optionYTDPercent
  },
  getQuotationChart: (state) => {
    return state.quotationChart
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
