//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { showRequestNum, hideRequestNum } from '@/directives/modal'
export default {
  name: 'MiscMemberVirtualPortfolioModalOrderStock',
  directives: {
    showRequestNum,
    hideRequestNum
  },
  props: {
    showRequestNum: {
      type: Number,
      default: 0
    },
    symbolList: {
      type: Array,
      default: () => [
        { symbol: 'PTT', nameTH: 'ปตท', nameEN: 'PTT', securityType: 'S' },
        { symbol: 'WHA', nameTH: 'WHA', nameEN: 'WHA', securityType: 'W' },
        { symbol: 'SCB-P', nameTH: 'ไทยพาณิชย์ X บุริมสิทธิ์', nameEN: 'Siam Commercial Bank X Preferred', securityType: 'P' },
        { symbol: 'SCB-F', nameTH: 'ไทยพาณิชย์ X ต่างชาติ', nameEN: 'Siam Commercial Bank X Foreign', securityType: 'F' }
      ]
    }
  },
  data () {
    return {
      hideRequestNum: 0,
      formData: {},
      disableBuy: false,
      validationState: {
        symbol: {},
        volume: {},
        price: {}
      },
      lockBtn: false
    }
  },
  computed: {
    ...mapGetters({
      // สำหรับ delete deactive symbol
      // That's need to pass order data from store
      orderStockData: 'member/virtual-portfolio/getModalOrderStockData'
    }),
    outstandingSecurities () {
      return this.$virtualPort.state.equity?.equityPortfolios ?? []
    },
    effectiveSymbolList () {
      const outstandingSecurities = this.outstandingSecurities
      return this.orderType === 2 ? this.symbolList.filter(x => outstandingSecurities.find(y => y.symbol === x.symbol)) : this.symbolList
    },
    orderType () {
      return this.formData.orderType ?? 1
    },
    value () {
      const volume = +(this.formData.volume ?? 0)
      const price = +(this.formData.price ?? 0)
      const fee = this.formData.hasFee ? (+(this.formData.fee ?? 0)) * 0.01 : 0
      if (this.orderType === 2) { // sell
        return (volume * price) * (1 - fee)
      } else { // buy
        return (volume * price) * (1 + fee)
      }
    },
    disableSell () {
      return this.outstandingSecurities.length === 0
    }
  },
  watch: {
    showRequestNum (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetFormData()
      }
    },
    // 'formData.orderType' () {
    //   this.formData.symbol = undefined
    // },
    'formData.symbol' () {
      this.validationState.symbol.errorLevel = 0
      this.validationState.symbol.errorType = undefined
    },
    'formData.volume' () {
      this.validationState.volume.errorLevel = 0
      this.validationState.volume.errorType = undefined
    },
    'formData.price' () {
      this.validationState.price.errorLevel = 0
      this.validationState.price.errorType = undefined
    }
  },
  methods: {
    resetFormData () {
      this.formData = {
        orderType: undefined,
        symbol: undefined,
        volume: undefined,
        price: undefined,
        hasFee: false,
        fee: 0.15
      }
      this.validationState = {
        symbol: { errorLevel: 0, errorType: undefined },
        volume: { errorLevel: 0, errorType: undefined },
        price: { errorLevel: 0, errorType: undefined }
      }
      this.disableBuy = false
    },
    async submit () {
      let validatedFail = false
      const { symbol, volume, price, hasFee, fee } = this.formData //eslint-disable-line
      const orderType = this.orderType
      const validationState = this.validationState
      const outstandingSecurityCount = this.outstandingSecurities.length
      const outstandingSecurity = this.outstandingSecurities.find(x => x.symbol === symbol)
      checkSymbol()
      checkVolume()
      checkPrice()
      if (!validatedFail) {
        const order = {
          transactionType: orderType === 2 ? 'sell' : 'buy',
          symbol,
          volume,
          commission: hasFee ? +fee ?? 0 : 0,
          price
        }
        this.lockBtn = true
        const result = await this.$virtualPort.sendOrder('equity', order)
        if (result) {
          this.$toast.success('success', 'ส่งคำสั่งสำเร็จ')
          this.$emit('onSubmitSuccess', symbol)
          this.hideModal()
          this.dotry(() =>
            this.$personalized.clickElement({
              name: 'memberVirtualPortfolio4',
              optionLang: { category: 'หุ้น' }
            })
          )
        } else {
          this.$toast.fail('warning', 'เกิดข้อผิดพลาด')
        }
        this.lockBtn = false
      }

      function checkSymbol () {
        if (!symbol || symbol === '') {
          validatedFail = true
          validationState.symbol.errorLevel = 2
          validationState.symbol.errorType = 'required'
          return  //eslint-disable-line
        }
        if (orderType === 1 && outstandingSecurityCount >= 30 && !outstandingSecurity) {
          validatedFail = true
          validationState.symbol.errorLevel = 2
          validationState.symbol.errorType = 'symbolLimitExceeded'
          return  //eslint-disable-line
        }
      }

      function checkVolume () {
        if (!volume || +volume === 0) {
          validatedFail = true
          validationState.volume.errorLevel = 2
          validationState.volume.errorType = 'required'
          return  //eslint-disable-line
        }
        if (orderType === 2 && outstandingSecurity && outstandingSecurity.volume < +volume) {
          validatedFail = true
          validationState.volume.errorLevel = 2
          validationState.volume.errorType = 'notEnoughOutstandingVolume'
          return  //eslint-disable-line
        }
      }

      function checkPrice () {
        if (!price || +price === 0) {
          validatedFail = true
          validationState.price.errorLevel = 2
          validationState.price.errorType = 'required'
          return  //eslint-disable-line
        }
      }
    },
    hideModal () {
      this.hideRequestNum++
    },
    handleOrderTypeChanged () {
      this.formData.symbol = undefined
    },
    handleInputSymbol ($event) {
      this.formData.symbol = $event
    },
    handleOnShow () {
      this.setOrderFormData()
    },
    handleOnHide () {
      this.resetFormData()
      this.$store.commit('member/virtual-portfolio/setModalOrderStockData', null)
    },
    setOrderFormData () {
      if (!this.orderStockData) { return }
      const {
        orderType,
        data: { symbol, avgPrice, volume }
      } = this.orderStockData ?? {}
      this.disableBuy = true
      this.formData = {
        orderType, // to be 2(Sell)
        symbol,
        volume,
        price: avgPrice,
        hasFee: false,
        fee: 0.00 // เมื่อขายด้วยปุ่มลบของระบบให้ทำการ Default ไว้ที่ 0.00
      }
      // console.log({
      //   formData: JSON.parse(
      //     JSON.stringify(this.formData)
      //   ),
      //   orderStockData: JSON.parse(
      //     JSON.stringify(this.orderStockData)
      //   )
      // })
    }
  }
}
