import { marketListId } from '@/utils/marketlist'
const getFavouriteCategoryType = (securityType) => {
  switch (securityType) {
    case 'S':
    case 'F':
    case 'P':
    case 'Q':
    case 'W':
    case 'U':
      return 'stock'
    case 'L':
      return 'etf'
    case 'V':
      return 'dw'
    case 'X':
      return 'dr'
    case 'XF':
      return 'drx'
    case marketListId.IF:
    case marketListId.SF:
    case marketListId.FT:
    case marketListId.PMF:
    case marketListId.DPM:
    case marketListId.CF:
    case marketListId.IRF:
    case marketListId.AF:
    case marketListId.TXI_F:
    case marketListId.TXS_F:
    case marketListId.TXM_F:
    case marketListId.TXD_F:
    case marketListId.TXC_F:
    case marketListId.TXR_F:
    case marketListId.TXA_F:
    case 'TFEX':
      return 'futures'
    case marketListId.IO:
      return 'options'
    case marketListId.TXI_O:
      return 'options'
    case marketListId.TXI_C:
      return 'options'
    case marketListId.TXI_P:
      return 'options'
    case 'FUND':
      return 'fund'
    default:
      return 'stock'
  }
}

export { getFavouriteCategoryType }
