//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'ModalDeleteFavourite',
  computed: {
    ...mapState('member/favourite', {
      _delete: state => state.delete
    })
  },
  methods: {
    openModal () {
      this.$refs.modalDeleteFavourite.show()
    },
    hideModal () {
      this.$refs.modalDeleteFavourite.hide()
    },
    async onDelete () {
      const result = await this.$favourite.removeFavourite(
        this._delete.category,
        this._delete.symbol
      )
      if (result.status === 202) {
        this.$toast.success('success', 'ลบหลักทรัพย์ออกจากรายการที่ติดตามแล้ว')
      } else if (result.status === 404) {
        this.$toast.fail('warning', 'เกิดข้อผิดพลาด')
      }
      this.hideModal()
    }
  }
}
