import indexFutures from './indexFutures/index'
import indexOptions from './indexOptions/index'
import singleStockFutures from './singleStockFutures/index'

export const modules = {
  indexFutures,
  indexOptions,
  singleStockFutures
}

const getDefaultState = () => {
  return {
    underlying: null
  }
}

const state = getDefaultState

const mutations = {
  setUnderlying (state, data) {
    state.underlying = data
  }
}

const actions = {
  async getUnderlying ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series/${quote}/underlying-price`)

      commit('setUnderlying', response)
    } catch {
      commit('setUnderlying', null)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
