import Vue from 'vue'
import * as api from '@/helpers/virtualport/api.js'

export default function (context, inject) {
  const eventBus = new Vue()
  let initialized
  const state = Vue.observable({
    equity: undefined,
    tfex: undefined,
    fund: undefined
  })
  const getPortHandler = {
    equity: api.getEquityPortfolio.bind(context),
    tfex: api.getDerivativePortfolio.bind(context),
    fund: api.getFundPortfolio.bind(context)
  }
  const sendOrderHandler = {
    equity: api.sendOrderEquity.bind(context),
    tfex: api.sendOrderDerivative.bind(context),
    fund: api.sendOrderFund.bind(context)
  }

  async function getPortfolio (category, id, name) {
    const data = await getPortHandler[category](id)
    return {
      id,
      category,
      name,
      ...data
    }
  }

  function * enumeratePortIds () {
    if (state.equity) {
      yield state.equity.id
    }
    if (state.tfex) {
      yield state.tfex.id
    }
    if (state.fund) {
      yield state.fund.id
    }
  }

  function initialization () {
    if (!initialized) {
      initialized = (async () => {
        if (!context.$authUser.permissionUser()) {
          throw new Error('don\'t call this method before authenticating the user!')
        }

        const portList = await api.getListOfPortfolios.call(context)
        const ports = await Promise.all(portList.map(x => getPortfolio(x.category, x.portfolioId, x.name)))
        for (const port of ports) {
          state[port.category] = port
        }
      })()
    }
    return initialized
  }

  async function reloadPortfolio (category) {
    if (state[category]) {
      const { id, name } = state[category]
      state[category] = await getPortfolio(category, id, name)
    }
  }

  async function createPortfolio (data) {
    if (state[data.category]) {
      throw new Error('this category of portfolio is already exists!')
    }
    if (await api.createPortfolio.call(context, data)) {
      const set = new Set(enumeratePortIds())
      const portList = await api.getListOfPortfolios.call(context)
      const added = []
      for (const { portfolioId: id } of portList) {
        if (!set.delete(id)) {
          added.push(id)
        }
      }
      const deleted = [...set]  //eslint-disable-line
      const { category, name, portfolioId: id } = portList.find(x => x.category === data.category && added.includes(x.portfolioId))
      state[category] = await getPortfolio(category, id, name)
      return true
    } else {
      return false
    }
  }

  async function editPortfolioName (data) {
    if (await api.editPortfolioName.call(context, state[data.category].id, data.name)) {
      const portList = await api.getListOfPortfolios.call(context)
      state[data.category].name = portList.find(x => x.portfolioId === state[data.category].id)?.name
      eventBus.$emit('portfolioChanged', data.category)
      return true
    } else {
      return false
    }
  }

  async function clearPortfolio (category) {
    const result = await api.deletePortfolio.call(context, state[category].id)
    if (result) {
      state[category] = undefined
      eventBus.$emit('portfolioChanged', category)
      return true
    } else {
      return false
    }
  }

  async function sendOrder (category, order) {
    const result = await sendOrderHandler[category](state[category].id, order)
    if (result) {
      await reloadPortfolio(category)
      eventBus.$emit('portfolioChanged', category)
      return true
    } else {
      return false
    }
  }

  inject('virtualPort', {
    eventBus,
    state,
    initialization,
    reloadPortfolio,
    createPortfolio,
    editPortfolioName,
    clearPortfolio,
    sendOrder
  })
}
