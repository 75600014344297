import _ from 'lodash'

const getDefaultState = () => {
  return {
    financialDataQuarterChart: [],
    financialDataAccumulatedChart: [],
    highlightFinancialData: [],
    tradingStat: [],
    stockList: {},
    financialCompareData: {},
    periods: null,
    financialStatement: null
  }
}

const state = getDefaultState

const mutations = {
  setFinancialDataQuarterChart (state, data) {
    state.financialDataQuarterChart = data
  },

  setFinancialDataAccumulatedChart (state, data) {
    state.financialDataAccumulatedChart = data
  },

  setHighlightFinancialData (state, data) {
    state.highlightFinancialData = data
  },

  setTradingStat (state, data) {
    state.tradingStat = data
  },

  setStockList (state, data) {
    state.stockList = data
  },

  setFinancialCompareData (state, data) {
    state.financialCompareData = data
  },

  setPeriods (state, data) {
    state.periods = data
  },

  setFinancialStatement (state, data) {
    state.financialStatement = data
  }
}

const actions = {
  async getFinancialDataChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const quarterData = this.$axios.$get(`/api/set/stock/${quote}/company-highlight/financial-data-chart`, {
        params: {
          accumulated: false
        }
      })

      const accumulatedData = this.$axios.$get(`/api/set/stock/${quote}/company-highlight/financial-data-chart`, {
        params: {
          accumulated: true
        }
      })

      const [{ value: quarterChart }, { value: accumulatedChart }] = await Promise.allSettled([quarterData, accumulatedData])

      commit('setFinancialDataQuarterChart', quarterChart)
      commit('setFinancialDataAccumulatedChart', accumulatedChart)
    } catch {
      commit('setFinancialDataQuarterChart', [])
      commit('setFinancialDataAccumulatedChart', [])
    }
  },

  async getHighlightFinancialData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/company-highlight/financial-data`)

      commit('setHighlightFinancialData', response)
    } catch {
      commit('setHighlightFinancialData', null)
    }
  },

  async getTradingStat ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/company-highlight/trading-stat`)
      if (response[response.length - 1].period === 'YTD') {
        response[response.length - 1].period = new Date().getFullYear()
      }

      commit('setTradingStat', response)
    } catch {
      commit('setTradingStat', null)
    }
  },

  async getStockList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/stock/list', {
        params: {
          securityType: 's'
        }
      })

      commit('setStockList', response)
    } catch {
      commit('setStockList', null)
    }
  },

  async getFinancialCompareData ({ commit }, payload) {
    const { quote } = payload
    try {
      const financialData = this.$axios.$get(`/api/set/stock/${quote}/company-highlight/financial-data`)

      const tradingData = this.$axios.$get(`/api/set/stock/${quote}/company-highlight/trading-stat`)

      const result = await Promise.allSettled([financialData, tradingData]).then((data) => {
        const [{ value: financial }, { value: trading }] = data
        const tempTrading = trading?.[trading.length - 1] ?? {}
        if (tempTrading.period === 'YTD') {
          tempTrading.period = new Date().getFullYear()
        }

        return {
          financialCompareData: financial[financial.length - 1],
          tradingCompareData: tempTrading
        }
      })

      commit('setFinancialCompareData', result)
    } catch {
      commit('setFinancialCompareData', null)
    }
  },

  async getPeriods ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/financialstatement/periods`)

      commit('setPeriods', response)
    } catch {
      commit('setPeriods', null)
    }
  },

  async getFinancialStatement ({ commit }, payload) {
    const { quote, accountType, fsType, period } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/financialstatement`, {
        params: {
          accountType, // balance_sheet | income_statement | cash_flow
          fsType, // company | consolidate
          period, // Q9_2019, Q3_2019
          language: 'th' // en
        }
      })
      commit('setFinancialStatement', response)
    } catch {
      commit('setFinancialStatement', null)
    }
  }
}

const getters = {
  financialDataQuarterList: (state) => {
    return _.mapValues(_.groupBy(state.financialDataQuarterChart, 'year'), charts =>
      charts.map(chart => _.omit(chart, 'year'))
    )
  },
  financialDataAccumulatedList: (state) => {
    return _.mapValues(_.groupBy(state.financialDataAccumulatedChart, 'year'), charts =>
      charts.map(chart => _.omit(chart, 'year'))
    )
  },
  yearFinancialData: (state, getters) => {
    const quarterList = Object.keys(getters.financialDataQuarterList ?? {})
    const accumulatedList = Object.keys(getters.financialDataAccumulatedList ?? {})
    return _.isEmpty(quarterList) ? (_.isEmpty(accumulatedList) ? [] : accumulatedList) : quarterList
  },
  highlightFinancialData: (state) => {
    return _.mapValues(_.groupBy(state.highlightFinancialData, 'year'), highlight => highlight[0])
  },
  tradingStat: (state) => {
    return _.mapValues(_.groupBy(state.tradingStat, 'period'), stat => stat[0])
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
