import { marketListId } from '@/utils/marketlist'
const generatorXTypeColor = (xType) => {
  switch (xType) {
    case 'XD':
      return '#16B916'
    case 'XR':
      return '#85D63E'
    case 'XW':
      return '#3FC1C0'
    case 'XT':
      return '#106FDC'
    case 'XM':
      return '#0C4484'
    case 'XI':
      return '#ECB32A'
    case 'XP':
      return '#EB7C15'
    case 'XA':
      return '#A5503D'
    case 'XE':
      return '#937CD6'
    case 'XN':
      return '#9D32CF'
    case 'XB':
      return '#E77EC3'
    default:
      return '#F3F7F9'
  }
}
const generatorXTypeCardColor = (xType) => {
  switch (xType) {
    case 'XD':
    case 'XD(ST)':
      return '#067726'
    case 'XR':
      return '#57B110'
    case 'XW':
      return '#037AA1'
    case 'XT':
      return '#FF3B3B'
    case 'XM':
      return '#3861CA'
    case 'XI':
      return '#ECB32A'
    case 'XP':
      return '#EB7C15'
    case 'XA':
      return '#A5503D'
    case 'XE':
      return '#42369D'
    case 'XN':
      return '#42369D'
    case 'XB':
      return '#FF3B3B'
    default:
      return '#F3F7F9'
  }
}
const generatorXTypeFontColor = (xType) => {
  switch (xType) {
    case 'XD':
    case 'XD(ST)':
      return 'xd-font-color'
    case 'XR':
      return 'xr-font-color'
    case 'XW':
      return 'xw-font-color'
    case 'XT':
      return 'xt-font-color'
    case 'XM':
      return 'xm-font-color'
    case 'XMMeeting':
      return 'xm-meet-font-color'
    case 'XI':
      return 'xi-font-color'
    case 'XP':
      return 'xp-font-color'
    case 'XA':
      return 'xa-font-color'
    case 'XE':
      return 'xe-font-color'
    case 'XN':
      return 'xn-font-color'
    case 'XB':
      return 'xb-font-color'
    default:
      return ''
  }
}
const generatorXTypeBGColor = (xType) => {
  switch (xType) {
    case 'XD':
    case 'XD(ST)':
      return 'xd-color'
    case 'XR':
      return 'xr-color'
    case 'XW':
      return 'xw-color'
    case 'XT':
      return 'xt-color'
    case 'XM':
      return 'xm-color'
    case 'XMMeeting':
      return 'xm-meet-color'
    case 'XI':
      return 'xi-color'
    case 'XP':
      return 'xp-color'
    case 'XA':
      return 'xa-color'
    case 'XE':
      return 'xe-color'
    case 'XN':
      return 'xn-color'
    case 'XB':
      return 'xb-color'
    default:
      return ''
  }
}
const convertArrayToObject = (array, key) =>
  array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item
    }),
    {}
  )
const convertKeyRankingTypeToPath = (rankingType) => {
  switch (rankingType) {
    case 'mostActiveVolume':
      return 'most-active-volume'
    case 'mostActiveValue':
      return 'most-active-value'
    case 'topGainer':
      return 'top-gainer'
    case 'topLoser':
      return 'top-loser'
    case 'popular':
      return 'popular-quote'
    case 'topPE':
      return 'top-pe'
    case 'topDividendYield':
      return 'top-dividend-yield'
    case 'topEPS':
      return 'top-eps'
    case 'topNetProfitMargin':
      return 'top-net-profit-margin'
    case 'topROE':
      return 'top-roe'
    case 'topROA':
      return 'top-roa'
  }
}
const setMoneynessStatusLabel = (key) => {
  switch (key) {
    case 'gt-20-ITM':
      return 'มากกว่า 20% ITM'
    case 'gte-10-ITM,lte-20-ITM':
      return '10 - 20 % ITM'
    case 'gte-0-ATM,lte-10-ITM':
      return 'ATM - 10% ITM'
    case 'gte-0-ATM,lte-10-OTM':
      return '10% OTM - ATM'
    case 'gte-10-OTM,lte-20-OTM':
      return '10 - 20% OTM'
    case 'gt-20-OTM':
      return 'มากกว่า 20% OTM'
  }
}
const setDwSearchTimeToMaturitiesLabel = (key) => {
  switch (key) {
    case '0-30':
      return 'น้อยกว่า 1 เดือน'
    case '31-90':
      return '1 - 3 เดือน'
    case '91-180':
      return '3 - 6 เดือน'
    case '181':
      return 'มากกว่า 6 เดือน'
  }
}
const setDwSearchEgListLabel = (key) => {
  switch (key) {
    case 'lt-5':
      return 'น้อยกว่า 5 เท่า'
    case 'gte-5,lt-10':
      return '5 - 10 เท่า'
    case 'gte-10,lte-15':
      return '10 - 15 เท่า'
    case 'gt-15':
      return 'มากกว่า 15 เท่า'
  }
}
const getYearOption = (numYearAgo = 4, numYearNext = 1) => {
  const currentYear = new Date().getFullYear() + numYearNext
  const yearsOption = []
  const startYears = new Date().getFullYear() - numYearAgo
  for (let i = startYears; i <= currentYear; i++) {
    yearsOption.push({
      label: (i + 543).toString(),
      value: i
    })
  }
  return yearsOption
}
const getMonthOption = () => {
  const monthsTh = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ]
  const monthOption = []
  for (let i = 0; i < monthsTh.length; i++) {
    monthOption.push({
      label: monthsTh[i],
      value: i + 1
    })
  }
  return monthOption
}
const setFlagStatus = (flag) => {
  switch (flag) {
    case 'D':
      return 'Delisted'
    case 'E':
      return 'Expired'
    default:
      return 'Delisted'
  }
}
const formatSecurityType = (securityType, optionsType) => {
  if (
    securityType === 'S' ||
    securityType === 'F' ||
    securityType === 'P' ||
    securityType === 'Q' ||
    securityType === 'W'
  ) {
    return 'หุ้น'
  } else if (securityType === 'U') {
    return 'หน่วยลงทุน'
  } else if (securityType === 'V') {
    return 'DW'
  } else if (securityType === 'X') {
    return 'DR'
  } else if (securityType === 'L') {
    return 'ETF'
  } else if (securityType === 'XF') {
    return 'DRx'
  } else if (securityType === 'FUND') {
    return 'กองทุนรวม'
  } else if (
    securityType === marketListId.IO ||
    securityType === marketListId.TXI_O ||
    securityType === marketListId.TXI_P ||
    securityType === marketListId.TXI_C
  ) {
    return 'ออปชัน'
  } else if (
    securityType === marketListId.IF ||
    securityType === marketListId.SF ||
    securityType === marketListId.PMF ||
    securityType === marketListId.DPM ||
    securityType === marketListId.CF ||
    securityType === marketListId.IRF ||
    securityType === marketListId.AF ||
    securityType === marketListId.TXI_F ||
    securityType === marketListId.TXS_F ||
    securityType === marketListId.TXM_F ||
    securityType === marketListId.TXD_F ||
    securityType === marketListId.TXC_F ||
    securityType === marketListId.TXR_F ||
    securityType === marketListId.TXA_F
  ) {
    return 'ฟิวเจอร์ส'
  } else if (optionsType !== undefined && optionsType !== '') {
    return 'ออปชัน'
  } else if (optionsType !== undefined && optionsType === '') {
    return 'ฟิวเจอร์ส'
  } else {
    return securityType
  }
}
const isTypeStockSubR = (btnQuoteData, securityType) => {
  if (
    btnQuoteData &&
    btnQuoteData.symbol &&
    (btnQuoteData?.securityType !== 'FUND' && securityType !== 'FUND') &&
    (btnQuoteData?.securityType === 'S' ||
      securityType === 'S' ||
      securityType === '')
  ) {
    const arraySplitString = btnQuoteData?.symbol?.split('-')
    return arraySplitString[arraySplitString.length - 1] === 'R'
  } else {
    return false
  }
}
export {
  generatorXTypeColor,
  convertArrayToObject,
  convertKeyRankingTypeToPath,
  setMoneynessStatusLabel,
  setDwSearchTimeToMaturitiesLabel,
  setDwSearchEgListLabel,
  generatorXTypeCardColor,
  generatorXTypeFontColor,
  generatorXTypeBGColor,
  getYearOption,
  getMonthOption,
  setFlagStatus,
  formatSecurityType,
  isTypeStockSubR
}
