import { customAlphabet } from 'nanoid'
import ElementToasts from '~/components/Toasts/Toasts.vue'
function toastBodyBuilder (type, id, message) {
  const { $createElement } = window.$nuxt
  const toastBody = $createElement(ElementToasts, { props: { toastType: type, toastId: id, message } })
  return toastBody
}
function createIDToast () {
  const nanoid = customAlphabet(
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    4
  )
  return nanoid()
}
export default (context, inject) => {
  const plugin = {
    success (type, message) {
      const nanoid = createIDToast()
      const { $bvToast } = window.$nuxt
      $bvToast.toast(toastBodyBuilder(type, 'bookmark-snackbar-success_' + nanoid, message), {
        id: 'bookmark-snackbar-success_' + nanoid,
        headerClass: 'd-none',
        bodyClass: 'notification-snackbar-success',
        toastClass: 'notification-snackbar',
        toaster: 'b-toaster-bottom-left',
        // noAutoHide: true,
        autoHideDelay: 4000,
        appendToast: true
      })
    },
    fail (type, message) {
      const nanoid = createIDToast()
      const { $bvToast } = window.$nuxt
      $bvToast.toast(toastBodyBuilder(type, 'bookmark-snackbar-failed_' + nanoid, message), {
        id: 'bookmark-snackbar-failed_' + nanoid,
        headerClass: 'd-none',
        bodyClass: ' notification-snackbar-failed',
        toastClass: 'notification-snackbar',
        toaster: 'b-toaster-bottom-left',
        // noAutoHide: true,
        autoHideDelay: 4000,
        appendToast: true
      })
    }
  }

  inject('toast', plugin)
  context.$toast = plugin
}
