//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ButtonStreaming',
  computed: {
    streamingUrl () {
      return process.env.SET_STREAMING_URL
    }
  },
  methods: {
    clickEventToUrl () {
      this.$personalized.clickElement({
        name: 'all11'
      })
    }
  }
}
