//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ButtonViewMore',
  props: {
    url: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    textClass: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    }
  }
}
