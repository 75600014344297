//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { integerStyle } from '@/helpers/index'
export default {
  name: 'SearchMiscPriceTag',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          change: 0,
          percentChange: 0
        }
      }
    },
    classTagPercent: {
      type: String,
      default: ''
    }
  },
  methods: {
    integerStyle,
    integerStyleBackground (number) {
      if (Number(number) === 0) {
        return 'bg-other-gray'
      } else if (Number(number) < 0) {
        return 'bg-negative'
      } else if (Number(number) > 0) {
        return 'bg-positive'
      } else {
        return 'bg-other-gray'
      }
    }
  }
}
