//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
import { elementPDPA } from '~/utils'
export default {
  name: 'Pdpa',
  data () {
    return {
      versionName: '',
      element: elementPDPA.name,
      show: false
    }
  },
  computed: {
    ...mapGetters({
      content: 'contents/getContentData'
    }),
    pDPACookie () {
      const { pdpaCookie } = cloneDeep(this.content) || {}
      return pdpaCookie || {}
    }
  },
  created () {
    this.removeDuplicateCookie()
    // await this.setPdpaData()
    this.versionName = this.pDPACookie.versionName ? `PDPA Version ${this.pDPACookie.versionName}` : ''
    this.show = this.pDPACookie.isActive
    if (this.pDPACookie && this.pDPACookie.version === (this.$cookies.get('SET_COOKIE_POLICY') && this.$cookies.get('SET_COOKIE_POLICY').toString())) {
      this.show = false // this.pDPACookie.isActive = false
    }
  },
  methods: {
    setPdpaData () {
      const { pdpaCookie } = cloneDeep(this.content) || {}
      this.pDPACookie = pdpaCookie || {}
    },
    async accecptPDPA () {
      await this.handleClickTracking()
      this.removeDuplicateCookie()
      this.show = false // this.pDPACookie.isActive = false
      // new Date(Number(new Date()) + 30 * 1000 * 60 * 60 * 24)
      const { cookiesExpires } = require('~/config/cookies')
      const cookieConfig = {
        path: '/',
        expires: cookiesExpires
      }
      this.$cookies.set('SET_COOKIE_POLICY', this.pDPACookie.version, cookieConfig)
    },
    handleClickTracking () {
      const dataAction = this.versionName // Data Action(Section)
      this.$personalized.clickElement({
        elementName: this.element,
        title: this.pDPACookie.btn,
        sectionName: dataAction,
        teamName: this.pDPACookie.teamName
      })
    },
    removeDuplicateCookie () {
      // remove by path
      // this.$cookies.remove('SET_COOKIE_POLICY', {
      //   path: '/'
      // })
      // remove by path
      this.$cookies.remove('SET_COOKIE_POLICY', {
        path: '/',
        domain: process.env.SITE_DOMAIN
      })
      // remove by path
      this.$cookies.remove('SET_COOKIE_POLICY', {
        path: '/th'
      })
      // remove by path
      this.$cookies.remove('SET_COOKIE_POLICY', {
        path: '/en'
      })
      // remove by path
      this.$cookies.remove('SET_COOKIE_POLICY', {
        path: this.$route.path
      })
      // remove by fullpath
      this.$cookies.remove('SET_COOKIE_POLICY', {
        path: this.$route.fullPath
      })
    }
  }
}
