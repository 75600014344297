import equity from './equity/index'
import currency from './currency/index'
import agriculture from './agriculture/index'
import preciousMetal from './preciousMetal/index'
import interestRate from './interestRate/index'
import { getCateParamResearch } from '@/helpers/categoryParams'

export const modules = {
  equity,
  currency,
  agriculture,
  preciousMetal,
  interestRate
}

const getDefaultState = () => {
  return {
    info: null,
    seriesList: null,
    marketList: null,
    tradingStatistics: null,
    seriesInfoList: null,
    tradingSchedule: null,
    iaaOverall: null,
    iaaConsensus: null,
    iaaChartQuotation: null,
    researchTotalCount: 0
  }
}

const state = getDefaultState

const mutations = {
  setInfo (state, data) {
    state.info = data
  },
  setSeriesList (state, data) {
    state.seriesList = data
  },
  setMarketList (state, data) {
    state.marketList = data
  },
  setTradingStatistics (state, data) {
    state.tradingStatistics = data
  },
  setSeriesInfoList (state, data) {
    state.seriesInfoList = data
  },
  setTradingSchedule (state, data) {
    state.tradingSchedule = data
  },
  setIaaOverall (state, data) {
    state.iaaOverall = data
  },
  setIaaConsensus (state, data) {
    state.iaaConsensus = data
  },
  setIaaChartQuotation (state, data) {
    state.iaaChartQuotation = data
  },
  setResearchTotalCount (state, data) {
    state.researchTotalCount = data
  }
}

const actions = {
  async getInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series/${quote}/info`)

      commit('setInfo', response)
    } catch {
      commit('setInfo', null)
    }
  },
  async getSeriesList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/series/list')
      commit('setSeriesList', response)
    } catch {
      commit('setSeriesList', null)
    }
  },
  async getMarketList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/list')

      commit('setMarketList', response)
    } catch {
      commit('setMarketList', null)
    }
  },
  async getTradingStatistics ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series/${quote}/trading-statistics`)

      commit('setTradingStatistics', response)
    } catch {
      commit('setTradingStatistics', null)
    }
  },
  async getSeriesInfoList ({ commit }, payload) {
    const { instrumentId } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/instrument/${instrumentId}/series-info-list`)

      commit('setSeriesInfoList', response)
    } catch {
      commit('setSeriesInfoList', null)
    }
  },
  async getTradingSchedule ({ commit }, payload) {
    const { instrumentId } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/instrument/${instrumentId}/trading-schedule`)

      commit('setTradingSchedule', response)
    } catch {
      commit('setTradingSchedule', null)
    }
  },
  async getIaaOverall ({ commit }, payload) {
    const { symbol } = payload
    try {
      const response = await this.$axios.$get('/api/set-fund/consensus/tfex/overall', {
        params: {
          lang: this.$i18n.locale,
          symbol
        }
      })

      commit('setIaaOverall', response)
    } catch {
      commit('setIaaOverall', null)
    }
  },
  async getIaaConsensus ({ commit }, payload) {
    const { symbol } = payload
    try {
      const response = await this.$axios.$get(`/api/set-fund/consensus/tfex/${symbol}/consensus`)

      commit('setIaaConsensus', response)
    } catch {
      commit('setIaaConsensus', null)
    }
  },
  async getChartQuotation ({ commit }, payload) {
    const { symbol } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series/${symbol}/chart-quotation`, {
        params: {
          period: '1D'
        }
      })

      commit('setIaaChartQuotation', response)
    } catch {
      commit('setIaaChartQuotation', null)
    }
  },
  async getResearch ({ commit }, payload) {
    const { symbol } = payload
    try {
      const response = await this.$apiCms
        .get('/api/cms/v1/research-settrade/search', {
          params: {
            symbol,
            cate: getCateParamResearch('tfex'),
            pageSize: 10
          }
        })

      commit('setResearchTotalCount', response.data.researchItems.totalCount)
    } catch {
      commit('setResearchTotalCount', 0)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
