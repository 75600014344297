const ObserverAdsAts = () => {
  // Select the node that will be observed for mutations
  const targetNode = document.querySelector('body')

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true }

  // Callback function to execute when mutations are observed
  const callback = function (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        if (mutation && mutation.target && mutation.target.className) {
          if (mutation.target.className === 'ats-overlay-bottom-wrapper-rendered' || mutation.target.className === 'ats-overlay-bottom-wrapper-unrendered') {
            document.body.classList.add('is-ats-ads')
            observer.disconnect()
            if (document.querySelector('.ats-overlay-bottom-close-button')) {
              document.querySelector('.ats-overlay-bottom-close-button').addEventListener('click', () => {
                document.body.classList.remove('is-ats-ads')
              })
            }
          }
        }
      }
    }
  }

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback)

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config)
}

export {
  ObserverAdsAts
}
