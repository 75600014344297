
export default {
  name: 'RawHtml',
  props: {
    tagName: {
      type: String,
      default: 'div'
    },
    html: {
      type: String,
      default: ''
    },
    evtTrackingElement: {
      type: String,
      default: ''
    },
    evtTrackingAction: {
      type: String,
      default: ''
    },
    evtTrackingTeamName: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.registerEvents()
  },
  beforeDestroy () {
    this.removeEvents()
  },
  methods: {
    registerEvents () {
      const element = this.$el
      const tagA = element?.querySelectorAll('a')
      tagA.forEach((el) => {
        el.addEventListener('click', this.handleClick)
      })
    },
    removeEvents () {
      const element = this.$el
      const tagA = element?.querySelectorAll('a')
      tagA.forEach((elA) => {
        elA.removeEventListener('click', this.handleClick)
      })
    },
    handleClick (event) {
      event.stopPropagation()
      const tagA = event.currentTarget || event.target
      const data = tagA.dataset || {}
      this.handleEventClickTracking(data)
    },
    handleEventClickTracking (data) {
      const eleData = data || {}
      const {
        eventtracking,
        eventtrackingAction,
        eventtrackingLabel
      } = eleData
      const isEtk = eventtracking === 'true'
      const dataTeamName = this.evtTrackingTeamName
      const dataLabel = eventtrackingLabel // Data Label
      let dataAction = eventtrackingAction // Data Action(Section)
      if (this.evtTrackingAction) {
        dataAction = `${this.evtTrackingAction} ${eventtrackingAction}`
      }
      if (isEtk) {
        this.$personalized.clickElement({
          elementName: this.evtTrackingElement,
          title: dataLabel,
          sectionName: dataAction,
          teamName: dataTeamName
        })
      }
    }
  },
  render (h) {
    return h(this.tagName, {
      class: 'raw-html',
      domProps: {
        innerHTML: decodeURI(this.html)
      }
    })
  }
}
