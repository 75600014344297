//
//
//
//
//
//

export default {
  name: 'IconFavoriteQuote'
}
