import { formatNumber } from '@/helpers/number'

const state = () => ({
  marketList: [],
  underlyingList: [],
  stockList: [],
  instrumentClass: [],
  contactMonth: [],
  strikePrice: [],
  seriesList: [],
  chartSeries: [],
  chartIndex: [],
  chartStock: []
})

const mutations = {
  setMarketList (state, data) {
    state.marketList = data
  },

  setUnderlyingList (state, data) {
    state.underlyingList = data
  },

  setStockList (state, data) {
    state.stockList = data
  },

  setInstrumentClass (state, data) {
    state.instrumentClass = data
  },

  setContactMonth (state, data) {
    state.contactMonth = data
  },

  setStrikePrice (state, data) {
    state.strikePrice = data
  },

  setSeriesList (state, data) {
    state.seriesList = data
  },

  setChartSeries (state, data) {
    state.chartSeries = data
  },

  setChartIndex (state, data) {
    state.chartIndex = data
  },

  setChartStock (state, data) {
    state.chartStock = data
  }
}

const actions = {
  async getMarketList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/list')
      commit('setMarketList', response)
    } catch (error) {
      commit('setMarketList', [])
    }
  },

  async getUnderlyingList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/tfex/underlying/equity/list?underlyingType=I'
      )
      commit('setUnderlyingList', response)
    } catch (error) {
      commit('setUnderlyingList', [])
    }
  },

  async getStockList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/tfex/underlying/equity/list?underlyingType=S'
      )
      commit('setStockList', response)
    } catch (error) {
      commit('setStockList', [])
    }
  },

  async getInstrumentClass ({ commit }, payload) {
    const { marketListId = '' } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series-search/instrument?marketListId=${marketListId}`
      )
      if (response && response.instruments) {
        commit('setInstrumentClass', response.instruments)
        return response.instruments
      }
    } catch (error) {
      commit('setInstrumentClass', [])
      return []
    }
  },

  async getContactMonth ({ commit }, payload) {
    const { marketListId = '', instrumentId = '' } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series-search/contract-month?marketListId=${marketListId}&instrumentId=${instrumentId}`
      )
      commit('setContactMonth', response)
      return response
    } catch (error) {
      commit('setContactMonth', [])
      return []
    }
  },

  async getStrikePrice ({ commit }, payload) {
    const { marketListId = '', instrumentId = '', contractMonth = '' } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series-search/strike-price?marketListId=${marketListId}&instrumentId=${instrumentId}&contractMonth=${contractMonth}`
      )
      commit('setStrikePrice', response)
      return response
    } catch (error) {
      commit('setStrikePrice', [])
      return []
    }
  },

  async getSeriesList ({ commit }, payload) {
    const {
      marketListId = '',
      instrumentId = '',
      contractMonth = '',
      strikePrice = '',
      optionsType = ''
    } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series-search/series?marketListId=${marketListId}&instrumentId=${instrumentId}&contractMonth=${contractMonth}&strikePrice=${strikePrice}&optionsType=${optionsType}`
      )
      if (response && response.series) {
        commit('setSeriesList', response.series)
        return response.series
      }
    } catch (error) {
      commit('setSeriesList', [])
      return []
    }
  },

  async getChartSeries ({ commit }, payload) {
    const { series, period } = payload
    try {
      const chartSeries = await this.$axios.$get(
        `/api/set/tfex/series/${series}/chart-performance?period=${period}`
      )
      commit('setChartSeries', chartSeries)
      return chartSeries
    } catch (error) {
      commit('setChartSeries', null)
      return null
    }
  },

  async getChartIndex ({ commit }, payload) {
    const { index, period } = payload
    try {
      const chartIndex = await this.$axios.$get(
        `/api/set/index/${index}/chart-performance?period=${period}`
      )
      commit('setChartIndex', chartIndex)
      return chartIndex
    } catch (error) {
      commit('setChartIndex', null)
      return null
    }
  },

  async getChartStock ({ commit }, payload) {
    const { stock, period } = payload
    try {
      const chartStock = await this.$axios.$get(
        `/api/set/stock/${stock}/chart-performance?period=${period}`
      )
      commit('setChartStock', chartStock)
      return chartStock
    } catch (error) {
      commit('setChartStock', null)
      return null
    }
  },

  async getChartCompare ({ commit }, payload) {
    const { series, index = 'set50', stock, period } = payload
    try {
      const chartSeriesService = this.$axios.$get(
        `/api/set/tfex/series/${series}/chart-performance?period=${period}`
      )
      const chartIndexService = this.$axios.$get(
        `/api/set/index/series/${index}/chart-performance?period=${period}`
      )
      const chartStockService = this.$axios.$get(
        `/api/set/stock/series/${stock}/chart-performance?period=${period}`
      )

      const [
        { value: chartSeries },
        { value: chartIndex },
        { value: chartStock }
      ] = await Promise.allSettled([
        chartSeriesService,
        chartIndexService,
        chartStockService
      ])
      if (chartSeries && chartIndex && chartStock) {
        const performanceData = [
          ...[
            ...(chartSeries
              ? chartSeries.quotations.map((item, index, array) => {
                return {
                  datetime: item.datetime,
                  seriesTitle: series,
                  indexTitle: index,
                  stockTitle: stock,
                  series: item.price
                    ? formatNumber(item.price, 2)
                    : item.price
                }
              })
              : []),
            ...(chartIndex
              ? chartIndex.quotations.map((item, index, array) => {
                return {
                  datetime: item.datetime,
                  index: item.price
                    ? formatNumber(item.price, 2)
                    : item.price
                }
              })
              : []),
            ...(chartStock
              ? chartStock.quotations.map((item, index, array) => {
                return {
                  datetime: item.datetime,
                  stock: item.price
                    ? formatNumber(item.price, 2)
                    : item.price
                }
              })
              : [])
          ]
            .reduce(
              (cur, acc) =>
                cur.set(
                  acc.datetime,
                  Object.assign(
                    cur.get(acc.datetime) || {
                      datetime: null,
                      seriesTitle: null,
                      indexTitle: null,
                      stockTitle: null,
                      series: null,
                      index: null,
                      stock: null
                    },
                    acc
                  )
                ),
              new Map()
            )
            .values()
        ]
        commit('setSeriesList', performanceData)
      }
    } catch (error) {
      commit('setSeriesList', [])
    }
  }
}

const getters = {
  getMarketList: (state) => {
    return state.marketList
  },

  getUnderlyingList: (state) => {
    return state.underlyingList
  },

  getStockList: (state) => {
    return state.stockList
  },

  getInstrumentClass: (state) => {
    return state.instrumentClass
  },

  getContactMonth: (state) => {
    return state.contactMonth
  },

  getStrikePrice: (state) => {
    return state.strikePrice
  },

  getSeriesList: (state) => {
    return state.seriesList
  },

  getChartSeries: (state) => {
    return state.chartSeries
  },

  getChartIndex: (state) => {
    return state.chartIndex
  },

  getChartStock: (state) => {
    return state.chartStock
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
