
import { convertScriptToObject } from './helpers'
/**
 * call for set raw Script
 *
 * @param {Object} content
 * @return {*}
 */
const setScript = (content) => {
  const head = content.head
  const headEmbedScript = (head.embedScript || {})
  const headScriptWebsite = headEmbedScript.website || null // string script website head
  const headScriptPage = headEmbedScript.page || null // string script page head
  let result = []
  // set for website head
  if (headScriptWebsite) {
    result = [...result, ...convertScriptToObject(decodeURI(headScriptWebsite) || '')]
  }
  // set for page head
  if (headScriptPage) {
    result = [...result, ...convertScriptToObject(decodeURI(headScriptPage) || '')]
  }

  // set for body
  if (content.body) {
    const body = content.body
    const bodyEmbedScript = (body.embedScript || {})
    const bodyScriptWebsite = bodyEmbedScript.website || null // string script website body
    const bodyScriptPage = bodyEmbedScript.page || null // string script page body
    // set for website body
    if (bodyScriptWebsite) {
      result = [...result, ...convertScriptToObject(decodeURI(bodyScriptWebsite) || '', true)]
    }
    // set for page body
    if (bodyScriptPage) {
      result = [...result, ...convertScriptToObject(decodeURI(bodyScriptPage) || '', true)]
    }
  }
  // if (scriptWebsite) {
  //   const head = decodeURI(scriptWebsite.head) || null
  //   const body = decodeURI(scriptWebsite.body) || null
  //   if (head) {
  //     result = [...result, ...convertScriptToObject(head)]
  //   }
  //   if (body) {
  //     result = [...result, ...convertScriptToObject(body, true)]
  //   }
  // }
  // // set for page
  // if (embedScriptPage) {
  //   const head = decodeURI(embedScriptPage.head) || null
  //   const body = decodeURI(embedScriptPage.body) || null
  //   if (head) {
  //     result = [...result, ...convertScriptToObject(head)]
  //   }
  //   if (body) {
  //     result = [...result, ...convertScriptToObject(body, true)]
  //   }
  // }
  if (result.length) {
    return result
  }
  return []
}
export {
  setScript
}
