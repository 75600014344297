const getDefaultState = () => {
  return {
    stockList: [],
    instrumentType: [],
    instrumentClass: [],
    contactMonth: [],
    brokers: [],
    authors: [],
    ratingViewList: [],
    searchResult: {},
    researchDetail: {},
    customLocation: null
  }
}

const state = getDefaultState

const mutations = {
  setStockList (state, data) {
    state.stockList = data
  },

  setInstrumentType (state, data) {
    state.instrumentType = data
  },

  setInstrumentClass (state, data) {
    state.instrumentClass = data
  },

  setContactMonth (state, data) {
    state.contactMonth = data
  },

  setBrokers (state, data) {
    state.brokers = data
  },

  setAuthors (state, data) {
    state.authors = data
  },

  setRatingViewList (state, data) {
    state.ratingViewList = data
  },

  setSearchResult (state, data) {
    state.searchResult = data
  },

  setResearchDetail (state, data) {
    state.researchDetail = data
  },
  setWindowLocation (state, data) {
    state.customLocation = data
  }
}

const actions = {
  async getStockList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/series/list')

      commit('setStockList', response.series.filter(x => x.active))
    } catch (error) {
      commit('setStockList', [])
    }
  },

  async getInstrumentType ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/list')

      commit('setInstrumentType', response)
    } catch (error) {
      commit('setInstrumentType', [])
    }
  },

  async getInstrumentClass ({ commit }, payload) {
    const { marketListId } = payload || {}
    try {
      const response = await this.$axios.$get('/api/set/tfex/series-search/instrument', {
        params: {
          marketListId
        }
      })

      commit('setInstrumentClass', response.instruments)
    } catch (error) {
      commit('setInstrumentClass', [])
    }
  },

  async getContactMonth ({ commit }, payload) {
    const { marketListId } = payload || {}
    try {
      const response = await this.$axios.$get('/api/set/tfex/series-search/contract-month', {
        params: {
          marketListId
        }
      })

      commit('setContactMonth', response)
    } catch (error) {
      commit('setContactMonth', [])
    }
  },

  async getBrokers ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set-fund/consensus/internal/broker-list', {
        params: {
          lang: this.$i18n.locale
        }
      })

      commit('setBrokers', response.filter(x => x.isActive))
    } catch (error) {
      commit('setBrokers', [])
    }
  },

  async getAuthors ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set-fund/consensus/internal/analyst-name-list', {
        params: {
          lang: this.$i18n.locale
        }
      })

      commit('setAuthors', response.filter(x => x.isActive).map(x => x.fullName))
    } catch (error) {
      commit('setAuthors', [])
    }
  },

  async getSearchResult ({ commit }, payload) {
    const {
      symbol,
      marketListId,
      instrumentId,
      contractMonth,
      broker,
      analystName
    } = payload
    try {
      const response = await this.$axios.$get('/api/set-fund/consensus/tfex/list', {
        params: {
          lang: this.$i18n.locale,
          symbol,
          marketListId,
          instrumentId,
          contractMonth,
          broker,
          analystName
        }
      })

      commit('setSearchResult', response)
    } catch (error) {
      commit('setSearchResult', null)
    }
  },

  async getRatingViewList ({ commit }) {
    try {
      const response = await this.$apiCms.get('/api/cms/v1/research-settrade/iaa/rating-views', {
        params: {
          language: this.$i18n.locale
        }
      })

      commit('setRatingViewList', response.data)
    } catch (error) {
      commit('setRatingViewList', [])
    }
  },

  async getResearchDetail ({ commit }, id) {
    try {
      const result = await this.$axios.$get(
        `/api/set-fund/consensus/tfex/research/${id}/related-documents`
      )
      commit('setResearchDetail', result)
    } catch {
      commit('setResearchDetail', {})
    }
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
