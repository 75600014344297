/**
 * get Convert meta list to format vue meta
 *
 * @param {Array} customMetaTags
 * @return {*}
 */
const convertFormatMeta = (customMetaTags) => {
  if (customMetaTags) {
    const meta = customMetaTags.map((r) => {
      const metaProps = {}
      metaProps[r.metaType] = r.name
      metaProps.content = r.content
      return metaProps
    })
    return meta
  }
  return []
}

/**
 * convert string tag <script></script> to format vue-meta
 *
 * @param {String} dataEmbedWebsite
 * @param {Boolean} bodySet
 * @return {Array}
 */
const convertScriptToObject = (dataEmbedWebsite, bodySet = false) => {
  const res = dataEmbedWebsite.match(/<script.*?>([\s\S]*?)<\/script>/g)
  let scriptTag = ''
  let attributeArray = []
  let scriptData = {}
  const scriptArray = []
  if (res) {
    res.forEach(function (item) {
      scriptData = {}
      scriptData.innerHTML = item.replace(/<\/?script.*?>/g, '')
      scriptTag = item.match(/<script.*?>/g)
      attributeArray = scriptTag.join().match(/([a-zA-z]+(\s?)+=(\s?)+("|')(.*?)("|'))|defer|async/g)
      if (attributeArray != null) {
        attributeArray.forEach(function (itemAttr) {
          if (itemAttr === 'defer' || itemAttr === 'async') {
            scriptData[itemAttr] = true
          } else {
            scriptData[itemAttr.split('=')[0]] = itemAttr.split('=')[1].replace(/"|'/g, '')
          }
        })
      }
      if (bodySet === true) {
        scriptData.body = true
      }
      scriptArray.push(scriptData)
      scriptData = {}
    })
  }
  return scriptArray
}

/**
 * convert string tag <style></style> to format vue-meta
 *
 * @param {String} StyleCss
 * @return {Object}
 */
const convertStyleToObject = (StyleCss) => {
  const res = StyleCss.match(/<style.*?>([\s\S]*?)<\/style>/g)
  let styleData = {}
  let styleTag = []
  let attributeArray = []
  const styleArray = []
  if (res) {
    res.forEach(function (item) {
      styleData.cssText = item.replace(/<\/?style.*?>/g, '')
      styleTag = item.match(/<style.*?>/g)
      attributeArray = styleTag.join().match(/([a-zA-z]+(\s?)+=(\s?)+("|')(.*?)("|'))/g)
      if (attributeArray != null) {
        attributeArray.forEach(function (itemAttr) {
          styleData[itemAttr.split('=')[0]] = itemAttr.split('=')[1].replace(/"|'/g, '')
        })
      }
      styleArray.push(styleData)
      styleData = {}
    })
  }
  return styleArray
}

/**
 * Custom seo for Specificpage
 *
 * @param {Object} routerData
 * @return {*}
 */
const specificPageSeo = (routerData, i18n, optionLang = {}) => {
  const { locale } = i18n
  // const currentRouteName = routerData.name
  const path = decodeURIComponent(routerData.path)
  const slug = routerData.params.slug
  const currentRoutePath = path.replace('/' + locale, '').replace('/' + slug, '')
  // get data page defult th
  let langDataList = require('~/lang/specific-page/th.json')
  if (locale === 'en') {
    // get data page en
    langDataList = require('~/lang/specific-page/en.json')
  }
  let seoSpecific = null
  // condition for specificPage
  if (langDataList) {
    const listPage = langDataList.pages
    const temp = listPage.find((item) => {
      const { routePath, routeName } = item
      if (routeName) {
        const regx = new RegExp(routeName, 'i')
        return regx.test(routerData.name)
      }
      return routePath === currentRoutePath
    })
    const thumbnailPath = `${process.env.MEDIA_HOST}/common/settrade/thumbnail/`
    if (temp !== undefined) {
      const imageFileName = i18n.t(`pages.${temp.name}.thumbnail`, optionLang)
      let thumbnail = null
      if (imageFileName) {
        thumbnail = `${thumbnailPath}${imageFileName}`
      }
      seoSpecific = {
        title: i18n.t(`pages.${temp.name}.title`, optionLang),
        description: i18n.t(`pages.${temp.name}.description`, optionLang),
        keywords: i18n.t(`pages.${temp.name}.keywords`, optionLang),
        thumbnail
      }
    }
  }
  if (seoSpecific) {
    return seoSpecific
  }
  return null
}

const isGrayscale = (settings) => {
  if (settings && settings.grayscaleMode) {
    return 'gray-scale-mode'
  }
  return null
}

export {
  convertFormatMeta,
  convertScriptToObject,
  convertStyleToObject,
  specificPageSeo,
  isGrayscale
}
