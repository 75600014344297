import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/th'
const buddhistEra = require('dayjs/plugin/buddhistEra')
const isBetween = require('dayjs/plugin/isBetween')
const dayOfYear = require('dayjs/plugin/dayOfYear')
const { timezone: defaultTimezone } = require('@/config/datetime')
dayjs.extend(dayOfYear)
dayjs.extend(isBetween)
dayjs.extend(buddhistEra)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(defaultTimezone)
export default dayjs
