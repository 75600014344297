//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AdvertisementLightbox',
  data () {
    return {
      loadComplete: false,
      mouseout: false
    }
  },
  mounted () {
    window.addEventListener('mouseout', this.handleShowBanner)
    this.$refs.lightboxImg.addEventListener('load', this.handleLoadImage)
  },
  beforeDestroy () {
    window.removeEventListener('mouseout', this.handleShowBanner)
    this.$refs.lightboxImg.removeEventListener('load', this.handleLoadImage)
  },
  methods: {
    handleShowBanner (event) {
      if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) { // when mouse out be return null
        this.mouseout = true
        if (this.loadComplete) {
          this.showBanner()
        }
      }
    },
    handleLoadImage () {
      this.loadComplete = true
      if (this.mouseout) {
        this.showBanner()
      }
    },
    showBanner () {
      if (this.$cookies.get('display-exit-ad')) {
        this.$refs.lightbox.classList.add('show')
      }
    },
    closeModal (event) {
      // this.$bvModal.hide('modal-lightbox')
      this.mouseout = false
      this.$refs.lightbox.classList.remove('show')
      this.$cookies.set('display-exit-ad', false, { expires: new Date(Number(new Date()) + 1 * 1000 * 60 * 60 * 24) })
    }
  }
}
