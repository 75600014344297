// import https from 'https'

const state = () => ({
  snapshotList: null,
  industrySet: [],
  industryMai: [],
  indexSet: [],
  indexMai: [],
  stockList: []
})
const mutations = {
  setSnapshotList (state, data) {
    state.snapshotList = data
  },
  setIndustrySet (state, data) {
    state.industrySet = data
  },
  setIndustryMai (state, data) {
    state.industryMai = data
  },
  setIndexSet (state, data) {
    state.indexSet = data
  },
  setIndexMai (state, data) {
    state.indexMai = data
  },
  setStockList (state, data) {
    state.stockList = data
  }
}

const actions = {
  async getSnapshotList ({ commit }, payload) {
    const { symbols, market, sector, type, index } = payload

    try {
      const response = await this.$axios.$get(
        '/api/set/company-snapshot/list',
        {
          params: {
            symbols,
            productType: type,
            market,
            sector,
            index,
            lang: this.$i18n.locale
          }
        }
      )
      commit('setSnapshotList', response)
    } catch {
      commit('setSnapshotList', null)
    }
  },
  async getIndustrys ({ commit }, payload) {
    try {
      const { market } = payload
      const response = await this.$axios.$get(
        `/api/set/index/list?type=industry&market=${market}`
      )
      if (response) {
        const items = response.map((data, index) => {
          return {
            label: data.symbol,
            value: data.querySymbol,
            select: false,
            indent: data.level === 'SECTOR',
            ...data
          }
        })
        if (market === 'SET') {
          commit('setIndustrySet', items)
        } else {
          commit('setIndustryMai', items)
        }
      } else {
        commit('setIndustrySet', null)
        commit('setIndustryMai', null)
      }
    } catch {
      commit('setIndustrySet', null)
      commit('setIndustryMai', null)
    }
  },
  async getIndexs ({ commit }, payload) {
    try {
      const { market } = payload
      const response = await this.$axios.$get(
        `/api/set/index/list?type=index&market=${market}`
      )
      if (response) {
        const items = response.map((data, index) => {
          return {
            label: data.symbol,
            value: data.querySymbol
          }
        })
        if (market === 'SET') {
          commit('setIndexSet', items)
        } else {
          commit('setIndexMai', items)
        }
      } else {
        commit('setIndexSet', null)
        commit('setIndexMai', null)
      }
    } catch {
      commit('setIndexSet', null)
      commit('setIndexMai', null)
    }
  },
  async getStockList ({ commit }) {
    try {
      await this.$axios
        .$get('/api/set/stock/list', {
          params: {
            securityType: 's'
          }
        })
        .then((res) => {
          commit('setStockList', res.securitySymbols)
        })
    } catch {
      commit('setStockList', null)
    }
  }
}

export default {
  state,
  actions,
  mutations
}
