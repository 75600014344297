import { mapState } from 'vuex'

export const type = {
  SummaryOfAnalystConsensus: 'SummaryOfAnalystConsensus',
  DailyUpdateArticles: 'DailyUpdateArticles',
  // ViewByProject: 'ViewByProject',
  Set50FinancialRatioEstimation: 'Set50FinancialRatioEstimation',
  NetProfitAndValuation: 'NetProfitAndValuation',
  GroupBySector: 'GroupBySector',
  GroupByBroker: 'GroupByBroker'
}

export const types = Object.freeze(Object.keys(type).map(x => type[x]))

export const metaData = new Map([
  [
    type.SummaryOfAnalystConsensus,
    {
      text: 'สรุปความเห็นนักวิเคราะห์',
      filterComponent: () =>
        import(
          '~/components/Pages/Research/IAAConsensus/SummaryOfAnalystConsensus/Filter.vue'
        ),
      tableComponent: () =>
        import(
          '~/components/Pages/Research/IAAConsensus/SummaryOfAnalystConsensus/Table.vue'
        )
    }
  ],
  [
    type.DailyUpdateArticles,
    {
      text: 'นักวิเคราะห์ Update ประจำวัน',
      filterComponent: () =>
        import(
          '~/components/Pages/Research/IAAConsensus/DailyUpdateArticles/Filter.vue'
        ),
      tableComponent: () =>
        import(
          '~/components/Pages/Research/IAAConsensus/DailyUpdateArticles/Table.vue'
        )
    }
  ],
  // [
  //   type.ViewByProject,
  //   {
  //     text: 'โครงการจัดทำบทวิเคราะห์สำหรับผู้ลงทุน',
  //     filterComponent: () => import('~/components/Pages/Research/IAAConsensus/ViewByProject/Filter.vue'),
  //     tableComponent: () => import('~/components/Pages/Research/IAAConsensus/ViewByProject/Table.vue')
  //   }
  // ],
  [
    type.Set50FinancialRatioEstimation,
    {
      text: 'ตัวเลขสำคัญทางการเงินของหุ้นใน SET50',
      filterComponent: () =>
        import(
          '~/components/Pages/Research/IAAConsensus/Set50FinancialRatioEstimation/Filter.vue'
        ),
      tableComponent: () =>
        import(
          '~/components/Pages/Research/IAAConsensus/Set50FinancialRatioEstimation/Table.vue'
        )
    }
  ],
  [
    type.NetProfitAndValuation,
    {
      text: 'ประมาณการ Net Profit และ Valuation',
      filterComponent: () => import('~/components/Pages/Research/IAAConsensus/NetProfitAndValuation/Filter.vue'),
      tableComponent: () => import('~/components/Pages/Research/IAAConsensus/NetProfitAndValuation/Table.vue')
    }
  ],
  [
    type.GroupBySector,
    {
      text: 'รายชื่อและจำนวนหุ้น',
      filterComponent: () => import('~/components/Pages/Research/IAAConsensus/GroupBySector/Filter.vue'),
      tableComponent: () => import('~/components/Pages/Research/IAAConsensus/GroupBySector/Table.vue')
    }
  ],
  [
    type.GroupByBroker,
    {
      text: 'โบรกเกอร์และจำนวนหุ้นที่ Update ใน 3 เดือน',
      filterComponent: () => import('~/components/Pages/Research/IAAConsensus/GroupByBroker/Filter.vue'),
      tableComponent: () => import('~/components/Pages/Research/IAAConsensus/GroupByBroker/Table.vue')
    }
  ]
])

export function createEmptyFilterObject () {
  return {
    symbol: undefined,
    type: type.SummaryOfAnalystConsensus,
    index: undefined,
    securityType: undefined,
    broker: undefined,
    author: undefined
  }
}

export const mixins = {
  indexOptions: {
    computed: {
      ...mapState('research/iaa-consensus', {
        _indexList: state => state.indexList
      }),
      indexOptions () {
        const index = buildIndexTree(this._indexList)
        return [
          { options: [{ label: 'ทั้งหมด', value: undefined }] },
          ...index.map(x => ({
            header: x.symbol,
            options: [...enumerateAll(x)].map(y => ({
              label: y.level === 'INDEX' ? 'ทั้งหมด' : y.symbol,
              value: y.querySymbol,
              indent: y.level === 'SECTOR'
            }))
          }))
        ]

        function buildIndexTree (indexList) {
          const indices = indexList.map(x => ({
            symbol: x.symbol,
            level: x.level,
            name: x.nameEN,
            querySymbol: x.querySymbol,
            children: []
          }))
          const indexLookup = new Map(indices.map(x => [x.querySymbol, x]))
          for (const index of indexList.filter(x => x.parentIndex)) {
            const parent = indexLookup.get(index.parentIndex)
            parent.children.push(indexLookup.get(index.querySymbol))
          }
          return indices.filter(x => x.level === 'INDEX')
        }

        function* enumerateAll (item) {
          yield item
          for (const child of item.children) {
            yield * enumerateAll(child)
          }
        }
      }
    },
    methods: {
      findIndexOption (value) {
        return this.indexOptions
          .flatMap(x => x.options)
          .find(x => x.value === value)
      }
    }
  },
  helpers: {
    methods: {
      sortBy (key, locale = 'th') {
        return (a, b) => a[key].localeCompare(b[key], locale)
      }
    }
  }
}
