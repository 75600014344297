//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconArrowTop'
}
