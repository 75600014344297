import { dateFormat, formatDateShort } from '@/helpers/dateTime'
import {
  setDwSearchEgListLabel,
  setDwSearchTimeToMaturitiesLabel,
  setMoneynessStatusLabel
} from '@/helpers/utilities'

const getDefaultState = () => {
  return {
    dwOverview: {},
    dwTradingSummary: {
      totalValue: {},
      indexValue: {},
      stockValue: {}
    },
    dwMostActive: {},
    dwMostActiveValue: {},
    dwMostActiveVolume: {},
    dwTopGainer: {},
    dwTopLoser: {},
    newListDw: {},
    expireListDw: {},
    underlyingList: [],
    effectiveList: [],
    issuerList: [],
    contractMonthList: [],
    ttmList: [],
    moneyStatusList: [],
    dwInfo: null,
    dwList: [],
    dwOutStanding: {},
    dwCalculator: null,
    dwNativeList: {},
    mmPerformance: [],
    dwMonthly: [],
    dwIssuerList: [],
    dwPopular: {}
  }
}

const state = getDefaultState

const mutations = {
  setDwOverview (state, data) {
    state.dwOverview = data
  },
  setDwTradingSummary (state, data) {
    state.dwTradingSummary = data
  },
  setDwMostActive (state, data) {
    state.dwMostActive = data
  },
  setDwMostActiveValue (state, data) {
    state.dwMostActiveValue = data
  },
  setDwMostActiveVolume (state, data) {
    state.dwMostActiveVolume = data
  },
  setDwTopGainer (state, data) {
    state.dwTopGainer = data
  },
  setDwTopLoser (state, data) {
    state.dwTopLoser = data
  },
  setNewListDw (state, data) {
    state.newListDw = data
  },
  setExpireListDw (state, data) {
    state.expireListDw = data
  },
  setUnderlyingList (state, data) {
    state.underlyingList = data
  },
  setEffectiveList (state, data) {
    state.effectiveList = data
  },
  setIssuerList (state, data) {
    state.issuerList = data
  },
  setContractMonthList (state, data) {
    state.contractMonthList = data
  },
  setTTMList (state, data) {
    state.ttmList = data
  },
  setMoneyStatusList (state, data) {
    state.moneyStatusList = data
  },
  setDwInfo (state, data) {
    state.dwInfo = data
  },
  setDwList (state, data) {
    state.dwList = data
  },
  setDwCalculator (state, data) {
    state.dwCalculator = data
  },
  setDwOutStanding (state, data) {
    state.dwOutStanding = data
  },
  setDwNativeList (state, data) {
    state.dwNativeList = data
  },
  setMMPerformance (state, data) {
    state.mmPerformance = data
  },
  setDWMonthly (state, data) {
    state.dwMonthly = data
  },
  setDWIssuerList (state, data) {
    state.dwIssuerList = data
  },
  setDWPopular (state, data) {
    state.dwPopular = data
  }
}

const actions = {
  async getDwOverview ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/dw-overview/overview')
      commit('setDwOverview', response)
    } catch {
      commit('setDwOverview', null)
    }
  },
  async getDwTradingSummary ({ commit }, payload) {
    try {
      let path = '/api/set/dw-overview/trading-summary?'
      if (payload && payload.underlyingNative) {
        path += `underlyingNative=${payload.underlyingNative}&`
      }
      if (payload && payload.market) {
        path += `underlyingType=${payload.market}&`
      }
      // if (payload && payload.dwType) {
      //   path += `dwType=${payload.dwType}&`
      // }
      const response = await this.$axios.$get(path)
      commit('setDwTradingSummary', response)
    } catch {
      commit('setDwTradingSummary', null)
    }
  },
  async getDwMostActive ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/dw-underlying/mostactive?limit=10'
      )
      commit('setDwMostActive', response)
    } catch {
      commit('setDwMostActive', null)
    }
  },
  async getDwMostActiveValue ({ commit, dispatch }, payload) {
    try {
      let path = '/api/set/dw-info/ranking/mostActiveValue?limit=10&'
      if (payload && payload.underlyingType) {
        path += `underlyingType=${payload.underlyingType}&`
      }
      if (payload && payload.underlyingNative) {
        path += `underlyingNative=${payload.underlyingNative}&`
      }
      if (payload && payload.dwType) {
        path += `dwType=${payload.dwType}&`
      }
      const response = await this.$axios.$get(path)
      if (payload && payload.isChartData) {
        if (response.dwList) {
          for (let i = 0; i < response.dwList.length; i++) {
            const Chart = await dispatch(
              'getQuotationChart',
              response.dwList[i].symbol
            )
            response.dwList[i].dayChart = Chart
          }
        }
      }
      commit('setDwMostActiveValue', response)
      return response
    } catch {
      commit('setDwMostActiveValue', null)
      return null
    }
  },
  async getDwMostActiveVolume ({ commit, dispatch }, payload) {
    try {
      let path = '/api/set/dw-info/ranking/mostActiveVolume?limit=10&'
      if (payload && payload.underlyingType) {
        path += `underlyingType=${payload.underlyingType}&`
      }
      if (payload && payload.underlyingNative) {
        path += `underlyingNative=${payload.underlyingNative}&`
      }
      if (payload && payload.dwType) {
        path += `dwType=${payload.dwType}&`
      }
      const response = await this.$axios.$get(path)
      if (payload && payload.isChartData) {
        if (response.dwList) {
          for (let i = 0; i < response.dwList.length; i++) {
            const Chart = await dispatch(
              'getQuotationChart',
              response.dwList[i].symbol
            )
            response.dwList[i].dayChart = Chart
          }
        }
      }
      commit('setDwMostActiveVolume', response)
      return response
    } catch {
      commit('setDwMostActiveVolume', null)
      return null
    }
  },
  async getDwTopGainer ({ commit, dispatch }, payload) {
    try {
      let path = '/api/set/dw-info/ranking/topGainer?limit=10&'
      if (payload && payload.underlyingType) {
        path += `underlyingType=${payload.underlyingType}&`
      }
      if (payload && payload.underlyingNative) {
        path += `underlyingNative=${payload.underlyingNative}&`
      }
      if (payload && payload.dwType) {
        path += `dwType=${payload.dwType}&`
      }
      const response = await this.$axios.$get(path)
      if (payload && payload.isChartData) {
        if (response.dwList) {
          for (let i = 0; i < response.dwList.length; i++) {
            const Chart = await dispatch(
              'getQuotationChart',
              response.dwList[i].symbol
            )
            response.dwList[i].dayChart = Chart
          }
        }
      }
      commit('setDwTopGainer', response)
      return response
    } catch {
      commit('setDwTopGainer', null)
      return null
    }
  },
  async getDwTopLoser ({ commit, dispatch }, payload) {
    try {
      let path = '/api/set/dw-info/ranking/topLoser?limit=10&'
      if (payload && payload.underlyingType) {
        path += `underlyingType=${payload.underlyingType}&`
      }
      if (payload && payload.underlyingNative) {
        path += `underlyingNative=${payload.underlyingNative}&`
      }
      if (payload && payload.dwType) {
        path += `dwType=${payload.dwType}&`
      }
      const response = await this.$axios.$get(path)
      if (payload && payload.isChartData) {
        if (response.dwList) {
          for (let i = 0; i < response.dwList.length; i++) {
            const Chart = await dispatch(
              'getQuotationChart',
              response.dwList[i].symbol
            )
            response.dwList[i].dayChart = Chart
          }
        }
      }
      commit('setDwTopLoser', response)
      return response
    } catch {
      commit('setDwTopLoser', null)
      return null
    }
  },
  async getQuotationChart ({ commit }, symbol) {
    try {
      let response = await this.$axios.$get(
        `/api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=false`
      )
      const quotationChart = response ?? {
        prior: 0,
        quotations: []
      }
      response = {
        ...quotationChart,
        quotations: quotationChart.quotations.map(x => ({
          ...x,
          datetime: new Date(x.datetime)
        }))
      }
      return response
    } catch (err) {
      return null
    }
  },
  async getNewListDw ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/dw-info/new-listed')
      commit('setNewListDw', response)
    } catch {
      commit('setNewListDw', null)
    }
  },
  async getExpireListDw ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/dw-info/near-expired-list'
      )
      commit('setExpireListDw', response)
    } catch {
      commit('setExpireListDw', null)
    }
  },
  async getUnderlyingList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/dw-fundamental/underlying/list'
      )
      const option = response.underlyingList.map((data) => {
        return {
          value: data.symbol,
          label: data.symbol
        }
      })
      commit('setUnderlyingList', option)
    } catch {
      commit('setUnderlyingList', null)
    }
  },
  async getEffectiveList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/dw-fundamental/effective-gearing/list'
      )
      const option = response.egList.map((data) => {
        return {
          value: data.id,
          label: setDwSearchEgListLabel(data.id)
        }
      })
      commit('setEffectiveList', option)
    } catch {
      commit('setEffectiveList', null)
    }
  },
  async getIssuerList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/dw-fundamental/issuer/list'
      )
      const option = response.issuerList.map((data) => {
        return {
          value: data.id.toString(),
          label: data.symbol
        }
      })
      commit('setIssuerList', option)
    } catch {
      commit('setIssuerList', null)
    }
  },
  async getContractMonthList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/dw-fundamental/contract-month/list'
      )
      const option = response.contractMonthList.map((data) => {
        return {
          value: dateFormat(data.date, 'EN'),
          label: formatDateShort(data.date).substring(1)
        }
      })
      commit('setContractMonthList', option)
    } catch {
      commit('setContractMonthList', null)
    }
  },
  async getTTMList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/dw-fundamental/ttm/list'
      )
      const option = response.timeToMaturities.map((data) => {
        return {
          value: data.value,
          label: setDwSearchTimeToMaturitiesLabel(data.value)
        }
      })
      commit('setTTMList', option)
    } catch {
      commit('setTTMList', null)
    }
  },
  async getMoneyStatusList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/dw-fundamental/moneyness-status/list'
      )
      const option = response.moneynessPeriodList.map((data) => {
        return {
          value: data.id,
          label: setMoneynessStatusLabel(data.id)
        }
      })
      commit('setMoneyStatusList', option)
    } catch {
      commit('setMoneyStatusList', null)
    }
  },
  async getDwInfo ({ commit }, payload) {
    const { symbol } = payload
    try {
      const response = await this.$axios.$get(`/api/set/dw/${symbol}/info`)
      commit('setDwInfo', response)
    } catch {
      commit('setDwInfo', null)
    }
  },
  async getDwList ({ commit }, payload) {
    const {
      underlyingSymbol,
      symbol,
      underlyingType,
      underlyingNative,
      dwType,
      issuerId,
      effectiveGearing,
      ttm,
      moneyness,
      contractMonth,
      minRightPrice,
      maxRightPrice,
      minPercentOutstanding,
      maxPercentOutstanding,
      minIV,
      maxIV
    } = payload
    let urlPath = '/api/set/dw-info/list?'
    urlPath += symbol ? `symbol=${symbol}&` : ''
    urlPath += underlyingType ? `underlyingType=${underlyingType}&` : ''
    urlPath += underlyingNative ? `underlyingNative=${underlyingNative}&` : ''
    urlPath += dwType ? `dwType=${dwType}&` : ''
    urlPath += underlyingSymbol ? `underlyingSymbol=${underlyingSymbol}&` : ''
    urlPath += issuerId ? `issuerId=${issuerId}&` : ''
    urlPath += effectiveGearing ? `effectiveGearing=${effectiveGearing}&` : ''
    urlPath += ttm ? `ttm=${ttm}&` : ''
    urlPath += moneyness ? `moneyness=${moneyness}&` : ''
    urlPath += contractMonth ? `contractMonth=${contractMonth}&` : ''
    urlPath +=
      minRightPrice || minRightPrice === 0
        ? `minRightPrice=${minRightPrice}&`
        : ''
    urlPath +=
      maxRightPrice || maxRightPrice === 0
        ? `maxRightPrice=${maxRightPrice}&`
        : ''
    urlPath +=
      minPercentOutstanding || minPercentOutstanding === 0
        ? `minPercentOutstanding=${minPercentOutstanding}&`
        : ''
    urlPath +=
      maxPercentOutstanding || maxPercentOutstanding === 0
        ? `maxPercentOutstanding=${maxPercentOutstanding}&`
        : ''
    urlPath += minIV || minIV === 0 ? `minIV=${minIV}&` : ''
    urlPath += maxIV || maxIV === 0 ? `maxIV=${maxIV}&` : ''

    try {
      const response = await this.$axios.$get(urlPath)
      if (!response.error) {
        commit('setDwList', response)
      } else {
        commit('setDwList', null)
      }
    } catch (err) {
      commit('setDwList', null)
    }
  },
  async getDwCalculator ({ commit }, payload) {
    const {
      symbol = '',
      underlyingPrice = '',
      volatility = '',
      calculateDate = '',
      interestRate = '',
      exchangeRate = '',
      dividendYield = '',
      isCalendarDay = ''
    } = payload
    let urlPath = `/api/set/dw/${symbol}/calculator?`
    urlPath += `underlyingPrice=${underlyingPrice}&`
    urlPath += `volatility=${volatility}&`
    urlPath += `calculateDate=${calculateDate}&`
    urlPath += `interestRate=${interestRate}&`
    urlPath += `exchangeRate=${exchangeRate}&`
    urlPath += `dividendYield=${dividendYield}&`
    urlPath += `isCalendarDay=${isCalendarDay}&`
    try {
      const response = await this.$axios.$get(urlPath)
      commit('setDwCalculator', response)
      return response
    } catch {
      commit('setDwCalculator', null)
      return null
    }
  },
  async getDwOutStanding ({ commit }, payload) {
    const { underlyings, issuerIds, dwType } = payload
    let urlPath = '/api/set/dw-outstanding/list?'
    urlPath += underlyings ? `underlyings=${underlyings}&` : ''
    urlPath += issuerIds ? `issuerIds=${issuerIds}&` : ''
    urlPath += dwType ? `dwType=${dwType}&` : ''
    try {
      const response = await this.$axios.$get(urlPath)
      commit('setDwOutStanding', response)
    } catch {
      commit('setDwOutStanding', null)
    }
  },
  async getDwNativeList ({ commit }, payload) {
    const { underlyingType, dwType } = payload
    let urlPath = '/api/set/dw-info/list?underlyingNative=F&'
    urlPath += underlyingType ? `underlyingType=${underlyingType}&` : ''
    urlPath += dwType ? `dwType=${dwType}&` : ''
    try {
      const response = await this.$axios.$get(urlPath)
      if (!response.error) {
        commit('setDwNativeList', response)
      } else {
        commit('setDwNativeList', {})
      }
    } catch (err) {
      commit('setDwNativeList', {})
    }
  },
  async getMMPerformance ({ commit }, payload) {
    let urlPath = '/api/set/dw-issuer/mm-performance/list?'
    urlPath += payload && payload.issuer ? `issuers=${payload.issuer}&` : ''
    urlPath += payload && payload.date ? `fromDate=${payload.date}&` : ''
    urlPath += payload && payload.date ? `toDate=${payload.date}&` : ''
    urlPath += payload && payload.lang ? `lang=${payload.lang}&` : ''
    try {
      const response = await this.$axios.$get(urlPath)
      if (!response.error) {
        commit('setMMPerformance', response)
      } else {
        commit('setMMPerformance', null)
      }
    } catch (err) {
      commit('setMMPerformance', null)
    }
  },
  async getDWMonthly ({ commit }) {
    const urlPath = '/api/set/report/monthly/product/dw/list'
    try {
      const response = await this.$axios.$get(urlPath)
      if (!response.error) {
        commit('setDWMonthly', response)
      } else {
        commit('setDWMonthly', null)
      }
    } catch (err) {
      commit('setDWMonthly', null)
    }
  },
  async getDWIssuerList ({ commit }) {
    const urlPath = '/api/set/dw-issuer/list'
    try {
      const response = await this.$axios.$get(urlPath)
      if (!response.error) {
        commit('setDWIssuerList', response)
      } else {
        commit('setDWIssuerList', null)
      }
    } catch (err) {
      commit('setDWIssuerList', null)
    }
  },
  async getDWPopular ({ commit, dispatch, state }, payload) {
    try {
      let path =
        '/api/cms/v1/popularquote/getpopular?market=SET&securityType=V&'
      if (payload && payload.underlyingType) {
        path += `underlyingType=${payload.underlyingType}&`
      }
      if (payload && payload.underlyingNative) {
        path += `underlyingNative=${payload.underlyingNative}&`
      }
      if (payload && payload.dwType) {
        path += `dwType=${payload.dwType}&`
      }
      const response = await this.$apiCms.get(path)
      let dwList = response.data.map((data) => {
        const dwItem = state.dwList.dwList.find((dataFind) => {
          return dataFind.symbol === data.symbol
        })
        return {
          ...data,
          dwType: dwItem ? dwItem.dwType : null
        }
      })
      if (payload && payload.dwType) {
        dwList = dwList.filter((data) => {
          return data.dwType === payload.dwType
        })
      }
      if (dwList) {
        for (let i = 0; i < dwList.length; i++) {
          const Chart = await dispatch('getQuotationChart', dwList[i].symbol)
          dwList[i].dayChart = Chart
        }
      }
      commit('setDWPopular', dwList)
      return dwList
    } catch (err) {
      commit('setDWPopular', null)
      return null
    }
  }
}

const getters = {
  getDwOverview: (state) => {
    return state.dwOverview
  },
  getDwTradingSummary: (state) => {
    return state.dwTradingSummary
  },
  getDwMostActive: (state) => {
    return state.dwMostActive
  },
  getDwMostActiveValue: (state) => {
    return state.dwMostActiveValue
  },
  getDwMostActiveVolume: (state) => {
    return state.dwMostActiveVolume
  },
  getDwTopGainer: (state) => {
    return state.dwTopGainer
  },
  getDwTopLoser: (state) => {
    return state.dwTopLoser
  },
  getNewListDw: (state) => {
    return state.newListDw
  },
  getExpireListDw: (state) => {
    return state.expireListDw
  },
  getUnderlyingList (state) {
    return state.underlyingList
  },
  getEffectiveList (state) {
    return state.effectiveList
  },
  getIssuerList (state) {
    return state.issuerList
  },
  getContractMonthList (state) {
    return state.contractMonthList
  },
  getTTMList (state) {
    return state.ttmList
  },
  getMoneyStatusList (state) {
    return state.moneyStatusList
  },
  getDwInfo (state) {
    return state.dwInfo
  },
  getDwList (state) {
    return state.dwList
  },
  getDwCalculator (state) {
    return state.dwCalculator
  },
  getDwOutStanding (state) {
    return state.dwOutStanding
  },
  getDwNativeList (state) {
    return state.dwNativeList
  },
  getMMPerformance (state) {
    return state.mmPerformance
  },
  getDWMonthly (state) {
    return state.dwMonthly
  },
  getDWIssuerList (state) {
    return state.dwIssuerList
  },
  getDWPopular (state) {
    return state.dwPopular
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
