import * as api from '~/helpers/mutualfund/api.js'

const getDefaultState = () => {
  return {
    activeFundList: [],
    fundTypeList: [],
    etfList: [],
    committedSymbols: [],
    performance: [],
    information: [],
    assetAllocation: [],
    fee: [],
    loading: false
  }
}

const state = getDefaultState

const mutations = {
  setActiveFundList (state, data) {
    state.activeFundList = data
  },
  setFundTypeList (state, data) {
    state.fundTypeList = data
  },
  setETFList (state, data) {
    state.etfList = data
  },
  setCommittedSymbols (state, data) {
    state.committedSymbols = data
  },
  setPerformance (state, data) {
    state.performance = data
  },
  setInformation (state, data) {
    state.information = data
  },
  setAssetAllocation (state, data) {
    state.assetAllocation = data
  },
  setFee (state, data) {
    state.fee = data
  },
  setLoading (state, data) {
    state.loading = data
  }
}

const actions = {
  async getActiveFundList ({ commit }) {
    try {
      const response = await api.getActiveFundList.call(this)
      commit('setActiveFundList', response)
    } catch {
      commit('setActiveFundList', [])
    }
  },
  async getFundTypeList ({ commit }) {
    try {
      const response = await api.getFundTypeList.call(this)
      commit('setFundTypeList', response)
    } catch {
      commit('setFundTypeList', [])
    }
  },
  async getETFList ({ commit }) {
    try {
      const response = await api.getETFList.call(this)
      commit('setETFList', response)
    } catch {
      commit('setETFList', [])
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
