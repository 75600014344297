//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { firstTextHighlight } from '~/plugins/text-highlight'
import { getFavouriteCategoryType } from '@/helpers/favourite/helpers'
import { formatSecurityType } from '@/helpers/utilities'

export default {
  name: 'MiscQuoteModalAddQuote',
  props: {
    securityType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      symbol: '',
      error: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      stocks: 'search/getStocks'
    }),
    favourite () {
      return this.$favourite?.state ?? {}
    },
    listSearchData () {
      return this.stocks.filter((data) => {
        return getFavouriteCategoryType(data.securityType) === this.securityType.toLowerCase()
      })
    },
    excludeListSearch () {
      return this.favourite[this.securityType] ?? []
    }
  },
  methods: {
    formatSecurityType,
    openModal () {
      this.symbol = ''
      this.error = false
      const modal = this.$refs.modal
      modal.show()
    },
    hideModal () {
      this.$refs.modal.hide()
      this.symbol = ''
      this.error = false
    },
    async addQuote () {
      // this.error = !this.error
      this.loading = true
      const inputSearchStock = this.$refs.inputSearchStock
      let securityType = ''
      if (inputSearchStock) {
        securityType = inputSearchStock.getSecurityType()
      }
      if (securityType) {
        const result = await this.$favourite.addFavourite(getFavouriteCategoryType(securityType), this.symbol)
        if (result.status === 201) {
          this.$toast.success('success', 'เพิ่มหลักทรัพย์ลงรายการที่ติดตามแล้ว')
          this.$personalized.clickElement({
            name: 'myQuote6',
            optionLang: {
              symbol: this.symbol
            }
          })
          this.hideModal()
        } else if (result.status === 400 && result.message === 'List full') {
          this.hideModal()
          this.$favourite.openModalFavSymbolFull()
        } else { // in other cases like Duplicate symbol or case status === 403 || 500
          this.$toast.fail('warning', 'เกิดข้อผิดพลาด')
        }
      }
      this.loading = false
    },
    highlight (content) {
      return firstTextHighlight(this.symbol, content)
    }
  }
}
