//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
export default {
  name: 'MiscEquityMarketInputSelect',
  components: {
    BDropdown,
    BDropdownItem
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [Number, String, Object],
      default: () => null
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showSearchFilter: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    placeholderSearch: {
      type: String,
      default: ''
    },
    tooltipTitle: {
      type: String,
      default: null
    },
    textNotFound: {
      type: String,
      default: 'ไม่พบผลลัพธ์ที่ค้นหา'
    }
  },
  data () {
    return {
      elementId: parseInt((Math.random() * 1000).toFixed()),
      textSearch: '',
      active: false
    }
  },
  computed: {
    labelSelect () {
      for (const data of this.options) {
        if (data.value !== undefined && data.value === this.value) {
          return data.label
        }
        if (data.children) {
          for (const dataChildren of data.children) {
            if (dataChildren.value !== undefined && dataChildren.value === this.value) {
              return dataChildren.label
            }
          }
        }
      }
      return ''
    },
    optionsFilter () {
      if (this.$refs.inputSelect) {
        this.$refs.inputSelect.updatePopper()
      }
      let options = cloneDeep(this.options)
      options = options.map((data) => {
        if (data && this.textSearch) {
          const parentLabel = data.label && data.label.toUpperCase().startsWith(this.textSearch.toUpperCase())
          if (!parentLabel && data && data.children) {
            data.children = data.children.filter((dataChild) => {
              return dataChild.label && dataChild.label.toUpperCase().startsWith(this.textSearch.toUpperCase())
            })
          }
        }
        return data
      })
      return options.filter((data) => {
        if (data && this.textSearch) {
          const parentLabel = data.label && data.label.toUpperCase().startsWith(this.textSearch.toUpperCase())
          if (!parentLabel && data && data.children) {
            return data.children.length
          }
          return parentLabel
        } else {
          return true
        }
      })
    }
  },
  mounted () {
    this.initTooltip()
  },
  methods: {
    selectOption (option) {
      if (option.type === 'button') {
        option.onSelect()
      } else {
        this.$emit('change', option.value)
        this.$emit('input', option.value)
      }
    },
    onShow () {
      this.active = false
      this.$emit('onShow')
    },
    onHide () {
      this.active = true
      this.textSearch = ''
      this.$emit('onHide')
    },
    initTooltip () {
      setTimeout(() => {
        const tooltipTriggerEl = this.$refs.tooltipInput
        if (tooltipTriggerEl) {
          const { Tooltip } = require('bootstrap')
          this.tooltip = new Tooltip(tooltipTriggerEl)
          this.tooltip._config.template = '<div class="tooltip custom-tooltip tooltip-input" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
        }
      }, 500)
    }
  }
}
