export function createPortfolio ({ category, name }) {
  return this.$apiMember.$post('/api/set-fund/virtualport/port/create', {
    category,
    name
  })
    .then(() => true)
    .catch(() => false)
}

export function editPortfolioName (portfolioId, name) {
  return this.$apiMember.$post('/api/set-fund/virtualport/port/update-name', {
    portfolioId,
    name
  })
    .then(() => true)
    .catch(() => false)
}

export function getListOfPortfolios () {
  return this.$apiMember.$get('/api/set-fund/virtualport/port/list')
}

export function getEquityPortfolio (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/equity', { params: { portfolioId } })
}

export function getFundPortfolio (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/fund', { params: { portfolioId, lang: this.i18n.locale } })
}

export function getDerivativePortfolio (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/tfex', { params: { portfolioId } }).then(res => res).catch(err => err)
}

export function sendOrderEquity (portfolioId, order) {
  return this.$apiMember.$post('/api/set-fund/virtualport/equity', { portfolioId, ...order })
    .then(() => true)
    .catch(() => false)
}

export function sendOrderFund (portfolioId, order) {
  return this.$apiMember.$post('/api/set-fund/virtualport/fund', { portfolioId, ...order })
    .then(() => true)
    .catch(() => false)
}

export function sendOrderDerivative (portfolioId, order) {
  return this.$apiMember.$post('/api/set-fund/virtualport/tfex', { portfolioId, ...order })
    .then(() => true)
    .catch(() => false)
}

export function getEquityHistory (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/equity/transaction', { params: { portfolioId } })
}

export function getFundHistory (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/fund/transaction', { params: { portfolioId } })
}

export function getDerivativeHistory (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/tfex/transaction', { params: { portfolioId } })
}

export function getEquityPerformance (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/equity/performance', { params: { portfolioId } })
}

export function getFundPerformance (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/fund/performance', { params: { portfolioId } })
}

export function getDerivativePerformance (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/tfex/performance', { params: { portfolioId } })
}

export function deletePortfolio (portfolioId) {
  return this.$apiMember.$delete('/api/set-fund/virtualport/delete', { params: { portfolioId } })
    .then(() => true)
    .catch(() => false)
}

export function getPortSummary () {
  return this.$apiMember.$get('/api/set-fund/virtualport/summary')
}

export function getEquityInvestmentByIndustry (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/equity/investment-by-industry', { params: { portfolioId } })
}

export function getEquityInvestmentBySymbol (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/equity/investment-by-symbol', { params: { portfolioId } })
}

export function getFundInvestmentBySymbol (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/fund/investment-by-symbol', { params: { portfolioId } })
}

export function getDerivativeInvestmentBySymbol (portfolioId) {
  return this.$apiMember.$get('/api/set-fund/virtualport/tfex/investment-by-symbol', { params: { portfolioId } })
}
