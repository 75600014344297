const state = () => ({
  StockInfo: null,
  drxInfo: null,
  tfexInfo: null,
  fundInfo: null
})

const actions = {
  async countPopular ({ commit }, payload) {
    const apiPath = '/api/cms-w/v1/popularquote/countPopular' // 'api/popularquote/countpopular'
    const response = await this.$apiCms.post(apiPath, payload).then((res) => {
      return res.data
    }).catch(err => err)
    return response
  },

  async fethStockInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.get(`/api/set/stock/${quote}/info`)

      commit('setStockInfo', response.data)
      return response.status
    } catch (err) {
      commit('setStockInfo', null)
      return err.response.status
    }
  },

  async fethDRxInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.get(`/api/set/drx/${quote}/info`)

      commit('setDRXInfo', response.data)
      return response.status
    } catch (err) {
      commit('setDRXInfo', null)
      return err.response.status
    }
  },

  async fethTfexInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series/${quote}/info`)

      commit('setTFEXInfo', response)
    } catch {
      commit('setTFEXInfo', null)
    }
  },

  async fethFundInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set-fund/fund-profile/${encodeURIComponent(encodeURIComponent(quote))}/overview`)

      commit('setFundInfo', response)
    } catch {
      commit('setFundInfo', null)
    }
  }
}

const mutations = {
  setStockInfo (state, data) {
    state.StockInfo = data
  },
  setDRXInfo (state, data) {
    state.drxInfo = data
  },
  setTFEXInfo (state, data) {
    state.tfexInfo = data
  },
  setFundInfo (state, data) {
    state.fundInfo = data
  }
}

const getters = {
  getStockInfo (state) {
    return state.StockInfo
  },
  getDRXInfo (state) {
    return state.drxInfo
  },
  getTFEXInfo (state) {
    return state.tfexInfo
  },
  getFundInfo (state) {
    return state.fundInfo
  }
}

export default {
  actions,
  mutations,
  state,
  getters
}
