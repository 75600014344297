//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  name: 'MiscNewsAndArticlesTagSymbol',
  props: {
    symbol: {
      type: String,
      default: ''
    },
    securityType: {
      type: String,
      default: null,
      required: false

    }
  },
  computed: {
    ...mapGetters({
      stocks: 'search/getStocks'
    })
  },
  methods: {
    getStockSecurityType () {
      const stockInfo = this.stocks.find((data) => {
        return data.symbol === this.symbol
      })
      if (stockInfo) {
        return stockInfo.securityType
      }
    },
    symbolRoute () {
      this.$emit('click')
      const securityType = this.securityType ?? this.getStockSecurityType()
      const urlString = this.$quote.getQuoteUrl({
        symbol: this.symbol,
        securityType
      })
      window.open(urlString, '_blank')
    }
  }
}
