import dayjs from 'dayjs'

const getDefaultState = () => {
  return {
    quotationChart: null,
    historicalTrading: [],
    historicalSessionTrading: [],
    futuresPriceCalculation: null,
    referenceData: null,
    otherMarket: null,
    otherRemark: null
  }
}

const state = getDefaultState

const mutations = {
  setQuotationChart (state, data) {
    state.quotationChart = data
  },

  setHistoricalTrading (state, data) {
    state.historicalTrading = data
  },

  setHistoricalSessionTrading (state, data) {
    state.historicalSessionTrading = data
  },

  setFuturesPriceCalculation (state, data) {
    state.futuresPriceCalculation = data
  },

  setReferenceData (state, data) {
    state.referenceData = data
  },

  setOtherMarket (state, data) {
    state.otherMarket = data
  },

  setAgricultureRubberOtherMarket (state, data) {
    state.otherRemark = data
  }
}

const actions = {
  async getQuotationChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${quote}/chart-quotation`,
        {
          params: {
            period
          }
        }
      )

      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },

  async getHistoricalTrading ({ commit }, payload) {
    const { quote } = payload
    // const now = dayjs()
    // const from = now.subtract(6, 'month').format('DD/MM/YYYY')
    // const to = now.format('DD/MM/YYYY')
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${quote}/historical-trading`,
        {
          params: {
            // fromDate: from,
            // toDate: to
          }
        }
      )
      commit('setHistoricalTrading', response)
    } catch {
      commit('setHistoricalTrading', [])
    }
  },

  async getHistoricalSessionTrading ({ commit }, payload) {
    const { quote } = payload
    const now = dayjs()
    const from = now.subtract(6, 'month').format('DD/MM/YYYY')
    const to = now.format('DD/MM/YYYY')
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${quote}/historical-session-trading`,
        {
          params: {
            fromDate: from,
            toDate: to
          }
        }
      )

      commit('setHistoricalSessionTrading', response)
    } catch {
      commit('setHistoricalSessionTrading', [])
    }
  },

  async getFuturesPriceCalculation ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series/${quote}/futures-price-calculation`)

      commit('setFuturesPriceCalculation', response)
    } catch {
      commit('setFuturesPriceCalculation', null)
    }
  },

  async getReferenceData ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/underlying/agriculture/rubber/reference-data')

      commit('setReferenceData', response)
    } catch {
      commit('setReferenceData', null)
    }
  },

  async getOtherMarket ({ commit }, payload) {
    const { market } = payload
    try {
      const response = await this.$axios.$get('/api/set/tfex/underlying/agriculture/rubber/other-market',
        {
          params: {
            market
          }
        })

      commit('setOtherMarket', response)
    } catch {
      commit('setOtherMarket', null)
    }
  },

  async getAgricultureRubberOtherMarket ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/underlying/agriculture/rubber/other-market')

      commit('setAgricultureRubberOtherMarket', response)
    } catch {
      commit('setAgricultureRubberOtherMarket', null)
    }
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
