//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconClose'
}
