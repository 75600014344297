//
//
//
//
//

import { formatSecurityType } from '@/helpers/utilities'
export default {
  name: 'SearchMiscSecurityTypeTag',
  props: {
    securityType: {
      type: String,
      default: () => ''
    },
    optionsType: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      securityTypeText: ''
    }
  },
  watch: {
    securityType () {
      this.securityTypeText = this.formatSecurityType(this.securityType, this.optionsType)
    }
  },
  mounted () {
    this.securityTypeText = this.formatSecurityType(this.securityType, this.optionsType)
  },
  methods: {
    formatSecurityType
  }
}
