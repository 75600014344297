const ObserverChatBot = (workEventCallback) => {
  // Select the node that will be observed for mutations
  const targetNode = document.querySelector('.bull-chat-bot')
  let isShowChatBot = false
  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true }

  // Callback function to execute when mutations are observed
  const callback = function (mutationsList, observer) {
    if (!isShowChatBot) {
      // eslint-disable-next-line no-unused-vars
      for (const mutation of mutationsList) {
        const addElementSuccess = document.querySelector('.button-close-chatbot')
        if (addElementSuccess && !isShowChatBot) {
          isShowChatBot = true
          addElementSuccess.addEventListener('click', () => {
            workEventCallback()
          })
          observer.disconnect()
        }
      }
    }
  }

  // Create an observer instance linked to the callback function
  if (targetNode) {
    const observer = new MutationObserver(callback)

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config)
  }
}

export {
  ObserverChatBot
}
