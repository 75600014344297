import { extend } from 'vee-validate'
import {
  required,
  alpha,
  length,
  email,
  numeric,
  dimensions,
  min_value as minValue,
  max_value as maxValue
} from 'vee-validate/dist/rules'
export default ({ app }) => {
  extend('multipleValues', (values) => {
    values = values || []
    const findEmpty = values.filter((v) => { return !v })
    const valid = values.length && !findEmpty.length
    if (valid) {
      return true
    }
    return app.i18n.t('form.validate.required')
  })
  extend('required', {
    ...required,
    message: app.i18n.t('form.validate.required')
  })
  extend('alpha', {
    ...alpha,
    message: 'This field must only contain alphabetic characters'
  })
  extend('length', {
    ...length
  })
  extend('email', {
    ...email,
    message: 'กรุณาระบุอีเมลให้ถูกต้อง'
  })
  extend('dimensions', {
    ...dimensions
  })
  extend('phone', {
    ...numeric,
    message: 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง'
  })
  extend('numeric', {
    ...numeric,
    message: 'กรุณาระบุข้อมูลในช่วง'
  })
  extend('min_value', {
    ...minValue,
    message: 'กรุณาระบุข้อมูลในช่วง'
  })
  extend('max_value', {
    ...maxValue,
    message: 'กรุณาระบุข้อมูลในช่วง'
  })
}
