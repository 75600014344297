//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  head () {
    return this.$embedded.embedded.default()
  }
}
