/* eslint-disable */
/**
 * Add startsWith
 */
if (!String.prototype.startsWith) {
  console.log('add startWith for IE')
  Object.defineProperty(String.prototype, 'startsWith', {
    value (search, rawPos) {
      const pos = rawPos > 0 ? rawPos | 0 : 0
      return this.substring(pos, pos + search.length) === search
    }
  })
}
