//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppBarSubMenu',
  props: {
    menuList: {
      type: Array,
      default: () => []
    },
    levelNumber: {
      type: Number,
      default: 3
    },
    parent: {
      type: String,
      default: null
    },
    parentTitle: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      level2Expanded: false
    }
  },
  computed: {
    levelClass () {
      return `menu-level${this.levelNumber}`
    }
  },
  methods: {
    subMenuOpening () {
      return document.querySelector('.nav-submenu.opening')
    },
    handleHover (itemId, hasSubMenu) {
      if (hasSubMenu) {
        this.updateMenu('hover', itemId)
      }
    },
    handleLeave (itemId, hasSubMenu) {
      if (hasSubMenu) {
        this.updateMenu('leave', itemId)
      }
    },
    updateMenu (action, itemId) {
      const submenuLevel3 = document.getElementById(`menu-level3_${itemId}`)
      const offsetTop = this.getOffsetTop(submenuLevel3)
      const clientHeight = submenuLevel3.clientHeight
      const overflow = offsetTop + clientHeight
      submenuLevel3.classList.add('expanded')
      if (action === 'hover') {
        if (overflow > window.innerHeight) {
          submenuLevel3.classList.add('top')
        }
      } else {
        submenuLevel3.classList.remove('expanded')
        setTimeout(() => {
          submenuLevel3.classList.remove('top')
        }, 200)
      }
    },
    getOffsetTop (elem) {
      let offsetTop = 0
      do {
        if (!isNaN(elem.offsetTop)) {
          offsetTop += elem.offsetTop
        }
      } while ((elem = elem.offsetParent))
      return offsetTop
    },
    // updateMenu (action, itemId) {
    //   // const menuOpening = this.subMenuOpening()
    //   const parent = document.querySelector(this.parent)
    //   const menuItem = document.getElementById(`menu-item_${itemId}`)
    //   const submenuLevel3 = document.getElementById(`menu-level3_${itemId}`)
    //   const submenuLevel3UL = document.querySelector(`#menu-level3_${itemId} >ul`)
    //   const submenuColumn2 = document.querySelector(`${this.parent} .nav-submenu-column-2`)
    //   const submenuLevel3ULHeight = submenuLevel3UL && submenuLevel3UL.clientHeight// menuOpening ? menuOpening.clientHeight : 0
    //   const parentHeight = parent && parent.clientHeight // > 500 ? 500 : parent.clientHeight // max height 500
    //   const offsetTop = menuItem.offsetTop
    //   if (!parent.dataset.height) {
    //     parent.dataset.height = parentHeight + 'px' // default height
    //   }
    //   if (submenuLevel3 && submenuColumn2) {
    //     if (parentHeight < submenuLevel3ULHeight) {
    //       submenuLevel3.style.height = submenuLevel3ULHeight ? submenuLevel3ULHeight + 'px' : null
    //     } else {
    //       submenuLevel3.style.height = parentHeight ? parentHeight + 'px' : null
    //     }
    //     submenuLevel3.style.transform = 'translate3d(0, ' + (offsetTop * -1) + 'px, 0)'
    //     if (action === 'hover') {
    //       submenuLevel3.classList.add('expanded')
    //       submenuColumn2.classList.add('expanded')
    //     } else {
    //       submenuLevel3.classList.remove('expanded')
    //       submenuColumn2.classList.remove('expanded')
    //     }
    //     if (!submenuLevel3.className.includes('expanded')) {
    //       parent.style.height = parent.dataset.height
    //     } else if (parentHeight < submenuLevel3ULHeight) {
    //       parent.style.height = submenuLevel3ULHeight ? (submenuLevel3ULHeight + 30/* gap top, bottom */) + 'px' : null
    //     }
    //   }
    // },
    selectMenuIcon (imgSrc) {
      const image = imgSrc?.filter((img) => { return img.size === 'default' })[0] || {}
      return image.url
    },
    handleClickItem (item) {
      const { url, name, EventTracking: { isEtk } } = item
      if (!isEtk) { return }
      if (url && url !== '#') {
        const _menuTitle = []
        if (this.parentTitle) {
          _menuTitle.push(this.parentTitle)
        }
        _menuTitle.push(name)
        this.$personalized.clickElement({
          name: 'all1',
          optionLang: {
            menuTitle: _menuTitle.join(',')
          }
        })
      }
    }
  }
}
