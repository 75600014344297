//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MiscMemberVirtualPortfolioTabButtonGroup',
  props: {
    derivative: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: undefined
    },
    disableBuy: {
      type: Boolean,
      default: false
    },
    disableSell: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    switchToSell () {
      if (!this.disableSell) {
        this.$emit('input', 2)
      }
    }
  }
}
