/**
 * State
 */
const state = () => ({
  marketMovement: {
    nvdr: {
      buy: [],
      sell: []
    },
    shortSales: [],
    bigLot: []
  }
})

/**
 * Mutations
 */
const mutations = {
  setMarketMovement (state, data) {
    if (data) {
      state.marketMovement.nvdr = {
        date: data[0].date,
        buy: data[0].nvdrTradings.slice(0, 5),
        sell: data[1].nvdrTradings.slice(0, 5)
      }
      state.marketMovement.shortSales = {
        fromDate: data[2].fromDate,
        toDate: data[2].toDate,
        data: data[2].shortSales.slice(0, 5)
      }
      state.marketMovement.bigLot = {
        date: data[3].date,
        data: data[3].data.sort(
          (a, b) => b.value - a.value
        ).slice(0, 5)
      }
    }
  }
}

/**
 * Actions
 */
const actions = {
  async getHomeMarketMovement ({ commit }) {
    try {
      const nvdrBuy = this.$axios.$get(
        'api/set/nvdr-trade/stock-trading?sortBy=netBuyValue'
      )
      const nvdrSell = this.$axios.$get(
        'api/set/nvdr-trade/stock-trading?sortBy=netSellValue'
      )
      const bigLot = this.$axios.$get(
        'api/set/biglot'
      )
      // const nvdrSell = this.$axios.$get(
      //   'api/set/nvdr-trade/stock-trading?sortBy=netSellValue'
      // )
      const shortSales = this.$axios.$get(
        'api/set/shortsales/trading/list'
      )

      const marketMovement = await Promise.allSettled([
        nvdrBuy,
        nvdrSell,
        shortSales,
        bigLot
      ]).then((data) => {
        return [
          data[0].value,
          data[1].value,
          data[2].value,
          data[3].value
        ]
      })

      commit('setMarketMovement', marketMovement)
    } catch (e) {
      commit('setMarketMovement', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  getMarketMovement (state) {
    return state.marketMovement
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
