// import dayjs from 'dayjs'
import customdayjs from '@/plugins/dayjs'
require('dayjs/locale/th')
// const buddhistEra = require('dayjs/plugin/buddhistEra')
// dayjs.extend(buddhistEra)
// const buddhistEra = require('dayjs/plugin/buddhistEra')
// customdayjs.extend(buddhistEra)

const dayjs = (date) => {
  if (date) {
    const timePattern = /\d{2}:\d{2}(:\d{2})?/
    const hasTime = timePattern.test(date)
    const inputDate = hasTime
      ? new Date(date)
      : new Date(date).toLocaleString('en-EN', {
        dateStyle: 'short',
        timeStyle: undefined
      })
    const mainUtcDate = inputDate.toLocaleString('en-EN', {
      timeZone: 'Asia/Bangkok',
      dateStyle: 'short',
      timeStyle: 'medium'
    })
    return customdayjs(mainUtcDate)
  } else {
    return customdayjs(date).tz()
  }
}
/**
 * Date short format
 * @param {String} date // YYYY-MM-DD
 * @param {String} locale // th, en, ...
 * @returns string DD/MM/YYYY
 */
const dateFormat = (date, locale = 'th') => {
  if (!date) { return '' }

  customdayjs.locale(locale)
  const dateByDayjs = dayjs(date)
  const dateFormat = []
  dateFormat.push(dateByDayjs.format('DD'))
  dateFormat.push(dateByDayjs.format('MM'))
  if (locale === 'th') {
    dateFormat.push(parseInt(dateByDayjs.format('YYYY')) + 543)
  } else {
    dateFormat.push(dateByDayjs.format('YYYY'))
  }
  return dateFormat.join('/')
}
/**
 * Date long format
 * @param {String} date // YYYY-MM-DD
 * @param {String} locale // th, en, ...
 * @returns string D MMMM YYYY
 */
const dateLongFormat = (date, locale = 'th', strFormat = 'D MMMM YYYY') => {
  if (!date) { return '' }
  const [fday, fmonth, fyear] = strFormat.split(' ')
  customdayjs.locale(locale)
  const dateByDayjs = dayjs(date)
  const dateFormat = []
  dateFormat.push(dateByDayjs.format(fday))
  dateFormat.push(dateByDayjs.format(fmonth))
  if (locale === 'th') {
    dateFormat.push(parseInt(dateByDayjs.format(fyear)) + 543)
  } else {
    dateFormat.push(dateByDayjs.format(fyear))
  }
  return dateFormat.join(' ')
}

// 21 ก.ย. 2564
const formatDateShort = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date).locale(locale).format('D MMM BBBB')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}

// 16/08/2018
const formatDateShort2 = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date).locale(locale).format('DD/MM/BBBB')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}

// 4
const formatDateOnly = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date).locale(locale).format('D')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}

// ก.ย. 64
const formatMonthYearShort = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date).locale(locale).format('MMM BB')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}

// 21 ก.ย. 2564 18:12
const formatDateTimeShort = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date)
      .locale(locale)
      .format('D MMM BBBB HH:mm')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}

// 21 ก.ย. 2564 18:12:56
const formatDateTimeLong = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date)
      .locale(locale)
      .format('D MMM BBBB HH:mm:ss')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}
// 18:12
const formatTimeShort = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date)
      .locale(locale)
      .format('HH:mm')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}

const formatMonthYear = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date)
      .locale(locale)
      .format('MMM BBBB')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}
// return 2021-31-12
const formatDateDash = (date, locale = 'th') => {
  if (!date) { return '' }

  dayjs().locale(locale)
  const dateByDayjs = dayjs(date)
  const dateFormat = []
  dateFormat.push(dateByDayjs.format('YYYY'))
  dateFormat.push(dateByDayjs.format('MM'))
  dateFormat.push(dateByDayjs.format('DD'))
  return dateFormat.join('-')
}

// return day name
const dayName = (date, locale = 'th') => {
  if (!date) { return '' }

  customdayjs.locale(locale)
  const dateByDayjs = dayjs(date)
  return dateByDayjs.format('dddd')
}
const setMonthOnly = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return 'ม.ค.'
    case 2:
      return 'ก.พ.'
    case 3:
      return 'มี.ค.'
    case 4:
      return 'เม.ย.'
    case 5:
      return 'พ.ค.'
    case 6:
      return 'มิ.ย.'
    case 7:
      return 'ก.ค.'
    case 8:
      return 'ส.ค.'
    case 9:
      return 'ก.ย.'
    case 10:
      return 'ต.ค.'
    case 11:
      return 'พ.ย.'
    case 12:
      return 'ธ.ค.'
  }
}
const convertToThaiDate = (date) => {
  const inputDate = new Date(date)
  const mainUtcDate = inputDate.toLocaleString('en-EN', {
    timeZone: 'Asia/Bangkok',
    dateStyle: 'short'
  })
  return mainUtcDate
}
export {
  dayjs,
  dayName,
  dateFormat,
  dateLongFormat,
  formatDateShort,
  formatDateShort2,
  formatDateTimeShort,
  formatDateTimeLong,
  formatTimeShort,
  formatDateDash,
  setMonthOnly,
  formatDateOnly,
  formatMonthYear,
  formatMonthYearShort,
  convertToThaiDate
}
