//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModalFullSymbol',
  data () {
    return {}
  },
  computed: {
    favouriteUrl () {
      return this.localePath({
        name: 'member-favourite'
      })
    }
  },
  methods: {
    openModal () {
      const modal = this.$refs.modal
      modal.show()
    }
  }
}
