import dr from '@/store/factsheet/dr/index'
import dw from '@/store/factsheet/dw/index'
export const modules = {
  dr,
  dw
}
const state = () => {
  return {
    info: null
  }
}

const mutations = {
  setInfo (state, data) {
    state.info = data
  }
}

const actions = {
  async getInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/profile`)
      commit('setInfo', response)
    } catch {
      commit('setInfo', null)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
