//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconSuccessNotice'
}
