import { render, staticRenderFns } from "./QuoteTabVirtualPortfolio.vue?vue&type=template&id=34c1b8f8&scoped=true&"
import script from "./QuoteTabVirtualPortfolio.vue?vue&type=script&lang=js&"
export * from "./QuoteTabVirtualPortfolio.vue?vue&type=script&lang=js&"
import style0 from "./QuoteTabVirtualPortfolio.vue?vue&type=style&index=0&id=34c1b8f8&lang=scss&scoped=true&"
import style1 from "./QuoteTabVirtualPortfolio.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c1b8f8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLoadingChart: require('/app/components/Icon/LoadingChart.vue').default,MiscEquityMarketInputSelect: require('/app/components/Misc/Equity/Market/InputSelect.vue').default,IconPlus: require('/app/components/Icon/Plus.vue').default,Button: require('/app/components/Button/Button.vue').default,SwiperCarousel: require('/app/components/SwiperCarousel/SwiperCarousel.vue').default})
