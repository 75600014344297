const state = () => ({
  dataDate: null,
  seriesListByPeriod: [],
  historicalData: [],
  sessionData: [],
  periodDate: null
})

const mutations = {
  setDataDate (state, data) {
    state.dataDate = data
  },

  setSeriesListByPeriod (state, data) {
    state.seriesList = data
  },

  setHistoricalData (state, data) {
    state.historicalData = data
  },

  setSessionData (state, data) {
    state.sessionData = data
  },

  setPeriodDate (state, data) {
    state.periodDate = data
  }
}

const actions = {
  async getDataDate ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/calendar/datadate')
      if (response && response.datetime) {
        commit('setDataDate', response.datetime)
      }
    } catch (error) {
      commit('setDataDate', null)
    }
  },

  async getSeriesListByPeriod ({ commit }, payload) {
    const { fromDate = '', toDate = '' } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series/list-by-period?fromDate=${fromDate}&toDate=${toDate}`)
      if (response && response.series) {
        commit('setSeriesListByPeriod', response.series)
        return response.series
      } else {
        return []
      }
    } catch (error) {
      commit('setSeriesListByPeriod', [])
      return []
    }
  },

  async getHistoricalData ({ commit }, payload) {
    const { symbol = '', fromDate = '', toDate = '' } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${symbol}/historical-trading?fromDate=${fromDate}&toDate=${toDate}`
      )
      commit('setHistoricalData', response)
      return response
    } catch (error) {
      commit('setHistoricalData', [])
      return []
    }
  },

  async getSessionData ({ commit }, payload) {
    const { symbol = '', fromDate = '', toDate = '' } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${symbol}/historical-session-trading?fromDate=${fromDate}&toDate=${toDate}`
      )
      commit('setSessionData', response)
      return response
    } catch (error) {
      commit('setSessionData', [])
      return []
    }
  },

  async getPeriodDate ({ commit }, payload) {
    const { period } = payload
    try {
      const response = await this.$axios.$get(`/api/set/calendar/period/${period}`)

      commit('setPeriodDate', response)
    } catch {
      commit('setPeriodDate', null)
    }
  }
}
const getters = {
  getDataDate: (state) => {
    return state.dataDate || null
  },

  getSeriesListByPeriod: (state) => {
    return state.seriesListByPeriod || []
  },

  getHistoricalData: (state) => {
    return state.historicalData || []
  },

  getSessionData: (state) => {
    return state.sessionData || []
  },

  getPeriodDate: (state) => {
    return state.periodDate || null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
