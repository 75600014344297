const state = () => {
  return {
    options: [], // { label: '', value: '', select: true/false }
    indexInfos: [],
    composition: {},
    is_loading: false
  }
}

const mutations = {
  setOptions (state, data) {
    state.options = data
  },
  setIndexComposition (state, data) {
    state.indexInfos = data.indexInfos
    state.composition = data.composition
  },
  setLoading (state, data) {
    state.is_loading = data
  }
}

const actions = {
  async getIndexList ({ commit }, payload) {
    const { type, market } = payload
    try {
      if (type === 'index') {
        const response = await this.$axios.$get(
          `/api/set/index/list?type=${type}&market=${market}`
        )
        commit(
          'setOptions',
          response.map((data, index) => {
            return {
              label: data.symbol,
              value: data.symbol,
              select: (data.symbol === 'SET' && market === 'set') || (data.symbol === 'mai' && market === 'mai'),
              indent: false,
              ...data
            }
          })
        )
      } else if (type === 'industry') {
        const response = await this.$axios.$get(
          `/api/set/index/list?type=${type}&market=${market}`
        )
        if (market === 'set') {
          commit('setOptions', [
            {
              label: 'ทั้งหมด',
              value: '',
              select: true
            },
            ...response.map((data, index) => {
              return {
                label: data.symbol,
                value: data.symbol,
                select: false,
                indent: data.level === 'SECTOR',
                ...data
              }
            })
          ])
        } else {
          commit('setOptions', [
            ...response.map((data, index) => {
              return {
                label: data.symbol,
                value: data.symbol,
                select: data.symbol === 'AGRO',
                indent: false,
                ...data
              }
            })
          ])
        }
      } else if (type === 'securities') {
        const response = await this.$axios.$get(
          `/api/set/market/${market}/security-type`
        )
        commit(
          'setOptions',
          response.map((data, index) => {
            return {
              label: data.nameTH,
              value: data.securityType,
              select: index === 0,
              indent: false,
              ...data
            }
          })
        )
      } else if (type === 'tradingmethod') {
        commit('setOptions', [
          {
            nameEN: 'Auto Matching',
            label: 'Auto Matching',
            value: 'auto_matching',
            select: true,
            indent: false
          },
          {
            nameEN: 'Trade Report',
            label: 'Trade Report',
            value: 'trade_report',
            select: false,
            indent: false
          },
          {
            nameEN: 'Odd Lot',
            label: 'Odd Lot',
            value: 'odd_lot',
            select: false,
            indent: false
          }
        ])
      }
    } catch {
      commit('setOptions', [])
    }
  },
  async getIndexComposition ({ commit }, symbol) {
    try {
      const response = await this.$axios.$get(
        `/api/set/index/${symbol}/composition`
      )
      commit('setIndexComposition', response)
    } catch {
      commit('setIndexComposition', { indexInfos: [], composition: {} })
    }
  },
  setLoading ({ commit }, isLoading) {
    commit('setLoading', isLoading)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
