const getDefaultState = () => {
  return {
    fundLst: null,
    amcLst: null,
    riskLevelLst: null,
    lastBusinessDate: null,
    searchResult: null
  }
}
const state = getDefaultState
const mutations = {
  setFundLst (state, data) {
    state.fundLst = data
  },
  setAmcLst (state, data) {
    state.amcLst = data
  },
  setRiskLevel (state, data) {
    state.riskLevelLst = data
  },
  setLastBusinessDate (state, data) {
    state.lastBusinessDate = data
  },
  setSearchResult (state, data) {
    state.searchResult = data
  }
}
const actions = {
  async getFundLst ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(
        '/api/set-fund/fund/active-fund/list'
      )
      commit('setFundLst', response?.funds)
    } catch {
      commit('setFundLst', null)
    }
  },
  async getAmcLst ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('/api/set-fund/fund/amc/list')
      commit('setAmcLst', response?.fundAmcs)
    } catch {
      commit('setAmcLst', null)
    }
  },
  getRiskLevel ({ commit }) {
    try {
      const options = []
      const riskGroup = ['1', '2', '3', '4', '5', '6', '7', '8', '8+']
      for (const item of riskGroup) {
        options.push({
          label: `ระดับ ${item} (${getLevel(item)})`,
          value: item
        })
      }
      commit('setRiskLevel', options)
      function getLevel (value) {
        switch (value) {
          case '1':
            return 'เสี่ยงต่ำ'
          case '2':
          case '3':
          case '4':
            return 'เสี่ยงปานกลางค่อนข้างต่ำ'
          case '5':
            return 'เสี่ยงปานกลางค่อนข้างสูง'
          case '6':
          case '7':
            return 'เสี่ยงสูง'
          case '8':
            return 'เสี่ยงสูงมาก'
          case '8+':
            return 'เสี่ยงสูงมากอย่างมีนัยสำคัญ'
          default:
            return value
        }
      }
    } catch {
      commit('setRiskLevel', null)
    }
  },
  async getLastBusinessDate ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(
        '/api/set-fund/fund/last-business-date'
      )
      commit('setLastBusinessDate', response)
    } catch {
      commit('setLastBusinessDate', null)
    }
  },
  async getSearchResult ({ commit }, payload) {
    const { symbol, amcId, spectrumRisk, beginIpoDate } = payload
    try {
      const response = await this.$axios.$get('/api/set-fund/fund/ipo', {
        params: {
          symbol,
          amcId,
          spectrumRisk,
          beginIpoDate
        }
      })
      commit('setSearchResult', response?.fundIPOs)
    } catch {
      commit('setSearchResult', null)
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
