import companyInformation from '@/store/equity/stock/unitTrust/companyInformation/index'

export const modules = {
  companyInformation
}

const getDefaultState = () => {
  return {
    quotationChart: null,
    quotationChartAccumulated: null,
    highlightData: null,
    historicalTrading: [],
    newsList: null
  }
}

const state = getDefaultState

const mutations = {
  setQuotationChart (state, data) {
    state.quotationChart = data
  },

  setQuotationChartAccumulated (state, data) {
    state.quotationChartAccumulated = data
  },

  setHighlightData (state, data) {
    state.highlightData = data
  },

  setHistoricalTrading (state, data) {
    state.historicalTrading = data
  },

  setNewsList (state, data) {
    state.newsList = data
  }
}

const actions = {
  async getQuotationChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/chart-quotation`, {
        params: {
          period,
          accumulated: false
        }
      })

      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },

  async getQuotationChartAccumulated ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/chart-quotation`, {
        params: {
          period,
          accumulated: true
        }
      })

      commit('setQuotationChartAccumulated', response)
    } catch {
      commit('setQuotationChartAccumulated', null)
    }
  },

  async getHighlightData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/highlight-data`)

      commit('setHighlightData', response)
    } catch {
      commit('setHighlightData', null)
    }
  },

  async getHistoricalTrading ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/historical-trading`)

      commit('setHistoricalTrading', response)
    } catch {
      commit('setHistoricalTrading', [])
    }
  },

  async getNewsList ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/news/${quote}/list`)

      commit('setNewsList', response)
    } catch {
      commit('setNewsList', null)
    }
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
