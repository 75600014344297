//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { firstTextHighlight } from '~/plugins/text-highlight'
export default {
  name: 'DropdownSearch',
  props: {
    value: {
      type: undefined,
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelNodata: {
      type: String,
      default: 'ไม่พบผลลัพธ์สำหรับการค้นหา'
    },
    listSearchData: {
      type: Array,
      default: Array
    },
    excludeListSearch: {
      type: Array,
      default: Array
    },
    isClearSymbolOnSelect: {
      type: Boolean,
      default: true
    },
    isSearchName: {
      type: Boolean,
      default: false
    },
    delayHideDropdown: {
      type: Number,
      default: 0
    },
    autoTextUppercase: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: 'เกิดข้อผิดพลาด'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      elementId: `dropdown-${(Math.random() * 1000).toFixed()}`,
      symbol: '',
      securityType: '',
      placeholderStatus: '',
      dropdownToggle: null,
      listSearch: [],
      isClear: false
    }
  },
  watch: {
    symbol () {
      this.handleSearch(this.symbol)
      if (!this.isClear) {
        this.showDropdown()
        this.placeholderStatus = this.listSearch.length > 0
      }
      this.isClear = false
      this.$emit('onTextSearchChange', this.symbol)
    },
    listSearchData (value) {
      this.listSearch = this.filterListSearch(value)
    },
    excludeListSearch () {
      this.listSearch = this.filterListSearch(this.listSearchData)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    firstTextHighlight,
    Highlight (content) {
      return this.firstTextHighlight(this.symbol, content)
    },
    init () {
      const { Dropdown } = require('bootstrap')
      const options = {
        display: 'dynamic'
      }
      const elementDropdownToggle = this.$refs.dropdownToggle
      this.dropdownToggle = new Dropdown(elementDropdownToggle, options)
      this.listSearch = this.filterListSearch(this.listSearchData)
    },
    handleSelect (el, type) {
      // console.log('🚀 ~ el, type', el, type)
      this.$nextTick(() => {
        this.$emit('onSelected', el || null)
      })
      // this.$emit('onSelected', event.currentTarget.innerHTML.replace(/\s/g, '') || null)
      if (this.isClearSymbolOnSelect) {
        this.clear()
      } else {
        this.isClear = true
        this.symbol = el
        this.securityType = type
      }
      this.$refs.inputDropdownSearch.blur()
    },
    handleSearch (keyword) {
      if (keyword.length > 0) {
        const tempKeyword = keyword.replace(/\s/g, '').toUpperCase()
        this.listSearch = this.listSearchData.filter(item =>
          item.symbol.replace(/\s/g, '').toUpperCase().startsWith(tempKeyword)
        )
        if (this.isSearchName && this.listSearch.length === 0) {
          this.listSearch = this.listSearchData.filter((item) => {
            return (
              item.nameTH
                .replace(/\s/g, '')
                .toUpperCase()
                .includes(tempKeyword) ||
              item.nameEN.replace(/\s/g, '').toUpperCase().includes(tempKeyword)
            )
          })
        }
      } else {
        this.listSearch = this.listSearchData
      }
      if (this.listSearch.length) {
        const elementDropdown = document.getElementById(this.elementId)
        if (elementDropdown) {
          this.listSearch = this.filterListSearch(this.listSearch)
          const activeItem = elementDropdown.querySelector('.cursor-active')
          if (activeItem) {
            activeItem.classList.remove('cursor-active')
          }
          const eleList = elementDropdown.getElementsByClassName(
            'dropdown-search-item'
          )
          if (eleList.length) {
            eleList[0].classList.add('cursor-active')
            elementDropdown.querySelector(
              '.dropdown-search .dropdown-menu'
            ).scrollTop = 0
          }
        }
      }
    },
    handleInputSearch (e) {
      const value = e.target.value
      if (this.autoTextUppercase) {
        this.symbol = value.toUpperCase()
      } else {
        this.symbol = value
      }
    },

    handleFocusInputSearch () {
      // if (this.symbol.length > 0) {
      this.showDropdown()
      // }
      this.placeholderStatus = true
    },
    handleBlurInputSearch () {
      setTimeout(() => {
        const symbolIndex = this.listSearch.findIndex(data => data.symbol === this.symbol)
        const isFoundSymbol = symbolIndex !== -1
        if (this.listSearch.length > 0 && isFoundSymbol) {
          if (this.securityType === '') {
            this.securityType = this.listSearch[symbolIndex].securityType
          }
        } else {
          this.clear()
        }
        this.hideDropdown()
      }, this.delayHideDropdown)
    },
    clear () {
      this.isClear = true
      this.symbol = ''
      this.securityType = ''
      this.hideDropdown()
      this.$emit('clear')
    },
    showDropdown () {
      this.dropdownToggle.show()
    },
    hideDropdown () {
      this.dropdownToggle.hide()
      this.placeholderStatus = false
    },
    moveActiveItemSearchResultWithArrow (key) {
      const elementDropdown = document.getElementById(this.elementId)
      if (elementDropdown) {
        const activeItem = elementDropdown.querySelector('.cursor-active')
        if (activeItem !== null) {
          const heightItem = activeItem.offsetHeight
          const currentIndex = Number(activeItem.id.split('_')[1])
          const lastIndex =
            elementDropdown.getElementsByClassName('dropdown-search-item')
              .length - 1
          if (key.keyCode === 38 && currentIndex > 0) {
            // ArrowUp
            activeItem.classList.remove('cursor-active')
            const nextIndex = currentIndex - 1
            const nextItem = elementDropdown.querySelector(
              `#dropdown-search-item_${nextIndex}`
            )
            nextItem.classList.add('cursor-active')
            if (
              nextItem.offsetTop <
              elementDropdown.querySelector('.dropdown-search .dropdown-menu')
                .scrollTop
            ) {
              elementDropdown.querySelector(
                '.dropdown-search .dropdown-menu'
              ).scrollTop = 0
              elementDropdown.querySelector(
                '.dropdown-search .dropdown-menu'
              ).scrollTop = nextItem.offsetTop
            }
          } else if (key.keyCode === 40 && currentIndex < lastIndex) {
            // ArrowDown
            activeItem.classList.remove('cursor-active')
            const nextIndex = currentIndex + 1
            const nextItem = elementDropdown.querySelector(
              `#dropdown-search-item_${nextIndex}`
            )
            nextItem.classList.add('cursor-active')
            if (
              nextItem.offsetTop + nextItem.offsetHeight >
                elementDropdown.querySelector('.dropdown-search .dropdown-menu')
                  .scrollTop +
                  elementDropdown.querySelector(
                    '.dropdown-search .dropdown-menu'
                  ).offsetHeight &&
              nextItem.offsetTop >
                elementDropdown.querySelector('.dropdown-search .dropdown-menu')
                  .scrollTop
            ) {
              elementDropdown.querySelector(
                '.dropdown-search .dropdown-menu'
              ).scrollTop = 0
              elementDropdown.querySelector(
                '.dropdown-search .dropdown-menu'
              ).scrollTop =
                heightItem +
                nextItem.offsetTop -
                elementDropdown.querySelector('.dropdown-search .dropdown-menu')
                  .offsetHeight
            }
          } else if (key.keyCode === 13) {
            // Enter
            const dataSet = JSON.parse(JSON.stringify(activeItem.dataset))
            this.handleSelect(dataSet.value, dataSet.type)
          }
          if (key.keyCode === 38 || key.keyCode === 40 || key.keyCode === 13) {
            // prevent arrow up, arrow down, enter
            key.preventDefault()
            key.stopImmediatePropagation()
            return false
          }
        }
      }
    },
    filterListSearch (listSearch) {
      return listSearch.filter((data) => {
        return !this.excludeListSearch.includes(data.symbol)
      })
    }
  }
}
