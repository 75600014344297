import { render, staticRenderFns } from "./DropdownSearch.vue?vue&type=template&id=2020e4f0&"
import script from "./DropdownSearch.vue?vue&type=script&lang=js&"
export * from "./DropdownSearch.vue?vue&type=script&lang=js&"
import style0 from "./DropdownSearch.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconSearch: require('/app/components/Icon/Search.vue').default,IconSearchClose: require('/app/components/Icon/SearchClose.vue').default,RawHtml: require('/app/components/RawHtml.vue').default,IconWarning: require('/app/components/Icon/Warning.vue').default})
