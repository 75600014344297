//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { deviceSizes } from '@/config/core'
export default {
  name: 'SearchBox',
  data () {
    return {
      isFocus: false,
      widthSearchBox: 0,
      isShowButtonClose: false,
      keyword: '',
      recentSearch: [],
      isActionOpenClose: false,
      breakpointXXL: 1366
    }
  },
  computed: {
    ...mapGetters({
      stocks: 'search/getStocks'
    })
  },
  watch: {
    keyword () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('changekeyword', this.keyword)
      }, 300)
    },
    isFocus (status) {
      if (this.$refs.inputBoxMobile) {
        this.$refs.inputBoxMobile.focus()
      }
      if (this.$refs.inputBox) {
        this.$refs.inputBox.focus()
      }
    }
  },
  mounted () {
    this.getRecentSearch()
    this.init()
  },
  destroyed () {
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    init () {
      this.registerEvent()
    },
    registerEvent () {
      this.$refs.inputBox.addEventListener('focus', this.handleFocusInputSearch)
      window.addEventListener('resize', this.onResize, { passive: true })
    },
    removeEvent () {
      this.$refs.inputBox.removeEventListener('focus', this.handleFocusInputSearch)
      window.removeEventListener('resize', this.onResize, { passive: true })
    },
    handleFocusInputSearch () {
      if (!this.isFocus && !this.isActionOpenClose) {
        this.isActionOpenClose = true
        this.widthSearchBox = document.querySelector('.appbar-search-box').offsetWidth
        this.$refs.searchBox.style.width = `${this.widthSearchBox}px`
        document.querySelector('.button-close-searchbox').classList.add('showing')
        document.querySelector('.headbar').classList.add('searchbox-focus')
        setTimeout(() => {
          this.isFocus = true
          document.querySelector('.button-close-searchbox').classList.add('show')
          this.$emit('focusInput')
          this.$emit('searchQuoteMobile', false)
        }, 0)
        setTimeout(() => {
          this.isActionOpenClose = false
        }, 200)
      }
    },
    close () {
      if (this.isFocus && !this.isActionOpenClose) {
        document.querySelector('.button-close-searchbox').classList.remove('show')
        document.querySelector('.headbar').classList.remove('searchbox-focus')
        const screenSize = window.innerWidth
        if (screenSize >= this.breakpointXXL) {
          this.$refs.searchBox.style.width = `${this.widthSearchBox}px`
        }
        setTimeout(() => {
          this.isFocus = false
          document.querySelector('.button-close-searchbox').classList.remove('showing')
        }, 100)
        setTimeout(() => {
          // this.$refs.searchBox.style.width = null
          this.keyword = ''
        }, 300)
        this.isActionOpenClose = true
        setTimeout(() => {
          this.$refs.inputBox.blur()
          this.isActionOpenClose = false
        }, 300)
        this.$emit('close')
      }
    },
    searchQuoteMobile () {
      if (!this.isFocus) {
        const inutSearch = this.$refs.inputBoxMobile
        inutSearch.classList.remove('d-none')
        const close = this.$refs.buttonCloseSearchMobile
        close.classList.remove('d-none')
        setTimeout(() => {
          this.isFocus = true
          this.$emit('focusInput')
          this.$emit('searchQuoteMobile', true)
        }, 0)
      }
    },
    closeMobile () {
      if (this.isFocus) {
        setTimeout(() => {
          this.isFocus = false
          const searchBox = this.$refs.searchBox
          const screenSize = window.innerWidth
          if (screenSize >= this.breakpointXXL) {
            searchBox.style.width = null
          } else if (screenSize > deviceSizes.xl) {
            searchBox.style.width = '68px'
          } else {
            searchBox.style.width = '35px'
          }
          const inutSearch = this.$refs.inputBoxMobile
          inutSearch.classList.add('d-none')
          const close = this.$refs.buttonCloseSearchMobile
          close.classList.add('d-none')
          this.keyword = ''
        }, 300)
        this.$emit('close')
        this.$emit('searchQuoteMobile', false)
      }
    },
    async moveActiveItemSearchResultWithArrow (key) {
      const activeItem = document.querySelector('.cursor-active')
      if (activeItem !== null) {
        const currentIndex = Number(activeItem.id.split('_')[1])
        const lastIndex = document.getElementsByClassName('search-list-items').length - 1
        if (key.keyCode === 38 && currentIndex > 0) {
          // ArrowUp
          activeItem.classList.remove('cursor-active')
          const nextIndex = currentIndex - 1
          const nextItem = document.getElementById(`search-list-item_${nextIndex}`)
          nextItem.classList.add('cursor-active')
          document.querySelector('.search-list-content').scrollTop = 0
          document.querySelector('.search-list-content').scrollTop = nextItem.offsetTop - document.querySelector('.search-list-content').offsetHeight
        } else if (key.keyCode === 40 && currentIndex < lastIndex) {
          // ArrowDown
          activeItem.classList.remove('cursor-active')
          const nextIndex = currentIndex + 1
          const nextItem = document.getElementById(`search-list-item_${nextIndex}`)
          nextItem.classList.add('cursor-active')
          document.querySelector('.search-list-content').scrollTop = 0
          document.querySelector('.search-list-content').scrollTop = nextItem.offsetTop - document.querySelector('.search-list-content').offsetHeight
        } else if (key.keyCode === 13) {
          // Enter
          const dataSet = JSON.parse(JSON.stringify(activeItem.dataset))
          this.$refs.inputBox.blur()
          this.handleSelectSearch({
            symbol: dataSet.value,
            securityType: dataSet.securitytype,
            securities: dataSet.securities | ''
          })
        }
        if (key.keyCode === 38 || key.keyCode === 40 || key.keyCode === 13) { // prevent arrow up, arrow down, enter
          key.preventDefault()
          key.stopImmediatePropagation()
          return false
        }
      } else if (key.keyCode === 13) {
        // Enter
        if (this.keyword) {
          let listStock = null
          if (this.stocks.length) {
            listStock = this.stocks
          } else {
            await this.getStockList()
            listStock = this.stocks
          }

          const isHave = listStock.find(s =>
            s.symbol.toLowerCase() ===
            this.keyword.trim().toLowerCase()
          )

          this.$refs.inputBox.blur()
          // this.$router.push(
          //   this.localePath({ path: `/search?keyword=${this.keyword}` })
          // )
          this.$personalized.siteSearch(this.keyword, 'panel')

          if (isHave) {
            this.handleSelectSearch(isHave)
          } else {
            window.location.href = this.localePath({ path: `/search?keyword=${this.keyword}` })
          }

          this.close()
        }
      }
      if (key.keyCode === 27) {
        this.$refs.inputBox.blur()
        this.close()
      }
    },
    async handleSelectSearch (item) {
      const symbol = item.symbol
      let tempQuote = await this.recentSearch
      const quoteNow = []
      if (symbol) {
        quoteNow.push(symbol)
      }
      if (tempQuote.length) {
        if (quoteNow.length) {
          const isSame = tempQuote.filter(q => q === quoteNow[0])
          if (isSame.length) {
            const index = tempQuote.indexOf(isSame[0])
            if (index > -1) {
              tempQuote.splice(index, 1)
            }
            tempQuote = [...quoteNow, ...tempQuote]
            tempQuote.splice(5, 1)
          } else {
            tempQuote = [...quoteNow, ...tempQuote]
            tempQuote.splice(5, 1)
          }
        }
        this.$cookies.set('recent-search', JSON.stringify(tempQuote))
      } else {
        this.$cookies.set('recent-search', JSON.stringify(quoteNow))
      }
      const urlString = this.$quote.getQuoteUrl(item)
      // this.$router.push(urlString)
      window.location.href = urlString
      this.close()
    },
    getRecentSearch () {
      if (this.$cookies.get('recent-search')) {
        this.recentSearch = this.$cookies.get('recent-search')
      } else {
        this.recentSearch = []
      }
    },
    onResize () {
      const eleSearchBox = this.$refs.searchBox
      // if (!this.isFocus) {
      if (eleSearchBox) {
        const screenSize = window.innerWidth
        if (screenSize >= this.breakpointXXL) {
          eleSearchBox.style.width = ''
          // this.widthSearchBox = eleSearchBox.offsetWidth
          // eleSearchBox.style.width = `${this.widthSearchBox}px`
        } else if (screenSize > deviceSizes.xl) {
          eleSearchBox.style.width = '68px'
        } else {
          eleSearchBox.style.width = '35px'
        }
      }
      // }
    }
  }
}
