/**
 * State
 */
const state = () => ({
  changed: false
})

/**
 * Mutations
 */
const mutations = {
  setChanged (state, data) {
    state.changed = data
  }
}
/**
 * Getters
 */
const getters = {
  getChanged: (state) => {
    return state.changed || false
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  getters
}
