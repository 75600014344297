export default function (context, inject) {
  async function gainEntryInternal (url, redirect) {
    if (!url.includes('member')) {
      throw new Error('invalid route')
    }

    const isFulfilled = await checkEntryRequirement()
    if (!isFulfilled) {
      const loginUrl = composeLoginUrl(url)
      redirect(loginUrl)
      return
    }
    redirect(url)
  }
  function gainEntry (url) {
    return gainEntryInternal(url, (x) => { window.location = x })
  }
  function gainNewTabEntry (url) {
    return gainEntryInternal(url, (x) => { window.open(x, '_blank') })
  }
  function checkEntryRequirement () {
    return context.$authUser.isLogin()
  }
  function composeLoginUrl (redirectUrl) {
    const setMemberLoginUrl = process.env.SETTRADE_MEMBER_LOGIN_URL
    return `${setMemberLoginUrl}&redirectUrl=${process.env.HOST_PROXY}${redirectUrl.slice(1)}`
  }

  inject('memberEntry', {
    gainEntry,
    gainNewTabEntry
  })
}
