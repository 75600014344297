export default async function ({ $authUser, redirect, $cookies, store, localePath }) {
  const accessToken = $cookies.get('sttid') || ''
  const permission = await store.dispatch('auth/authUser', accessToken)
  if (!permission) {
    $authUser.removeMemberCookie()
    const redirectUrl = $authUser.login()
    redirect(redirectUrl)
    // redirect(localePath({ name: 'home' }))
  }
}
