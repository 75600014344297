import Vue from 'vue';

import {
  TablePlugin,
  PaginationPlugin,
  ToastPlugin
} from 'bootstrap-vue';

Vue.use(TablePlugin);
Vue.use(PaginationPlugin);
Vue.use(ToastPlugin);
