import https from 'https'
import http from 'http'
const { selectApiEndpoint } = require('@/helpers/select-endpoint')
const _API_RESPONSE_TIME_TO_PRINT_LOG = 300
export default function ({ $axios, route }) {
  const ENV_USE_DEVELOPMENT = process.env.USE_DEVELOPMENT === 'true'
  const isDevelopment = ENV_USE_DEVELOPMENT ? true : process.env.NODE_ENV === 'development'
  $axios.defaults.httpAgent = new http.Agent({ keepAlive: true })
  $axios.defaults.httpsAgent = new https.Agent({
    keepAlive: true,
    rejectUnauthorized: process.env.HTTPS_AGENT === 'true'
  })
  const baseURL = process.server || isDevelopment ? process.env.HOST_PROXY : '/'
  $axios.setBaseURL(baseURL)
  $axios.interceptors.request.use((config) => {
    // Select api endpoint
    if (process.server) {
      const { endpoint, requestPath } = selectApiEndpoint(config.url)
      config.url = requestPath
      config.baseURL = endpoint

      // console.log('=================================================')
      // console.log('[INFO]', 'Page URL:', route.fullPath)
      // console.log('[INFO]', '[Request]API MARKET - path:', config.url)
      // console.log('[INFO]', '[Request]API MARKET - params:', config.params || '-')
      // console.log('[INFO]', '[Request]API MARKET - endpoint:', config.baseURL)
    }
    config.meta = config.meta || {}
    config.meta.requestStartedAt = new Date().getTime()
    return config
  }, (x) => {
    if (process.server) {
      console.log('=================================================')
      console.error('Page URL:', route.fullPath)
      console.error('[Request]API MARKET error details:', x)
    }
    throw x
  })

  $axios.interceptors.response.use((x) => {
    // console.log('[Response]API MARKET - baseURL:', x.config.baseURL)
    if (process.server) {
      const respTime = new Date().getTime() - x.config.meta.requestStartedAt
      const printRespLog = respTime >= _API_RESPONSE_TIME_TO_PRINT_LOG
      if (printRespLog) {
        console.log('=================================================')
        console.log('[INFO]', 'Page URL:', route.fullPath)
        console.log('[INFO]', '[Response]API MARKET - endpoint:', x.config.baseURL)
        console.log('[INFO]', `[Response]API MARKET - Execution time for: ${x.config.url} - ${respTime} ms`)
      }
    }

    return x
  }, (x) => {
    if (process.server) {
      console.log('=================================================')
      console.error('Page URL:', route.fullPath)
      console.error('[Response]API MARKET - status:', x.response?.status)
      console.error('[Response]API MARKET - endpoint:', x.config.baseURL)
      console.error(`[Response]API MARKET - Error response time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
      console.error('[Response]API MARKET - Error details:', x)
    }
    // console.error('[Response]API MARKET error details : ', x)
    throw x
  })
}
