//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SymbolSign',
  props: {
    symbol: {
      type: String,
      default: null
    },
    sign: {
      type: String,
      default: ''
    }
  },
  computed: {
    signs () {
      const _sign = this.sign
      const signs = _sign ? _sign.split(',') : [] // ['CB', 'CC', 'CF', 'T1', 'CS', 'NC', 'NP']
      return signs.map((s) => {
        const sign = s.trim()
        const className = /^X|^x/i.test(sign) ? 'fill' : '' // ถ้า sign ไม่ได้ขึ้นต้นด้วย X จะไม่มีสีพื้นหลัง
        return { sign, className }
      })
    }
  }
}
