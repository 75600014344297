const state = () => ({
  blockTrading: []
})

const mutations = {
  setBlockTrading (state, data) {
    state.blockTrading = data
  }
}

const actions = {
  async getBlockTrading ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/block-trading')
      commit('setBlockTrading', response)
    } catch (error) {
      commit('setBlockTrading', null)
    }
  }
}
const getters = {
  getBlockTrading: (state) => {
    return state.blockTrading
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
