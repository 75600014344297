const cateNews = [
  {
    name: 'การลงทุน',
    value: '60ad3cae-ba3d-4405-af14-3ed4af1e5065',
    param: 'investment'
  },
  {
    name: 'ข่าว SET',
    value: 'SETNEWS',
    param: 'set'
  },
  {
    name: 'หุ้น',
    value: 'S',
    param: 'stock'
  },
  {
    name: 'หน่วยลงทุน',
    value: 'U',
    param: 'unittrust'
  },
  {
    name: 'ETF',
    value: 'L',
    param: 'etf'
  },
  {
    name: 'DW',
    value: 'V',
    param: 'dw'
  },
  {
    name: 'DR',
    value: 'X',
    param: 'dr'
  },
  {
    name: 'DRx',
    value: 'XF',
    param: 'drx'
  },
  {
    name: 'อนุพันธ์',
    value: 'TFEX',
    param: 'tfex'
  },
  {
    name: 'กองทุนรวม',
    value: 'bcb41578-9ccf-42b4-bbd3-df8e6bb21d87',
    param: 'mutualfund'
  }
]

const cateResearch = [
  {
    name: 'หุ้น',
    value: 'c2b75714-6f90-4680-92cb-320c2492f099',
    param: 'stock'
  },
  {
    name: 'ETF',
    value: 'c9f93230-a50b-4871-ace3-6cb3f699a6a5',
    param: 'etf'
  },
  {
    name: 'DR',
    value: '7822d285-fd0a-4bc7-a3c8-edd856f7a829',
    param: 'dr'
  },
  {
    name: 'DRx',
    value: 'ab3f7e3b-5992-4ced-bfe0-5e92b87ee967',
    param: 'drx'
  },
  {
    name: 'DW',
    value: '7f6f8ebc-7a6f-4501-bd81-37f41f7c1107',
    param: 'dw'
  },
  {
    name: 'อนุพันธ์',
    value: '33b03e00-1ccd-4df1-999d-e1a78c8cd75b',
    param: 'tfex'
  },
  {
    name: 'กองทุนรวม',
    value: '28c49cf8-83ed-45fa-a60f-7f07bca6bbcd',
    param: 'mutualfund'
  },
  {
    name: 'อื่นๆ',
    value: '85427a18-4e4c-4b57-85cd-6bf1223616e8',
    param: 'other'
  }
]

function getCateParam (value) {
  const param = cateNews.filter((cate) => {
    return cate.value === value
  })
  if (param.length > 0) {
    return param[0].param
  } else {
    return ''
  }
}

function getValueFromParam (param) {
  const value = cateNews.filter((cate) => {
    return cate.param === param
  })
  if (value.length > 0) {
    return value[0].value
  } else {
    return ''
  }
}

function getCateParamResearch (value) {
  const param = cateResearch.filter((cate) => {
    return cate.value === value
  })
  if (param.length > 0) {
    return param[0].param
  } else {
    return ''
  }
}

function getValueFromParamResearch (param) {
  const value = cateResearch.filter((cate) => {
    return cate.param === param
  })
  if (value.length > 0) {
    return value[0].value
  } else {
    return ''
  }
}

function getMapPathName (uuid) {
  const value = getCateParamResearch(uuid)
  if (value === 'stock') {
    return 'equities-quote-slug-all'
  } else if (value === 'etf') {
    return 'equities-etf-quote-slug-all'
  } else if (value === 'dr') {
    return 'equities-dr-quote-slug-all'
  } else if (value === 'drx') {
    return 'equities-drx-quote-slug-all'
  } else if (value === 'dw') {
    return 'equities-dw-quote-slug-all'
  } else if (value === 'tfex') {
    return 'derivatives-quote-slug-all'
  } else if (value === 'mutualfund') {
    return 'mutualfund-quote-slug-all'
  } else {
    return ''
  }
}

export {
  getCateParam,
  getValueFromParam,
  getCateParamResearch,
  getValueFromParamResearch,
  getMapPathName
}
