import companyInformation from '@/store/equity/stock/preferred/companyInformation/index'

export const modules = {
  companyInformation
}

const getDefaultState = () => {
  return {
    quotationChart: null,
    quotationChartAccumulated: null,
    highlightData: null,
    pricePerformanceChart: [],
    historicalTrading: [],
    benefits: [],
    dividendChart: [],
    dividendChartYearly: [],
    newsList: null
  }
}

const state = getDefaultState

const mutations = {
  setQuotationChart (state, data) {
    state.quotationChart = data
  },

  setQuotationChartAccumulated (state, data) {
    state.quotationChartAccumulated = data
  },

  setHighlightData (state, data) {
    state.highlightData = data
  },

  setPricePerformanceChart (state, data) {
    state.pricePerformanceChart = data
  },

  setHistoricalTrading (state, data) {
    state.historicalTrading = data
  },

  setBenefits (state, data) {
    state.benefits = data
  },

  setDividendChart (state, data) {
    state.dividendChart = data
  },

  setDividendChartYearly (state, data) {
    state.dividendChartYearly = data
  },

  setNewsList (state, data) {
    state.newsList = data
  }
}

const actions = {
  async getQuotationChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/chart-quotation`, {
        params: {
          period,
          accumulated: false
        }
      })

      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },

  async getQuotationChartAccumulated ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/chart-quotation`, {
        params: {
          period,
          accumulated: true
        }
      })

      commit('setQuotationChartAccumulated', response)
    } catch {
      commit('setQuotationChartAccumulated', null)
    }
  },

  async getHighlightData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/highlight-data`)

      commit('setHighlightData', response)
    } catch {
      commit('setHighlightData', null)
    }
  },

  async getPricePerformanceChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const quoteData = this.$axios.$get(`/api/set/stock/${quote}/chart-performance`, {
        params: {
          period
        }
      })

      const indexData = this.$axios.$get(`/api/set/stock/${quote}/index-list`)

      const relatedData = this.$axios.$get(`/api/set/stock/${quote}/related-product/o`)

      const result = await Promise.allSettled([quoteData, indexData, relatedData]).then(async (data) => {
        const [{ value: quoteList }, { value: indexList }, { value: relatedList }] = data
        const quoteParent = relatedList.relatedProducts.find(item => item.securityType === 'S')
        const performanceData = []

        performanceData.push({
          legend: indexList.symbol,
          quotations: quoteList.quotations.map((item, index, array) => {
            return {
              datetime: item.datetime,
              pricePercent: item.price.toFixed(2)
            }
          }) || []
        })

        if (indexList.market && quoteParent.symbol) {
          const parentData = this.$axios.$get(`/api/set/stock/${quoteParent.symbol}/chart-performance`, {
            params: {
              period
            }
          })

          const marketData = this.$axios.$get(`/api/set/index/${indexList.market}/chart-performance`, {
            params: {
              period
            }
          })

          const [{ value: marketList }, { value: parentList }] = await Promise.allSettled([marketData, parentData])

          performanceData.push({
            legend: quoteParent.symbol,
            quotations: parentList.quotations.map((item, index, array) => {
              return {
                datetime: item.datetime,
                pricePercent: item.price.toFixed(2)
              }
            }) || []
          })

          performanceData.push({
            legend: indexList.market,
            quotations: marketList.quotations.map((item, index, array) => {
              return {
                datetime: item.datetime,
                pricePercent: item.price.toFixed(2)
              }
            }) || []
          })
        }

        return performanceData
      })

      commit('setPricePerformanceChart', result)
    } catch {
      commit('setPricePerformanceChart', [])
    }
  },

  async getHistoricalTrading ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/historical-trading`)

      commit('setHistoricalTrading', response)
    } catch {
      commit('setHistoricalTrading', [])
    }
  },

  async getBenefits ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/corporate-action`)

      commit('setBenefits', response)
    } catch {
      commit('setBenefits', [])
    }
  },

  async getDividendChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/dividend-chart`)

      commit('setDividendChart', response)
    } catch {
      commit('setDividendChart', [])
    }
  },

  async getDividendChartYearly ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/yearly-dividend-chart`)

      commit('setDividendChartYearly', response)
    } catch {
      commit('setDividendChartYearly', [])
    }
  },

  async getNewsList ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/news/${quote}/list`)

      commit('setNewsList', response)
    } catch {
      commit('setNewsList', null)
    }
  }
}

const getters = {
  pricePerformanceData: (state) => {
    return state.pricePerformanceChart.map(item => item.quotations)
  },
  pricePerformanceLegend: (state) => {
    return state.pricePerformanceChart.map(item => item.legend)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
