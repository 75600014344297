import Vue from 'vue'
import * as api from '@/helpers/favourite/api.js'

export default function (context, inject) {
  let initialized
  let priceInitialized

  const state = Vue.observable({
    stock: [],
    etf: [],
    dw: [],
    dr: [],
    drx: [],
    futures: [],
    options: [],
    fund: []
  })

  const category = [
    'stock',
    'etf',
    'dw',
    'dr',
    'drx',
    'futures',
    'options',
    'fund'
  ]

  const eventBus = new Vue()

  function initialization () {
    if (!initialized) {
      initialized = new Promise((resolve) => {
        (async () => {
          if (!context.$authUser.permissionUser()) {
            throw new Error('don\'t call this method before authenticating the user!')
          }

          const response = await Promise.all(category.map(key => api.getFavouriteByCategory.call(context, key)))
          for (const [index, cate] of category.entries()) {
            state[cate] = response[index]
          }

          resolve()
        })()
      })
    }
    return initialized
  }

  async function addFavourite (category, symbol) {
    try {
      const response = await await api.addFavourite.call(context, category, symbol)
      if (response.status === 201) {
        state[category].push(symbol)

        eventBus.$emit('favouriteChanged', category)
      }

      return {
        status: response.status,
        message: response.data.message
      }
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message
      }
    }
  }

  async function removeFavourite (category, symbol) {
    try {
      const response = await await api.removeFavourite.call(context, category, symbol)
      if (response.status === 202) {
        const index = state[category].indexOf(symbol)
        if (index !== -1) {
          state[category].splice(index, 1)
        }

        eventBus.$emit('favouriteChanged', category)
      }

      return {
        status: response.status,
        message: response.data.message
      }
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message
      }
    }
  }

  async function reloadFavourite (category) {
    if (state[category]) {
      state[category] = await api.getFavouriteByCategory.call(context, category)
    }
  }

  // TODO: shouldn't be here
  function openModalFavSymbolFull () {
    const { Modal } = require('bootstrap')
    const myModalEl = document.querySelector('#modalFullSymbol')
    const modal = Modal.getOrCreateInstance(myModalEl)
    modal.show()
  }

  const mapFavouritePrice = new Map([
    ['stock', 'member/favourite/getStockPrice'],
    ['etf', 'member/favourite/getETFPrice'],
    ['dw', 'member/favourite/getDWPrice'],
    ['dr', 'member/favourite/getDRPrice'],
    ['drx', 'member/favourite/getDRxPrice'],
    ['futures', 'member/favourite/getFuturesPrice'],
    ['options', 'member/favourite/getOptionsPrice'],
    ['fund', 'member/favourite/getFundPrice']
  ])

  const mapSetFavouritePrice = new Map([
    ['stock', 'member/favourite/setStockPrice'],
    ['etf', 'member/favourite/setETFPrice'],
    ['dw', 'member/favourite/setDWPrice'],
    ['dr', 'member/favourite/setDRPrice'],
    ['drx', 'member/favourite/setDRxPrice'],
    ['futures', 'member/favourite/setFuturesPrice'],
    ['options', 'member/favourite/setOptionsPrice'],
    ['fund', 'member/favourite/setFundPrice']
  ])

  function initialFavouritePrice () {
    if (!priceInitialized) {
      priceInitialized = new Promise((resolve) => {
        (async () => {
          if (!context.$authUser.permissionUser()) {
            throw new Error('don\'t call this method before authenticating the user!')
          }

          await Promise.all(category.map((key) => {
            if (state[key].length > 0) {
              context.store.dispatch(mapFavouritePrice.get(key), state[key])
            }

            return null
          }))
          resolve()
        })()
      })
    }
    return priceInitialized
  }

  async function reloadFavouritePrice (category) {
    await context.store.dispatch(mapFavouritePrice.get(category), state[category])
  }

  function removeFavouritePrice (category) {
    context.store.commit(mapSetFavouritePrice.get(category), null)
  }

  inject('favourite', {
    state,
    category,
    eventBus,
    initialization,
    addFavourite,
    removeFavourite,
    reloadFavourite,
    // TODO: shouldn't be here
    openModalFavSymbolFull,
    initialFavouritePrice,
    reloadFavouritePrice,
    removeFavouritePrice
  })
}
