import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=0d8a2c7c&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GoTop: require('/app/components/GoTop.vue').default,FooterDownloadApp: require('/app/components/Footer/FooterDownloadApp.vue').default,FooterSocial: require('/app/components/Footer/FooterSocial.vue').default,Divider: require('/app/components/Divider.vue').default,FooterBar: require('/app/components/Footer/FooterBar.vue').default,FooterSitemap: require('/app/components/Footer/Sitemap/FooterSitemap.vue').default,Footer: require('/app/components/Footer/Footer.vue').default})
