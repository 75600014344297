export const dotry = {
  methods: {
    dotry (callback) {
      return new Promise((resolve) => {
        callback()
        resolve()
      }).catch(() => {})
    }
  }
}
