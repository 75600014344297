//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppBarNav',
  // serverCacheKey: () => 'AppBarNav-v1',
  props: {
    lastUpdate: {
      type: Date,
      default: null
    },
    menuList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isMouseHover: false
    }
  },
  // mounted () {
  //   console.log(this.menuList)
  // },
  methods: {
    handleHover (menuId) {
      const menu = document.getElementById(`menu-level2_${menuId}`)
      if (menu) {
        // document.getElementById(`menu-level2_${menuId}`).classList.add('menuopening')
        this.isMouseHover = true
      }
    },
    handleLeave (menuId) {
      const menu = document.getElementById(`menu-level2_${menuId}`)
      if (menu) {
        // document.getElementById(`menu-level2_${menuId}`).classList.remove('menuopening')
        this.isMouseHover = false
      }
    },
    handleClickItem (item) {
      const { url, name, EventTracking: { isEtk } } = item
      if (!isEtk) { return }
      if (url && url !== '#') {
        this.$personalized.clickElement({
          name: 'all1',
          optionLang: {
            menuTitle: name
          }
        })
      }
    }
  }
}
