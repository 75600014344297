const marketListId = {
  IO: 'IO',
  IF: 'IF',
  SIF: 'SIF', // ยุบเป็น Equity Index Futures(IF/TXI_F)
  SF: 'SF',
  FT: 'FUTURES',
  PMF: 'PMF',
  DPM: 'DPM',
  CF: 'CF',
  IRF: 'IRF',
  AF: 'AF',
  TXI_O: 'TXI_O',
  TXI_C: 'TXI_C',
  TXI_P: 'TXI_P',
  TXI_F: 'TXI_F',
  TXS_F: 'TXS_F',
  TXM_F: 'TXM_F',
  TXD_F: 'TXD_F',
  TXC_F: 'TXC_F',
  TXR_F: 'TXR_F',
  TXA_F: 'TXA_F'
}

export {
  marketListId
}
