//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MiscMemberVirtualPortfolioValidationState',
  props: {
    errorLevel: {
      type: Number,
      default: 0
    },
    errorType: {
      type: String,
      default: undefined
    }
  }
}
