export default function ({ $cookies }, inject) {
  const cookieKey = 'customize'
  const pattern = '((?:[\\w-]+)(?:@[\\w-]+)?)\\|(\\w{3})\\[((?:[\\w-]+)(?:,(?:[\\w-]+))*)?\\]'
  const extractingRegex = new RegExp(pattern)
  const splittingRegex = new RegExp(pattern, 'g')
  const validModes = new Set(['dif', 'inc', 'exc'])

  function recallCustomizeFromCookie () {
    const cookieValue = $cookies.get(cookieKey)
    return cookieValue
      ?.match(splittingRegex)
      ?.map(x => x.match(extractingRegex).slice(1))
      .map(([location, mode, fields]) => ({ location, mode, fields: fields?.split(',') ?? [] }))
      ?? [] //eslint-disable-line
  }

  function getFieldsFromDiff (defaultFields, fields) {
    const diff = [...defaultFields]
    for (const field of fields) {
      const findIndex = diff.findIndex(x => x === field)
      if (findIndex >= 0) {
        diff.splice(findIndex, 1)
      } else {
        diff.push(field)
      }
    }
    return diff
  }

  function subtractArray (a, b) {
    const set = new Set(b)
    const result = []
    for (const value of a) {
      if (!set.has(value)) {
        result.push(value)
      }
    }
    return result
  }

  const customizeMemory = {
    rememberInclude (location, fields) {
      customizeMemory.remember({ location, mode: 'inc', fields })
    },
    recallInclude (location) {
      const recalled = customizeMemory.recall(location)
      if (recalled && recalled.mode !== 'inc') {
        throw new Error(`mismatched mode recalled; requesting 'inc', but instead got '${recalled.mode}'`)
      }
      return recalled?.fields
    },
    rememberExclude (location, fields, allFields) {
      if (!allFields) {
        throw new TypeError('invalid allFields')
      }
      const exclude = subtractArray(allFields, fields)
      customizeMemory.remember({ location, mode: 'exc', fields: exclude })
    },
    recallExclude (location, allFields) {
      if (!allFields) {
        throw new TypeError('invalid allFields')
      }

      const recalled = customizeMemory.recall(location)
      if (recalled && recalled.mode !== 'exc') {
        throw new Error(`mismatched mode recalled; requesting 'exc', but instead got '${recalled.mode}'`)
      }

      const exclude = recalled?.fields
      if (!exclude) {
        return undefined
      }

      const fields = subtractArray(allFields, exclude)
      return fields
    },
    rememberDiff (location, fields, defaultFields) {
      if (!defaultFields) {
        throw new TypeError('invalid defaultFields')
      }
      const diff = getFieldsFromDiff(defaultFields, fields)
      customizeMemory.remember({ location, mode: 'dif', fields: diff })
    },
    recallDiff (location, defaultFields) {
      if (!defaultFields) {
        throw new TypeError('invalid defaultFields')
      }

      const recalled = customizeMemory.recall(location)
      if (recalled && recalled.mode !== 'dif') {
        throw new Error(`mismatched mode recalled; requesting 'dif', but instead got '${recalled.mode}'`)
      }

      const diff = recalled?.fields
      if (!diff) {
        return undefined
      }

      const fields = getFieldsFromDiff(defaultFields, diff)
      return fields
    },
    /**
     * Remember the customize options into the cookies
     * @param {{ location: string, mode: string, fields: string[] }} customize
     */
    remember (customize) {
      if (!customize.location || !/^[\w-]+(@[\w-]+)?$/.test(customize.location)) {
        throw new TypeError('invalid location format')
      }

      if (!customize.mode || !validModes.has(customize.mode)) {
        throw new TypeError('invalid mode value')
      }

      if (customize.fields && !customize.fields.every(x => /^[\w-]+$/.test(x))) {
        throw new TypeError('some of the fields contain invalid value')
      }

      const allCustomize = recallCustomizeFromCookie()
      const findIndex = allCustomize.findIndex(x => x.location === customize.location)
      if (findIndex >= 0) {
        allCustomize[findIndex] = customize
      } else {
        allCustomize.push(customize)
      }
      const serializedValue = allCustomize
        .map(x => `${x.location}|${x.mode}[${x.fields?.join(',') ?? ''}]`)
        .join(',')
      $cookies.set(cookieKey, JSON.stringify(serializedValue), { path: '/', maxAge: 365 * 24 * 60 * 60 })
    },
    /**
     * Recall the customize options from the cookies
     * @param {String} location
     * @returns {{ location: string, mode: string, fields: string[] }}
     */
    recall (location) {
      const recalledCustomize = recallCustomizeFromCookie()
      return recalledCustomize.find(x => x.location === location)
    }
  }
  inject('customizeMemory', customizeMemory)
}
