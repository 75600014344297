
import cloneDeep from 'lodash/cloneDeep'
import { convertStyleToObject } from './helpers'

/**
 * call for set raw Style
 *
 * @param {Object} content
 * @return {*}
 */
const setStyle = (content) => {
  const styleWebsite = cloneDeep(content.head.customCss) || null // string style website
  if (styleWebsite) {
    return convertStyleToObject(decodeURI(styleWebsite))
  }
  return []
}
/**
 * call for set raw Style client
 *
 * @param {Object} content
 */
const setStyleClient = (content) => {
  const styleWebsite = cloneDeep(content.head.customCss) || null // string style website
  if (styleWebsite) {
    document.head.insertAdjacentHTML('beforeend', decodeURI(styleWebsite))
  }
}

export {
  setStyle,
  setStyleClient
}
