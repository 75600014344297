const dropdownSymbol = Symbol('Bootstrap\'s Dropdown Object')
export const dropdownShown = {
  async bind (el, binding, vnode) {
    const { Dropdown } = await import('bootstrap')
    el[dropdownSymbol] = new Dropdown(el, {
      display: 'dynamic'
    })
  },
  update (el, binding, vnode) {
    if (binding.value !== binding.oldValue) {
      const dropdown = el[dropdownSymbol]
      if (binding.value) {
        dropdown.show()
      } else {
        dropdown.hide()
      }
    }
  }
}
