/**
 * Get render resource
 * @param {Array} renderResource
 * @param {String} resourceKey
 * @returns Object
 */
const getResource = (renderResource, resourceKey) => {
  if (renderResource.length) {
    const resource = renderResource.filter((r) => {
      return r.key === resourceKey
    })
    if (resource.length) {
      return resource[0]
    }
  }
  return {}
}

const isPreview = (contentUrl) => {
  return /\/preview\//i.test(contentUrl)
}

export {
  getResource,
  isPreview
}
