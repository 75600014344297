const state = () => ({
  indexListDropDown: null,
  industryList: null,
  securitiesList: null,
  securitiesTypeList: null,
  indexCompositionList: null,
  optionFilter: {
    market: 'all',
    security: 'all',
    sector: null,
    industry: null,
    index: null,
    indexSymbol: null
  }
})

const actions = {
  async fetchIndexListDropDown ({ commit }, payload) {
    const { type, market } = payload
    try {
      const response = await this.$axios.$get(
        `api/set/index/list?type=${type}&market=${market}`
      )
      commit('setIndexListDropDown', response)
      return response || []
    } catch (err) {
      commit('setIndexListDropDown', null)
    }
  },

  async fetchIndexIndustryList ({ commit }, payload) {
    const { market } = payload
    try {
      const response = await this.$axios.$get(
        `api/set/index/info/list?type=industry&market=${market}`
      )
      if (response && response.indexIndustrySectors.length > 0) {
        let industryList = []
        const indexIndustrySectors = response.indexIndustrySectors
        const INDUSTRY = indexIndustrySectors.filter(
          item => item.level === 'INDUSTRY'
        )
        industryList = INDUSTRY.map(industry => ({
          ...industry,
          sectorList: indexIndustrySectors.filter(
            item =>
              item.industryName === industry.industryName &&
              item.level === 'SECTOR'
          )
        }))
        commit('setIndexIndustryList', industryList)
      }
    } catch (err) {
      commit('setIndexIndustryList', null)
    }
  },

  async fetchSecuritiesList ({ commit }, payload) {
    const { market } = payload
    try {
      commit('setSecuritiesList', null)
      commit('setSecuritiesTypeList', null)

      const response = this.$axios.$get(
        `api/set/index/list?type=industry&market=${market}`
      )
      const [{ value: indexList }] = await Promise.allSettled([response])

      if (indexList) {
        const INDUSTRY = indexList.filter(item => item.level === 'INDUSTRY')
        const SECTOR = indexList.filter(item => item.level === 'SECTOR')

        if (market === 'SET') {
          let sectorService = []
          SECTOR.forEach((sectorItem) => {
            sectorService = [
              ...sectorService,
              this.$axios.$get(
                `api/set/stock-info/list-by-index?index=${encodeURIComponent(
                  sectorItem.querySymbol
                )}`
              )
            ]
          })

          let securitiesList = []

          const sectorList = await Promise.allSettled([...sectorService])
          for (const [index, { value }] of [...sectorList].entries()) {
            SECTOR[index].items = value
          }
          for (const indexItem of INDUSTRY) {
            securitiesList = [
              ...securitiesList,
              {
                ...indexItem,
                sectorList: SECTOR.filter(
                  item =>
                    item.parentIndex === indexItem.querySymbol &&
                    item.level === 'SECTOR'
                )
              }
            ]
          }

          commit('setSecuritiesList', securitiesList)
        } else if (market === 'mai') {
          let sectorService = []

          INDUSTRY.forEach((industryItem) => {
            sectorService = [
              ...sectorService,
              this.$axios.$get(
                `api/set/stock-info/list-by-index?index=${encodeURIComponent(
                  industryItem.querySymbol
                )}`
              )
            ]
          })

          let securitiesList = []

          await Promise.allSettled([...sectorService]).then((res) => {
            for (const [index, { value }] of res.entries()) {
              securitiesList = [
                ...securitiesList,
                { ...INDUSTRY[index], items: value }
              ]
            }
          })

          commit('setSecuritiesList', securitiesList)
        }
      }
    } catch (err) {
      commit('setSecuritiesList', null)
    }
  },

  async fetchSecuritiesTypeList ({ commit }, payload) {
    const { type, market } = payload

    commit('setSecuritiesList', null)
    commit('setSecuritiesTypeList', null)
    try {
      const response = await this.$axios.$get(
        `api/set/stock-info/list-by-security-type?securityType=${type}&market=${market}&filterTraded=${true}`
      )
      commit('setSecuritiesTypeList', response)
    } catch (err) {
      commit('setSecuritiesTypeList', null)
    }
  },

  async fetchIndexCompositionList ({ commit }, payload) {
    const { index } = payload
    try {
      // const marketComposition = this.$axios.$get(`api/set/index/${market}/info`)
      // commit('setIndexCompositionChart', null)
      const symbolComposition = this.$axios.$get(
        `api/set/index/${index}/composition`
      )
      // const [
      //   { value: marketInfos },
      //   {
      //     value: { indexInfos, composition }
      //   }
      // ] = await Promise.allSettled([marketComposition, symbolComposition])
      const [
        {
          // value: { indexInfos, composition }
          value: { composition }
        }
      ] = await Promise.allSettled([symbolComposition])
      // commit('setIndexCompositionInfo', indexInfos)
      commit('setIndexCompositionList', composition)

      // if (
      //   composition &&
      //   composition.subIndices &&
      //   composition.subIndices.length > 0
      // ) {
      //   let stockListService = []
      //   const stockListServiceList = []

      //   console.log('🚀 ~ stockList1', composition)
      //   for (const [indexIndices, { stockInfos }] of composition.subIndices.entries()) {
      //     for (const { symbol } of stockInfos) {
      //       stockListService = [
      //         ...stockListService,
      //         this.$axios.$get(
      //           `api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=true`
      //         )
      //       ]
      //     }
      //     await Promise.allSettled([...stockListService]).then((res) => {
      //       stockListServiceList[indexIndices] = res.map(item => item.value)
      //     })
      //   }
      //   console.log('🚀 ~ stockList2', stockListServiceList)
      //   commit('setIndexCompositionChart', stockListServiceList)
      // }
      // if (
      //   composition &&
      //   composition.stockInfos &&
      //   composition.stockInfos.length > 0
      // ) {
      //   let stockListService = []
      //   let stockListServiceList = []
      //   for (const { symbol } of composition.stockInfos) {
      //     stockListService = [
      //       ...stockListService,
      //       this.$axios.$get(
      //         `api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=true`
      //       )
      //     ]
      //   }
      //   await Promise.allSettled([...stockListService]).then((res) => {
      //     for (const { value } of res) {
      //       stockListServiceList = [...stockListServiceList, value]
      //     }
      //   })
      //   commit('setIndexCompositionChart', stockListServiceList)
      //   console.log('🚀 ~ stockListServiceList', stockListServiceList)
      // }
    } catch (err) {
      commit('setIndexCompositionList', null)
    }
  },

  defaultFilterOption ({ commit }, data) {
    commit('setOptionFilter', data)
  }
}

const mutations = {
  setIndexListDropDown (state, data) {
    state.indexListDropDown = data
  },

  setIndexIndustryList (state, data) {
    state.industryList = data || []
  },

  setSecuritiesList (state, data) {
    state.securitiesList = data
  },

  setSecuritiesTypeList (state, data) {
    state.securitiesTypeList = data
  },

  setIndexCompositionList (state, data) {
    state.indexCompositionList = data
  },

  setOptionFilter (state, data) {
    state.optionFilter = data
  }
}

const getters = {
  getIndexListDropDown (state) {
    return state.indexListDropDown
  },

  getIndexIndustryList (state) {
    return state.industryList
  },

  getSecuritiesList (state) {
    return state.securitiesList
  },

  getSecuritiesTypeList (state) {
    return state.securitiesTypeList
  },

  getIndexCompositionList (state) {
    return state.indexCompositionList
  },

  getOptionFilter (state) {
    return state.optionFilter
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
