import * as api from '@/helpers/mutualfund/api.js'

const getDefaultState = () => {
  return {
    overviewInfo: null,
    risk: null,
    performance: null,
    historicalPerformance: null,
    quotationChart: null,
    dividend: null,
    fundFee: null,
    fundSubscription: null,
    fundAssetAllocation: null,
    fundTop5Holding: null,
    newsList: null,
    recommendList: null,
    thematic: null
  }
}

const state = getDefaultState

const mutations = {
  setOverviewInfo (state, data) {
    state.overviewInfo = data
  },
  setRisk (state, data) {
    state.risk = data
  },
  setPerformance (state, data) {
    state.performance = data
  },
  setHistoricalPerformance (state, data) {
    state.historicalPerformance = data
  },
  setQuotationChart (state, data) {
    state.quotationChart = data
  },
  setDividend (state, data) {
    state.dividend = data
  },
  setFundFee (state, data) {
    state.fundFee = data
  },
  setFundSubscription (state, data) {
    state.fundSubscription = data
  },
  setFundAssetAllocation (state, data) {
    state.fundAssetAllocation = data
  },
  setFundTop5Holding (state, data) {
    state.fundTop5Holding = data
  },
  setNewsList (state, data) {
    state.newsList = data
  },
  setRecommendList (state, data) {
    state.recommendList = data
  },
  setThematic (state, data) {
    state.thematic = data
  }
}

const actions = {
  async getOverviewInfo ({ commit }, payload) {
    const { quote } = payload
    const response = await api.getFundInformation.call(this, quote, this.$i18n.locale)
    commit('setOverviewInfo', response)
  },
  async getRisk ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await api.getFundRiskProfile.call(this, quote)
      commit('setRisk', response)
    } catch {
      commit('setRisk', null)
    }
  },
  async getPerformance ({ commit }, payload) {
    const { quote } = payload
    const response = await api.getFundPerformance.call(this, quote)
    commit('setPerformance', response)
  },
  async getHistoricalPerformance ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await api.getETFPerformance.call(this, quote)
      commit('setHistoricalPerformance', response)
    } catch {
      commit('setHistoricalPerformance', null)
    }
  },
  async getQuotationChart ({ commit }, payload) {
    const { quote, period, accumulated } = payload
    try {
      const isLogin = await this.$authUser.isLogin()
      const response = await getResult.call(this, isLogin)
      function getResult (isLogin) {
        if (isLogin) {
          return api.getFundHistoricalNAVWithMember.call(this, quote, period, accumulated)
        } else {
          return api.getFundHistoricalNAV.call(this, quote, period, accumulated)
        }
      }
      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },
  async getDividend ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await api.getFundDividend.call(this, quote)
      commit('setDividend', response)
    } catch {
      commit('setDividend', null)
    }
  },
  async getFundFee ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await api.getFundFeeInformation.call(this, quote)
      commit('setFundFee', response)
    } catch {
      commit('setFundFee', null)
    }
  },
  async getFundSubscription ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await api.getFundSubscription.call(this, quote)
      commit('setFundSubscription', response)
    } catch {
      commit('setFundSubscription', null)
    }
  },
  async getFundAssetAllocation ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await api.getFundAssetAllocation.call(this, quote)
      commit('setFundAssetAllocation', response)
    } catch {
      commit('setFundAssetAllocation', null)
    }
  },
  async getFundTop5Holding ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await api.getFundTop5Holding.call(this, quote)
      commit('setFundTop5Holding', response)
    } catch {
      commit('setFundTop5Holding', null)
    }
  },
  async getNewsList ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/news/${api.fixSymbol(quote)}/list`)

      commit('setNewsList', response)
    } catch {
      commit('setNewsList', null)
    }
  },
  async getRecommendList ({ commit }, payload) {
    const { quote } = payload
    try {
      const responsess = await api.getRelatedRecommendedFunds.call(this, quote)
      const items = responsess.slice(0, 3)
      const results = []
      for (const symbol of items) {
        const overview = api.getFundInformation.call(this, symbol)
        const performance = api.getFundPerformance.call(this, symbol)
        const result = await Promise.allSettled([overview, performance])
          .then((data) => {
            const [{ value: item1 }, { value: item2 }] = data
            const performances = item2?.performances
            const performance = performances
              ? performances?.find(x => x.period === '1Y')
              : {}
            return {
              symbol: item1.symbol,
              name: item1.name,
              date: item1.date,
              navPerUnit: item1.navPerUnit,
              nav: item1.nav,
              percentChange: performance?.percentChange
            }
          })
          .catch(_error => null)
        if (result) {
          results.push(result)
        }
      }
      commit('setRecommendList', results)
    } catch {
      commit('setRecommendList', null)
    }
  },
  async getThematic ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set-fund/fund/aimc-type-performance/list'
      )
      commit('setThematic', response)
    } catch {
      commit('setThematic', null)
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
