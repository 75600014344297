import { render, staticRenderFns } from "./Standard.vue?vue&type=template&id=a90ece72&"
import script from "./Standard.vue?vue&type=script&lang=js&"
export * from "./Standard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerItems: require('/app/components/Banner/BannerItems.vue').default,BannerPersonalized: require('/app/components/Banner/Personalized.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default})
