//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setAttributeLink } from '~/helpers'
export default {
  name: 'FooterBar',
  props: {
    lastUpdate: {
      type: Date,
      default: null
    },
    footerMenuDisclaimerAndPolicy: {
      type: Array,
      default: Array
    }
  },
  // serverCacheKey: props => `FooterBar-${props.footerMenuDisclaimerAndPolicy?.length}`,
  serverCacheKey: props => `M_POLICY_FOOTER::${props.lastUpdate?.toISOString()}`,
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    setAttrLink (menu) {
      return setAttributeLink(menu)
    },
    triggerOpenSitemap () {
      const sitemapEl = document.getElementById('footer-site-map')
      const heightSitemap = document.getElementById('footer-site-map-wrapper').offsetHeight
      const isShow = sitemapEl.classList.contains('show')
      if (isShow) {
        sitemapEl.style.height = `${document.getElementById('footer-site-map-wrapper').offsetHeight}px`
        setTimeout(() => {
          this.$nextTick(() => {
            sitemapEl.classList.remove('show')
            sitemapEl.style.height = '0px'
            this.isActive = false
          })
        }, 200)
      } else {
        sitemapEl.classList.add('show')
        sitemapEl.style.height = `${heightSitemap}px`
        setTimeout(() => {
          this.$nextTick(() => {
            sitemapEl.style.height = 'auto'
            this.isActive = true
          })
        }, 200)
      }
    },
    handleClickItem (menuTitle, url) {
      if (url && url !== '#') {
        this.$personalized.clickElement({
          name: 'all15',
          optionLang: {
            menuTitle
          }
        })
      }
    }
  }
}
