import dayjs from 'dayjs'

const getDefaultState = () => {
  return {
    underlying: null,
    quotationChart: null,
    historicalTrading: [],
    historicalSessionTrading: [],
    futuresPriceCalculation: null,
    intradaySessionQuotation: null
  }
}

const state = getDefaultState

const mutations = {
  setUnderlying (state, data) {
    state.underlying = data
  },

  setQuotationChart (state, data) {
    state.quotationChart = data
  },

  setHistoricalTrading (state, data) {
    state.historicalTrading = data
  },

  setHistoricalSessionTrading (state, data) {
    state.historicalSessionTrading = data
  },

  setFuturesPriceCalculation (state, data) {
    state.futuresPriceCalculation = data
  },

  setIntradaySessionQuotation (state, data) {
    state.intradaySessionQuotation = data
  }
}

const actions = {
  async getUnderlying ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/underlying/currency/reference-data')

      commit('setUnderlying', response)
    } catch {
      commit('setUnderlying', null)
    }
  },

  async getQuotationChart ({ commit }, payload) {
    const { quote, period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${quote}/chart-quotation`,
        {
          params: {
            period
          }
        }
      )

      commit('setQuotationChart', response)
    } catch {
      commit('setQuotationChart', null)
    }
  },

  async getHistoricalTrading ({ commit }, payload) {
    const { quote } = payload
    // const now = dayjs()
    // const from = now.subtract(6, 'month').format('DD/MM/YYYY')
    // const to = now.format('DD/MM/YYYY')
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${quote}/historical-trading`,
        {
          params: {
            // fromDate: from,
            // toDate: to
          }
        }
      )
      commit('setHistoricalTrading', response)
    } catch {
      commit('setHistoricalTrading', {})
    }
  },

  async getHistoricalSessionTrading ({ commit }, payload) {
    const { quote } = payload
    const now = dayjs()
    const from = now.subtract(6, 'month').format('DD/MM/YYYY')
    const to = now.format('DD/MM/YYYY')
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series/${quote}/historical-session-trading`,
        {
          params: {
            fromDate: from,
            toDate: to
          }
        }
      )

      commit('setHistoricalSessionTrading', response)
    } catch {
      commit('setHistoricalSessionTrading', [])
    }
  },

  async getFuturesPriceCalculation ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series/${quote}/futures-price-calculation`)

      commit('setFuturesPriceCalculation', response)
    } catch {
      commit('setFuturesPriceCalculation', null)
    }
  },

  async getIntradaySessionQuotation ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/tfex/series/${quote}/intraday-session-trading`)

      commit('setIntradaySessionQuotation', response)
    } catch {
      commit('setIntradaySessionQuotation', null)
    }
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
