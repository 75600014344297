import { formatDateShort } from '@/helpers/dateTime'
import { marketListId } from '@/utils/marketlist'
const state = () => ({
  instrumentType: [],
  tradingDate: [],
  underlyingSector: [],
  underlyingPrice: [],
  referenceMetal: {},
  instrumentClass: [],
  instrumentTrading: {
    instruments: []
  },
  optionsTrading: {
    instruments: [{
      contractMonths: []
    }]
  },
  currentInstrumentType: '',
  currentTradingDate: '',
  currentUnderlyingSector: '',
  loading: false
})

const mutations = {
  setInstrumentType (state, data) {
    state.instrumentType = data
  },
  setTradingDate (state, data) {
    state.tradingDate = data
  },
  setUnderlyingPrice (state, data) {
    state.underlyingPrice = data
  },
  setReferenceMetal (state, data) {
    state.referenceMetal = data
  },
  setUnderlyingSector (state, data) {
    state.underlyingSector = data
  },
  setInstrumentClass (state, data) {
    if (data && data.instruments && data.instruments.length > 0) {
      state.instrumentClass = data.instruments.map((item) => {
        return {
          label: item.value,
          value: item.value
        }
      })
    } else {
      state.instrumentClass = []
    }
  },
  setInstrumentTrading (state, data) {
    state.instrumentTrading = data ?? {
      instruments: []
    }
  },
  setOptionsTrading (state, data) {
    state.optionsTrading = data ?? {
      instruments: [{
        contractMonths: []
      }]
    }
  },
  setCurrentInstrumentType (state, data) {
    state.currentInstrumentType = data
  },
  setCurrentTradingDate (state, data) {
    state.currentTradingDate = data
  },
  setCurrentUnderlyingSector (state, data) {
    state.currentUnderlyingSector = data
  },
  setLoading (state, data) {
    state.loading = data
  }
}

const actions = {
  async getInstrumentType ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/list')
      commit('setInstrumentType', response)
    } catch (error) {
      commit('setInstrumentType', null)
    }
  },
  async getTradingDate ({ commit }, marketList) {
    try {
      marketList = marketList || marketList !== '' ? marketList : 'IF'
      const response = await this.$axios.$get(`/api/set/tfex/marketlist/${marketList}/trading-date`)
      commit('setTradingDate', response)
    } catch (error) {
      commit('setTradingDate', null)
    }
  },
  async getUnderlyingPrice ({ commit }, marketList) {
    try {
      marketList = marketList || marketList !== '' ? marketList : 'IF'
      const response = await this.$axios.$get(`/api/set/tfex/underlying/equity/${marketList}/price`)
      commit('setUnderlyingPrice', response)
    } catch (error) {
      commit('setUnderlyingPrice', null)
    }
  },
  async getReferenceMetal ({ commit }, marketList) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/underlying/metal/reference-data')
      commit('setReferenceMetal', response)
    } catch (error) {
      commit('setReferenceMetal', null)
    }
  },
  async getUnderlyingSector ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/tfex/market/SF/underlying-sector')
      commit('setUnderlyingSector', response)
    } catch (error) {
      commit('setUnderlyingSector', null)
    }
  },
  async getInstrumentClass ({ commit }, payload) {
    const { typeId } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/tfex/series-search/instrument?marketListId=${typeId}`
      )
      commit('setInstrumentClass', response)
    } catch (error) {
      commit('setInstrumentClass', null)
    }
  },
  async getInstrumentTrading ({ commit }, payload) {
    const { marketList, tradeDateType, underlyingSector } = payload
    try {
      let url = `/api/set/tfex/marketlist/${marketList || marketList !== '' ? marketList : marketListId.IF}/instrument-trading?`
      url += tradeDateType ? `tradeDateType=${tradeDateType}&` : ''
      url += (marketList === marketListId.SF || marketList === marketListId.TXS_F) && underlyingSector ? `underlyingSector=${underlyingSector}&` : ''
      const response = await this.$axios.$get(url)
      commit('setInstrumentTrading', response)
    } catch (error) {
      commit('setInstrumentTrading', null)
    }
  },
  async getOptionsTrading ({ commit }, payload) {
    const { marketList, tradeDateType } = payload
    try {
      let url = `/api/set/tfex/marketlist/${marketList || marketList !== '' ? marketList : marketListId.IF}/options-trading?`
      url += tradeDateType ? `tradeDateType=${tradeDateType}&` : ''
      const response = await this.$axios.$get(url)
      commit('setOptionsTrading', response)
    } catch (error) {
      commit('setOptionsTrading', null)
    }
  },
  setCurrentInstrumentType ({ commit }, instrumentType) {
    commit('setCurrentInstrumentType', instrumentType)
  },
  setCurrentTradingDate ({ commit }, data) {
    commit('setCurrentTradingDate', data)
  },
  setCurrentUnderlyingSector ({ commit }, data) {
    commit('setCurrentUnderlyingSector', data)
  },
  setLoading ({ commit }, loading) {
    commit('setLoading', loading)
  }
}
const getters = {
  getInstrumentType: (state) => {
    return state.instrumentType ?? []
  },
  getTradingDate: (state) => {
    return state.tradingDate ?? []
  },
  getUnderlyingSector: (state) => {
    return state.underlyingSector ?? []
  },
  getUnderlyingPrice: (state) => {
    return state.underlyingPrice ?? []
  },
  getReferenceMetal: (state) => {
    return state.referenceMetal ?? {}
  },
  getInstrumentClass: (state) => {
    return state.instrumentClass ?? []
  },
  getInstrumentTrading: (state) => {
    return state.instrumentTrading
  },
  getOptionsTrading: (state) => {
    return state.optionsTrading
  },
  getCurrentInstrumentType: (state) => {
    return state.currentInstrumentType
  },
  getCurrentLabelInstrumentType: (state) => {
    const labelInstrumentType = state.instrumentType.find((data) => {
      return data.instrumentId === state.currentInstrumentType
    })?.name ?? 'ทั้งหมด'
    return labelInstrumentType
  },
  getCurrentLabelTradingDate: (state) => {
    if (state.tradingDate && state.tradingDate.length && state.tradingDate[0].dateList && state.tradingDate[0].dateList.length > 0) {
      const labelTradingDate = state.tradingDate[0].dateList.find((data) => {
        return data.type === state.currentTradingDate
      })?.date ?? new Date()
      return formatDateShort(labelTradingDate)
    }
    return ''
  },
  getCurrentLabelUnderlyingSector: (state) => {
    const labelUnderlyingSector = state.underlyingSector.find((data) => {
      return data.id === state.currentUnderlyingSector
    })?.id ?? 'ทั้งหมด'
    return labelUnderlyingSector
  },
  getLoading: (state) => {
    return state.loading ?? false
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
