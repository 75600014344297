
import axios from 'axios'
const state = () => ({
  user: null,
  fullName: null,
  activityLogData: {
    ipAddress: '',
    Browser: '',
    BrowserVersion: '',
    ChannelCode: ''
  }
})

const mutations = {
  setUser (state, data) {
    state.user = data
  },
  setFullName (state, data) {
    state.fullName = data
  },
  setActivityLogData (state, data) {
    state.activityLogData.ipAddress = data.ipAddress
    state.activityLogData.Browser = data.Browser
    state.activityLogData.BrowserVersion = data.BrowserVersion
    state.activityLogData.ChannelCode = data.ChannelCode
  }
}

const actions = {
  async getUserStatus ({ commit }, token) {
    try {
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
      }
      const response = await axios.get(process.env.API_STATUS)
      return response.data
    } catch (e) {
      return null
    }
  },

  async addUser ({ commit }, data) {
    if (data) {
      await this.$axios
        .post(process.env.API_ENDPOINT + 'setmember/saveSetMem', data)
        .then((res) => {
          if (res) {
            commit('setUser', data)
            commit('setFullName', data.firstName + ' ' + data.lastName)
          }
        })
        .catch(() => {
          commit('setUser', null)
        })
    } else {
      commit('setUser', null)
    }
  },

  async logOut ({ commit, state }) {
    try {
      const data = {
        ActivityLogData: state.activityLogData,
        userRefToken: state.user.token
      }
      await this.$axios
        .post(process.env.API_ENDPOINT + 'setmember/logout', data)
    } catch (e) {
      return e
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
