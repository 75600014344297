const getDefaultState = () => {
  return {
    activeFundList: [],
    amcList: [],
    typeFundList: [],
    specificationCodeList: [],
    searchResult: []
  }
}

const state = getDefaultState

const mutations = {
  setActiveFundList (state, data) {
    state.activeFundList =
      data && data.funds
        ? data.funds.map((data) => {
          return {
            ...data
          }
        })
        : []
  },

  setAmcList (state, data) {
    state.amcList = data && data.fundAmcs
      ? data.fundAmcs.map((data) => {
        return {
          value: data.id,
          label: `${data.code} (${this.$i18n.locale === 'th' ? data.nameTh : data.nameEn})`
        }
      })
      : []
  },

  setTypeFundList (state, data) {
    const CONST_LABEL_GROUP = {
      Domestic_Equity: 'กองทุนรวมตราสารทุนในประเทศ/ต่างประเทศบางส่วน',
      Foreign_Equity: 'กองทุนรวมตราสารทุนต่างประเทศ',
      Sector_Fund: 'กองทุนรวมตราสารทุนประเภท Sector Fund',
      Index_Fund: 'กองทุนรวมตราสารทุนประเภทกองทุนรวมดัชนี',
      Domestic_Fixed_Income: 'กองทุนรวมตราสารหนี้ในประเทศ/ต่างประเทศบางส่วน',
      Foreign_Fixed_Income: 'กองทุนรวมตราสารหนี้ต่างประเทศ',
      Domestic_Allocation: 'กองทุนรวมผสมในประเทศ/ต่างประเทศบางส่วน',
      Foreign_Allocation: 'กองทุนรวมผสมต่างประเทศ',
      Property: 'กองทุนรวมอสังหาริมทรัพย์/Infra/REIT',
      Commodities: 'กองทุนรวม Commodities',
      Miscellaneous: 'กองทุนรวมที่ไม่สามารถจัดกลุ่มได้'
    }
    state.typeFundList = data && data.fundTypeGroups
      ? data.fundTypeGroups.map((data) => {
        return {
          label: CONST_LABEL_GROUP[data.fundTypes[0].groupCode],
          children: data.fundTypes.map((fundType) => {
            return {
              value: fundType.aimcType,
              label: `${fundType.description} (${fundType.aimcType})`
            }
          })
        }
      })
      : []
  },

  setSpecificationCodeList (state, data) {
    state.specificationCodeList = data && data.specificationCodes
      ? data.specificationCodes.map((data) => {
        return {
          value: data.code,
          label: data.description === 'ALL' ? 'ทั่วไป' : data.description
        }
      })
      : []
  },

  setSearchResult (state, data) {
    state.searchResult = data && data.filterFunds
      ? data.filterFunds.map((data) => {
        return {
          ...data.overviewInfo,
          ...data.performanceInfo,
          ...data.feeInfo
        }
      })
      : []
  }
}

const actions = {
  async getActiveFundList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set-fund/fund/active-fund/list')
      commit('setActiveFundList', response)
    } catch {
      commit('setActiveFundList', [])
    }
  },

  async getAmcList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set-fund/fund/amc/list')
      commit('setAmcList', response)
    } catch {
      commit('setAmcList', [])
    }
  },

  async getTypeFundList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set-fund/fund/type/list')
      commit('setTypeFundList', response)
    } catch {
      commit('setTypeFundList', [])
    }
  },

  async getSpecificationCodeList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set-fund/fund/specification-code/list')
      commit('setSpecificationCodeList', response)
    } catch {
      commit('setSpecificationCodeList', [])
    }
  },

  async getSearchResult ({ commit }, payload) {
    const {
      amcId,
      aimcType,
      specificationCode,
      isMoreThan,
      percentageReturn,
      performancePeriod,
      dividendPolicy
    } = payload
    try {
      const response = await this.$axios.$get('/api/set-fund/fund-compare/list', {
        params: {
          lang: this.$i18n.locale,
          amcId,
          aimcType,
          specificationCode: specificationCode.join(),
          isMoreThan: isMoreThan === 'TRUE' ? true : isMoreThan === 'FALSE' ? false : null,
          percentageReturn: isMoreThan !== null ? percentageReturn : null,
          performancePeriod: isMoreThan !== null ? performancePeriod : null,
          dividendPolicy
        }
      })

      commit('setSearchResult', response)
    } catch (error) {
      commit('setSearchResult', null)
    }
  }
}

const getters = {
  overviewInfo: (state) => {
    return state.searchResult.map((data) => {
      return {
        symbol: data.symbol,
        name: data.name,
        amcName: data.amcName,
        aimcType: data.aimcType,
        ytdPercentChange: data.ytdPercentChange,
        dividendPolicy: data.dividendPolicy,
        navPerUnit: data.navPerUnit,
        date: data.date,
        managementStyle: data.managementStyle,
        riskLevel: data.riskLevel,
        taxAllowance: data.taxAllowance,
        nav: data.nav
      }
    }) || []
  },
  performanceInfo: (state) => {
    return state.searchResult.map((data) => {
      return {
        symbol: data.symbol,
        amcName: data.amcName,
        navPerUnit: data.navPerUnit,
        date: data.date,
        ytdPercentChange: data.ytdPercentChange,
        oneMonthPercentChange: data.oneMonthPercentChange,
        threeMonthPercentChange: data.threeMonthPercentChange,
        sixMonthPercentChange: data.sixMonthPercentChange,
        oneYearPercentChange: data.oneYearPercentChange,
        threeYearPercentChange: data.threeYearPercentChange,
        fiveYearPercentChange: data.fiveYearPercentChange,
        tenYearPercentChange: data.tenYearPercentChange
      }
    }) || []
  },
  feeInfo: (state) => {
    return state.searchResult.map((data) => {
      return {
        symbol: data.symbol,
        name: data.name,
        amcName: data.amcName,
        actualFrontEndFee: data.actualFrontEndFee,
        maximumFrontEndFee: data.maximumFrontEndFee,
        actualBackEndFee: data.actualBackEndFee,
        maximumBackEndFee: data.maximumBackEndFee,
        actualManagementFee: data.actualManagementFee,
        maximumManagementFee: data.maximumManagementFee,
        actualTrusteeFee: data.actualTrusteeFee,
        maximumTrusteeFee: data.maximumTrusteeFee
      }
    }) || []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
