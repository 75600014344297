export function getActiveFundList () {
  return this.$axios.$get('/api/set-fund/fund/active-fund/list')
}

export function getFundTypeList () {
  return this.$axios.$get('/api/set-fund/fund/type/list')
}

export function getETFList () {
  return this.$axios.$get('/api/set/stock/list', { params: { securityType: 'L' } })
}

export function getRelatedRecommendedFunds (symbol) {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/recommend`)
}

export function getFundPerformance (symbol) {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/performance`)
}

export function getETFPerformance (symbol) {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/historical-performance`)
}

export function getFundHistoricalNAV (symbol, period, accumulated = null) {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/quotations`, { params: { period, accumulated } })
}

export function getFundHistoricalNAVWithMember (symbol, period, accumulated = null) {
  return this.$apiMember.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/quotations`, { params: { period, accumulated } })
}

export function getFundInformation (symbol, lang = 'th') {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/overview`, { params: { lang } })
}

export function getETFInformation (symbol) {
  return this.$axios.$get(`/api/set/etf/${fixSymbol(symbol)}/profile`)
}

export function getFundRiskProfile (symbol) {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/risk`)
}

export function getFundAssetAllocation (symbol) {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/asset-allocation`)
}

export function getFundTop5Holding (symbol) {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/top5-holding`)
}

export function getFundFeeInformation (symbol) {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/fee`)
}

export function getFundDividend (symbol) {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/dividend`)
}

export function getFundSubscription (symbol) {
  return this.$axios.$get(`/api/set-fund/fund-profile/${fixSymbol(symbol)}/subscription`)
}

export function fixSymbol (symbol) {
  const fixedSymbol = encodeURIComponent(encodeURIComponent(symbol))
  return fixedSymbol
}

// export function fixSymbol (symbol) {
//   let fixedSymbol = symbol
//   if (symbol.includes('/')) {
//     // fixedSymbol = fixedSymbol?.replaceAll('/', encodeURIComponent(encodeURIComponent('/')))
//     fixedSymbol = encodeURIComponent(encodeURIComponent(fixedSymbol))
//   }
//   return fixedSymbol
// }
