// import { isValidUrl } from '~/plugins/url-validator'
import { setClientId, isInvalidPath } from '@/helpers'
import isFunctionalPage from '~/helpers/functionalPage'

export default async function ({
  store,
  route,
  $cookies,
  redirect,
  i18n
}) {
  try {
    // Validate url
    // const validUrl = await isValidUrl(route.path)
    // if (validUrl === false) {
    //   redirect('/th/error/404')
    // }
    // handle invalid path | example: /th/?fbclid=
    const invalidPath = isInvalidPath(route.fullPath)
    if (invalidPath === true) {
      redirect('/')
    }

    // Some error pages do not require content data
    const _isFunctionalPage = isFunctionalPage(route.name)
    if (_isFunctionalPage) { return }

    // Set client id
    if (process.server) {
      const clientUuidFromCookie = $cookies.get('clientUuid')
      setClientId(clientUuidFromCookie).then((uuid) => {
        const { options } = require('@/config/cookies')
        store.commit('setClientId', uuid)
        $cookies.set('clientUuid', uuid, options)
      })
      store.commit('setDate', new Date())
    }

    await store.dispatch('contents/getContent', route)
  } catch (error) {
  }
}
