const getDefaultState = () => {
  return {
    hightlights: [],
    profiles: [],
    performances: [],
    performanceChart: null,
    marketstatistics: [],
    financials: [],
    financialAdvisorStics: [],
    financialAdvisors: [],
    industrySet: [],
    industryMai: [],
    searchParam: {
      market: null,
      industry: null,
      type: null,
      faId: null,
      year: null
    },
    isLoading: true
  }
}

const state = getDefaultState

const mutations = {
  setHightlights (state, data) {
    state.hightlights = data
  },
  setProfiles (state, data) {
    state.profiles = data
  },
  setPerformances (state, data) {
    state.performances = data
  },
  setPerformanceChart (state, data) {
    state.performanceChart = data
  },
  setMarketstatistics (state, data) {
    state.marketstatistics = data
  },
  setFinancials (state, data) {
    state.financials = data
  },
  setFinancialAdvisors (state, data) {
    state.financialAdvisors = data
  },
  setFinancialAdvisorStics (state, data) {
    state.financialAdvisorStics = data
  },
  setIndustrySet (state, data) {
    state.industrySet = data
  },
  setIndustryMai (state, data) {
    state.industryMai = data
  },
  setSearchParam (state, data) {
    state.searchParam = data
  },
  setLoading (state, data) {
    state.isLoading = data
  }
}

const actions = {
  setSearchParam: ({ commit }, payload) => commit('setSearchParam', payload),
  loading: ({ commit }, payload) => commit('setLoading', payload),
  async getHightlights ({ commit, state }) {
    const search = state.searchParam
    const year = search?.year.split('|')
    const fromYear = year[0]
    const toYear = year[1]
    try {
      const response = await this.$axios.$get('/api/set/ipo/highlight/list', {
        params: {
          market: search.market,
          industry: search.industry,
          type: search.type,
          faId: search.faId,
          fromYear,
          toYear,
          lang: this.$i18n.locale
        }
      })
      commit('setHightlights', response.ipoHighlights)
    } catch {
      commit('setHightlights', null)
    }
  },
  async getProfiles ({ commit, state }) {
    const search = state.searchParam
    const year = search?.year.split('|')
    const fromYear = year[0]
    const toYear = year[1]
    try {
      const response = await this.$axios.$get('/api/set/ipo/profile/list', {
        params: {
          market: search.market,
          industry: search.industry,
          type: search.type,
          faId: search.faId,
          fromYear,
          toYear,
          lang: this.$i18n.locale
        }
      })
      commit('setProfiles', response.ipoProfiles)
    } catch {
      commit('setProfiles', null)
    }
  },
  async getPerformances ({ commit, state }) {
    const search = state.searchParam
    const year = search?.year.split('|')
    const fromYear = year[0]
    const toYear = year[1]
    try {
      const response = await this.$axios.$get('/api/set/ipo/performance/list', {
        params: {
          market: search.market,
          industry: search.industry,
          type: search.type,
          faId: search.faId,
          fromYear,
          toYear,
          lang: this.$i18n.locale
        }
      })
      commit('setPerformances', response.ipoPerformances)
    } catch {
      commit('setPerformances', null)
    }
  },
  async getPerformanceChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/ipo/performance/${quote}/info`
      )
      commit('setPerformanceChart', response)
    } catch {
      commit('setPerformanceChart', null)
    }
  },
  async getMarketstatistics ({ commit, state }) {
    const search = state.searchParam
    const year = search?.year.split('|')
    const fromYear = year[0]
    const toYear = year[1]
    try {
      const response = await this.$axios.$get(
        '/api/set/ipo/marketstatistics/list',
        {
          params: {
            market: search.market,
            industry: search.industry,
            type: search.type,
            faId: search.faId,
            fromYear,
            toYear,
            lang: this.$i18n.locale
          }
        }
      )
      commit('setMarketstatistics', response.ipoStatistics)
    } catch {
      commit('setMarketstatistics', null)
    }
  },
  async getFinancials ({ commit, state }) {
    const search = state.searchParam
    const year = search?.year.split('|')
    const fromYear = year[0]
    const toYear = year[1]
    try {
      const response = await this.$axios.$get('/api/set/ipo/financial/list', {
        params: {
          market: search.market,
          industry: search.industry,
          type: search.type,
          faId: search.faId,
          fromYear,
          toYear,
          lang: this.$i18n.locale
        }
      })
      commit('setFinancials', response.ipoFinancials)
    } catch {
      commit('setFinancials', null)
    }
  },
  async getFinancialAdvisors ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/ipo/financial-advisor/list'
      )
      commit('setFinancialAdvisors', response.financialAdvisors)
    } catch {
      commit('setFinancialAdvisors', null)
    }
  },
  async getFinancialAdvisorStics ({ commit, state }) {
    const search = state.searchParam
    const year = search?.year.split('|')
    const fromYear = year[0]
    const toYear = year[1]
    try {
      const response = await this.$axios.$get(
        '/api/set/ipo/financial-advisor/statistics',
        {
          params: {
            faId: search.faId,
            fromYear,
            toYear,
            lang: this.$i18n.locale
          }
        }
      )
      commit('setFinancialAdvisorStics', response.financialAdvisorStats)
    } catch {
      commit('setFinancialAdvisorStics', null)
    }
  },
  async getIndustrys ({ commit }, payload) {
    try {
      const { market } = payload
      const response = await this.$axios.$get(
        `/api/set/index/list?type=industry&market=${market}`
      )
      if (response) {
        const items = response.map((data, index) => {
          return {
            label: data.symbol,
            value: data.querySymbol,
            select: false,
            indent: data.level === 'SECTOR',
            ...data
          }
        })
        if (market === 'SET') {
          commit('setIndustrySet', items)
        } else {
          commit('setIndustryMai', items)
        }
      } else {
        commit('setIndustrySet', null)
        commit('setIndustryMai', null)
      }
    } catch {
      commit('setIndustrySet', null)
      commit('setIndustryMai', null)
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
