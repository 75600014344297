import { marketListId } from '@/utils/marketlist'
export default ({ app }, inject) => {
  const plugin = {
    /**
     * Get goToQuote
     * @param {Object} btnQuoteData
     * @exampleParam {  securities: '',symbol: '',securityType: 'S', pathMatch: overview}
     */
    getQuoteUrl (btnQuoteData) {
      const pathMatch = btnQuoteData.pathMatch || 'overview'
      if (btnQuoteData && btnQuoteData.securityType) {
        if (
          ((btnQuoteData && btnQuoteData.securityType) || '') === 'S' ||
          ((btnQuoteData && btnQuoteData.securityType) || '') === 'F' ||
          ((btnQuoteData && btnQuoteData.securityType) || '') === 'Q' ||
          ((btnQuoteData && btnQuoteData.securityType) || '') === 'P' ||
          ((btnQuoteData && btnQuoteData.securityType) || '') === 'W' ||
          ((btnQuoteData && btnQuoteData.securityType) || '') === 'U'
        ) {
          return app.localePath(
            `/equities/quote/${
              btnQuoteData.securities || btnQuoteData.symbol
            }/${pathMatch}`
          )
        } else if (btnQuoteData.securityType === 'V') {
          return app.localePath(
            `/equities/dw/quote/${
              btnQuoteData.securities || btnQuoteData.symbol
            }/${pathMatch}`
          )
        } else if (btnQuoteData.securityType === 'X') {
          return app.localePath(
            `/equities/dr/quote/${
              btnQuoteData.securities || btnQuoteData.symbol
            }/${pathMatch}`
          )
        } else if (btnQuoteData.securityType === 'L') {
          return app.localePath(
            `/equities/etf/quote/${
              btnQuoteData.securities || btnQuoteData.symbol
            }/${pathMatch}`
          )
        } else if (btnQuoteData.securityType === 'XF') {
          return app.localePath(
            `/equities/drx/quote/${
              btnQuoteData.securities || btnQuoteData.symbol
            }/${pathMatch}`
          )
        } else if (btnQuoteData.securityType === 'underlying') {
          const params = [
            'tab=search-filter-1',
            'symbol=' + btnQuoteData.securities || btnQuoteData.symbol
          ]
          return app.localePath(`/equities/dw/index/search?${params.join('&')}`)
        } else if (
          btnQuoteData.securityType === marketListId.IO ||
          btnQuoteData.securityType === marketListId.IF ||
          btnQuoteData.securityType === marketListId.SF ||
          btnQuoteData.securityType.toUpperCase() === marketListId.FT ||
          btnQuoteData.securityType === marketListId.PMF ||
          btnQuoteData.securityType === marketListId.DPM ||
          btnQuoteData.securityType === marketListId.CF ||
          btnQuoteData.securityType === marketListId.IRF ||
          btnQuoteData.securityType === marketListId.AF ||
          btnQuoteData.securityType === marketListId.TXI_O ||
          btnQuoteData.securityType === marketListId.TXI_C ||
          btnQuoteData.securityType === marketListId.TXI_P ||
          btnQuoteData.securityType === marketListId.TXI_F ||
          btnQuoteData.securityType === marketListId.TXS_F ||
          btnQuoteData.securityType === marketListId.TXM_F ||
          btnQuoteData.securityType === marketListId.TXD_F ||
          btnQuoteData.securityType === marketListId.TXC_F ||
          btnQuoteData.securityType === marketListId.TXR_F ||
          btnQuoteData.securityType === marketListId.TXA_F ||
          btnQuoteData.securityType === 'TFEX'
        ) {
          return app.localePath(
            `/derivatives/quote/${
              btnQuoteData.securities || btnQuoteData.symbol
            }/${pathMatch}`
          )
        } else if (btnQuoteData.optionsType !== undefined) {
          return app.localePath(
            `/derivatives/quote/${
              btnQuoteData.securities || btnQuoteData.symbol
            }/${pathMatch}`
          )
        } else if (
          btnQuoteData.securityType &&
          btnQuoteData.securityType.toUpperCase() === 'FUND'
        ) {
          return app.localePath(
            `/mutualfund/quote/${encodeURIComponent(
              btnQuoteData.securities || btnQuoteData.symbol
            )}/${pathMatch}`
          )
        } else {
          return '#'
        }
      } else {
        return '#'
      }
    }
  }
  inject('quote', plugin)
  app.context.$quote = plugin
}
