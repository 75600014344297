const state = () => {
  return {
    info: null
  }
}

const mutations = {
  setInfo (state, data) {
    state.info = data
  }
}

const actions = {
  async getDW ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/dw/${quote}/profile`)
      commit('setInfo', response)
    } catch {
      commit('setInfo', null)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
