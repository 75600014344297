/**
 * State
 */
const state = () => ({
  marketIndex: {
    marketInfo: [],
    marketChart: {
      SET: {},
      SET50: {},
      SET100: {},
      sSET: {},
      SETCLMV: {},
      SETHD: {},
      SETWB: {},
      mai: {}
    }
  }
})

/**
 * Mutations
 */
const mutations = {
  setMarketIndex (state, marketData) {
    if (marketData) {
      const { marketInfo, marketChart } = marketData
      state.marketIndex.marketInfo = marketInfo.indexIndustrySectors

      state.marketIndex.marketChart.SET = marketChart[0]
      state.marketIndex.marketChart.SET50 = marketChart[1]
      state.marketIndex.marketChart.SET100 = marketChart[2]
      state.marketIndex.marketChart.sSET = marketChart[3]
      state.marketIndex.marketChart.SETCLMV = marketChart[4]
      state.marketIndex.marketChart.SETHD = marketChart[5]
      state.marketIndex.marketChart.SETTHSI = marketChart[6]
      state.marketIndex.marketChart.SETWB = marketChart[7]
      state.marketIndex.marketChart.mai = marketChart[8]
    }
  }
}

/**
 * Actions
 */
const actions = {
  async getMarketIndex ({ commit }) {
    try {
      const marketInfo = await this.$axios.$get(
        'api/set/index/info/list?type=INDEX'
      )

      const SETChart = this.$axios.$get(
        'api/set/index/SET/chart-quotation?period=1D'
      )
      const SET50Chart = this.$axios.$get(
        'api/set/index/SET50/chart-quotation?period=1D'
      )
      const SET100Chart = this.$axios.$get(
        'api/set/index/SET100/chart-quotation?period=1D'
      )
      const sSETChart = this.$axios.$get(
        'api/set/index/sSET/chart-quotation?period=1D'
      )
      const SETCLMVChart = this.$axios.$get(
        'api/set/index/SETCLMV/chart-quotation?period=1D'
      )
      const SETHDChart = this.$axios.$get(
        'api/set/index/SETHD/chart-quotation?period=1D'
      )
      const SETTHSIChart = this.$axios.$get(
        'api/set/index/SETTHSI/chart-quotation?period=1D'
      )
      const SETWBChart = this.$axios.$get(
        'api/set/index/SETWB/chart-quotation?period=1D'
      )
      const maiChart = this.$axios.$get(
        'api/set/index/mai/chart-quotation?period=1D'
      )
      let marketChart = []

      marketChart = await Promise.allSettled([
        SETChart,
        SET50Chart,
        SET100Chart,
        sSETChart,
        SETCLMVChart,
        SETHDChart,
        SETTHSIChart,
        SETWBChart,
        maiChart
      ]).then((data) => {
        return [
          data[0].value,
          data[1].value,
          data[2].value,
          data[3].value,
          data[4].value,
          data[5].value,
          data[6].value,
          data[7].value,
          data[8].value
        ]
      })

      commit('setMarketIndex', { marketInfo, marketChart })
    } catch (e) {
      commit('setMarketIndex', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  getMarketIndex: (state) => {
    return state.marketIndex
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
