//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Modal',
  props: {
    modalId: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '' // sm, lg, xl, and auto
    },
    header: {
      type: Boolean,
      default: true
    },
    footer: {
      type: Boolean,
      default: true
    },
    centered: {
      type: Boolean,
      default: true
    },
    backdrop: {
      type: String,
      default: 'false'
    },
    keyboard: {
      type: String,
      default: 'true'
    },
    onShown: {
      type: Function,
      default: () => {}
    },
    onHidden: {
      type: Function,
      default: () => {}
    },
    onShow: {
      type: Function,
      default: () => {}
    },
    onHide: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      modal: null
    }
  },
  computed: {
    modalSize () {
      if (this.size) {
        return 'modal-' + this.size
      }
      return null
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.dispose()
  },
  methods: {
    async init () {
      await this.initModal()
      this.registerEvents()
      this.$emit('modalInit', this.modal)
    },
    initModal () {
      const { Modal } = require('bootstrap')
      const modal = this.$refs.modal
      this.modal = new Modal(modal, {})
    },

    registerEvents () {
      const modal = this.$refs.modal
      // show
      modal.addEventListener('show.bs.modal', (e) => {
        this.onShow(e)
      })
      // hide
      modal.addEventListener('hide.bs.modal', (e) => {
        this.onHide(e)
      })
      // shown
      modal.addEventListener('shown.bs.modal', (e) => {
        this.onShown(e)
      })
      // hidden
      modal.addEventListener('hidden.bs.modal', (e) => {
        this.onHidden(e)
      })

      // click to close
      const buttonClose = modal.querySelector('[aria-label="Close"]')
      if (buttonClose) {
        buttonClose.addEventListener('click', () => {
          this.hide()
        })
      }
    },

    show () {
      this.modal.show()
    },
    hide () {
      this.modal.hide()
    },

    dispose () {
      this.modal.dispose()
    }
  }
}
