const getDefaultState = () => {
  return {
    info: null,
    report: {
      annualReport: [],
      from56Report: []
    },
    boardDirector: [],
    shareholders: null
  }
}

const state = getDefaultState

const mutations = {
  setInfo (state, data) {
    state.info = data
  },

  setReport (state, data) {
    state.report = data
  },

  setBoardDirector (state, data) {
    if (data) {
      const temp = []
      let order = 1
      for (const director of data) {
        let positionIndex = 1
        for (const position of director.positions) {
          temp.push({
            order,
            positionIndex,
            name: positionIndex <= 1 ? director.name : '',
            position
          })
          positionIndex++
        }
        order++
      }
      state.boardDirector = temp
    }
  },

  setShareholders (state, data) {
    if (data) {
      data.majorShareholders = data.majorShareholders.slice(0, 10)
      state.shareholders = data
    }
  }
}

const actions = {
  async getInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const companyInfo = await this.$axios.$get(`api/set/company/${quote}/profile`,
        {
          params: {
            lang: this.$i18n.locale
          }
        }
      )

      commit('setInfo', companyInfo)
    } catch {
      commit('setInfo', null)
    }
  },

  async getReport ({ commit }, payload) {
    const { quote } = payload
    try {
      const annualReport = await this.$axios.$get(`api/set/company/${quote}/report/annual`)

      const from56Report = await this.$axios.$get(`api/set/company/${quote}/report/form56`)

      const companyReport = await Promise.allSettled([
        annualReport,
        from56Report
      ]).then((data) => {
        return {
          annualReport: data[0].value,
          from56Report: data[1].value
        }
      })

      commit('setReport', companyReport)
    } catch {
      commit('setReport', null)
    }
  },

  async getBoardDirector ({ commit }, payload) {
    const { quote } = payload
    try {
      const boardDirector = await this.$axios.$get(`api/set/company/${quote}/board-of-director`)

      commit('setBoardDirector', boardDirector)
    } catch {
      commit('setBoardDirector', null)
    }
  },

  async getShareholders ({ commit }, payload) {
    const { quote, lang } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/shareholder?lang=${lang}`)

      commit('setShareholders', response)
    } catch {
      commit('setShareholders', null)
    }
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
