//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Tab',
  props: {
    title: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    activeClassName: {
      type: String,
      default: null
    },
    controls: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: 'button'
    },
    tabId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    activeClass () {
      if (this.active && this.activeClassName) {
        return this.activeClassName
      }
      return null
    },
    isNoLink () {
      return !this.url
    },
    selected () {
      if (this.active === true) {
        return 'true'
      }
      return 'false'
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.activeTab()
      this.registerEvent()
    })
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    activeTab () {
      if (this.isActive === true) { return }
      const hash = this.$route.hash
      if (!hash || hash === '#') { return }
      const selector = `${hash}[data-bs-toggle="tab"]`
      const triggerEl = document.querySelector(selector)
      if (triggerEl) {
        this.isActive = true
        const { Tab } = require('bootstrap')
        const tab = Tab.getOrCreateInstance(triggerEl)
        tab.show()
      }
    },
    registerEvent () {
      const tabEl = this.$refs.navTab
      tabEl?.addEventListener('show.bs.tab', this.handleShow)
      tabEl?.addEventListener('shown.bs.tab', this.handleShown)
      tabEl?.addEventListener('hidden.bs.tab', this.handleHidden)
    },
    removeEvent () {
      const tabEl = this.$refs.navTab
      tabEl?.removeEventListener('show.bs.tab', this.handleShow)
      tabEl?.removeEventListener('shown.bs.tab', this.handleShown)
      tabEl?.removeEventListener('hidden.bs.tab', this.handleHidden)
    },
    handleShow (event) {
      this.$emit('click', event)
    },
    handleShown () { this.$emit('onShown') },
    handleHidden () { this.$emit('onHidden') }
  }
}
