import * as api from '~/helpers/iaa-consensus/api.js'

const getDefaultState = () => {
  return {
    stockList: [],
    indexList: [],
    ratingViewList: [],
    SummaryOfAnalystConsensus: {
      securityTypes: [],
      brokers: [],
      authors: [],
      searchResult: { data: [] }
    },
    DailyUpdateArticles: {
      securityTypes: [],
      brokers: [],
      authors: [],
      searchResult: { data: [] }
    },
    ViewByProject: {
      securityTypes: [],
      brokers: [],
      authors: [],
      searchResult: { data: [] }
    },
    Set50FinancialRatioEstimation: {
      securityTypes: [],
      brokers: [],
      authors: [],
      searchResult: { data: [] }
    },
    NetProfitAndValuation: {
      securityTypes: [],
      brokers: [],
      authors: [],
      searchResult: { groupByMarket: [] }
    },
    GroupBySector: {
      securityTypes: [],
      brokers: [],
      authors: [],
      searchResult: { groupByMarket: [] }
    },
    GroupByBroker: {
      securityTypes: [],
      brokers: [],
      authors: [],
      searchResult: { groupByBroker: [] }
    },
    researchDetail: {},
    customLocation: null
  }
}

const state = getDefaultState

const mutations = {
  setStockList (state, data) {
    state.stockList = data
  },
  setIndexList (state, data) {
    state.indexList = data
  },
  setRatingViewList (state, data) {
    state.ratingViewList = data
  },

  setSummaryOfAnalystConsensusSecurityTypes (state, data) {
    state.SummaryOfAnalystConsensus.securityTypes = data
  },
  setSummaryOfAnalystConsensusBrokers (state, data) {
    state.SummaryOfAnalystConsensus.brokers = data
  },
  setSummaryOfAnalystConsensusAuthors (state, data) {
    state.SummaryOfAnalystConsensus.authors = data
  },
  setSummaryOfAnalystConsensusSearchResult (state, data) {
    state.SummaryOfAnalystConsensus.searchResult = data
  },

  setDailyUpdateArticlesSecurityTypes (state, data) {
    state.DailyUpdateArticles.securityTypes = data
  },
  setDailyUpdateArticlesBrokers (state, data) {
    state.DailyUpdateArticles.brokers = data
  },
  setDailyUpdateArticlesAuthors (state, data) {
    state.DailyUpdateArticles.authors = data
  },
  setDailyUpdateArticlesSearchResult (state, data) {
    state.DailyUpdateArticles.searchResult = data
  },

  setViewByProjectSecurityTypes (state, data) {
    state.ViewByProject.securityTypes = data
  },
  setViewByProjectBrokers (state, data) {
    state.ViewByProject.brokers = data
  },
  setViewByProjectAuthors (state, data) {
    state.ViewByProject.authors = data
  },
  setViewByProjectSearchResult (state, data) {
    state.ViewByProject.searchResult = data
  },

  setSet50FinancialRatioEstimationSecurityTypes (state, data) {
    state.Set50FinancialRatioEstimation.securityTypes = data
  },
  setSet50FinancialRatioEstimationBrokers (state, data) {
    state.Set50FinancialRatioEstimation.brokers = data
  },
  setSet50FinancialRatioEstimationAuthors (state, data) {
    state.Set50FinancialRatioEstimation.authors = data
  },
  setSet50FinancialRatioEstimationSearchResult (state, data) {
    state.Set50FinancialRatioEstimation.searchResult = data
  },

  setNetProfitAndValuationSecurityTypes (state, data) {
    state.NetProfitAndValuation.securityTypes = data
  },
  setNetProfitAndValuationBrokers (state, data) {
    state.NetProfitAndValuation.brokers = data
  },
  setNetProfitAndValuationAuthors (state, data) {
    state.NetProfitAndValuation.authors = data
  },
  setNetProfitAndValuationSearchResult (state, data) {
    state.NetProfitAndValuation.searchResult = data
  },

  setGroupBySectorSecurityTypes (state, data) {
    state.GroupBySector.securityTypes = data
  },
  setGroupBySectorBrokers (state, data) {
    state.GroupBySector.brokers = data
  },
  setGroupBySectorAuthors (state, data) {
    state.GroupBySector.authors = data
  },
  setGroupBySectorSearchResult (state, data) {
    state.GroupBySector.searchResult = data
  },

  setGroupByBrokerSecurityTypes (state, data) {
    state.GroupByBroker.securityTypes = data
  },
  setGroupByBrokerBrokers (state, data) {
    state.GroupByBroker.brokers = data
  },
  setGroupByBrokerAuthors (state, data) {
    state.GroupByBroker.authors = data
  },
  setGroupByBrokerSearchResult (state, data) {
    state.GroupByBroker.searchResult = data
  },
  setResearchDetail (state, data) {
    state.researchDetail = data
  },
  setWindowLocation (state, data) {
    state.customLocation = data
  }
}

const actions = {
  async getStockList ({ commit }) {
    const stocks = await api.getStockList
      .call(this)
      .catch(() => [])

    commit('setStockList', stocks)
  },

  async getIndexList ({ commit }) {
    const stocks = await api.getIndexList
      .call(this)
      .catch(() => [])

    commit('setIndexList', stocks)
  },

  async getRatingViewList ({ commit }) {
    const ratingViews = await api.getRatingViewList
      .call(this)
      .catch(() => [])

    commit('setRatingViewList', ratingViews)
  },

  async getSecurityTypes ({ commit }, type) {
    const securitytypes = await api.getSecurityTypes
      .call(this, type)
      .catch(() => [])

    commit(`set${type}SecurityTypes`, securitytypes)
  },

  async getBrokers ({ commit }, type) {
    const brokers = await api.getBrokers.call(this, type).catch(() => [])

    commit(`set${type}Brokers`, brokers)
  },

  async getAuthors ({ commit }, type) {
    const authors = await api.getAuthors.call(this, type).catch(() => [])

    commit(`set${type}Authors`, authors)
  },

  async getSearchResult ({ commit }, condition) {
    const type = condition.type
    const result = await getResult.call(this)

    commit(`set${type}SearchResult`, result)

    function getResult () {
      switch (type) {
        case 'SummaryOfAnalystConsensus':
          return api.getSummaryOfAnalystConsensus.call(this, condition).catch(() => {})
        case 'DailyUpdateArticles':
          return api.getDailyUpdateArticles.call(this, {
            symbol: condition.symbol,
            index: condition.index,
            broker: condition.broker,
            author: condition.author
          })
            .catch(() => ({ data: {} }))
        case 'ViewByProject':
          return api.getViewByProject.call(this, {
            symbol: condition.symbol,
            index: condition.index,
            broker: condition.broker,
            author: condition.author
          })
            .catch(() => ({ data: {} }))
        case 'Set50FinancialRatioEstimation':
          return api.getSet50FinancialRatioEstimation.call(this, {
            symbol: condition.symbol,
            index: condition.index,
            broker: condition.broker,
            author: condition.author
          })
            .catch(() => ({ data: [] }))
        case 'NetProfitAndValuation':
          return api.getNetProfitAndValuation.call(this, {
            symbol: condition.symbol,
            index: condition.index,
            broker: condition.broker,
            author: condition.author
          })
            .catch(() => ({ groupByMarket: [] }))
        case 'GroupBySector':
          return api.getGroupBySector.call(this, {
            symbol: condition.symbol,
            index: condition.index,
            broker: condition.broker,
            author: condition.author
          })
            .catch(() => ({ groupByMarket: [] }))
        case 'GroupByBroker':
          return api.getGroupByBroker.call(this, {
            symbol: condition.symbol,
            index: condition.index,
            broker: condition.broker,
            author: condition.author
          })
            .catch(() => ({ groupByBroker: [] }))
      }
    }
  },
  async getResearchDetail ({ commit }, id) {
    try {
      const result = await this.$axios.$get(
        `/api/set-fund/consensus/research/${id}/related-documents`
      )
      commit('setResearchDetail', result)
    } catch {
      commit('setResearchDetail', {})
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
