const middleware = {}

middleware['authorization'] = require('../middleware/authorization.js')
middleware['authorization'] = middleware['authorization'].default || middleware['authorization']

middleware['memberEntry'] = require('../middleware/memberEntry.js')
middleware['memberEntry'] = middleware['memberEntry'].default || middleware['memberEntry']

middleware['redirect-to-th'] = require('../middleware/redirect-to-th.js')
middleware['redirect-to-th'] = middleware['redirect-to-th'].default || middleware['redirect-to-th']

export default middleware
