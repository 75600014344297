/**
 * State
 */
const state = () => ({
  topRanking: {
    info: {},
    chart: {}
  }
})

/**
   * Mutations
   */
const mutations = {
  setTop5Data (state, data) {
    state.topRanking.info = data
    state.topRanking.chart = {}
  },
  setTop5Chart (state, data) {
    state.topRanking.chart[data.symbol] = data.chart
  }
}

/**
   * Actions
   */
const actions = {
  async getTop5Data ({ commit, dispatch }, payload) {
    const { rankingType } = payload
    try {
      if (rankingType === 'popular') {
        const _market = 'SET'
        const url = `/api/cms/v1/popularquote/getpopular?market=${_market}&securityType=S&limit=5`
        const options = {
          errorRedirect: true
        }
        const response = await this.$apiCms.get(url, options)
        const popularObject = {
          market: _market,
          ranking: rankingType,
          securityType: 'S',
          stocks: response.data
        }
        commit('setTop5Data', popularObject)
        const promisePopular = []
        for (let i = 0; i < popularObject.stocks.length; i++) {
          promisePopular.push(dispatch('getStockTop5IntradayChart', { quote: popularObject.stocks[i].symbol }))
        }
        await Promise.all(promisePopular)
        return popularObject
        // const res = await this.$apiCms.$get(url).then((res) => {
        //   const popularObject = {
        //     market: _market,
        //     ranking: rankingType,
        //     securityType: 'S',
        //     stocks: res.data
        //   }
        //   return popularObject
        // commit('setTop5Data', popularObject)
        // res.stocks.forEach(async (item) => {
        //   await dispatch('getStockTop5IntradayChart', { quote: item.symbol })
        // })
        // })
        // return res
      } else {
        const apiPath = `/api/set/ranking/${rankingType}/SET/S?count=5`
        const options = {
          errorRedirect: true
        }
        const response = await this.$axios.$get(apiPath, options)
        commit('setTop5Data', response)
        const promisePopular = []
        for (let i = 0; i < response.stocks.length; i++) {
          promisePopular.push(dispatch('getStockTop5IntradayChart', { quote: response.stocks[i].symbol }))
        }
        await Promise.all(promisePopular)
        return response
      }
    } catch (error) {
      commit('setTop5Data', null)
    }
  },
  async getStockTop5IntradayChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-performance?period=1Y`
      )
      commit('setTop5Chart', { symbol: quote, chart: response })
      return { symbol: quote, chart: response }
    } catch (err) {
      return null
    }
  }
}

/**
   * Getters
   */
const getters = {
  getTopRankingInfo: (state) => {
    return state.topRanking.info
  },
  getTopRankingChart: (state) => {
    return state.topRanking.chart
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
