//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { deviceSizes } from '~/config/core'
import { setAttributeLink } from '~/helpers'
export default {
  name: 'FooterSitemap',
  props: {
    lastUpdate: {
      type: Date,
      default: null
    },
    listItem: {
      type: Array,
      default: Array
    }
  },
  // serverCacheKey: props => `FooterSitemap-${props.listItem?.length}`,
  serverCacheKey: props => `M_WEBLINK_SITEMAP::${props.lastUpdate?.toISOString()}`,
  data () {
    return {
      isMobile: this.$device.isMobile
    }
  },
  beforeDestroy () {
    this.removeEvent()
  },
  async mounted () {
    await this.registerEvent()
    // await this.handleResponsive()
    // this.$nextTick(() => {

    // })
  },
  methods: {
    setAttributeLink,
    handleResponsive () {
      const wInnerWidth = window.innerWidth
      if (wInnerWidth <= deviceSizes.xl) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    registerEvent () {
      if (window) {
        window.addEventListener('resize', this.handleResponsive)
      }
    },
    removeEvent () {
      if (window) {
        window.removeEventListener('resize', this.handleResponsive)
      }
    },
    handleClickItem (menuTitle, url) {
      if (url && url !== '#') {
        this.$personalized.clickElement({
          name: 'all16',
          optionLang: {
            menuTitle
          }
        })
      }
    }
  }
}
