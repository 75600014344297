//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SearchSuggestion',
  props: {
    value: {
      type: undefined,
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelNodata: {
      type: String,
      default: 'ไม่พบผลลัพธ์สำหรับการค้นหา'
    },
    listSearchData: {
      type: Array,
      default: Array
    },
    isSearchName: {
      type: Boolean,
      default: false
    },
    delayHideDropdown: {
      type: Number,
      default: 0
    },
    autoTextUppercase: {
      type: Boolean,
      default: true
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: 'เกิดข้อผิดพลาด'
    },
    excludeListSearch: {
      type: Array,
      default: Array
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (value) {
      this.setValueOnWatch(value)
    }
  },
  mounted () {
    process.nextTick(() => {
      this.setValueOnWatch(this.value)
    })
  },
  methods: {
    setValue (value) {
      if (value !== null) {
        this.$emit('input', value)
      }
    },
    onSelected (item) {
      this.$nextTick(() => {
        this.$emit('onSelected', item)
      })
    },
    setValueOnWatch (value) {
      const eleDropdownSearch = this.$refs.dropdownSearch
      if (eleDropdownSearch) {
        if (!eleDropdownSearch.dropdownToggle._isShown()) {
          eleDropdownSearch.isClear = true
        }
        eleDropdownSearch.symbol = value !== null ? value : ''
      }
    },
    reset () {
      this.$refs.dropdownSearch.clear()
    },
    getSecurityType () {
      const eleDropdownSearch = this.$refs.dropdownSearch
      if (eleDropdownSearch) {
        return eleDropdownSearch.securityType
      }
    }
  }
}
