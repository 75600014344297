//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconClock',
  // serverCacheKey: () => 'IconTriangle-v1'
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
