//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { customAlphabet } from 'nanoid'
export default {
  name: 'AppBarNavMobileMenu',
  props: {
    menuList: {
      type: Array,
      default: () => []
    },
    levelNumber: {
      type: Number,
      default: 1
    },
    uuid: {
      type: String,
      default: () => customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789', 8)()
    },
    prefixId: {
      type: String,
      default: 'm-menu-collapse'
    },
    parentTitle: {
      type: String,
      default: null
    }
  },
  computed: {
    menuId () {
      return `menuAccordion${this.uuid}`
    },
    levelClass () {
      return `m-menu-level${this.levelNumber}`
    }
  },
  methods: {
    linkClass (isLast) {
      const classList = []
      classList.push('d-flex align-items-center')
      if (this.levelNumber === 3) {
        classList.push('default-font-family fw-500')
      }
      // remove border of first item
      if (this.levelNumber < 3) {
        classList.push('border-bottom')
      } else if (isLast) {
        classList.push('border-bottom')
      }
      return classList.join(' ')
    },
    selectMenuIcon (imgSrc) {
      const image = imgSrc?.filter((img) => { return img.size === 'default' })[0] || {}
      return image.url
    },
    handleClickItem (menuTitle, url) {
      if (url && url !== '#') {
        const _menuTitle = []
        if (this.parentTitle) {
          _menuTitle.push(this.parentTitle)
        }
        _menuTitle.push(menuTitle)
        this.$personalized.clickElement({
          name: 'all1',
          optionLang: {
            menuTitle: _menuTitle.join(',')
          }
        })
      }
    }
  }
}
