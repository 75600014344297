const actions = {
  async submitForm ({ commit }, payload) {
    const apiPath = `/api/cms-w/v1/forms/${payload.formUuid}/submit`
    const configs = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const result = await this.$apiCms.post(apiPath, payload.data, configs)
      .then((res) => {
        return res
      })
      .catch(err => err)
    return result
  },
  async getForm ({ commit }, formUuid) {
    const apiPath = `/api/cms/v1/forms/preview/${this.$i18n.locale}/${formUuid}`
    const config = {
      method: 'get',
      url: apiPath
    }
    const result = await this.$apiCms(config)
      .then((res) => {
        return res
      })
      .catch(err => err)
    return result
  }
}
export default {
  namespaced: true,
  actions
}
