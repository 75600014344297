//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MiscEquityTabButtonGroup',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    divClass: {
      type: String,
      default: 'd-flex'
    },
    divTabs: {
      type: String,
      default: ''
    },
    divTab: {
      type: String,
      default: ''
    },
    shadowNone: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
