//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from 'dayjs'
export default {
  name: 'HomeQuote',
  data () {
    const cookiesName = 'myquote-popup-hidden'
    return {
      loadingPanelOpening: false,
      isOpenFooter: false,
      securityTypeFavourite: '',
      showRequestNumStock: 0,
      showEditPortfolioNameRequestNum: 0,
      showRequestNumDerivative: 0,
      showRequestNumMutualFund: 0,
      category: 'equity',
      cookiesName,
      showButtonMyQuote: true,
      onCreatePortSuccess: () => Promise.resolve(true)
    }
  },
  computed: {
    myQuotePopupHidden () {
      return this.$cookies.get(this.cookiesName)
    },
    stockSymbolList () {
      return this.$store.state.search.equityList.map(
        x => ({
          symbol: x.symbol,
          nameTH: x.nameTH,
          nameEN: x.nameEN,
          securityType: x.securityType
        })
      )
    },
    derivativeSymbolList () {
      return this.$store.state.search.tfexList.map(
        x => ({
          symbol: x.symbol,
          nameTH: x.nameTH,
          nameEN: x.nameEN,
          securityType: x.marketListId
        })
      )
    },
    mutualFundSymbolList () {
      return this.$store.state.search.fundList.map(
        x => ({
          symbol: x.symbol,
          nameTH: x.nameTH,
          nameEN: x.nameEN,
          securityType: 'FUND' // กองทุนรวม
        })
      )
    }
  },
  mounted () {
    const isOpenMyQuote = localStorage.getItem('isOpenMyQuote')
    if (isOpenMyQuote) {
      this.openFooter()
    }
  },
  methods: {
    handleCloseMyQuote () {
      const alreadyCookie = this.myQuotePopupHidden === 0 || this.myQuotePopupHidden === 1
      if (alreadyCookie) { return }
      const d = dayjs().add(7, 'day') // new Date(date)
      const expiresDate = d.$d // new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59) // expires at midnight
      const option = {
        path: '/',
        expires: expiresDate
      }
      this.$cookies.set(this.cookiesName, 1, option)
      this.showButtonMyQuote = false
    },
    elementObserver (parent, nodeId, callback) {
      // console.log('observer start')
      const elementToObserve = parent
      const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach(function (mutation) {
          mutation.addedNodes.forEach(function (addedNode) {
            if (addedNode.id === nodeId) {
              callback(addedNode)
              // console.log('#child has been added')
              observer.disconnect()
            }
          })
        })
      })
      observer.observe(elementToObserve, { subtree: false, childList: true })
    },
    async openFooter () {
      this.loadingPanelOpening = true
      await this.$authUser.isLogin()
      this.loadingPanelOpening = false
      this.isOpenFooter = true
      const bodyTag = document.body
      if (bodyTag) {
        bodyTag.classList.add('my-quote-open')
        // const bullChatBot = document.querySelector('.bull-chat-bot') // zwiz-web-chat
        const bullChatBot = document.getElementById('zwiz-web-chat') // zwiz-web-chat
        if (bullChatBot) {
          bullChatBot.classList.add('myquote-active')
        }
        if (!bullChatBot) {
          this.elementObserver(bodyTag, 'zwiz-web-chat', (node) => {
            node.classList.add('myquote-active')
          })
        }
      }
    },
    closeFooter () {
      this.isOpenFooter = false
      const bodyTag = document.getElementsByTagName('body')
      if (bodyTag) {
        setTimeout(() => {
          bodyTag[0].classList.remove('my-quote-open')
          localStorage.removeItem('isOpenMyQuote')
          const bullChatBot = document.getElementById('zwiz-web-chat')
          if (bullChatBot) {
            bullChatBot.classList.remove('myquote-active')
          }
        }, 650)
      }
    },
    openModalAddQuote (type) {
      this.securityTypeFavourite = type
      this.$refs.divModalAddQuote.openModal()
    },
    openModalAddPort (payload) {
      const { type, onCreatePortSuccess } = payload
      this.category = type
      this.onCreatePortSuccess = onCreatePortSuccess
      this.showEditPortfolioNameRequestNum++
    },
    openModalAddPortSymbol (type) {
      if (type === 'equity') {
        this.showRequestNumStock++
      }
      if (type === 'tfex') {
        this.showRequestNumDerivative++
      }
      if (type === 'fund') {
        this.showRequestNumMutualFund++
      }
    },
    async onCreatePort (payload) {
      const result = await this.$virtualPort.createPortfolio(payload)
      if (result) {
        await this.onCreatePortSuccess(this.category)
        let type = ''
        if (this.category === 'equity') {
          type = 'หุ้น'
        }
        if (this.category === 'tfex') {
          type = 'อนุพันธ์'
        }
        if (this.category === 'fund') {
          type = 'กองทุนรวม'
        }
        this.$personalized.clickElement({
          name: 'myQuote9',
          optionLang: {
            type
          }
        })
      }
      return result
    },
    onSendOrderSuccess (symbol) {
      this.$personalized.clickElement({
        name: 'myQuote11',
        optionLang: {
          symbol
        }
      })
    }
  }
}
