const getDefaultState = () => {
  return {
    setHolidays: [],
    setHolidaysYear: [],
    setXCalendar: [],
    setMeetingCalendar: [],
    setStockList: []
  }
}

const state = getDefaultState

const mutations = {
  setHolidays (state, data) {
    state.setHolidays = data
  },
  setHolidaysYear (state, data) {
    state.setHolidaysYear = data
  },
  setXCalendar (state, data) {
    state.setXCalendar = data
  },
  setMeetingCalendar (state, data) {
    state.setMeetingCalendar = data
  },
  setStockList (state, data) {
    state.setStockList = data
  }
}

const actions = {
  async getSetHolidays ({ commit }, payload) {
    const { year, month } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock-calendar/${year}/${month}/holiday`
      )
      commit('setHolidays', response)
      return response
    } catch {
      commit('setHolidays', null)
      return null
    }
  },
  async getSetHolidaysYear ({ commit }, payload) {
    const { year } = payload
    try {
      const response = await this.$axios.$get(`/api/set/holiday/year/${year}`)
      commit('setHolidaysYear', response)
      return response
    } catch {
      commit('setHolidaysYear', null)
      return null
    }
  },
  async getXCalendar ({ commit }, payload) {
    const { year, month, symbolList, cType } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock-calendar/${year}/${month}/x-calendar?symbols=${symbolList}&caTypes=${cType}`
      )
      commit('setXCalendar', response)
    } catch {
      commit('setXCalendar', null)
    }
  },
  async getMeetingCalendar ({ commit }, payload) {
    const { year, month, symbolList } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock-calendar/${year}/${month}/meeting?symbols=${symbolList}`
      )
      commit('setMeetingCalendar', response)
    } catch {
      commit('setMeetingCalendar', null)
    }
  },
  async getStockList ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('/api/set/stock/list')
      commit('setStockList', response.securitySymbols)
    } catch (err) {
      commit('setStockList', null)
    }
  }
}

const getters = {
  getSetHolidays (state) {
    return state.setHolidays
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
