import { render, staticRenderFns } from "./ModalOrderDerivative.vue?vue&type=template&id=61edbabc&scoped=true&"
import script from "./ModalOrderDerivative.vue?vue&type=script&lang=js&"
export * from "./ModalOrderDerivative.vue?vue&type=script&lang=js&"
import style0 from "./ModalOrderDerivative.vue?vue&type=style&index=0&id=61edbabc&scoped=true&lang=scss&"
import style1 from "./ModalOrderDerivative.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61edbabc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClose: require('/app/components/Icon/Close.vue').default,Button: require('/app/components/Button/Button.vue').default,IconInfo: require('/app/components/Icon/Info.vue').default,MiscMemberVirtualPortfolioTabButtonGroup: require('/app/components/Misc/Member/VirtualPortfolio/TabButtonGroup.vue').default,MiscMemberVirtualPortfolioInputSymbol: require('/app/components/Misc/Member/VirtualPortfolio/InputSymbol.vue').default,MiscMemberVirtualPortfolioValidationState: require('/app/components/Misc/Member/VirtualPortfolio/ValidationState.vue').default,MiscMemberVirtualPortfolioInputNumber: require('/app/components/Misc/Member/VirtualPortfolio/InputNumber.vue').default,InputCheckBox: require('/app/components/Input/CheckBox.vue').default,MiscMemberVirtualPortfolioNotation: require('/app/components/Misc/Member/VirtualPortfolio/Notation.vue').default,Button: require('/app/components/Button/Button.vue').default,Modal: require('/app/components/Modal/Modal.vue').default})
