const getDefaultState = () => {
  return {
    upcoming: [],
    delete: null
  }
}
const state = getDefaultState

const mutations = {
  setUpcoming (state, data) {
    state.upcoming = data
  },
  setDelete (state, data) {
    state.delete = data
  }
}

const actions = {
  async getUpcomingFavorite ({ commit }) {
    const response = await this.$apiMember
      .$get('api/set-fund/upcoming-favorite')
      .then((res) => {
        return res.data
      })
      .catch(() => [])
    commit('setUpcoming', response)
  },
  async addUpcomingFavourite ({ commit }, symbol) {
    try {
      const response = await this.$apiMember.post(
        `/api/set-fund/upcoming-favorite/${symbol}`
      )
      return {
        status: response.status,
        message: response.data.message
      }
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message
      }
    }
  },
  async removeUpcomingFavourite ({ commit }, symbol) {
    try {
      const response = await this.$apiMember.delete(
        `/api/set-fund/upcoming-favorite/${symbol}`
      )
      return {
        status: response.status,
        message: response.data.message
      }
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message
      }
    }
  }
}

const getters = {
  favouriteUpcomingSymbols: (state) => {
    return state.upcoming.map(item => item.symbol)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
