const getDefaultState = () => {
  return {
    pathMatch: [],
    conditionsList: [],
    stockDividendList: {
      overview: {},
      pricePerformance: {},
      conditionResult: {}
    },
    stockFundamentalList: {
      overview: {},
      pricePerformance: {},
      conditionResult: {}
    },
    stockGrowthList: {
      overview: {},
      pricePerformance: {},
      conditionResult: {}
    },
    stockCustomList: {
      overview: {},
      pricePerformance: {},
      conditionResult: {}
    }
  }
}

const state = getDefaultState

const mutations = {
  setPathMatch (state, data) {
    state.pathMatch = data
  },

  setConditionsList (state, data) {
    state.conditionsList = data
  },

  setStockDividendOverview (state, data) {
    state.stockDividendList.overview = data
  },

  setStockDividendPricePerformance (state, data) {
    state.stockDividendList.pricePerformance = data
  },

  setStockDividendConditionResult (state, data) {
    state.stockDividendList.conditionResult = data
  },

  setStockFundamentalOverview (state, data) {
    state.stockFundamentalList.overview = data
  },

  setStockFundamentalPricePerformance (state, data) {
    state.stockFundamentalList.pricePerformance = data
  },

  setStockFundamentalConditionResult (state, data) {
    state.stockFundamentalList.conditionResult = data
  },

  setStockGrowthOverview (state, data) {
    state.stockGrowthList.overview = data
  },

  setStockGrowthPricePerformance (state, data) {
    state.stockGrowthList.pricePerformance = data
  },

  setStockGrowthConditionResult (state, data) {
    state.stockGrowthList.conditionResult = data
  },

  setStockCustomOverview (state, data) {
    state.stockCustomList.overview = data
  },

  setStockCustomPricePerformance (state, data) {
    state.stockCustomList.pricePerformance = data
  },

  setStockCustomConditionResult (state, data) {
    state.stockCustomList.conditionResult = data
  }
}

const actions = {
  async getConditionsList ({ commit }, payload) {
    const { theme } = payload
    try {
      const response = await this.$axios.$get('/api/set/stock-screen/condition', {
        params: {
          lang: this.$i18n.locale,
          theme
        }
      })

      commit('setConditionsList', response)
    } catch (error) {
      commit('setConditionsList', [])
    }
  },

  async getStockDividendOverview ({ commit }, payload) {
    const { filterMode, conditions } = payload || {}

    const promise = filterMode === 'guidelineIAA'
      ? getOverviewByThemes(this, 'dividend')
      : getCustomOverview(this, conditions)

    const response = await promise

    commit('setStockDividendOverview', response)
  },

  async getStockDividendPricePerformance ({ commit }, payload) {
    const { filterMode, conditions } = payload || {}

    const promise = filterMode === 'guidelineIAA'
      ? getPricePerformanceByThemes(this, 'dividend')
      : getCustomPricePerformance(this, conditions)

    const response = await promise

    commit('setStockDividendPricePerformance', response)
  },

  async getStockDividendConditionResult ({ commit }, payload) {
    const { filterMode, conditions } = payload || {}

    const promise = filterMode === 'guidelineIAA'
      ? getConditionResultByThemes(this, 'dividend')
      : getCustomConditionResult(this, conditions)

    const response = await promise

    commit('setStockDividendConditionResult', response)
  },

  async getStockFundamentalOverview ({ commit }, payload) {
    const { filterMode, conditions } = payload || {}

    const promise = filterMode === 'guidelineIAA'
      ? getOverviewByThemes(this, 'fundamental')
      : getCustomOverview(this, conditions)

    const response = await promise

    commit('setStockFundamentalOverview', response)
  },

  async getStockFundamentalPricePerformance ({ commit }, payload) {
    const { filterMode, conditions } = payload || {}

    const promise = filterMode === 'guidelineIAA'
      ? getPricePerformanceByThemes(this, 'fundamental')
      : getCustomPricePerformance(this, conditions)

    const response = await promise

    commit('setStockFundamentalPricePerformance', response)
  },

  async getStockFundamentalConditionResult ({ commit }, payload) {
    const { filterMode, conditions } = payload || {}

    const promise = filterMode === 'guidelineIAA'
      ? getConditionResultByThemes(this, 'fundamental')
      : getCustomConditionResult(this, conditions)

    const response = await promise

    commit('setStockFundamentalConditionResult', response)
  },

  async getStockGrowthOverview ({ commit }, payload) {
    const { filterMode, conditions } = payload || {}

    const promise = filterMode === 'guidelineIAA'
      ? getOverviewByThemes(this, 'growth')
      : getCustomOverview(this, conditions)

    const response = await promise

    commit('setStockGrowthOverview', response)
  },

  async getStockGrowthPricePerformance ({ commit }, payload) {
    const { filterMode, conditions } = payload || {}

    const promise = filterMode === 'guidelineIAA'
      ? getPricePerformanceByThemes(this, 'growth')
      : getCustomPricePerformance(this, conditions)

    const response = await promise

    commit('setStockGrowthPricePerformance', response)
  },

  async getStockGrowthConditionResult ({ commit }, payload) {
    const { filterMode, conditions } = payload || {}

    const promise = filterMode === 'guidelineIAA'
      ? getConditionResultByThemes(this, 'growth')
      : getCustomConditionResult(this, conditions)

    const response = await promise

    commit('setStockGrowthConditionResult', response)
  },

  async getStockCustomOverview ({ commit }, payload) {
    const { conditions } = payload
    const response = await getCustomOverview(this, conditions)

    commit('setStockCustomOverview', response)
  },

  async getStockCustomPricePerformance ({ commit }, payload) {
    const { conditions } = payload
    const response = await getCustomPricePerformance(this, conditions)

    commit('setStockCustomPricePerformance', response)
  },

  async getStockCustomConditionResult ({ commit }, payload) {
    const { conditions } = payload
    const response = await getCustomConditionResult(this, conditions)

    commit('setStockCustomConditionResult', response)
  }
}

function getOverviewByThemes ({ $axios, $i18n: { locale } }, theme) {
  return $axios.$get(`/api/set/stock-screen/themes/${theme}/overview`, { params: { lang: locale } })
    .then(result => result)
    .catch(_error => ({}))
}

function getPricePerformanceByThemes ({ $axios, $i18n: { locale } }, theme) {
  return $axios.$get(`/api/set/stock-screen/themes/${theme}/price-performance`, { params: { lang: locale } })
    .then(result => result)
    .catch(_error => ({}))
}

function getConditionResultByThemes ({ $axios, $i18n: { locale } }, theme, conditions) {
  return $axios.$get(`/api/set/stock-screen/themes/${theme}/condition-result`, { params: { lang: locale } })
    .then(result => result)
    .catch(_error => ({}))

  // return $axios.$post(`/api/set/stock-screen/themes/${theme}/condition-result`, conditions, { params: { lang: locale } })
  //   .then(result => result)
  //   .catch(_error => ({}))
}

function getCustomOverview ({ $axios, $i18n: { locale } }, conditions) {
  return $axios.$post('/api/set/stock-screen/custom/overview', conditions, { params: { lang: locale } })
    .then(result => result)
    .catch(_error => ({}))
}

function getCustomPricePerformance ({ $axios, $i18n: { locale } }, conditions) {
  return $axios.$post('/api/set/stock-screen/custom/price-performance', conditions, { params: { lang: locale } })
    .then(result => result)
    .catch(_error => ({}))
}

function getCustomConditionResult ({ $axios, $i18n: { locale } }, conditions) {
  return $axios.$post('/api/set/stock-screen/custom/condition-result', conditions, { params: { lang: locale } })
    .then(result => result)
    .catch(_error => ({}))
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
