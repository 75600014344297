export function setLog (eventData) {
  const dayjs = require('dayjs')
  let eventTrackingList = []
  const cookieEventTracking = localStorage.getItem('event-tracking')
  if (cookieEventTracking) {
    const eventTracking = JSON.parse(decodeURI(cookieEventTracking))
    if (eventTracking && Array.isArray(eventTracking.eventTrackingList)) {
      eventTrackingList = eventTracking.eventTrackingList.slice(0, 20)
    } else {
      eventTrackingList = []
    }
  }
  eventTrackingList.unshift({
    ...eventData,
    created_at: dayjs().format('HH:mm:ss')
  })
  localStorage.setItem('event-tracking', encodeURI(JSON.stringify({
    expireDate: new Date(new Date(new Date().setHours(23)).setMinutes(59)),
    eventTrackingList
  })))
}
export function getLog () {
  return JSON.parse(decodeURI(localStorage.getItem('event-tracking'))).eventTrackingList
}
export function checkForResetData () {
  if (localStorage.getItem('event-tracking')) {
    const eventTrack = JSON.parse(decodeURI(localStorage.getItem('event-tracking')))
    if (eventTrack) {
      const expireDate = eventTrack.expireDate
      if (new Date() > new Date(expireDate)) {
        localStorage.removeItem('event-tracking')
      }
    }
  }
}
